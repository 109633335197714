const APP_UPDATE_AVAILABLE_EVENT = 'SPIR__APP_UPDATE_AVAILABLE'

type Message = { buildDate: number }

function updateApp() {
  window.location.reload()
}

const broadcastChannelIsSupported = 'BroadcastChannel' in window

export function setupAppUpdateAvailableHandler(guardAppUpdate: (updateApp: () => void) => void) {
  if (!broadcastChannelIsSupported) {
    return
  }

  function handleAppUpdateAvailable(event: MessageEvent<Message>) {
    // @ts-expect-error TS2345
    const notificationIsNeeded = event.data.buildDate > parseInt(process.env.VUE_APP_BUILD_DATE)
    if (notificationIsNeeded) {
      guardAppUpdate(updateApp)
    }
  }

  const channel = new BroadcastChannel(APP_UPDATE_AVAILABLE_EVENT)
  channel.addEventListener('message', handleAppUpdateAvailable)
}

export function noticeAppUpdateAvailable() {
  if (!broadcastChannelIsSupported) {
    return
  }
  const channel = new BroadcastChannel(APP_UPDATE_AVAILABLE_EVENT)
  // @ts-expect-error TS2345
  const message: Message = { buildDate: parseInt(process.env.VUE_APP_BUILD_DATE) }
  channel.postMessage(message)
}
