<template>
  <div class="auth-form-frame" :class="{ 'no-support': isNotSupported }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthFormFrame',
  props: {
    isNotSupported: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped lang="scss">
.auth-form-frame {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.no-support {
    max-width: 400px;
    width: 90%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
