import { render, staticRenderFns } from "./SpirDropdownItem.vue?vue&type=template&id=3d1e0d38&scoped=true&"
import script from "./SpirDropdownItem.vue?vue&type=script&lang=ts&"
export * from "./SpirDropdownItem.vue?vue&type=script&lang=ts&"
import style0 from "./SpirDropdownItem.vue?vue&type=style&index=0&id=3d1e0d38&prod&scoped=true&lang=scss&"
import style1 from "./SpirDropdownItem.vue?vue&type=style&index=1&id=3d1e0d38&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_tokc7palmetupskgeg32hwtjve/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d1e0d38",
  null
  
)

export default component.exports