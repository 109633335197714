import { Event, EventAttendee, OnlineMeeting } from '@spirinc/contracts'

export class EventModel {
  id?: string
  accountId?: string
  calendarId?: string
  summary?: string
  location?: string
  start: string
  end: string
  description?: string
  visibility?: 'default' | 'public' | 'private' | 'confidential'
  allDay?: boolean
  onlineMeeting?: OnlineMeeting
  attendees?: Array<EventAttendee>
  /**
   * 参加者に登録されているうち、会議室等のリソース
   */
  resources?: Array<EventAttendee>
  attendeeFlag?: 'needsAction' | 'declined' | 'tentative' | 'accepted'
  /**
   * カレンダーに書き込み出来る場合に、更新・削除可能なイベントかどうか。
   */
  writable?: boolean
  confirmed?: boolean
  constructor(data?: Event) {
    this.id = data?.id
    this.accountId = data?.accountId
    this.calendarId = data?.calendarId
    this.summary = data?.summary
    // @ts-expect-error TS2322
    this.start = data?.start
    // @ts-expect-error TS2322
    this.end = data?.end
    this.description = data?.description
    this.visibility = data?.visibility
    this.allDay = data?.allDay
    this.onlineMeeting = data?.onlineMeeting
    this.attendees = data?.attendees
    this.resources = data?.resources
    this.attendeeFlag = data?.attendeeFlag
    this.writable = data?.writable
  }
}
