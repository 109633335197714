import {
  DefaultOnlineMeetingConnectResult,
  DefaultOnlineMeetingToolResult,
  ICalendar,
  IOnlineMeeting,
  OnlineMeetingType
} from '@/types'

const decideOnlineMeeting = (
  calendar: ICalendar,
  isConnectedZoom?: boolean,
  previousOnlineMeeting?: IOnlineMeeting
): DefaultOnlineMeetingToolResult => {
  const returnOnlineMeetingResult = (
    onlineMeetingType: OnlineMeetingType,
    result: DefaultOnlineMeetingConnectResult = DefaultOnlineMeetingConnectResult.DEFAULT_ONLINE_MEETING_CONNECTED
  ): DefaultOnlineMeetingToolResult => {
    return {
      onlineMeeting: {
        type: onlineMeetingType
      },
      result
    }
  }
  //初回の場合
  if (!previousOnlineMeeting) {
    if (isConnectedZoom) {
      return returnOnlineMeetingResult(OnlineMeetingType.zoom)
    }
    if (calendar.availableOnlineMeetings.length > 0) {
      return returnOnlineMeetingResult(calendar.availableOnlineMeetings[0])
    }
    return returnOnlineMeetingResult(OnlineMeetingType.none)
  }
  //2回目以降の場合
  if (previousOnlineMeeting.type === OnlineMeetingType.none) {
    return returnOnlineMeetingResult(OnlineMeetingType.none)
  }
  //前回Zoomだった
  if (previousOnlineMeeting.type === OnlineMeetingType.zoom) {
    //今回もZoomが使える
    if (isConnectedZoom) {
      return returnOnlineMeetingResult(OnlineMeetingType.zoom)
    }
    //今回はZoomが使えない
    if (!isConnectedZoom) {
      if (calendar.availableOnlineMeetings.length > 0) {
        return returnOnlineMeetingResult(
          calendar.availableOnlineMeetings[0],
          DefaultOnlineMeetingConnectResult.DEFAULT_ONLINE_MEETING_DISCONNECTED
        )
      }
      return returnOnlineMeetingResult(
        OnlineMeetingType.none,
        DefaultOnlineMeetingConnectResult.DEFAULT_ONLINE_MEETING_DISCONNECTED
      )
    }
  }
  //初回でもなく前回Zoomでもない（つまりMeetかTeams）
  return returnOnlineMeetingResult(
    calendar.availableOnlineMeetings ? calendar.availableOnlineMeetings[0] : OnlineMeetingType.none
  )
}

export { decideOnlineMeeting }
