<template>
  <b-button
    :id="fieldId"
    :expanded="expanded"
    :icon-right="active ? 'chevron-up' : 'chevron-down'"
    class="spir-dropdown-button is-justify-content-space-between"
    :class="[{ active: active }, size]"
    @click="$emit('click')"
  >
    <slot />
  </b-button>
</template>
<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdownButton',
  props: {
    fieldId: {
      type: String
    },
    active: {
      type: Boolean,
      required: true
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large' | 'x-large'>,
      default: 'medium'
    },
    expanded: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style lang="scss">
.spir-dropdown-button.button {
  color: $spir2_black;
  height: 32px;
  border-radius: 6px;
  &.small {
    height: 24px;
    padding: 6px 8px;
    font-size: 12px;
  }
  &.large {
    height: 40px;
    padding: 12px;
  }
  &.x-large {
    height: 48px;
    padding: 12px 16px;
  }
  @mixin active_color {
    box-shadow: none;
    border-color: $spir2_primaryBlue;
    color: $spir2_black;
  }
  border-color: $spir2_border;
  &:hover {
    border-color: $spir2_border;
    background-color: $spir2_bg;
    color: $spir2_black;
  }
  &.active,
  &:active {
    @include active_color;
  }
  &:focus-visible {
    @include active_color;
    outline: none;
  }
  &:focus:not(:active) {
    @include active_color;
  }
  > span {
    // このspanの子要素(slot)内にtext-overflowが指定されていることがあるため指定
    min-width: 0;
    flex-grow: 1;
    text-align: left;
  }
  > .icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: auto;
    height: auto;
    margin: 0;
    &:last-child:not(:first-child) {
      margin: 0;
    }
    i.mdi {
      display: flex;
      justify-content: center;
      align-items: center;
      &::before {
        width: 17px;
        font-size: 18px;
        color: $spir2_gray;
      }
    }
  }
}
</style>
