<template>
  <div class="attendee-item" :class="{ clickable: attendee.id }" @click="openCreatorProfile">
    <AttendeeIcon
      :photoURL="userPhoto"
      :fullName="userName"
      :hideTooltip="true"
      :iconSize="iconSize"
      :attendanceStatus="attendee.responseStatus"
    />
    <span class="name">{{ userName }}</span>
  </div>
</template>

<script lang="ts">
import AttendeeIcon from '@/components/features/attendees/AttendeeIcon/index.vue'
import ProfileContentModal from '@/components/modal/ProfileContent.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IAttendee } from '../../types/schedule'

@Component({
  components: {
    AttendeeIcon
  }
})
export default class ProfileBadge extends Vue {
  // @ts-expect-error TS2564
  @Prop() attendee: IAttendee
  // @ts-expect-error TS2564
  @Prop() iconSize: number

  get userName() {
    return this.attendee.fullName || this.attendee.email
  }
  get userPhoto() {
    return this.attendee.photoURL
  }
  openCreatorProfile() {
    if (!this.attendee.id) {
      return
    }
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ProfileContentModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        id: this.attendee.id
      },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
}
</script>

<style scoped lang="scss">
.attendee-item {
  align-items: center;
  display: flex;
  min-height: 30px;
  width: 100%;
  i {
    margin-left: auto;
    display: none;
  }
}
.clickable {
  cursor: pointer;
  i {
    display: block;
  }
}
</style>
