<template>
  <div class="uploader">
    <FileUploader :onFileChange="handleFileChange">
      <MaterialDesignIcon icon="pencil" size="s" />
    </FileUploader>
  </div>
</template>

<script lang="ts">
import { FileUploader } from '@/components/ui/layouts/FileUploader'
import { MaterialDesignIcon } from '@/components/ui/layouts/MaterialDesignIcon'
import { useDialog } from '@/composables/useDialog'
import { defineComponent, PropType } from '@vue/composition-api'
import { checkFileSize, KB } from './misc'

export default defineComponent({
  name: 'ProfileImageUploader',
  components: {
    FileUploader,
    MaterialDesignIcon
  },
  props: {
    sizeLimitKB: {
      type: Number,
      default: 100
    },
    onFileChange: {
      type: Function as PropType<(file: File) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const { openAlertDialog } = useDialog()

    function openAlert() {
      openAlertDialog({ message: `File size exceeded the maximum size of ${props.sizeLimitKB / KB}MB` })
    }

    function handleFileChange(file: File) {
      checkFileSize({
        file,
        sizeLimitKB: props.sizeLimitKB,
        onFileSizeExceed: openAlert,
        onFileChange: props.onFileChange
      })
    }

    return {
      handleFileChange
    }
  }
})
</script>

<style lang="scss" scoped>
.uploader {
  width: 25px;
  height: 25px;
  background: white;
  border: 1px solid lightgrey;
  border-radius: 50%;
  cursor: pointer;
  span.icon {
    color: grey;
  }
}
</style>
