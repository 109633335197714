import { AuthGuestRegistrationModal } from '@/components/features/auth/AuthGuestRegistrationModal'
import { useModal } from '@/composables/useModal'

export const useAuthGuestRegistrationModal = () => {
  const { openModal } = useModal()

  function openAuthGuestRegistrationModal(data: { title: string }) {
    openModal({
      component: AuthGuestRegistrationModal,
      hasModalCard: true,
      props: {
        title: data.title
      }
    })
  }

  return {
    openAuthGuestRegistrationModal
  }
}
