import { isActive } from '@/lib/utils'
import { SubscriptionStatus } from '@/models'
import { Plan, TeamId } from '@/models/team'
import { TeamFeatureUsabilityStatus } from '@/models/teamFeatureUsability'
import store from '@/store'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'

const MODULE_NAME = 'TeamPlanStatePlan'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TeamPlanStateStore extends VuexModule {
  private table: Record<TeamId, Plan> = {}
  private usableTable: Record<TeamId, boolean> = {}
  private alertingMenuTable: Record<TeamId, { admin: boolean; member: boolean }> = {}

  get plan(): (teamId: TeamId) => Plan | undefined {
    return (teamId: string) => {
      return this.table[teamId]
    }
  }

  get checkUsableStatus(): (teamId: TeamId) => TeamFeatureUsabilityStatus {
    return (teamId: string) => {
      const found = this.usableTable[teamId]
      return found !== undefined ? { status: 'loaded', result: found } : { status: 'loading' }
    }
  }

  get usable(): (teamId: TeamId) => boolean {
    return (teamId: TeamId) => {
      const status = this.checkUsableStatus(teamId)
      return status.status === 'loading' ? true : status.result
    }
  }

  get isMenuAlerting(): (data: { teamId: TeamId; isAdmin: boolean }) => boolean {
    return ({ teamId, isAdmin }: { teamId: TeamId; isAdmin: boolean }) => {
      const found = this.alertingMenuTable[teamId]
      return typeof found === 'object' ? (isAdmin ? found.admin : found.member) : false
    }
  }

  @Mutation
  setUsable({ teamId, result }: { teamId: TeamId; result: boolean }) {
    this.usableTable = { ...this.usableTable, [teamId]: result }
  }

  @Mutation
  setState({ teamId, plan }: { teamId: TeamId; plan: Plan }) {
    this.table = { ...this.table, [teamId]: plan }
  }

  @Mutation
  setAlertingMenu({ teamId, result }: { teamId: TeamId; result: { admin: boolean; member: boolean } }) {
    this.alertingMenuTable = { ...this.alertingMenuTable, [teamId]: result }
  }

  @Mutation
  clearAll() {
    this.table = {}
    this.usableTable = {}
    this.alertingMenuTable = {}
  }

  @Action
  checkAndSetUsable({ teamId, active }: { teamId: TeamId; active: boolean }) {
    this.setUsable({ teamId, result: active })
  }

  @Action
  checkAndSetAlertingMenu({ teamId, plan }: { teamId: TeamId; plan: Plan }) {
    if (plan.type === 'free') return
    const subscriptionStatus = plan.state.status
    const adminAlertingStatuses: SubscriptionStatus[] = [
      SubscriptionStatus.trialWithoutSetup,
      SubscriptionStatus.canceling,
      SubscriptionStatus.canceled,
      SubscriptionStatus.pastDue
    ]
    const memberAlertingStatuses: SubscriptionStatus[] = [SubscriptionStatus.canceling, SubscriptionStatus.canceled]
    const adminResult = adminAlertingStatuses.includes(subscriptionStatus)
    const memberResult = memberAlertingStatuses.includes(subscriptionStatus)
    this.setAlertingMenu({ teamId, result: { admin: adminResult, member: memberResult } })
  }

  @Action
  save({ teamId, plan }: { teamId: TeamId; plan: Plan }) {
    this.setState({ teamId, plan })
    this.checkAndSetUsable({
      teamId,
      active: isActive(plan)
    })
    this.checkAndSetAlertingMenu({ teamId, plan })
  }
}

export const TeamPlanStateModule = getModule(TeamPlanStateStore)
