import { render, staticRenderFns } from "./CopyUrlAndHtml.vue?vue&type=template&id=2a4c8d0d&"
import script from "./CopyUrlAndHtml.vue?vue&type=script&lang=ts&"
export * from "./CopyUrlAndHtml.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_tokc7palmetupskgeg32hwtjve/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports