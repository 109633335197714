<template>
  <SpirModalFrame>
    <div class="schedule-detail-modal">
      <ClosingPageheadTitleBox
        :canCloseEvenUsingMobile="true"
        :title="$t('message.createdSchedule', { type: $t(`labels.${type}`) })"
        :onClose="handleClose"
      />
      <Divider />
      <ListItemSpacer>
        <CopyUrlAndHtml
          :activeCandidates="activeCandidates"
          :title="schedule.title"
          :duration="schedule.duration"
          :scheduleType="schedule.type"
          :canHtmlCopy="canHtmlCopy"
          :urlForConfirm="urlForConfirm"
        />
      </ListItemSpacer>
    </div>
  </SpirModalFrame>
</template>

<script lang="ts">
import CopyUrlAndHtml from '@/components/organisms/CopyUrlAndHtml.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import Divider from '@/components/ui/layouts/Divider/index.vue'
import ListItemSpacer from '@/components/ui/layouts/ListItemSpacer/index.vue'
import { SpirModalFrame } from '@/components/ui/spir/SpirModalFrame'
import { PollModel, ScheduleModelCommon } from '@/models/data'
import { ListType } from '@/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'ScheduleDetailModal',
  components: {
    CopyUrlAndHtml,
    Divider,
    ClosingPageheadTitleBox,
    SpirModalFrame,
    ListItemSpacer
  },
  props: {
    schedule: {
      type: Object as PropType<ScheduleModelCommon | PollModel>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { schedule } = toRefs(props)
    const type = computed(() => {
      return schedule.value.type
    })
    const canHtmlCopy = computed(() => {
      return schedule.value.type === ListType.SCHEDULE
    })
    const activeCandidates = computed(() => {
      if (schedule.value instanceof ScheduleModelCommon) {
        return schedule.value.activeCandidates
      }
      return []
    })
    const urlForConfirm = computed(() => {
      return schedule.value.urlForConfirmer
    })
    function handleClose() {
      emit('close')
    }
    return {
      type,
      canHtmlCopy,
      activeCandidates,
      urlForConfirm,
      handleClose
    }
  }
})
</script>
