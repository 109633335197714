<template>
  <div class="invalidate-queries-listener" />
</template>

<script lang="ts">
import { EVENTS, EventBus } from '@/lib/eventBus'
import { useQueryClient } from '@tanstack/vue-query'
import { defineComponent, onMounted, onUnmounted } from '@vue/composition-api'

export default defineComponent({
  name: 'InvalidateQueriesListener',
  setup() {
    const queryClient = useQueryClient()

    function invalidateQueries(args: { queryKey: string[]; exact?: boolean }) {
      queryClient.invalidateQueries({
        queryKey: args.queryKey,
        exact: args.exact ?? true,
        refetchType: 'none'
      })
    }
    onMounted(() => {
      EventBus.on(EVENTS.INVALIDATE_QUERIES, invalidateQueries)
    })
    onUnmounted(() => {
      EventBus.off(EVENTS.INVALIDATE_QUERIES, invalidateQueries)
    })
    return {}
  }
})
</script>

<style lang="scss" scoped>
.invalidate-queries-listener {
  position: absolute;
}
</style>
