import { patchMemberCalendar } from '@/lib/api/team'
import { FeatureUsableMarkedTeamCalendar, TeamMemberCalendar } from '@/models/data/team'
import store from '@/store'
import { MemberCalendar } from '@spirinc/contracts'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import EditAvailabilityModule from './editAvailability'
import EditAvailabilityTeamModule from './editAvailabilityTeam'
import TeamRecordModule from './teamRecord'
import { TeamPlanStateModule } from './teamSubscriptionState'

const MODULE_NAME = 'TeamCalendar'

const KEY_SPLITTER = '$$$'

export const decodeTeamCalendarKey = (teamCalendarKey: string) => {
  const split = teamCalendarKey.split(KEY_SPLITTER)
  return {
    teamId: split[0],
    memberId: split[1]
  }
}

export const encodeTeamCalendarKey = (teamId, memberId) => {
  return [teamId, memberId].join(KEY_SPLITTER)
}
export interface IModuleTeamCalendar {
  _teamCalendars: TeamMemberCalendar[]
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class TeamCalendar extends VuexModule {
  _teamCalendars: TeamMemberCalendar[] = []
  get memberCalendarByTeamAndMemberId() {
    return (teamId: string, memberId: string): MemberCalendar => {
      const key = encodeTeamCalendarKey(teamId, memberId)
      return this.allTeamMembers[key]
    }
  }
  get teamCalendars(): TeamMemberCalendar[] {
    return this._teamCalendars
  }
  get featureUsableMarkedTeamCalendars(): FeatureUsableMarkedTeamCalendar[] {
    return this.teamCalendars.map(tc => ({
      calendar: tc,
      usable: TeamPlanStateModule.usable(tc.id)
    }))
  }
  get usableTeamCalendars(): FeatureUsableMarkedTeamCalendar[] {
    return this.featureUsableMarkedTeamCalendars.filter(tc => tc.usable)
  }
  get allTeamMembers(): { [key: string]: MemberCalendar } {
    const returnValue = {}
    this.usableTeamCalendars.forEach(markedTc => {
      const tc = markedTc.calendar
      return tc.memberCalendars.forEach(memberCalendar => {
        const key = encodeTeamCalendarKey(tc.id, memberCalendar.id)
        returnValue[key] = memberCalendar
      })
    })
    return returnValue
  }
  get allTeamMembersWithEmailArray(): Array<{ teamId: string; member: MemberCalendar & { email: string } }> {
    const returnValue: Array<{ teamId: string; member: MemberCalendar & { email: string } }> = []
    this.usableTeamCalendars.forEach(markedTc => {
      const tc = markedTc.calendar
      tc.memberCalendars.forEach(memberCalendar => {
        const memberInfo = TeamRecordModule.memberInfo(tc.id, memberCalendar.id)
        // todo: 現在チームカレンダーと、チーム情報が分けれていて、メンバーのEmailの取得にはチーム情報を参照する必要がある。
        // そのため、チーム情報がまだ取得できていない場合はチームメンバーが表示されない。
        // チームカレンダーにEmailをいれる対応をしたら書き方を変えるべき。
        if (!memberInfo) {
          return
        }
        returnValue.push({
          teamId: tc.id,
          member: {
            ...memberCalendar,
            // @ts-expect-error TS2322
            email: memberInfo.email
          }
        })
      })
    })
    return returnValue
  }
  get visibleTeamMembers(): { [key: string]: MemberCalendar } {
    const returnValue = {}
    this.usableTeamCalendars.forEach(markedTc => {
      const tc = markedTc.calendar
      const visibleMemberCalendars = tc.memberCalendars.filter(mc => mc.visible)
      visibleMemberCalendars.forEach(memberCalendar => {
        const key = encodeTeamCalendarKey(tc.id, memberCalendar.id)
        returnValue[key] = memberCalendar
      })
    })
    return returnValue
  }
  get visibleTeamMemberKeys(): string[] {
    // 個人の公開URLの編集モードでは、普通Calendarは表示しない。
    if (EditAvailabilityModule.showCalendars) {
      return []
    }
    if (EditAvailabilityTeamModule.showMemberCalendars) {
      return EditAvailabilityTeamModule.showMemberCalendars.map(c =>
        encodeTeamCalendarKey(EditAvailabilityTeamModule.getModel.teamId, c)
      )
    }
    return Object.keys(this.visibleTeamMembers)
  }
  @Action
  async toggleMemberCalendar({ teamId, memberId }: { teamId: string; memberId: string }) {
    const findMemberCalendar = this.teamCalendars
      .find(t => t.id === teamId)
      ?.memberCalendars.find(mc => mc.id === memberId)
    if (!findMemberCalendar) {
      return
    }
    const toggledVisible = !findMemberCalendar.visible
    try {
      // hack. in order to fetch calendar events.
      this.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, visible: toggledVisible, isLoading: true })
      const response = await patchMemberCalendar(teamId, memberId, { visible: toggledVisible })
      this.SET_TEAM_MEMBER_CALENDAR({ teamId, memberCalendar: response })
    } catch (e) {
      this.SET_TEAM_MEMBER_CALENDAR({ teamId, memberCalendar: findMemberCalendar })
      // on visible and there was any error. then, set false loading status
      if (toggledVisible) {
        this.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, isLoading: false })
      }
    }
  }
  @Action
  async changeMemberCalendarColor({ teamId, memberId, color }: { teamId: string; memberId: string; color: string }) {
    const foundMemberCalendar = this.memberCalendarByTeamAndMemberId(teamId, memberId)
    if (!foundMemberCalendar) {
      return
    }
    try {
      this.SET_TEAM_MEMBER_CALENDAR({ teamId, memberCalendar: { ...foundMemberCalendar, backgroundColor: color } })
      // hack. in order to fetch calendar events.
      this.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, visible: foundMemberCalendar.visible, isLoading: true })
      const response = await patchMemberCalendar(teamId, memberId, { color })
      this.SET_TEAM_MEMBER_CALENDAR({ teamId, memberCalendar: response })
    } catch (e) {
      this.SET_TEAM_MEMBER_CALENDAR({ teamId, memberCalendar: foundMemberCalendar })
      this.SET_MEMBER_CALENDAR_LOADING({ teamId, memberId, isLoading: false })
    }
  }
  @Mutation
  SET_TEAM_CALENDARS(teamCalendars: TeamMemberCalendar[]) {
    this._teamCalendars = teamCalendars
  }
  @Mutation
  SET_TEAM_MEMBER_CALENDAR({ teamId, memberCalendar }: { teamId: string; memberCalendar: MemberCalendar }) {
    const teamCalendarIndex = this._teamCalendars.findIndex(t => t.id === teamId)
    if (teamCalendarIndex < 0) {
      return
    }
    const teamCalendar = { ...this._teamCalendars[teamCalendarIndex] }
    const memberCalendarIndex = teamCalendar.memberCalendars.findIndex(mc => mc.id === memberCalendar.id)
    teamCalendar.memberCalendars[memberCalendarIndex] = memberCalendar
    this._teamCalendars.splice(teamCalendarIndex, 1, teamCalendar)
  }
  @Mutation
  SET_MEMBER_CALENDAR_LOADING({
    teamId,
    memberId,
    isLoading,
    visible
  }: {
    teamId: string
    memberId: string
    isLoading?: boolean
    visible?: boolean
  }) {
    const teamCalendarIndex = this._teamCalendars.findIndex(t => t.id === teamId)
    if (teamCalendarIndex < 0) {
      return
    }
    const teamCalendar = { ...this._teamCalendars[teamCalendarIndex] }
    const memberCalendarIndex = teamCalendar.memberCalendars.findIndex(mc => mc.id === memberId)
    if (memberCalendarIndex < 0) {
      return
    }
    teamCalendar.memberCalendars[memberCalendarIndex] = {
      ...teamCalendar.memberCalendars[memberCalendarIndex],
      isLoading,
      visible: visible ?? teamCalendar.memberCalendars[memberCalendarIndex].visible
    }
    this._teamCalendars.splice(teamCalendarIndex, 1, teamCalendar)
  }
  @Mutation
  RESET_STATE() {
    this._teamCalendars = []
  }
}

export default getModule(TeamCalendar)
