<template>
  <div class="add-calendar-description-item">
    <b-icon class="icon" :icon="icon" custom-size="mdi-18px" />
    <div>
      <p class="caption">{{ caption }}</p>
      <ListItemSpacer top="8" />
      <p class="description">{{ description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import ListItemSpacer from '@/components/ui/layouts/ListItemSpacer/index.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    ListItemSpacer
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    caption: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.add-calendar-description-item {
  display: flex;
  flex-direction: row;
  .icon {
    margin-right: 16px;
    justify-content: flex-start;
    color: $spir2_gray;
  }
  p.caption {
    color: $spir2_black;
    @include inter_font_setting($font_weight: 600);
  }
  p.description {
    color: $spir2_black;
    @include font_setting_12($font_weight: 400);
  }
}
</style>
