<template>
  <div>
    <div class="modal-input__field">
      <div class="label-wrapper">
        <div class="mr-2">
          <b-icon icon="account-multiple" class="label-icon" />
        </div>
        <label class="label">{{ $t('message.confirm.attendeeInputSubLabel') }}</label>
      </div>
    </div>
    <AttendeeItem
      :ignoreEmails="ignoreEmails"
      :updateHeightWhenDropdownIsActive="true"
      @confirm="handleConfirm"
      @delete="handleDelete"
    />
  </div>
</template>

<script lang="ts">
import AttendeeItem from '@/components/forms/AttendeeItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

type Item = {
  email: string
  name: string
}

@Component({
  components: {
    AttendeeItem
  }
})
export default class AttendeeInput extends Vue {
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  items: Item[] = []

  get getItems() {
    return this.items
  }
  handleConfirm(item: Item) {
    this.items = [...this.items, item]
    this.$emit('confirm', item)
  }
  handleDelete(index: number) {
    this.items = [...this.items.slice(0, index), ...this.items.slice(index + 1, this.items.length)]
    this.$emit('delete', index)
  }
}
</script>
<style scoped lang="scss">
.label-wrapper {
  display: flex;
  margin-bottom: 12px;

  .label {
    color: $spir2_gray;
    font-size: 12px;
    font-weight: 700;
  }

  .label-icon {
    height: 20px;
    width: 20px;
    margin-right: 12px;
    color: $spir2_black;
  }
}
.account-icon {
  color: $spir2_gray;
}
.modal-input__field {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
