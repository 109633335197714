export const SubscriptionStatus = {
  active: 'active',
  canceled: 'canceled',
  trialing: 'trialing',
  canceling: 'canceling',
  pastDue: 'pastDue',
  trialWithoutSetup: 'trialWithoutSetup'
} as const
export type SubscriptionStatus = keyof typeof SubscriptionStatus

export const CancelReasons = ['userCancel', 'paymentFailed', 'trialExpired', 'unknownReason'] as const
export type CancelReason = (typeof CancelReasons)[number]
