import { ScheduleModelTeam } from '@/models/data'
import { ScheduleStatus } from '@/types'
import {
  Event,
  TeamsTeamIdSchedulesGetResponse,
  TeamsTeamIdSchedulesPostRequest,
  TeamsTeamIdSchedulesScheduleIdDispatchPostRequestConfirm,
  TeamsTeamIdSchedulesScheduleIdGetResponse,
  TeamsTeamIdSchedulesScheduleIdPutRequest
} from '@spirinc/contracts'
import qs from 'qs'
import axiosInstance from '../axios'

export const create = async (teamId: string, schedule: ScheduleModelTeam) => {
  const payload: TeamsTeamIdSchedulesPostRequest = schedule.payload
  return (await axiosInstance.post(`teams/${teamId}/schedules`, payload)).data
}

export const getList = async (
  teamId: string,
  option: { status?: ScheduleStatus[]; organizerMemberId?: string }
): Promise<TeamsTeamIdSchedulesGetResponse> => {
  const queryParams = { ...option, limit: 1000 }
  return (
    await axiosInstance.get(`teams/${teamId}/schedules`, {
      params: queryParams,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  ).data
}

export const updateSchedule = async (schedule: ScheduleModelTeam) => {
  const payload: TeamsTeamIdSchedulesScheduleIdPutRequest = schedule.payload
  return (await axiosInstance.patch(`teams/${schedule.teamId}/schedules/${schedule.id}`, payload)).data
}
export const deleteSchedule = async (teamId: string, scheduleId: string) => {
  return (
    await axiosInstance.post(`teams/${teamId}/schedules/${scheduleId}/dispatch`, {
      action: 'delete'
    })
  ).data
}
export const deleteCandidate = async (teamId: string, scheduleId: string, candidateId: string) => {
  return (await axiosInstance.delete(`teams/${teamId}/schedules/${scheduleId}/candidates/${candidateId}`)).data
}
export const getSchedule = async (
  teamId: string,
  scheduleId: string
): Promise<TeamsTeamIdSchedulesScheduleIdGetResponse> => {
  return (await axiosInstance.get(`teams/${teamId}/schedules/${scheduleId}`)).data
}

/**
 * @deprecated
 * use src/infrastructures/apis/teamSchedule.ts#getPublicTeamScheduleById
 */
export const getPublicSchedule = async (
  teamId: string,
  scheduleId: string
): Promise<TeamsTeamIdSchedulesScheduleIdGetResponse> => {
  return (await axiosInstance.get(`public/teams/${teamId}/schedules/${scheduleId}`)).data
}
export const getConfirmedEvent = async (teamId: string, scheduleId: string): Promise<Event> => {
  return (await axiosInstance.get(`public/teams/${teamId}/schedules/${scheduleId}/confirmedEvent`)).data
}

/**
 * @deprecated
 * if public api, use src/infrastructures/apis/teamSchedule.ts#confirmAsGuest
 * if private api, create
 */
export const confirm = async (
  teamId: string,
  scheduleId: string,
  payload: Omit<TeamsTeamIdSchedulesScheduleIdDispatchPostRequestConfirm, 'action'>
) => {
  let endpoint = `teams/${teamId}/schedules/${scheduleId}/dispatch`
  if (payload.confirmer.type === 'guest') {
    endpoint = `public/${endpoint}`
  }
  return (
    await axiosInstance.post(endpoint, {
      action: 'confirm',
      ...payload
    })
  ).data
}
