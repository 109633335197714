import { updateAttendeeStatus as updateAttendeeStatusAPICaller } from '@/infrastructures/apis/event'
import { APIError } from '@/lib/sdk/error'
import { Result } from '@/lib/utils/result'
import { AccountsAccountIdCalendarsCalendarIdEventsEventIdAttendeesStatusPatchError } from '@spirinc/contracts'
import { ERROR_CODE } from '@spirinc/message-catalog'

export async function updateAttendeeStatus(data: {
  accountId: string
  calendarId: string
  eventId: string
  responseStatus: 'declined' | 'tentative' | 'accepted'
}): Promise<
  Result<
    undefined,
    {
      type:
        | 'account_not_found'
        | 'calendar_event_not_found'
        | 'attendee_not_found'
        | 'calendar_not_found'
        | 'invalid_calendar'
        | 'invalid_token'
        | 'user_not_found'
        | 'no_calendar_invitation_email_address'
    }
  >
> {
  try {
    await updateAttendeeStatusAPICaller(data)
    return { status: 'success', value: undefined }
  } catch (e: unknown) {
    if (e instanceof APIError) {
      const code = e.code as AccountsAccountIdCalendarsCalendarIdEventsEventIdAttendeesStatusPatchError['code']
      switch (code) {
        case ERROR_CODE.ACCOUNT_NOT_FOUND:
          return { status: 'failure', error: { type: 'account_not_found' } }
        case ERROR_CODE.CALENDAR_EVENT_NOT_FOUND:
          return { status: 'failure', error: { type: 'calendar_event_not_found' } }
        case ERROR_CODE.ATTENDEE_NOT_FOUND:
          return { status: 'failure', error: { type: 'attendee_not_found' } }
        case ERROR_CODE.CALENDAR_NOT_FOUND:
          return { status: 'failure', error: { type: 'calendar_not_found' } }
        case ERROR_CODE.INVALID_CALENDAR:
          return { status: 'failure', error: { type: 'invalid_calendar' } }
        case ERROR_CODE.INVALID_TOKEN:
          return { status: 'failure', error: { type: 'invalid_token' } }
        case ERROR_CODE.USER_NOT_FOUND:
          return { status: 'failure', error: { type: 'user_not_found' } }
        case ERROR_CODE.NO_CALENDAR_INVITATION_EMAIL_ADDRESS:
          return { status: 'failure', error: { type: 'no_calendar_invitation_email_address' } }
        default:
          throw e
      }
    } else {
      throw e
    }
  }
}
