import { createVisitorIdentificationToken } from '../api/hubspot'

class HubspotService {
  async setUser() {
    if (window.HubSpotConversations === undefined) {
      this.addHubspotReadyCallback(() => this.setUser())
      return
    }
    const result = await createVisitorIdentificationToken()
    if (result.type === 'error') return
    window.hsConversationsSettings = {
      identificationEmail: result.value.email,
      identificationToken: result.value.token
    }
    window.HubSpotConversations.clear({ resetWidget: true })
  }

  unsetUser() {
    if (window.HubSpotConversations === undefined) {
      this.addHubspotReadyCallback(() => this.unsetUser())
      return
    }
    window.hsConversationsSettings = {}
    window.HubSpotConversations.clear({ resetWidget: true })
  }

  showMessenger() {
    if (window.HubSpotConversations === undefined) {
      this.addHubspotReadyCallback(() => this.showMessenger())
      return
    }
    window.HubSpotConversations.widget.open()
  }

  onBadgeChanged(callback: (unreadCount: number) => void) {
    if (window.HubSpotConversations === undefined) {
      this.addHubspotReadyCallback(() => this.onBadgeChanged(callback))
      return
    }
    window.HubSpotConversations.on('unreadConversationCountChanged', payload => callback(payload.unreadCount))
  }

  refresh() {
    if (window.HubSpotConversations === undefined) {
      this.addHubspotReadyCallback(() => this.refresh())
      return
    }
    window.HubSpotConversations.widget.refresh()
  }

  private addHubspotReadyCallback(callback: () => void) {
    window.hsConversationsOnReady = [...(window.hsConversationsOnReady ?? []), callback]
  }
}

export const hubspotService = new HubspotService()
