import * as Sentry from '@sentry/vue'
import { AxiosError, AxiosHeaders, type CreateAxiosDefaults } from 'axios'

export const createAxiosDefaults: CreateAxiosDefaults = {
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 90000 // 90秒
}

interface CustomHeadersArgs {
  firebaseUser: { uid: string; idToken: string }
}
export const getCustomHeaders = (args: CustomHeadersArgs): AxiosHeaders => {
  const headers = new AxiosHeaders()
  headers.set('X-Spir-User-Id', args.firebaseUser.uid)
  headers.set('Authorization', `Bearer ${args.firebaseUser.idToken}`)
  return headers
}

/**
 * Because some errors are not subclasses of `Error` but a plain objects, Sentry can't handle it like https://sentry.io/organizations/spir-inc/issues/3584938645/?project=1844005&referrer=slack
 * According to the Sentry log, these plain objects have `message` field.
 * This function pass to captureException
 *  - error itself if error inherits Error
 *  - `message` field if error doesn't inherits Error
 *  - 'Unknown http error ocurred' otherwise (unexpected pattern)
 *
 * See also [Sentry's source code](https://github.com/getsentry/sentry-javascript/blob/7954cf31d0f55bc1c16dd1c0dfe8229343c5f298/packages/browser/src/eventbuilder.ts#L55-L63)
 */
export const sendAxiosErrorToSentry = (error: AxiosError | Record<string, unknown>) => {
  if (error instanceof Error) {
    const traceId: string | undefined = error.response?.headers?.['x-cloud-trace-context']?.split(';')?.[0]
    if (traceId !== undefined) {
      Sentry.setContext('tracing', { traceId })
    }
    Sentry.captureException(error)
    return
  }
  if ('message' in error) {
    Sentry.captureException(error.message)
    return
  }
  Sentry.captureException('Unknown http error ocurred')
}
