<template>
  <AuthButtonsFrame>
    <AuthIconButton type="google" @click="addGoogleCalendar">
      <span>{{ $t('buttons.addCalendarWith', { type: 'Google' }) }}</span>
    </AuthIconButton>
    <div class="pat-8" />
    <AuthIconButton type="microsoft" @click="addMicrosoftCalendar">
      <span>{{ $t('buttons.addCalendarWith', { type: 'Microsoft' }) }}</span>
    </AuthIconButton>
  </AuthButtonsFrame>
</template>

<script lang="ts">
import AuthButtonsFrame from '@/components/auth/AuthButtonsFrame.vue'
import { AuthIconButton } from '@/components/features/auth/AuthIconButton'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    AuthButtonsFrame,
    AuthIconButton
  },
  props: {
    addMicrosoftCalendar: {
      type: Function,
      required: true
    },
    addGoogleCalendar: {
      type: Function,
      required: true
    }
  }
})
</script>
