<template>
  <SpirDropdown
    class="timezone-selector spir-input-select"
    :class="size"
    aria-role="list"
    :expanded="true"
    :mobileModal="false"
    @active-change="activeChanged"
  >
    <template #trigger="{ active }">
      <b-input
        v-if="active"
        class="timezone-search-input"
        ref="searchInput"
        v-model="searchText"
        @input="searchTimezone"
        @click.native.stop=""
        icon-right="close"
        icon-right-clickable
        @icon-right-click="clearText"
      />
      <template v-else>
        <slot name="selectorLabel" />
      </template>
    </template>
    <template #dropdown>
      <div class="spir-input-select__timezone-group">
        <p class="timezone-group-label">{{ $t('timezone.timeZoneModal.currentLocation') }}</p>
        <TimezoneItem
          :selectedTimezone="timeZone"
          :timezoneInfo="getLocalTimeZone"
          :size="size"
          @selectTimeZone="handleSelectTimezone"
        />
      </div>

      <div v-for="group in groups" :key="group" class="spir-input-select__timezone-group">
        <template v-if="groupByTimezone[group].length > 0">
          <p class="timezone-group-label">{{ group }}</p>
          <TimezoneItem
            v-for="tz in groupByTimezone[group]"
            :key="tz.code"
            :selectedTimezone="timeZone"
            :timezoneInfo="tz"
            :size="size"
            @selectTimeZone="handleSelectTimezone"
            aria-role="listitem"
          />
        </template>
      </div>
    </template>
  </SpirDropdown>
</template>

<script lang="ts">
import TimezoneItem from '@/components/organisms/timezoneSelector/TimezoneItem.vue'
import SpirDropdown from '@/components/ui/spir/SpirDropdown/SpirDropdown.vue'
import { localTimezone, timezoneGroups } from '@/lib/timezone'
import TimeZoneModule from '@/store/modules/timezones'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TimezoneItem,
    SpirDropdown
  }
})
export default class TimezoneSelectorCommon extends Vue {
  // @ts-expect-error TS2564
  $refs: {
    searchInput: any
  }
  // @ts-expect-error TS2564
  @Prop({ default: '' }) timeZone: string
  // @ts-expect-error TS2564
  @Prop({ default: 'medium' }) size: 'small' | 'medium'

  searchText = ''
  searchedKeys = []

  get getLocalTimezoneKey() {
    return localTimezone()
  }
  get getLocalTimeZone() {
    return this.timezoneInfoByKey(localTimezone())
  }
  get groupByTimezone() {
    const allGroup = TimeZoneModule.timezonesByGroup
    if (this.searchedKeys.length === 0) {
      return allGroup
    }
    const filtered = Object.keys(allGroup).reduce((a, c) => {
      // @ts-expect-error TS2345
      a[c] = allGroup[c].filter(tz => this.searchedKeys.indexOf(tz.key) >= 0)
      return a
    }, {})
    return filtered
  }
  get groups(): string[] {
    return timezoneGroups
  }
  timezoneInfoByKey(key: string) {
    return TimeZoneModule.timezoneByKey({ key })
  }
  activeChanged(dropdownStatus: boolean) {
    this.$emit('activeChanged', dropdownStatus)
    if (dropdownStatus) {
      const timeZoneByKey = this.timezoneInfoByKey(this.timeZone)
      if (timeZoneByKey) {
        this.searchText = timeZoneByKey.label
      }
      this.$nextTick(() => {
        this.resetSearchInput()
      })
    } else {
      this.searchText = ''
      this.searchedKeys = []
    }
  }
  resetSearchInput() {
    this.$refs.searchInput.focus()
    if (this.$refs.searchInput.getElement()) {
      this.$refs.searchInput.getElement().select()
    }
  }
  handleSelectTimezone(tz: string) {
    this.$emit('onChangeTimeZone', tz)
  }
  clearText() {
    this.searchText = ''
    this.searchTimezone()
  }
  searchTimezone() {
    // @ts-expect-error TS2322
    this.searchedKeys = TimeZoneModule.searchTimezoneByString(this.searchText)
  }
}
</script>

<style scoped lang="scss">
.spir-input-select {
  &__timezone-group {
    .timezone-group-label {
      color: $spir2_gray;
      font-size: 12px;
      line-height: 130%;
      padding: 16px 16px 4px;
    }
  }
}
</style>
<style lang="scss">
.timezone-selector.spir-input-select {
  a.dropdown-item {
    padding-right: 10px;
  }
  .timezone-search-input {
    .input {
      font-size: 12px;
    }
    .input:focus {
      border-bottom: 1px solid $spir2_primaryBlue !important;
    }
  }
  // smallになる場合はURL共有モーダルを表示する場合で、dropdownのリストが右端からはみ出すのを防ぐために
  // 表示場所を上書きする
  &.small {
    .dropdown-menu {
      left: -50%;
      width: 150%;
    }
  }
}
</style>
