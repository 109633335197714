<template>
  <CommonLayoutModal @close="$emit('close')" :showFooter="false">
    <template v-slot:modalBody>
      <ProfileContent v-if="profile" :profile="profile" />
    </template>
  </CommonLayoutModal>
</template>

<script lang="ts">
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import ProfileContent from '@/components/profile/ProfileContent.vue'
import { getProfile } from '@/lib/api/profile'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ProfileContent,
    CommonLayoutModal
  }
})
export default class ProfileContentModal extends Vue {
  // @ts-expect-error TS2564
  @Prop() id: string
  profile = null

  mounted() {
    getProfile(this.id).then(({ data }) => {
      this.profile = data
    })
  }
}
</script>

<style scoped lang="scss"></style>
