<template>
  <InputField
    class="confirm-message-input"
    type="textarea"
    v-model="currenValue"
    :rules="validationRule"
    :icon="icon"
    :label="label"
    :placeholder="description"
    :required="required"
    @input="newValue => $emit('input', newValue)"
  />
</template>

<script lang="ts">
import InputField from '@/components/newForm/molecules/InputField.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    InputField
  }
})
export default class ConfirmationFormTextArea extends Vue {
  // @ts-expect-error TS2564
  @Prop() label: string
  // @ts-expect-error TS2564
  @Prop() description: string
  // @ts-expect-error TS2564
  @Prop() icon: string
  // @ts-expect-error TS2564
  @Prop() required: boolean
  // @ts-expect-error TS2564
  @Prop() value: string

  currenValue = null
  created() {
    // @ts-expect-error TS2322
    this.currenValue = this.value
  }
  get validationRule() {
    const defaultRule = 'max:500'
    return this.required ? `required|${defaultRule}` : defaultRule
  }
}
</script>

<style scoped lang="scss">
.confirm-message-input {
  ::v-deep {
    .textarea {
      border: 1px solid $spir2_border;
      border-radius: 4px !important;
      padding: calc(0.75em - 1px);
    }
  }
}
</style>
