<template>
  <div class="setting-input">
    <ValidationProvider v-if="rules" v-slot="{ errors, valid, touched, dirty, validated }" :name="label" :rules="rules">
      <b-field
        v-bind="$attrs"
        :type="inputType(touched || dirty || validated, valid)"
        :message="errorMessage(touched || dirty || validated, valid, errors)"
        expanded
      >
        <b-input v-bind="$attrs" :disabled="disabled" @input="updatedValue" expanded></b-input>
      </b-field>
    </ValidationProvider>
    <div v-else>
      <b-field v-bind="$attrs" expanded>
        <b-input v-bind="$attrs" :disabled="disabled" @input="updatedValue" expanded></b-input>
      </b-field>
    </div>
  </div>
</template>

<script lang="ts">
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import { ValidationProvider } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    FormItem,
    ValidationProvider
  }
})
export default class FormInput extends Vue {
  // @ts-expect-error TS2564
  @Prop() required: boolean
  // @ts-expect-error TS2564
  @Prop({ default: 'alphabetical' }) icon: string
  // @ts-expect-error TS2564
  @Prop() label: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) disabled: boolean
  // @ts-expect-error TS2564
  @Prop({ default: null }) rules: string
  @Prop({ default: null }) helpText

  inputType(dirty, isValid) {
    if (!dirty) {
      return
    }
    return !isValid ? 'is-danger' : ''
  }
  errorMessage(dirty, isValid, errors) {
    if (isValid || !dirty) {
      return this.helpText
    }
    return errors[0]
  }
  updatedValue(e) {
    this.$emit('input', e)
  }
}
</script>

<style scoped lang="scss">
.setting-input {
  &::v-deep {
    input.input {
      padding: 6px 12px;
      @include text_input_colors;
    }
  }
}
</style>
