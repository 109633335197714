import { pick } from 'lodash'

export abstract class LocalStorage {
  #keys: Array<string>
  protected localStorageKey: string
  protected localStorage = window.localStorage
  constructor(localStorageKey: string, keys: Array<string>) {
    this.localStorageKey = localStorageKey
    this.#keys = keys
  }
  saveToLocalStorage(targetObject: any) {
    const targetValues = pick(targetObject, this.#keys)
    this.localStorage.setItem(this.localStorageKey, JSON.stringify(targetValues))
  }
  loadFromLocalStorage() {
    // @ts-expect-error TS2345
    return JSON.parse(this.localStorage.getItem(this.localStorageKey)) || {}
  }
}
