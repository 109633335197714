<template>
  <!-- SpirUIとデザインがちょっと違うけど時間かけたくないので一旦これでいきましょう。 -->
  <b-checkbox
    class="spir-checkbox"
    type="is-success"
    :size="buefySize"
    :value="value"
    :indeterminate="isIndeterminate"
    @input="onClick"
  >
    <slot />
  </b-checkbox>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirCheckbox',
  props: {
    size: {
      type: String as PropType<'s' | 'm' | 'l'>,
      default: 's'
    },
    value: {
      type: Boolean
    },
    onClick: {
      type: Function as PropType<(v: boolean) => void>,
      required: true
    }
  },
  setup(props) {
    const isIndeterminate = computed(() => props.value === undefined)
    const buefySize = computed(() => {
      switch (props.size) {
        case 's':
          return 'is-small'
        case 'm':
          return 'is-medium'
        case 'l':
          return 'is-large'
        default:
          return 'is-small'
      }
    })
    return {
      isIndeterminate,
      buefySize
    }
  }
})
</script>

<style lang="scss">
.spir-checkbox {
  .control-label {
    @include font_14_400;
    color: $spir2_black;
    display: flex;
    align-items: center;
  }
  &.b-checkbox.checkbox input[type='checkbox']:focus + .check,
  &.b-checkbox.checkbox input[type='checkbox']:focus:checked + .check.is-success {
    box-shadow: none;
  }
}
</style>
