<template>
  <SpirModalFrame>
    <div class="auto-peek-candidates-info">
      <ClosingPageheadTitleBox
        :canCloseEvenUsingMobile="true"
        :title="$t('modals.autoPeekCandidatesInfo.title')"
        :onClose="handleClose"
      />
      <Divider />
      <div class="body-frame">
        <ListItemSpacer>
          <div class="video-frame">
            <video :key="currentLanguage" class="video" autoplay muted loop playsInline>
              <source :src="mp4Link" type="video/mp4" />
              <source :src="webmLink" type="video/webm" />
              Sorry, your browser does not support embedded videos.
            </video>
          </div>
        </ListItemSpacer>
        <ListItemSpacer>
          <p class="description" v-html="$t('modals.autoPeekCandidatesInfo.body', { href: '/settings' })" />
        </ListItemSpacer>
      </div>
      <div class="footer-frame">
        <ListItemSpacer :bottom="true">
          <SpirCheckbox :value="dontShowNextTimeChecked" :onClick="onDontShowNextTimeCheckToggle">
            <p class="description">{{ $t('checkbox.dontShowNextTime') }}</p>
          </SpirCheckbox>
        </ListItemSpacer>
      </div>
    </div>
  </SpirModalFrame>
</template>

<script lang="ts">
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import Divider from '@/components/ui/layouts/Divider/index.vue'
import ListItemSpacer from '@/components/ui/layouts/ListItemSpacer/index.vue'
import { SpirCheckbox } from '@/components/ui/spir/SpirCheckbox'
import { SpirModalFrame } from '@/components/ui/spir/SpirModalFrame'
import ProfileModule from '@/store/modules/profile'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'AutoPeekCandidatesInfoModal',
  components: {
    SpirModalFrame,
    ClosingPageheadTitleBox,
    ListItemSpacer,
    Divider,
    SpirCheckbox
  },
  props: {
    dontShowNextTimeChecked: {
      type: Boolean,
      required: true
    },
    onDontShowNextTimeCheckToggle: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup(_, { emit }) {
    const currentLanguage = computed(() => ProfileModule.getLanguage)
    return {
      currentLanguage,
      mp4Link: computed(() =>
        require(`@/assets/byLanguage/${currentLanguage.value}/videos/autoPeekCandidates/introduce.mp4`)
      ),
      webmLink: computed(() =>
        require(`@/assets/byLanguage/${currentLanguage.value}/videos/autoPeekCandidates/introduce.webm`)
      ),
      handleClose: () => {
        emit('close')
      }
    }
  }
})
</script>

<style scoped lang="scss">
.auto-peek-candidates-info {
  .video-frame {
    border-radius: 4px;
    width: 100%;
    height: 207px;
    border: 1px solid $spir2_border;
    .video {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
  .description {
    color: $spir2_black;

    @include inter_font_setting;
  }
}
</style>

<style lang="scss">
.auto-peek-candidates-info {
  .description {
    a.setting-page {
      color: $spir2_primaryBlue;
    }
  }
}
</style>
