import candidateDates from '@/filters/candidateDates'
import { FromAttendee, FromUserInfoModel } from '@/lib/utils'
import { PreVoteGroupPollVoter } from '@/models'
import { PollModel } from '@/models/data/poll'
import PollLocalStorage from '@/models/localStorage/Poll'
import store from '@/store'
import AfterConfirmModule from '@/store/modules/afterConfirm'
import ProfileModule from '@/store/modules/profile'
import TempEventModule from '@/store/modules/tempEvent'
import { Attendee, CandidateVote, FullCalendarEvent, IPoll } from '@/types'
import { addMinutes, parseISO } from 'date-fns'
import { cloneDeep } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import * as pollAPI from '../../lib/api/poll'
import { DefaultSchedule, adjustCandidatesByDuration } from '../lib/utils'
import CalendarsModule from './calendars'
import CandidatesModule from './candidates'
import PollListModule from './pollList'
import VoteModule from './vote'

export const MAX_CANDIDATE_COUNT = 20

const updateCandidateByChangedDuration = (
  candidate: CandidateVote[],
  oldDuration: number,
  newDuration: number
): CandidateVote[] => {
  if (oldDuration === newDuration) {
    return candidate
  }
  return candidate.map(c => {
    return {
      ...c,
      end: addMinutes(parseISO(c.start), newDuration).toISOString()
    }
  })
}
@Module({
  dynamic: true,
  name: 'EditPoll',
  namespaced: true,
  store
})
class EditPoll extends VuexModule {
  // @ts-expect-error TS2322
  _editingPoll: PollModel = null
  _isDirty = false
  _submitted = false
  _isLoading = false
  _overMaxCandidate = false
  _removeCandidates: Array<string> = [] // 削除対象のCandidate
  localStorageSaver = new PollLocalStorage()

  get isOverMaxCandidate() {
    return this._overMaxCandidate
  }
  get editingForm(): PollModel {
    return this._editingPoll
  }
  get editingPoll(): PollModel {
    return this._editingPoll
  }
  get candidateDateRange(): string {
    return candidateDates(this.editingPoll)
  }
  get getAttendees() {
    return this.isExist ? this.editingPoll.attendees : []
  }
  get preVoteGroupPollVoters(): PreVoteGroupPollVoter[] {
    // @ts-expect-error TS2532
    return this.getAttendees
      .filter((attendee: Attendee) => attendee.id !== this.editingPoll.author?.id)
      .map((attendee: Attendee) => {
        return FromAttendee.convertToPreVoteGroupPollVoter(attendee)
      })
  }
  get isValid() {
    return this._editingPoll && !!this._editingPoll.title
  }
  get isDirty() {
    return this._isDirty
  }
  get isExist() {
    return !!this._editingPoll
  }
  get isConfirmed() {
    return this.editingPoll.status === 'confirmed'
  }
  get isLoading() {
    return this._isLoading
  }
  get isSyncing() {
    return this.isExist && !this.editingForm.candidates.every(c => !!c.eventId)
  }
  get getExistCandidates() {
    if (!this.isExist) {
      return []
    }
    return this._editingPoll.candidates.filter(c => c.status !== 'deleted')
  }
  get getEditingEventByCalendarFormat(): FullCalendarEvent[] {
    if (!this.isExist || this.isConfirmed) {
      return []
    }
    return this.editingPoll.getEditingEventByCalendarFormat
  }
  get isMine() {
    return this._editingPoll && this._editingPoll.author?.id === this.context.rootGetters['User/currentUser']?.uid
  }
  @Action
  startCreatingNewPoll() {
    const newPoll = DefaultSchedule<IPoll>(
      this.localStorageSaver,
      CalendarsModule.primaryCalendars,
      // @ts-expect-error TS2345
      TempEventModule.getTempEvent
    )
    const userInfo = ProfileModule.userInfo
    const authorInfo = FromUserInfoModel.convertToSpirUser(userInfo)
    newPoll.author = authorInfo

    this.SET_DIRTY(false)
    this.SET_EDITING_POLL(new PollModel(newPoll))
    CalendarsModule.visibleCalendarIfNotVisible({
      accountId: newPoll.accountId,
      calendarId: newPoll.calendarId
    })
  }
  @Action
  async afterUpdate(poll: PollModel) {
    PollListModule.fetchPollList({ hideLoader: true })
    PollListModule.ON_ADD_UPDATE_POLL(poll)
    CandidatesModule.updatePollCandidates(poll)
    this.SET_DIRTY(false)
  }
  @Action
  async createPoll() {
    if (!this.isValid) {
      return false
    }
    this.SET_LOADING(true)
    try {
      const response = await pollAPI.create(this.editingPoll)
      const newPoll = new PollModel(response)
      this.localStorageSaver.saveToLocalStorage(this.editingPoll)
      this.SET_EDITING_POLL(newPoll)
      this.afterUpdate(newPoll)
      return newPoll.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async updatePoll() {
    this.SET_LOADING(true)
    try {
      const newPoll = cloneDeep(this.editingPoll)
      // @ts-expect-error TS2790
      delete newPoll.candidates
      this.localStorageSaver.saveToLocalStorage(this.editingPoll)
      await Promise.all([
        pollAPI.update(newPoll),
        // @ts-expect-error TS2345
        pollAPI.removeCandidates(this.editingPoll.id, this._removeCandidates)
      ])
      this.afterUpdate(this.editingPoll)
    } finally {
      this.SET_LOADING(false)
      this.initEditingPoll()
    }
  }
  @Action
  async confirmPoll(candidateId) {
    this.SET_LOADING(true)
    try {
      // @ts-expect-error TS2345
      const response = await pollAPI.confirm(this.editingForm.id, candidateId)
      const newPoll = new PollModel(response)
      this.SET_DIRTY(false)
      this.SET_EDITING_POLL(newPoll)
      this.afterUpdate(newPoll)
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async update(payload: Partial<PollModel>) {
    const candidates = updateCandidateByChangedDuration(
      this._editingPoll.candidates,
      this._editingPoll.duration,
      // @ts-expect-error TS2345
      payload.duration
    )
    const newSchedule: PollModel = new PollModel({ ...this._editingPoll, ...payload, candidates })
    this.SET_EDITING_POLL(newSchedule)
    this.SET_DIRTY(true)
  }
  @Action
  initEditingPoll() {
    // @ts-expect-error TS2345
    this.SET_EDITING_POLL(null)
    this.SET_DIRTY(false)
    this.SET_REMOVE_TARGET_CANDIDATE()
    this.SET_OVER_MAX_CANDIDATE_COUNT(false)
  }

  @Action
  copyFromEditingObject(otherSchedule) {
    const newSchedule: PollModel = new PollModel(otherSchedule)
    newSchedule.candidates = adjustCandidatesByDuration(newSchedule.candidates, newSchedule.duration)
    this.SET_EDITING_POLL(newSchedule)
    this.SET_DIRTY(true)
  }
  @Action
  addCandidate(payload: { start: Date; end: Date }) {
    const { start, end } = payload
    this.editingPoll.addCandidate(start, end)
    this.updatePollWithAdjustCandidate(this.editingPoll)
    return this.SET_DIRTY(true)
  }
  @Action
  removeCandidate(candidateId) {
    const newSchedule: PollModel = cloneDeep(this._editingPoll)
    const targetCandidateIndex = newSchedule.candidates.findIndex(c => c.id === candidateId)
    if (targetCandidateIndex >= 0) {
      newSchedule.candidates.splice(targetCandidateIndex, 1)
    }
    this.SET_REMOVE_TARGET_CANDIDATE(candidateId)
    this.updatePollWithAdjustCandidate(newSchedule)
    this.SET_DIRTY(true)
  }
  @Action
  updateOrAddEventStartAndEnd(payload): void {
    if (payload.id) {
      this.removeCandidate(payload.id)
    }
    return this.addCandidate({ start: payload.start, end: payload.end })
  }
  @Action
  clearCandidates() {
    const newSchedule: PollModel = cloneDeep(this._editingPoll)
    newSchedule.candidates = []
    this.SET_EDITING_POLL(newSchedule)
  }
  @Action
  replaceAllCandidates(newCandidates: { start: string; end: string; id: string }[]) {
    const newSchedule: PollModel = cloneDeep(this._editingPoll)
    newSchedule.candidates = newCandidates
    this.SET_EDITING_POLL(newSchedule)
  }
  @Action
  async setPollAsEditingPoll(payload: { pollId: string; hideLoading?: boolean }) {
    try {
      this.SET_LOADING(true)
      const poll = await PollListModule.fetchPoll({
        pollId: payload.pollId,
        hideLoading: !!payload.hideLoading
      })
      if (poll.status === 'confirmed') {
        await AfterConfirmModule.setModelFromPoll(poll)
      }
      this.SET_DIRTY(false)
      this.SET_EDITING_POLL(poll)
      VoteModule.initVotes()
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  updatePollWithAdjustCandidate(poll: PollModel) {
    const newPoll = new PollModel({ ...poll })
    if (newPoll.candidates.length > MAX_CANDIDATE_COUNT) {
      this.SET_OVER_MAX_CANDIDATE_COUNT(true)
      newPoll.candidates = newPoll.candidates.slice(0, MAX_CANDIDATE_COUNT)
    } else {
      this.SET_OVER_MAX_CANDIDATE_COUNT(false)
    }
    this.SET_EDITING_POLL(newPoll)
  }
  @Mutation
  SET_DIRTY(flag: boolean) {
    this._isDirty = flag
  }

  @Mutation
  SET_EDITING_POLL(poll: PollModel) {
    this._editingPoll = poll
  }
  @Mutation
  SET_OVER_MAX_CANDIDATE_COUNT(isOver: boolean) {
    this._overMaxCandidate = isOver
  }
  @Mutation
  SET_LOADING(flag: boolean) {
    this._isLoading = flag
  }

  @Mutation
  SET_REMOVE_TARGET_CANDIDATE(candidateId?: string) {
    if (!candidateId) {
      this._removeCandidates = []
    } else {
      this._removeCandidates.push(candidateId)
    }
  }
}

export default getModule(EditPoll)
