import store from '@/store'
import axiosInstance from '../axios'

export const changeToPublicPath = path => {
  if (store.getters['User/isSignIn']) {
    return path
  }
  return `public/${path}`
}

// @ts-expect-error TS2322
export const searchAddress = async (searchQuery: string, key: string = null) => {
  const options = {
    params: {
      q: searchQuery,
      session_key: key
    }
  }
  return (await axiosInstance.get('utils/search-address', options)).data
}
