import { SignalType } from '@/lib/analytics/LogEntry'
import { useAnalytics } from './useAnalytics'

export type SendSignal = (
  signalType: SignalType,
  customPayload?: { [key: string]: string | number | boolean | undefined }
) => void

export const useSendSignal = () => {
  const analytics = useAnalytics()
  const sendSignal: SendSignal = (signalType, customPayload) => {
    analytics.send(signalType, customPayload)
  }

  const sendSignUpTopSignal = (type: 'microsoft' | 'google') => {
    sendSignal(SignalType.CLICK_SIGNUP_TOP, { type })
  }

  const sendSignInSignal = (type: 'microsoft' | 'google') => {
    analytics.send(SignalType.CLICK_SIGNIN, { type })
  }
  const sendSignUpConfirm = (type: 'listView' | 'signupGuideView') => {
    sendSignal(SignalType.CLICK_SIGNUP_CONFIRM, { viewType: type })
  }

  const sendConfirmPublicURL = (customPayload?: { [key: string]: string | number | boolean }) => {
    sendSignal(SignalType.CONFIRM_PUBLICURL, customPayload)
  }
  return {
    sendSignal,
    sendSignUpTopSignal,
    sendSignInSignal,
    sendSignUpConfirm,
    sendConfirmPublicURL
  }
}
