var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile-images",style:({
    backgroundImage: ("url(" + _vm.backgroundPhotoURLSynced + ")")
  })},[_c('div',{staticClass:"uploader-background"},[(_vm.isProfileOwner && !_vm.isViewOnly)?_c('ProfileImageUploader',{attrs:{"sizeLimitKB":_vm.imageSizeLimitKB,"onFileChange":function (file) { return _vm.uploadImage('backgroundPhoto', file); }}}):_vm._e()],1),_c('div',{staticClass:"avater-wrapper"},[_c('figure',{staticClass:"image avatar"},[_c('img',{staticClass:"is-rounded",style:({
          'object-fit': 'cover',
          background: 'lightgrey',
          height: '5em',
          width: '5em'
        }),attrs:{"src":_vm.photoURLSynced}}),_c('div',{staticClass:"uploader-avatar"},[(_vm.isProfileOwner && !_vm.isViewOnly)?_c('ProfileImageUploader',{attrs:{"sizeLimitKB":_vm.imageSizeLimitKB,"onFileChange":function (file) { return _vm.uploadImage('photo', file); }}}):_vm._e()],1)])]),_c('div',{staticClass:"headline"},[_c('p',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.fullName)+" "),(_vm.isProfileOwner && _vm.isViewOnly)?_c('router-link',{staticClass:"more-cliclable-area",attrs:{"to":"/profile-edit"}},[_c('b-icon',{attrs:{"icon":"pencil","custom-size":"mdi-18px"}})],1):_vm._e()],1),_c('p',{staticClass:"company-info"},[_vm._v(_vm._s(_vm.headLine))])])])}
var staticRenderFns = []

export { render, staticRenderFns }