import { SessionStorage } from '@/models/sessionStorage/sessionStorage'

// TODO: 初期値をセットする処理を1回で済むようにリファクタリングしたときに、こちらのキーも一つにする。
const SESSION_STORAGE_KEY_DEFAULT_CALENDER_VIEW_VALUES = 'spir-calender-state/default-calender-view'
const SESSION_STORAGE_KEY_DEFAULT_DATE_VALUES = 'spir-calender-state/default-date'
const SESSION_STORAGE_KEY_CREATEDAT_VALUES = `spir-calender-state/createdAt`
const SESSION_STORAGE_KEY_SCROLL_POSITION = 'spir-calender-state/scrollPosition'

type DefaultCalenderViewSessionStorageState = {
  defaultCalenderView?: 1 | 3 | 7
}

export class DefaultCalenderViewSessionStorage extends SessionStorage<DefaultCalenderViewSessionStorageState> {
  constructor() {
    super(SESSION_STORAGE_KEY_DEFAULT_CALENDER_VIEW_VALUES, ['defaultCalenderView'])
  }
}

type DefaultDateSessionStorageState = {
  defaultDate?: string // date string
}

export class DefaultDateSessionStorage extends SessionStorage<DefaultDateSessionStorageState> {
  constructor() {
    super(SESSION_STORAGE_KEY_DEFAULT_DATE_VALUES, ['defaultDate'])
  }
}

type CreatedAtSessionStorageState = {
  createdAt?: string // date string
}

export class CreatedAtSessionStorage extends SessionStorage<CreatedAtSessionStorageState> {
  constructor() {
    super(SESSION_STORAGE_KEY_CREATEDAT_VALUES, ['createdAt'])
  }
}

type ScrollPositionSessionStorageState = {
  scrollPosition?: number
}

export class ScrollPositionSessionStorage extends SessionStorage<ScrollPositionSessionStorageState> {
  constructor() {
    super(SESSION_STORAGE_KEY_SCROLL_POSITION, ['scrollPosition'])
  }
}
