import { LocalStorage } from '@/models/localStorage/localStorage'

const LOCAL_STORAGE_KEY_TIMEZONE = 'LOCAL_STORAGE_KEY_TIMEZONE'

export default class TimezoneStorage extends LocalStorage {
  constructor() {
    super(LOCAL_STORAGE_KEY_TIMEZONE, ['localTimezone', 'userTimezone'])
  }
  saveTimezone(localTimezone: string, userTimezone: string) {
    this.saveToLocalStorage({
      localTimezone,
      userTimezone
    })
  }
  loadTimezone(): {
    localTimezone?: string
    userTimezone?: string
  } {
    const { localTimezone, userTimezone } = this.loadFromLocalStorage()
    return {
      localTimezone,
      userTimezone
    }
  }
}
