export interface IContactForList {
  isSpirUser?: boolean
  spirUserId?: string
  name?: string
  photo?: string
  phoneNumber?: string
  email?: string
  organization?: string
  jobTitle?: string
  // connections?: IContact[]
}
export enum CONTACT_TYPES {
  GOOGLE = 'google',
  DEVICE = 'device'
}
