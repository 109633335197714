<template>
  <div class="attendee">
    <div class="media">
      <div class="media-left-col">
        <AttendeeIcon
          :photoURL="photoURL"
          :fullName="email || fullName"
          :attendanceStatus="attendanceStatus"
          :hideAttendanceIcon="!isStatusIconON"
          :isLoading="isLoading"
          toolTipPosition="is-right"
        />
        <div class="media-content">
          <p class="name">{{ fullName }}</p>
          <p v-if="isAuthor" class="organizer">{{ $t('scheduleEventList.organizer') }}</p>
        </div>
      </div>
      <div class="media-right-col icon">
        <b-button class="icon-button" type="is-ghost" :disabled="!userId" @click="openCreatorProfile">
          <b-icon type="is-secondary" icon="information-outline" custom-size="mdi-18px" />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AttendeeIcon from '@/components/features/attendees/AttendeeIcon/index.vue'
import ProfileContentModal from '@/components/modal/ProfileContent.vue'
import UserBadge from '@/components/molecules/UserBadge.vue'
import { ProfileOnOverview, SpirAttendeeStatus } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

// FIXME: This component is depend on `b-xxx`. We should remove dependency of buefy in `features/` dir.
@Component({
  components: {
    UserBadge,
    AttendeeIcon
  }
})
export default class AttendeeWithProfileButton extends Vue {
  // @ts-expect-error TS2564
  @Prop() attendeeInfo: ProfileOnOverview
  // @ts-expect-error TS2564
  @Prop({ default: true }) isStatusIconON: boolean

  get fullName() {
    return this.attendeeInfo.fullName
  }
  get photoURL() {
    return this.attendeeInfo.photoURL
  }
  get isLoading() {
    return this.attendeeInfo.isLoading
  }
  get email() {
    return this.attendeeInfo.email
  }

  get userId() {
    return this.attendeeInfo.userId || null
  }

  get attendanceStatus() {
    return this.attendeeInfo.attendanceStatus || SpirAttendeeStatus.ACCEPTED
  }
  get isAuthor() {
    return this.attendeeInfo.isAuthor || false
  }
  openCreatorProfile() {
    this.$emit('clickProfile')
    const modal = this.$buefy.modal.open({
      parent: this,
      component: ProfileContentModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        id: this.userId
      },
      events: {
        close: () => {
          modal.close()
        }
      }
    })
  }
}
</script>

<style scoped lang="scss">
.attendee {
  width: 100%;
  padding: 0.4rem 0;
  border: none;
  .media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    .media-left-col {
      display: flex;
      align-items: center;
      flex: 1;
      min-width: 0;
      margin-right: 0;
      .media-content {
        flex: 1;
        min-width: 0;
        height: 100%;
        padding-left: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;

          align-items: center;
          letter-spacing: 0.64px;
          color: $spir2_black;
        }
        .organizer {
          font-size: 10px;
          display: flex;
          align-items: center;
          color: $spir2_gray;
        }
      }
    }
    .margin-right-col {
      &.icon {
        margin: 0;
        width: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-button {
          border: none;
        }
      }
    }
  }

  .btn {
    padding: 4px 8px;
    height: 24px;
    font-size: 12px;
  }
}
</style>
