<template>
  <ValidationProvider
    class="validation-input-field hide-field-border"
    v-slot="{ errors, valid, touched, dirty, validated }"
    :vid="vid"
    :name="$attrs.name || $attrs.label || $attrs.placeholder"
    :rules="rules"
  >
    <slot name="label"></slot>
    <b-field
      v-bind="$attrs"
      :type="inputType(touched || dirty || validated, valid)"
      :message="errorMessage(touched || dirty || validated, valid, errors)"
    >
      <b-input
        class="with-validation"
        v-model="innerValue"
        v-bind="$attrs"
        :disabled="disabled"
        @icon-right-click="$emit('icon-right-click')"
        @keyup.native="valid ? $emit('keyup') : ''"
        @blur="$emit('blur')"
      ></b-input>
    </b-field>
  </ValidationProvider>
</template>
<script lang="ts">
import { ValidationProvider } from 'vee-validate'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: { ValidationProvider }
})
export default class WithValidationInput extends Vue {
  // @ts-expect-error TS2564
  @Prop() vid: string
  // @ts-expect-error TS2564
  @Prop() rules: Record<string, any> | string
  // @ts-expect-error TS2564
  @Prop() value: string
  // @ts-expect-error TS2564
  @Prop() helpText: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) hasError: boolean
  // @ts-expect-error TS2564
  @Prop({ default: false }) disabled: boolean

  innerValue = ''

  @Watch('innerValue')
  updatedInnerValue(newValue) {
    if (newValue === this.value) {
      return
    }
    this.$emit('input', newValue)
  }

  @Watch('value')
  updatedValue(newValue) {
    this.innerValue = newValue
  }

  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }

  inputType(dirty, isValid) {
    if (!dirty) {
      return
    }
    return this.hasError || !isValid ? 'is-danger' : ''
  }

  errorMessage(dirty, isValid, errors) {
    if (isValid || !dirty) {
      return this.helpText
    }
    return errors[0]
  }
}
</script>
<style scopped lang="scss">
.validation-input-field {
  &.hide-field-border {
    .field {
      box-shadow: none !important;
    }
  }
}
</style>
<style lang="scss">
.validation-input-field.hide-field-border {
  .label {
    color: $spir2_black;
  }
  .with-validation {
    input.input {
      padding: 6px 12px;
      @include text_input_colors;
      border-radius: 6px !important;
    }
  }
}
</style>
