export const common = {
  register: 'Sign up',
  proceedToRegister: 'Continue to sign-up',
  checkCandidatesOnCalendar: 'Overlay my calendar',
  pleaseSelectCandidates: 'Select a date and time',
  overlayYourCalendar: 'Sign up to overlay your calendar',
  confirm: 'OK',
  noAvailableCandidate: 'No availabilities',
  pleaseConfirmCandidate: 'Choose the best time for you',
  cancel: 'Cancel',
  messageToOrganizer: 'Message',
  emailAddress: 'Email',
  name: 'Name',
  back: 'Back',
  confirmSchedule: 'Confirm event',
  noAvailableConfirmationDate: 'If no slots work for you...',
  requestAlternative: 'Request alternate slots',
  suggestNewSlots: 'Suggest alternate slots',
  addPossibleDates: 'Add slots',
  personal: 'Personal',
  team: 'Team',
  teamSuspended: 'Team Plan expired',
  calendar: 'Calendars',
  teamCalendars: 'Team calendars',
  you: 'You',
  teamSetting: 'Team settings',
  member: 'Members',
  plan: 'Team Plan',
  paymentInfo: 'Billing',
  attendee: {
    label: 'Attendees'
  },
  duration: 'Duration',
  timeBuffer: 'Buffer',
  delete: 'Delete',
  planConfirmationSucceeded: 'Success! Team Plan is now available for your team.',
  teamRestricted: 'Your team is restricted',
  registrationCaution: {
    header: 'Please read carefully',
    headerAttention: '',
    freeTrial:
      'The free trial is limited to once per team regardless of usage. We may suspend accounts if we suspect that the free trial is used more than once in the same team.',
    paymentMethod: 'Payment method: Credit card',
    others:
      'See information regarding the <a class="registration-caution-ul__link" href="{link}" target="_blank" rel="noopener noreferrer">Specified Commercial Transactions Act</a> for details (Japanese)',
    othersUrl: 'https://www.spirinc.com/law' // 英語も日本語ページで対応
  },
  links: {
    landingPage: 'https://www.spirinc.com/en',
    privacyPolicy: 'https://www.spirinc.com/en/privacy',
    termsOfService: 'https://www.spirinc.com/en/terms',
    teamHelpPage: 'https://help.spirinc.com/en'
  },
  requiredAtLeastPurposeCheck: 'Select at least one',
  priceAmount: '{amount} <span class="unit">JPY</span><span class="interval">{interval}</span>',
  recurringInterval: {
    day: '/day',
    week: '/week',
    month: '/mo',
    year: '/year'
  },
  currentTeamMembersCount: '<span style="color: #6bca7a; font-weight: 600">{num} member</span> in team',
  currentTeamMembersCounts: '<span style="color: #6bca7a; font-weight: 600">{num} members</span> in team',
  weekdays: {
    friday: 'F',
    monday: 'M',
    saturday: 'Sa',
    sunday: 'Su',
    thursday: 'Th',
    tuesday: 'T',
    wednesday: 'W'
  },
  nav: {
    availabilityList: 'Templates',
    confirmedList: 'Confirmed',
    unconfirmedList: 'Planning'
  },
  questionnaires: {
    toasts: {
      failed: {
        notMember: 'You must be a member of the team.',
        teamNotAvailable: 'Could not access team.',
        questionnaireNotFound: 'Could not find form.'
      }
    },
    defaultQuestions: {
      name: {
        title: 'Name',
        placeholder: 'Enter your name'
      },
      email: {
        title: 'Email',
        placeholder: 'Enter your email',
        note: 'The confirmation email and calendar event invitation will be sent to this address.',
        noteWithoutInvitingCalendar: 'The confirmation email will be sent to this address.'
      },
      messageToOrganizer: {
        title: 'Message',
        placeholder: 'Enter your message'
      }
    }
  },
  accountTokenInvalidError: {
    message: 'Spir couldn’t access the organizer’s calendar. Please contact the organizer to let them know.'
  },
  availabilitySharingCreateButton: 'Create template'
}
