<template>
  <div id="app">
    <AppUpdateAvailableSnackbar />
    <!-- loading spinner for changing router. do not tracing bcz page load event will be capturing by Sentry -->
    <LoadingSpinner :fullPage="true" :active="isPageLoading" loaderId="pageLoading" :disableTracing="true" />
    <router-view v-if="init" />
    <SnackbarPortal />
    <InvalidateQueriesListener />
  </div>
</template>
<script lang="ts">
import { AuthGuestRegistrationModal } from '@/components/features/auth/AuthGuestRegistrationModal'
import AppUpdateAvailableSnackbar from '@/components/functional/AppUpdateAvailableSnackbar.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import { LoadingSpinner } from '@/components/ui/layouts/LoadingSpinner'
import { SnackbarPortal } from '@/components/ui/layouts/SnackbarPortal'
import { SignalType } from '@/lib/analytics/LogEntry'
import { EventBus, EVENTS } from '@/lib/eventBus'
import AppModule from '@/store/modules/app'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule, { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import UserModule from '@/store/modules/user'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AllRouteNames } from './router'
import { InvalidateQueriesListener } from '@/components/functional/InvalidateQueriesListener'

const INTERVAL_SEND_SIGNAL = 1000 * 60 * 5 // 5min

@Component({
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'robots',
          name: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }
  },
  components: {
    LoadingSpinner,
    AppUpdateAvailableSnackbar,
    SnackbarPortal,
    InvalidateQueriesListener
  }
})
export default class App extends Vue {
  init = false
  intervalIdForSendingSignal = null

  @Watch('isIdle')
  changedIdleStatus(newStatus: boolean) {
    // active
    if (!newStatus) {
      // send signal and start interval
      this.sendActiveSignalAndSetInterval()
    } else {
      // idle
      // stop interval
      this.stopSendSignalInterval()
    }
  }
  @Watch('currentLanguage')
  changedLanguage() {
    this.updateLanguage()
  }
  @Watch('isNarrow')
  changedWidth(newVal: boolean) {
    // widthをスマホサイズに減らし、現在のPathがRootの場合は、公開URLにリダイレクトする
    if (newVal && this.$route.name === 'Main') {
      this.$router.push({ name: AllRouteNames.AvailabilityList })
    }
  }
  get isNarrow() {
    return AppModule.isNarrow
  }
  get isPageLoading(): boolean {
    return AppModule.isPageLoading
  }
  get isIdle(): boolean {
    return this.$store.state.idleVue.isIdle
  }
  get currentLanguage() {
    return ProfileModule.getLanguage
  }
  async created() {
    try {
      this.sendActiveSignalAndSetInterval()
      await this.updateTimezoneIfItIsNeeded()
      this.init = true
      window.showSpirAuthModal = () => {
        EventBus.emit(EVENTS.SHOW_AUTH_MODAL_FROM_OUTSIDE)
      }
      EventBus.on(EVENTS.SHOW_AUTH_MODAL_FROM_OUTSIDE, this.showAuthModal)
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          type: 'is-danger',
          position: 'is-bottom',
          message: `Failed to fetch Spir schedules: ${err.message}`
        })
      }
    }
    AppModule.setWindowWidth()
    window.addEventListener('resize', AppModule.setWindowWidth)
    ProfileModule.setLanguage()
    this.updateLanguage()
    if (UserModule.isRedirectedAfterSignIn === 'error') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.errorCommon').toString()
      })
    }
    if (UserModule.isRedirectedAfterSignIn === 'noUser') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.error.auth.noUser').toString()
      })
    }
    if (UserModule.isRedirectedAfterSignIn === 'userExists') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: this.$t('message.error.accountExistsInAnotherCredential').toString(),
        duration: 5000
      })
    }
  }

  beforeDestroy() {
    window.removeEventListener('resize', AppModule.setWindowWidth)
    this.stopSendSignalInterval()
    EventBus.off(EVENTS.SHOW_AUTH_MODAL_FROM_OUTSIDE, this.showAuthModal)
  }

  async showAuthModal() {
    this.$buefy.modal.open({
      parent: this,
      component: AuthGuestRegistrationModal,
      hasModalCard: true,
      props: {
        title: this.$t('modals.signUp.title')
      }
    })
  }
  sendActiveSignalAndSetInterval() {
    if (this.intervalIdForSendingSignal) {
      return
    }
    this.$analytics.send(SignalType.ACTIVE)
    // @ts-expect-error TS2322
    this.intervalIdForSendingSignal = setInterval(() => {
      this.$analytics.send(SignalType.ACTIVE)
    }, INTERVAL_SEND_SIGNAL)
  }
  stopSendSignalInterval() {
    if (this.intervalIdForSendingSignal) {
      clearInterval(this.intervalIdForSendingSignal)
      this.intervalIdForSendingSignal = null
    }
  }
  updateLanguage() {
    this.$i18n.locale = this.currentLanguage
  }
  async updateTimezoneIfItIsNeeded() {
    const needToChangeTimezone = await TimezoneModule.needToChangeTimezone()
    if (needToChangeTimezone) {
      return new Promise(resolve => {
        const displayTimezone = (timezoneInfo: TimezoneDetailWithTimeLabel) => {
          return `${timezoneInfo.label}(${timezoneInfo.abbreviation})`
        }
        const localTimezone = TimezoneModule.localTimezoneInfo
        const userTimezone = TimezoneModule.userTimezoneInfo
        this.$buefy.modal.open({
          parent: this,
          component: ConfirmModal,
          hasModalCard: true,
          canCancel: true,
          props: {
            header: this.$t('timezone.updateTimezone.header').toString(),
            body: this.$t('timezone.updateTimezone.body', {
              userTimezone: displayTimezone(userTimezone),
              localTimezone: displayTimezone(localTimezone)
            }).toString(),
            confirmBtn: this.$t('timezone.updateTimezone.confirmButton').toString(),
            cancelBtn: this.$t('timezone.updateTimezone.cancelButton').toString()
          },
          events: {
            confirm: async () => {
              try {
                await TimezoneModule.updatePrimaryTimezone(localTimezone.key)
                this.$buefy.toast.open({
                  type: 'is-primary',
                  position: 'is-top',
                  message: this.$t('message.success.updated', { type: this.$t('timezone.label') }).toString()
                })
              } catch (_) {
                this.$buefy.toast.open({
                  type: 'is-danger',
                  position: 'is-bottom',
                  message: this.$t('message.errorCommon').toString()
                })
              } finally {
                resolve(true)
              }
            },
            cancel: () => {
              TimezoneModule.saveCurrentTimezoneToLocalStorage()
              resolve(true)
            }
          }
        })
      })
    } else {
      return Promise.resolve()
    }
  }
}
</script>
<style lang="scss">
#app {
  height: 100%;
  position: relative;
}
.update-timezone-body {
  color: $spir2_gray;
  &__timezone {
    font-weight: bold;
    color: $spir2_black;
  }
}
</style>
