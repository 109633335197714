import { PreVoteGroupPollVoter } from '@/models'
import { Attendee, ProfileOnOverview } from '@/types'

export const FromAttendee = {
  convertToProfileOnOverview(attendee: Attendee, overrideOption?: Partial<ProfileOnOverview>): ProfileOnOverview {
    return {
      // @ts-expect-error TS2322
      fullName: overrideOption?.fullName || attendee.fullName,
      photoURL: overrideOption?.photoURL || attendee.photoURL,
      email: overrideOption?.email || attendee.email,
      userId: overrideOption?.userId || attendee.userId || attendee.id,
      isAuthor: overrideOption?.isAuthor || !!attendee.organizer,
      attendanceStatus: overrideOption?.attendanceStatus || attendee.responseStatus,
      isLoading: overrideOption?.isLoading || false
    }
  },
  convertToPreVoteGroupPollVoter(attendee: Attendee): PreVoteGroupPollVoter {
    return {
      userId: attendee.id,
      // @ts-expect-error TS2322
      fullName: attendee.fullName,
      // @ts-expect-error TS2322
      email: attendee.email,
      // @ts-expect-error TS2322
      attendanceStatus: attendee.responseStatus,
      photoURL: attendee.photoURL
    }
  }
}
