<template>
  <div class="user-badge">
    <Avatar class="user-badge__avatar mr-2" :src="photoUrl" :username="fullName" :size="20"></Avatar>
    <span class="user-badge__name">{{ fullName }}</span>
  </div>
</template>

<script lang="ts">
import Avatar from 'vue-avatar'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Avatar
  }
})
export default class FormMemberAvatar extends Vue {
  @Prop() photoUrl?: string
  // @ts-expect-error TS2564
  @Prop() fullName: string
}
</script>

<style scoped lang="scss">
.user-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  &__avatar {
    flex-shrink: 0;
  }
  &__name {
    color: $spir2_black;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
