import { getNextPath } from '@/lib/utils/authRedirect'
import UserModule from '@/store/modules/user'

export default (to, _, next) => {
  const isSignIn = UserModule.isSignIn
  const isRedirected = UserModule.isRedirectedAfterSignIn
  // @ts-expect-error TS2322
  const redirectTo = getNextPath({ isSignIn, isRedirected: isRedirected, redirect: to.query.redirect })
  redirectTo ? next(redirectTo.redirect) : next()
}
