import { GoogleEvent } from '../../types'
import axiosInstance from '../axios'

const resource = (accountId: string, calendarId: string) => {
  return `accounts/${accountId}/calendars/${calendarId}/events`
}

export const create = (payload: GoogleEvent) => {
  const eventPayload = { ...payload }
  delete eventPayload.id
  delete eventPayload.accountId
  delete eventPayload.calendarId
  delete eventPayload.writable

  // @ts-expect-error TS2345
  return axiosInstance.post(`${resource(payload.accountId, payload.calendarId)}`, eventPayload)
}

export const update = (payload: GoogleEvent) => {
  const eventPayload = { ...payload }
  delete eventPayload.id
  delete eventPayload.accountId
  delete eventPayload.calendarId
  delete eventPayload.writable

  // @ts-expect-error TS2345
  return axiosInstance.put(`${resource(payload.accountId, payload.calendarId)}/${payload.id}`, eventPayload)
}

export const getEventById = async (payload: {
  accountId: string
  calendarId: string
  eventId: string
}): Promise<{ event: GoogleEvent }> => {
  return (await axiosInstance.get(`${resource(payload.accountId, payload.calendarId)}/${payload.eventId}`)).data
}

export const deleteEvent = payload => {
  return axiosInstance.delete(`${resource(payload.accountId, payload.calendarId)}/${payload.id}`)
}
