<template>
  <b-dropdown-item class="timezone-item" aria-role="listitem" @click="selectTimeZone()">
    <div class="timezone-label" :class="{ 'is-active': isSelectedTimeZone }">
      <div class="checkicon-frame" :class="size">
        <b-icon v-if="isSelectedTimeZone" custom-size="mdi-18px" icon="check" />
      </div>
      <div class="timezone-content-frame">
        <div class="timezone-row">
          <div class="col-left">
            <div class="timezone-label__main" :class="size">
              <span>{{ timezoneInfo.abbreviation }}</span>
              &nbsp;
              <span>{{ timezoneInfo.label }}</span>
            </div>
          </div>
          <div class="col-right">
            <TimeLabelWithInterval :timeZoneKey="timezoneInfo.key" />
          </div>
        </div>
        <p class="timezone-sublabel">{{ timezoneInfo.subLabel }}</p>
      </div>
    </div>
  </b-dropdown-item>
</template>

<script lang="ts">
import TimeLabelWithInterval from '@/components/atoms/TimeLabelWithInterval.vue'
import { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TimeLabelWithInterval
  }
})
export default class TimezoneItem extends Vue {
  @Prop() timezoneInfo!: TimezoneDetailWithTimeLabel
  // @ts-expect-error TS2564
  @Prop() icon: string
  @Prop({ default: false }) hideSubLabel
  // @ts-expect-error TS2564
  @Prop() isActive: boolean
  // @ts-expect-error TS2564
  @Prop() selectedTimezone: string
  // @ts-expect-error TS2564
  @Prop({ default: 'medium' }) size: 'small' | 'medium'

  get isSelectedTimeZone() {
    return this.selectedTimezone === this.timezoneInfo.key
  }
  selectTimeZone() {
    this.$emit('selectTimeZone', this.timezoneInfo.key)
  }
}
</script>

<style scoped lang="scss">
.timezone-label {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
  .checkicon-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 28px;
    &.small {
      flex: 0 0 18px;
    }
  }
  .timezone-content-frame {
    width: 100%;
    min-width: 0;
    .timezone-row {
      min-width: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      .col-left {
        min-width: 0;
        .timezone-label__main {
          color: $spir2_black;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @include inter_font_setting;
          &.small {
            @include inter_font_setting($font_size: 12px, $line_height: 18px);
          }
        }
      }
      .col-right {
        flex: 0 0 auto;
      }
    }
    .timezone-sublabel {
      color: $spir2_gray;
      font-size: 10px;
      line-height: 140%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &.is-active {
    color: $spir2_primaryBlue;
    .timezone-content-frame {
      .col-left {
        .timezone-label__main {
          color: $spir2_primaryBlue;
        }
      }
      .timezone-sublabel {
        color: $spir2_primaryBlue;
      }
    }
  }
}
</style>

<style lang="scss">
.timezone-label {
  .timezone-content-frame {
    .timezone-row {
      .col-right {
        color: $spir2_gray;
      }
    }
  }
}
</style>
