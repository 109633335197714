<template>
  <SpirModalFrame>
    <div class="share-text-modal">
      <ClosingPageheadTitleBox
        :canCloseEvenUsingMobile="true"
        :title="$t('shareTextModal.scheduleTitleFromUnconfirmedList')"
        :onClose="handleClose"
      />
      <Divider />
      <ListItemSpacer>
        <CopyUrlAndHtml
          :activeCandidates="activeCandidates"
          :title="title"
          :duration="duration"
          :urlForConfirm="urlForConfirm"
          :scheduleType="schedule.type"
          :canHtmlCopy="true"
        />
      </ListItemSpacer>
    </div>
  </SpirModalFrame>
</template>
<script lang="ts">
import CopyUrlAndHtml from '@/components/organisms/CopyUrlAndHtml.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import Divider from '@/components/ui/layouts/Divider/index.vue'
import ListItemSpacer from '@/components/ui/layouts/ListItemSpacer/index.vue'
import { SpirModalFrame } from '@/components/ui/spir/SpirModalFrame'
import { ScheduleModel } from '@/models/data'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'ShareTextModal',
  components: {
    Divider,
    ClosingPageheadTitleBox,
    CopyUrlAndHtml,
    SpirModalFrame,
    ListItemSpacer
  },
  props: {
    schedule: {
      type: Object as PropType<ScheduleModel>,
      required: true
    }
  },
  setup(props, { emit }) {
    const { schedule } = toRefs(props)

    const activeCandidates = computed(() => {
      return schedule.value.activeCandidates
    })
    const title = computed(() => {
      return schedule.value.title
    })
    const duration = computed(() => {
      return schedule.value.duration
    })
    const urlForConfirm = computed(() => {
      return schedule.value.urlForConfirmer
    })
    function handleClose() {
      emit('close')
    }
    return {
      activeCandidates,
      title,
      duration,
      urlForConfirm,
      handleClose
    }
  }
})
</script>
