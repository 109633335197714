<template>
  <div class="open-map-button-frame">
    <div v-if="isMapOpenButtonTextType" class="text">
      <Button :disabled="disabled" @click="openGoogleMaps" type="text" size="small" outlined> Map </Button>
    </div>
    <div v-else class="icon">
      <ButtonToWrapIcon :disabled="disabled" :onClick="openGoogleMaps">
        <b-icon :type="disabled ? 'is-light' : 'is-dark'" icon="open-in-new" custom-size="mdi-18px" />
      </ButtonToWrapIcon>
    </div>
  </div>
</template>

<script lang="ts">
import ButtonToWrapIcon from '@/components/ui/layouts/ButtonToWrapIcon/index.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'

@Component({
  components: {
    ButtonToWrapIcon,
    Button
  }
})
export default class MapOpenButton extends Vue {
  // @ts-expect-error TS2564
  @Prop() location: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) disabled: boolean
  // @ts-expect-error TS2564
  @Prop({ default: 'text' }) mapOpenButtonType: 'text' | 'icon'

  get isMapOpenButtonTextType(): boolean {
    return this.mapOpenButtonType === 'text'
  }

  openGoogleMaps() {
    if (!this.location) {
      return
    }
    window.open(`https://www.google.com/maps?q=${this.location}`, '_blank')
  }
}
</script>
