// Event List: https://docs.google.com/spreadsheets/d/1756oVXGno-1t5-1xQO1HQU3SoBkLKSd1KhG6YTAhCHw/edit
const SPIR_PREFIX = 'SPIR__'
export const SignalType = {
  ACTIVE: 'ACTIVE',
  SEE_PROFILE_OVERVIEW: 'SEE_PROFILE_OVERVIEW', // 画面でプロフィールを閲覧（Overview)
  SEE_PROFILE_CONFIRM_MODAL: 'SEE_PROFILE_CONFIRM_MODAL', // でプロフィールを閲覧（ConfirmModal)
  CLICK_SIGNUP_CONFIRM: 'CLICK_SIGNUP_CONFIRM', // 確定画面で登録を開始
  SEE_PROFILE_ON_EVENT_MODAL: 'SEE_PROFILE_ON_EVENT_MODAL', // EventModalでプロフィールを表示
  SEE_PROFILE_ON_CANDIDATE_MODAL: 'SEE_PROFILE_ON_CANDIDATE_MODAL', // 候補Modalでプロフィールを表示
  CLICK_SIGNUP_TOP: 'CLICK_SIGNUP_TOP', // Top画面で登録を開始
  CLICK_SIGNUP_TEAM_INVITED: 'CLICK_SIGNUP_TEAM_INVITED', // Team招待画面で登録を開始
  CLICK_SIGNIN_TEAM_INVITED: 'CLICK_SIGNIN_TEAM_INVITED', // Team招待画面でログインを開始
  SYNC_ACCOUNT: 'SYNC_ACCOUNT', // 最初のGoogleアカウント連携を開始
  SYNC_ACCOUNT_SUCCESS: 'SYNC_ACCOUNT_SUCCESS', // 最初のGoogleアカウント連携を完了
  DELETE_ACCOUNT: 'DELETE_ACCOUNT', // Googleアカウント削除
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS', // Googleアカウント削除を完了
  CLICK_LOGIN_TOP: 'CLICK_LOGIN_TOP', // Top画面でログイン
  LOGOUT: 'LOGOUT', // ログアウト
  CONFIRM_SCHEDULING: 'CONFIRM_SCHEDULING', // 日程調整を確定
  CONFIRM_PUBLICURL: 'CONFIRM_PUBLICURL', // 公開URLを確定
  CONFIRM_GROUPPOLL: 'CONFIRM_GROUPPOLL', // 日程投票に投票
  VOTE_GROUPPOLL: 'VOTE_GROUPPOLL', // 日程投票に投票
  CLICK_CANDIDATE_ON_CALENDAR: 'CLICK_CANDIDATE_ON_CALENDAR', // カレンダから候補を選択
  HOVER_CANDIDATE_ON_CALENDAR: 'HOVER_CANDIDATE_ON_CALENDAR', // 候補をHoverする
  CLICK_CANDIDATE_ON_PANEL: 'CLICK_CANDIDATE_ON_PANEL', // パネルから候補を選択
  HOVER_CANDIDATE_ON_PANEL: 'HOVER_CANDIDATE_ON_PANEL', // パネルでHover
  REQUEST_ALTERNATIVE_SCHEDULING: 'REQUEST_ALTERNATIVE_SCHEDULING', // 日程調整確定画面で代替日の依頼
  SUGGEST_ALTERNATIVE_SCHEDULING: 'SUGGEST_ALTERNATIVE_SCHEDULING', // 代替日提案
  EDIT_MYPROFILE: 'EDIT_MYPROFILE', // プロフィールを編集
  CONNECT_ZOOM: 'CONNECT_ZOOM', // Zoomを連携
  UNCONNECT_ZOOM: 'UNCONNECT_ZOOM', // Zoom連携を解除
  OPEN_EVENTDETAIL: 'OPEN_EVENTDETAIL', // 予定をクリックして予定詳細を確認
  EDIT_EVENT: 'EDIT_EVENT', // 予定詳細で編集ボタンをクリック
  DELETE_EVENT: 'DELETE_EVENT', // 予定を削除
  DRAGCALENDAR: 'DRAGCALENDAR', // カレンダーの空いている部分をクリックした
  CLICKMENU: 'CLICKMENU', // 左上の作成をクリックした
  GO_FROM_MENU: 'GO_FROM_MENU', // 画面遷移
  CREATE_EVENT: 'CREATE_EVENT', // 予定作成
  CREATE_SCHEDULE: 'CREATE_SCHEDULE', // 日程調整作成
  EDIT_SCHEDULE: 'EDIT_SCHEDULE', // 日程調整編集
  CREATE_POLL: 'CREATE_POLL', // 日程投票作成
  EDIT_POLL: 'EDIT_POLL', // 日程投票作成
  CREATE_PUBLICURL: 'CREATE_PUBLICURL', // 公開URL作成
  EDIT_PUBLICURL: 'EDIT_PUBLICURL', // 公開URL編集
  DELETE_PUBLICURL: 'DELETE_PUBLICURL', // 公開URL削除
  CLICK_AUTOCANDIDATE: 'CLICK_AUTOCANDIDATE', // 自動抽出クリック
  OPEN_CANDIDATE: 'OPEN_CANDIDATE', // 候補のModal表示
  CLICK_EDIT_ON_CANDIDATE_MODAL: 'CLICK_EDIT_ON_CANDIDATE_MODAL', // 候補のModalからEdit
  CLICK_URL_SHARE_ON_CANDIDATE_MODAL: 'CLICK_URL_SHARE_ON_CANDIDATE_MODAL', // 候補のModalからURLShare
  CLICK_DELETE_ON_CANDIDATE_MODAL: 'CLICK_DELETE_ON_CANDIDATE_MODAL', // 候補のModalからDelete
  CLICK_CONFIRM_ON_CANDIDATE_MODAL: 'CLICK_CONFIRM_ON_CANDIDATE_MODAL', // 候補のModalからConfirm
  GO_FROM_LIST: 'GO_FROM_LIST', // リストから遷移
  CLICK_DELETE_ON_LIST: 'CLICK_DELETE_ON_LIST', // リストから削除
  CLICK_URL_SHARE_ON_EDIT_PAGE: 'CLICK_URL_SHARE_ON_EDIT_PAGE', // 編集画面でURLShare
  CLICK_CONFIRM_ON_EDIT_PAGE: 'CLICK_CONFIRM_ON_EDIT_PAGE', // 編集画面で確定
  CLICK_EDIT_ON_EDIT_PAGE: 'CLICK_EDIT_ON_EDIT_PAGE', // 編集画面で編集
  REMOVE_CANDIDATE_ON_EDIT_PAGE: 'REMOVE_CANDIDATE_ON_EDIT_PAGE', // 編集画面で候補削除
  CLICK_DELETE_ON_EDIT_PAGE: 'CLICK_DELETE_ON_EDIT_PAGE', // 編集画面で削除
  SHOW_OVERVIEW: 'SHOW_OVERVIEW', // Overviewを表示
  SHOW_CALENDAR: 'SHOW_CALENDAR', // Overviewからカレンダーを表示
  SHOW_PAGE_AFTER_CONFIRM: 'SHOW_PAGE_AFTER_CONFIRM', // 確定後のAccess
  CLICK_HTML_SHARE_ON_CANDIDATE_MODAL: 'CLICK_HTML_SHARE_ON_CANDIDATE_MODAL', // 候補のModalからHTMLShare
  SEE_PUBLIC_HTML_URL_SHARE_MODAL: 'SEE_PUBLIC_HTML_URL_SHARE_MODAL', // 公開URLのShare用Modal
  CLICK_PUBLIC_URL_LINK: 'CLICK_PUBLIC_URL_LINK', // 公開URLのリンクをコピー
  SHOW_AVAILABILITY_SHARING_CANDIDATES: 'SHOW_AVAILABILITY_SHARING_CANDIDATES', // 公開URLの候補日を表示
  TOGGLE_PUBLICURL: 'TOGGLE_PUBLICURL', // 公開URLの公開設定変更
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS', // サインアップ成功
  SIGNIN_SUCCESS: 'SIGNIN_SUCCESS', // サインイン成功
  CLICK_NAV_LOGO: 'CLICK_NAV_LOGO', // グロナビのロゴクリック
  CLICK_NAV_PLUS: 'CLICK_NAV_PLUS', // グロナビの生成ボタン
  CLICK_NAV_LIST: 'CLICK_NAV_LIST',
  SHOW_PUBLIC_URL_LIST: 'SHOW_PUBLIC_URL_LIST', // 公開URLのリスト表示
  SHOW_TEAM_SETTING: 'SHOW_TEAM_SETTING', // チーム設定画面表示
  PAGE_ACCESS: 'PAGE_ACCESS', // すべての画面のアクセスを送る
  CLICK_ADD_CALENDAR: 'CLICK_ADD_CALENDAR', // カレンダー追加ボタン押下
  CLICK_CONNECT_GOOGLE_ACCOUNT: 'CLICK_CONNECT_GOOGLE_ACCOUNT', // Google用の登録画面でボタンクリック
  CLICK_ADD_INITIAL_CALENDAR: 'CLICK_ADD_INITIAL_CALENDAR', // 新規登録のカレンダー追加画面でカレンダー追加ボタン押下
  SYNC_ACCOUNT_FAILURE: 'SYNC_ACCOUNT_FAILURE', // カレンダーの追加で失敗
  SIGNUP_FAILURE: 'SIGNUP_FAILURE', // 新規登録で失敗
  CLICK_SIGNIN: 'CLICK_SIGNIN', //
  CLICK_PEEK_CANDIDATE_OPEN: 'CLICK_PEEK_CANDIDATE_OPEN', // 候補を自動抽出をクリック
  CLICK_PEEK_CANDIDATE_SAVE: 'CLICK_PEEK_CANDIDATE_SAVE', // 候補を自動抽出で保存をクリック
  CLICK_PEEK_CANDIDATE_CANCEL: 'CLICK_PEEK_CANDIDATE_CANCEL', // 候補を自動抽出でキャンセルをクリック
  CLICK_ARRANGEMENT_SAVE: 'CLICK_ARRANGEMENT_SAVE', // 候補を提案で保存をクリック
  CLICK_ARRANGEMENT_PREVIEW: 'CLICK_ARRANGEMENT_PREVIEW', // 候補を提案で相手への見え方をクリック
  CLICK_CANDIDATE_ADD: 'CLICK_CANDIDATE_ADD', // 候補を追加をクリック
  CLICK_CANDIDATE_EDIT: 'CLICK_CANDIDATE_EDIT', // 候補を編集をクリック
  CLICK_CANDIDATE_DELETE: 'CLICK_CANDIDATE_DELETE', // 候補を削除をクリック
  CLICK_CANDIDATE_ALL_DELETE: 'CLICK_CANDIDATE_ALL_DELETE', // 候補をすべて削除をクリック
  CLICK_LOGOUT_ON_ADD_CALENDAR: 'CLICK_LOGOUT_ON_ADD_CALENDAR',
  CLICK_ADD_CALENDAR_ON_ADD_CALENDAR: 'CLICK_ADD_CALENDAR_ON_ADD_CALENDAR', // カレンダー追加画面で、登録ボタン押下（登録用Modalが表示される） // カレンダー追加画面で、ログアウトする。
  CLICK_REGISTER_ON_ADD_CALENDAR_MODAL: 'CLICK_REGISTER_ON_ADD_CALENDAR_MODAL', // カレンダー追加画面の確認Modalで登録ボタン押下
  UPDATE_FILTER: 'UPDATE_FILTER', // 空き時間URLのFilterを更新
  DELETE_FILTER: 'DELETE_FILTER', // 空き時間URLのFilterを削除
  TEAM_PLAN_NEW_STRIPE: 'TEAM_PLAN_NEW_STRIPE', // チーム新規プラン選択
  TEAM_PLAN_ASK: 'TEAM_PLAN_ASK', // チームプランお問い合わせ
  TEAM_PLAN_UPDATE_STRIPE: 'TEAM_PLAN_UPDATE_STRIPE', // チームプラン更新
  TEAM_PLAN_REACH_CANCEL: 'TEAM_PLAN_REACH_CANCEL', // チームプラン解約手続きへのリンククリック
  TEAM_ORDER_CANCEL: 'TEAM_ORDER_CANCEL', // チームプランカード登録キャンセル
  TEAM_ORDER_CONFIRM: 'TEAM_ORDER_CONFIRM', // チームプランカード登録確定
  TEAM_PAYMENT_REACH_STRIPE_CUSTOMER_PORTAL: 'TEAM_PAYMENT_REACH_STRIPE_CUSTOMER_PORTAL', // チームプラン支払いページからStripeカスタマーポータルへの遷移
  TEMPLATE_DUPLICATE: 'TEMPLATE_DUPLICATE', // 空き時間URLの複製
  NEW_TEAM_ACCOUNT_SIGN_UP: 'NEW_TEAM_ACCOUNT_SIGN_UP', // チーム作成時のSpirアカウント新規作成
  NEW_TEAM_ACCOUNT_ADD_CALENDAR: 'NEW_TEAM_ACCOUNT_ADD_CALENDAR', //チーム作成時にカレンダー未連携Spirアカウントがカレンダー連携モーダルをクリック
  NEW_TEAM_ACCOUNT_ADD_CALENDAR_AFTER_SIGN_UP: 'NEW_TEAM_ACCOUNT_ADD_CALENDAR_AFTER_SIGN_UP', // SpirAccount作成後にカレンダー未連携Spirアカウントがカレンダー連携モーダルをクリック
  INTEGRATION_SLACK_CONNECT_START: 'INTEGRATION_SLACK_CONNECT_START', // Slack連携開始
  INTEGRATION_SLACK_CONNECT_END: 'INTEGRATION_SLACK_CONNECT_END', // Slack連携完了
  INTEGRATION_SLACK_DISCONNECT: 'INTEGRATION_SLACK_DISCONNECT', // Slack連携解除
  INTEGRATION_SLACK_SET_CHANNEL: 'INTEGRATION_SLACK_SET_CHANNEL', // Slackチャンネルをセット
  INTEGRATION_SLACK_CHANNELS_UPDATE: 'INTEGRATION_SLACK_CHANNELS_UPDATE', // Slackチャンネル更新
  TEAM_CREATED_SUCCESS: 'TEAM_CREATED_SUCCESS', // チーム作成完了（トライアル開始）
  CLICK_CANDIDATE_ON_CONFIRMATION_PAGE: 'CLICK_CANDIDATE_ON_CONFIRMATION_PAGE', // 確定ページで候補を選択
  CLICK_CONFIRM_ON_CONFIRMATION_PAGE: 'CLICK_CONFIRM_ON_CONFIRMATION_PAGE', // 確定ページで確定ボタン押下
  CLICK_CUSTOMIZE_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_CUSTOMIZE_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルを編集開始ボタンをクリック
  CLICK_RE_CUSTOMIZE_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_RE_CUSTOMIZE_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルを再編集開始ボタンをクリック
  CLICK_CANCEL_ON_SLIDE_OUT_OF_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_CANCEL_ON_SLIDE_OUT_OF_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルを編集のスライドUIを閉じるキャンセルボタンをクリック
  CLICK_SAVE_ON_SLIDE_OUT_OF_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_SAVE_ON_SLIDE_OUT_OF_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルを編集のスライドUI上の保存ボタンをクリック
  CLICK_RESET_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_RESET_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルをデフォルトに戻すボタンをクリック
  CLICK_RESET_EXECUTION_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_RESET_EXECUTION_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルをデフォルトに戻すを実行するモーダル上のResetボタンをクリック
  CLICK_RESET_CANCELLATION_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING:
    'CLICK_RESET_CANCELLATION_ON_TITLE_FOR_INDIVIDUAL_EVENTS_OF_TEAM_AVAILABILITY_SHARING', //	チーム空き時間URLの予定のタイトルをデフォルトに戻すをモーダル上でキャンセルするボタンをクリック
  PROVIDER_RESOURCES_FETCH_STARTED: 'PROVIDER_RESOURCES_FETCH_STARTED', //	リソースページでのProviderResource一覧取得開始
  PROVIDER_RESOURCES_FETCH_SUCCEEDED: 'PROVIDER_RESOURCES_FETCH_SUCCEEDED', // リソースページでのProviderResource一覧取得成功
  PROVIDER_RESOURCES_FETCH_FAILED: 'PROVIDER_RESOURCES_FETCH_FAILED', // リソースページでのProviderResource一覧取得失敗
  ROOMS_FETCH_IN_SETTING_STARTED: 'ROOMS_FETCH_IN_SETTING_STARTED', // リソースページでの登録済みRoomsの一覧取得開始
  ROOMS_FETCH_IN_SETTING_SUCCEEDED: 'ROOMS_FETCH_IN_SETTING_SUCCEEDED', // リソースページでの登録済みRoomsの一覧取得成功
  ROOMS_FETCH_IN_SETTING_FAILED: 'ROOMS_FETCH_IN_SETTING_FAILED', // リソースページでの登録済みRoomsの一覧取得失敗
  ROOMS_SAVE_STARTED: 'ROOMS_SAVE_STARTED', // リソースページでのRoomの保存開始
  ROOMS_SAVE_SUCCEEDED: 'ROOMS_SAVE_SUCCEEDED', // リソースページでのRoomの保存成功
  ROOMS_SAVE_FAILED: 'ROOMS_SAVE_FAILED', // リソースページでのRoomの保存失敗
  ROOM_DELETE_STARTED: 'ROOM_DELETE_STARTED', // リソースページでのRoomの削除開始
  ROOM_DELETE_SUCCEEDED: 'ROOM_DELETE_SUCCEEDED', // リソースページでのRoomの削除成功
  ROOM_DELETE_FAILED: 'ROOM_DELETE_FAILED', // リソースページでのRoomの削除失敗
  REGISTER_MEMBER_UPDATE_STARTED: 'REGISTER_MEMBER_UPDATE_STARTED', // リソースページでのRoomの登録メンバーの変更開始
  REGISTER_MEMBER_UPDATE_SUCCEEDED: 'REGISTER_MEMBER_UPDATE_SUCCEEDED', // リソースページでのRoomの登録メンバーの変更成功
  REGISTER_MEMBER_UPDATE_FAILED: 'REGISTER_MEMBER_UPDATE_FAILED', // リソースページでのRoomの登録メンバーの変更失敗
  GOOGLE_PROVIDER_RESOURCE_GRANT_STARTED: 'GOOGLE_PROVIDER_RESOURCE_GRANT_STARTED', //	Googleでの権限追加開始
  NEW_ROOM_GROUP: 'NEW_ROOM_GROUP', // チーム空き時間URLの会議室優先度グループ追加
  ROOM_GROUP_ORDER_CHANGE: 'ROOM_GROUP_ORDER_CHANGE', // チーム空き時間URLの会議室優先度並び替え
  ROOM_GROUP_DELETE: 'ROOM_GROUP_DELETE', //, チーム空き時間URLの会議室グループ削除
  UPGRADE_LINK_CLICKED_IN_PERSONAL_PAGES: 'UPGRADE_LINK_CLICKED_IN_PERSONAL_PAGES', //	個人ページで「無料でアップグレード」ボタンをクリック
  OPEN_EVENTDETAIL_MEMBER_CAN_EDIT: 'OPEN_EVENTDETAIL_MEMBER_CAN_EDIT', // チームの予定でメンバーが代理調整できるイベントをクリックして予定詳細を確認
  CLICK_DELETE_BUTTON_ON_TEAM_MEMBER_EDIT_MODAL: 'CLICK_DELETE_BUTTON_ON_TEAM_MEMBER_EDIT_MODAL', //代理削除ボタンをクリック
  DELETE_TEAM_EVENT_ON_TEAM_MEMBER_EDIT_MODAL: 'DELETE_TEAM_EVENT_ON_TEAM_MEMBER_EDIT_MODAL', //確認モーダルが終わり、代理削除を実行
  CLICK_PLAIN_TEXT_SHARE_ON_CANDIDATE_MODAL: 'CLICK_PLAIN_TEXT_SHARE_ON_CANDIDATE_MODAL', // 候補のModalからPlainTextShare
  EDIT_BY_MEMBER_UPDATE_CONFIRMATION_DIALOG_OPEN: 'EDIT_BY_MEMBER_UPDATE_CONFIRMATION_DIALOG_OPEN', // 代理編集で編集の確定ボタンをクリックしてダイアログを表示
  EDIT_BY_MEMBER_UPDATE_STARTED: 'EDIT_BY_MEMBER_UPDATE_STARTED', // 代理編集での更新内容を送信開始
  EDIT_BY_MEMBER_UPDATE_SUCCEEDED: 'EDIT_BY_MEMBER_UPDATE_SUCCEEDED', // 代理編集の更新成功
  EDIT_BY_MEMBER_UPDATE_FAILED: 'EDIT_BY_MEMBER_UPDATE_FAILED', // 代理編集の更新失敗
  SEND_TEAM_MEMBER_INVITATION: 'SEND_TEAM_MEMBER_INVITATION', // チームメンバー招待送信
  TEAM_MEMBER_JOIN_ACCEPTED: 'TEAM_MEMBER_JOIN_ACCEPTED' //	チームメンバーセットアップ完了ボタンクリック
} as const

// add prefix
Object.keys(SignalType).forEach(typeKey => (SignalType[typeKey] = `${SPIR_PREFIX}${SignalType[typeKey]}`))
export type SignalType = keyof typeof SignalType

// routerで使うためページ遷移系は別に定義する
export const PageAccessSignalType = {
  ACCESS_ARRANGEMENT_CREATE: 'ACCESS_ARRANGEMENT_CREATE', // 候補提案作成ページにアクセス
  ACCESS_SCHEDULE_EDIT: 'ACCESS_SCHEDULE_EDIT', // 日程調整編集ページにアクセス
  ACCESS_SCHEDULE_CONFIRM: 'ACCESS_SCHEDULE_CONFIRM', // 日程調整確定画面にアクセス
  ACCESS_GROUPPOLL_EDIT: 'ACCESS_GROUPPOLL_EDIT', // 日程投票編集画面にアクセス
  ACCESS_GROUPPOLL_VOTE: 'ACCESS_GROUPPOLL_VOTE', // 日程投票投票画面にアクセス
  ACCESS_PUBLICURL_CREATE: 'ACCESS_PUBLICURL_CREATE', // 公開URL作成画面にアクセス
  ACCESS_PUBLICURL_EDIT: 'ACCESS_PUBLICURL_EDIT', // 公開URL編集画面にアクセス
  ACCESS_PUBLICURL_CONFIRM: 'ACCESS_PUBLICURL_CONFIRM', // 公開URL確定画面にアクセス
  ACCESS_PUBLICURL_CONFIRM_GUEST: 'ACCESS_PUBLICURL_CONFIRM_GUEST', // 公開URLゲスト確定画面にアクセス
  ACCESS_EVENT_CREATE: 'ACCESS_EVENT_CREATE', // 予定作成画面にアクセス
  ACCESS_EVENT_EDIT: 'ACCESS_EVENT_EDIT', // 予定編集画面にアクセス
  ACCESS_MYPROFILE: 'ACCESS_MYPROFILE', // プロフィールページにアクセス
  ACCESS_SIGNIN: 'ACCESS_SIGNIN',
  ACCESS_SIGNUP: 'ACCESS_SIGNUP',
  ACCESS_TEAM_PLAN: 'ACCESS_TEAM_PLAN', // チームプラン選択ページ
  ACCESS_TEAM_ORDER: 'ACCESS_TEAM_ORDER', // チームプランクレジットカード登録ページ
  ACCESS_TEAM_PAYMENT: 'ACCESS_TEAM_PAYMENT', // チーム支払い情報ページ
  ACCESS_AUTH_TEAMS_CREATE: 'ACCESS_AUTH_TEAMS_CREATE', // チーム作成画面にアクセス
  ACCESS_AUTH_TEAMS_CREATE_FREE: 'ACCESS_AUTH_TEAMS_CREATE_FREE', // フリープラン用のチーム作成画面にアクセス
  ACCESS_AUTH_TEAM_ID_START_FREE: 'ACCESS_AUTH_TEAM_ID_START_FREE' // トライアルプラン用のチーム作成画面にアクセス
}
// add prefix
Object.keys(PageAccessSignalType).forEach(
  typeKey => (PageAccessSignalType[typeKey] = `${SPIR_PREFIX}${PageAccessSignalType[typeKey]}`)
)
export type PageAccessSignalType = keyof typeof PageAccessSignalType
