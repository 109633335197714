import { GetScheduleListPayload } from '@/lib/api/schedule'
import { Confirmer } from '@/models/data'
import { ScheduleModel } from '@/models/data/schedule'
import ScheduleLocalStorage from '@/models/localStorage/Schedule'
import store from '@/store'
import { uniqBy } from 'lodash'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import * as scheduleAPI from '../../lib/api/schedule'
import { ISchedule, ScheduleStatus } from '../../types'
import CandidatesModule from './candidates'
import EditScheduleModule from './editSchedule'
import EventsModule from './events'
import UserModule from './user'

const MODULE_NAME = 'ScheduleList'

export type GetScheduleListFunction = (payload: GetScheduleListPayload) => Promise<Array<ISchedule>>

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class ScheduleList extends VuexModule {
  scheduleModelList: ScheduleModel[] = []
  scheduleLoading = false

  _confirmedScheduleModelList: ScheduleModel[] = []
  _confirmedScheduleLoading = false

  localStorageSaver = new ScheduleLocalStorage()

  get getScheduleModelList(): ScheduleModel[] {
    return this.scheduleModelList
  }
  get isScheduleLoading() {
    return this.scheduleLoading
  }
  get isConfirmedScheduleLoading() {
    return this._confirmedScheduleLoading
  }
  @Action
  getScheduleByScheduleId(scheduleId: string): ScheduleModel | undefined {
    const schedules: ScheduleModel[] = this.getScheduleModelList
    return schedules.find(s => s.id === scheduleId)
  }
  @Action
  async fetchScheduleList(option?: { hideLoader: boolean }) {
    if (!UserModule.isSignIn) {
      return
    }
    try {
      if (!option?.hideLoader) {
        this.SET_SCHEDULE_LOADING(true)
      }
      const status: ScheduleStatus[] = [
        'suggestedByOrganizer',
        'requestedByConfirmer',
        'requestedByOrganizer',
        'suggestedByConfirmer',
        'expired'
      ]
      const schedules = await scheduleAPI.getList({ status })
      this.ON_SCHEDULE_LIST(schedules)
    } finally {
      this.SET_SCHEDULE_LOADING(false)
    }
  }
  @Action
  async fetchConfirmedScheduleList(option: { isPast?: boolean; getList?: GetScheduleListFunction }) {
    if (!UserModule.isSignIn) {
      return
    }
    try {
      this.SET_CONFIRMED_SCHEDULE_LOADING(true)
      const status: ScheduleStatus[] = ['confirmed']
      let start, end
      if (option?.isPast) {
        start = '2000-01-01T00:00:00Z'
        end = new Date().toISOString()
      } else {
        start = new Date().toISOString()
        end = '2100-01-01T00:00:00Z'
      }
      const callGetApi = option.getList ?? scheduleAPI.getList
      const schedules = await callGetApi({ status, start, end })
      this.ON_CONFIRMED_SCHEDULE_LIST(schedules)
    } finally {
      this.SET_CONFIRMED_SCHEDULE_LOADING(false)
    }
  }
  @Action
  async deleteSchedule(scheduleId: string) {
    this.SET_SCHEDULE_LOADING(true)
    try {
      await scheduleAPI.deleteSchedule(scheduleId)
      this.fetchScheduleList()
      this.ON_DELETE_SCHEDULE(scheduleId)
    } finally {
      this.SET_SCHEDULE_LOADING(false)
    }
  }
  @Action
  async sendNewCandidates(schedule: Pick<ISchedule, 'calendarId' | 'candidates'> & { id: string }) {
    this.SET_SCHEDULE_LOADING(true)
    try {
      await scheduleAPI.sendNewCandidates(schedule.id, schedule.calendarId, schedule.candidates)
      EventsModule.fetchEvents()
    } finally {
      this.SET_SCHEDULE_LOADING(false)
    }
  }
  @Action
  async requestAlternativeCandidates({ scheduleId, confirmer }: { scheduleId: string; confirmer: Confirmer }) {
    this.SET_SCHEDULE_LOADING(true)
    try {
      await scheduleAPI.executeScheduleStatusUpdateAction(scheduleId, 'confirmerRequest', null, confirmer)
      await EditScheduleModule.setScheduleAsEditingSchedule({ scheduleId })
    } finally {
      this.SET_SCHEDULE_LOADING(false)
    }
  }

  @Mutation
  RESET_STATE() {
    this.scheduleModelList = []
    this._confirmedScheduleModelList = []
    this._confirmedScheduleLoading = false
    this.scheduleLoading = false
  }
  @Mutation
  ON_SCHEDULE_LIST(scheduleList: Array<ISchedule>) {
    this.scheduleModelList = scheduleList.map(s => new ScheduleModel(s))
  }
  @Mutation
  ON_ADD_UPDATE_SCHEDULE(schedule: ScheduleModel) {
    const findIndex = this.scheduleModelList.findIndex(s => s.id === schedule.id)
    if (findIndex >= 0) {
      Vue.set(this.scheduleModelList, findIndex, schedule) // make reactive
    } else {
      this.scheduleModelList.push(schedule)
    }
  }
  @Mutation
  ON_DELETE_SCHEDULE(scheduleId: string) {
    const findIndex = this.scheduleModelList.findIndex(s => s.id === scheduleId)
    if (findIndex >= 0) {
      this.scheduleModelList.splice(findIndex, 1)
    }
  }
  @Mutation
  SET_SCHEDULE_LOADING(status: boolean) {
    this.scheduleLoading = status
  }
  @Mutation
  ON_CONFIRMED_SCHEDULE_LIST(scheduleList: Array<ISchedule>) {
    const newList = [...this._confirmedScheduleModelList, ...scheduleList.map(s => new ScheduleModel(s))]
    this._confirmedScheduleModelList = uniqBy(newList, 'id')
  }
  @Mutation
  SET_CONFIRMED_SCHEDULE_LOADING(status: boolean) {
    this._confirmedScheduleLoading = status
  }
}

export default getModule(ScheduleList)
