<template>
  <button class="timezone-button" @click="openModal">
    <span>{{ timeZoneDisplayName }}</span>
  </button>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component'
import timezoneButtonMixin from './updateAllTimezone'

@Component
export default class TimezoneButton extends mixins(timezoneButtonMixin) {}
</script>

<style scoped lang="scss">
.timezone-button {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  font-size: 8px;
  line-height: 120%;
  background-color: white;
  color: $spir2_gray;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  padding: 3px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  span {
    white-space: nowrap;
  }
}
</style>
