import { common } from './ja/common'
import { features } from './ja/features'
import { layouts } from './ja/layouts'
import { pages } from './ja/pages'
export const ja = {
  pages: pages,
  features: features,
  layouts: layouts,
  afterConfirm: {
    attendee: '参加者{number}名',
    header: {
      canceled: {
        description: '予定が削除され、あなたと参加者にキャンセル通知のメールを配信しました。',
        'description-mobile': '予定が削除され、\nあなたと参加者にキャンセル通知のメールを配信しました。',
        title: '予定をキャンセルしました'
      },
      normal: {
        description: 'あなたと参加者に\n確定通知のメールを配信しました。',
        'description-mobile': 'あなたと参加者に\n確定通知のメールを配信しました。',
        title: '日程が確定されました'
      }
    },
    confirmUrl: {
      link: "再度日程調整をする場合は<a href='{href}' target='_blank' rel='noopener noreferrer'>こちら</a>からお申し込みください。",
      'link-mobile':
        "再度日程調整をする場合は\n<a href='{href}' target='_blank' rel='noopener noreferrer'>こちら</a>からお申し込みください。"
    }
  },
  alternativeCandidates: {
    confirmationModal: {
      body: '調整相手から代替日が提案されています。<br>日程調整の確定画面から日程を確定してください。',
      title: '提案された代替日から日程調整を確定しますか？'
    },
    dropdown: {
      requestDetail: '調整相手に別の代替日を依頼する',
      requestTitle: '代替日の依頼',
      suggestDetail: '別の代替日を選択して提案する',
      suggestTitle: '代替日の提案'
    },
    inRequestingMessage: '代替日を提案中です。',
    modal: {
      confirmedAnnotation: '※ 改めて調整相手にメッセージを送付する必要はございません',
      mail: 'メールアドレスを入力',
      otherEmail: '選択肢以外のカレンダーに登録',
      otherEmailDescription: 'カレンダー招待と日程調整通知が以下のメールアドレスに送信されます。',
      requestDetail: {
        notSignIn: '代替日の通知先を登録し、確定してください。',
        signIn: '日程調整に登録するカレンダーを選択し、確定してください。'
      },
      requestedByConfirmer: {
        detail: '改めて日程調整の連絡が来るのをお待ちください。',
        title: '代替日を依頼しました'
      },
      requestTitle: '調整相手に代替日を依頼しますか？',
      suggestDetail: {
        notSignIn: '日程調整確定時の通知先を登録し、確定してください。',
        signIn: 'カレンダーを選択し、確定してください。'
      },
      suggestedByOrganizer: {
        detail: '日程調整相手に代替日をメールで通知しました。',
        title: '代替日を提案しました'
      },
      suggestTitle: '調整相手に代替日を提案しますか？'
    },
    toastMessage: '代替日をカレンダーからドラッグして選択'
  },
  app: {
    update: '更新',
    updateAvailableMessage: '新しいバージョンがあります'
  },
  availabilitySharing: {
    attendee: {
      and: '全員予定が合う',
      or: '1人でも予定が合えば'
    },
    confirmationForm: {
      description: '日程確定時に表示するフォーム',
      message: {
        description: common.messageToOrganizer,
        tooltip:
          '調整相手が日程確定時に主催者にメッセージを伝えることができます。\nメッセージ欄の表示有無と、メッセージを必須とするか任意とするかを選択してください。'
      }
    },
    createButton: {
      teamAndPrivateSelector: {
        label: '空き時間URLを作成する対象を選択'
      },
      teamPrivateSelector: {
        nextButton: '次へ'
      }
    },
    description: 'スケジュールの空きをURLで共有する',
    editLabel: '空き時間URLの設定',
    extractCalendarConfirm: {
      attendee: {
        body: '予定を考慮するカレンダーから同席メンバーのカレンダーを除外すると、同席メンバーの予定とダブルブッキングしてしまう可能性があります。よろしいですか？',
        header: '同席メンバーのカレンダーを除外しますか？'
      },
      organizer: {
        body: '抽出対象から主催者のカレンダーを除外すると、主催者の予定とブッキングしてしまう可能性があります。よろしいですか？',
        header: '主催者のカレンダーを除外しますか？'
      }
    },
    form: {
      attendee: {
        helpText: '※調整相手は除く',
        label: '同席メンバー',
        placeholder: '同席メンバーを追加'
      },
      attendingMembers: {
        help: '主催者と共に予定に参加する\nメンバーを設定してください。\n ※調整相手を設定する必要はありません',
        label: '主催者側の同席メンバー',
        placeholder: '同席メンバーを追加',
        notice:
          "同席メンバーとして追加するには<a class='support-page-link' href='https://help.spirinc.com/ja/google/microsoft%E3%81%A7%E8%A1%A8%E7%A4%BA%E5%8F%AF%E8%83%BD%E3%81%AA%E3%82%AB%E3%83%AC%E3%83%B3%E3%83%80%E3%83%BC%E3%81%8Cspir%E3%81%A7%E8%A1%A8%E7%A4%BA%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%9B%E3%82%93' target='_blank' rel='noopener noreferrer'>カレンダーを連携する</a>必要があります"
      },
      candidateDescription: {
        help: '調整相手が日程確定する画面で、この空き時間URLの説明文として表示されます。',
        label: '相手に見える説明文',
        placeholder: '例 : 外部の方々とのミーティング用に空き時間を提示しています。ご都合の良い日時をお選びください。'
      },
      conditionForExtract: {
        end: '期間',
        label: '抽出条件',
        max: '1日の予定数の上限',
        start: '開始日'
      },
      confirmationForm: {
        active: {
          off: '非表示',
          on: '表示'
        },
        required: {
          off: '任意',
          on: '必須'
        }
      },
      confirmMessage: {
        label: '確定時に主催者に送信するメッセージ',
        placeholder: '予定の目的や趣旨を入力'
      },
      eventTitle: {
        help: '調整相手が日程確定する画面での表示タイトルです。\n確定時には予定のタイトルとして表示されます。',
        label: '相手に見えるタイトル',
        placeholder: '相手に見えるタイトルを入力'
      },
      exception: {
        addButton: '除外日時を追加',
        description: '設定した抽出条件から例外的に除外したい日時を設定できます',
        emptyList: '除外日時はありません',
        exclude: '除外',
        excludeList: '除外日時の一覧',
        help: 'カレンダーをドラッグすると候補から除外したい日時を追加できます。',
        holidayExclude: '除外祝日',
        holidayObserved: '振替休日',
        include: '追加',
        label: '候補から除外する日時',
        noException:
          '設定している候補の抽出条件から例外的に除外したい日時をカレンダーをドラッグ、もしくは「除外日時を追加」ボタンから指定できます。',
        set: 'に指定する'
      },
      extractCalendar: {
        attendeeLabel: '同席メンバー',
        attendeeLabelForOr: '参加する候補メンバー',
        help: 'チェックしたカレンダーの予定を考慮して抽出します。',
        label: '予定を考慮するカレンダー',
        organizerLabel: '主催者',
        otherCalendarLabel: '他のカレンダー',
        placeholder: 'カレンダーを追加'
      },
      holidayException: {
        addButton: '除外する祝日を追加',
        addButtonDisabled: '最大{count}つの国を選択できます',
        country: '{country}の祝日',
        help: '指定した祝日の候補を除外します。',
        label: '候補から除外する祝日'
      },
      panels: {
        basicInfo: {
          inputAdditional: '基本情報の他の項目を設定する',
          label: '基本情報'
        },
        condition: {
          inputAdditional: '候補枠の詳細設定',
          label: '候補枠'
        },
        memberPick: {
          inputAdditional: '詳細を設定',
          label: '主催者'
        },
        organizerAndAttendee: {
          inputAdditional: '候補枠の抽出するカレンダーを選択',
          label: '主催者'
        },
        targetCalendars: '予定を考慮するカレンダーの設定'
      },
      title: {
        help: 'この空き時間URLの管理画面でのタイトルです。\n調整相手には見えません。',
        label: '管理用タイトル',
        placeholder: 'タイトルを入力'
      },
      webMeeting: {
        disabled: '設定しない',
        enabled: '設定する',
        enabledDescriptions: {
          description: '選ばれたメンバーのアカウントで順位が高いツールから優先してWEB会議を作成します。',
          label: '自動作成の優先順'
        },
        label: 'Web会議'
      },
      weekdayCondition: {
        editButton: '曜日と時間の設定',
        label: '候補の設定'
      },
      confirmationPage: {
        label: '日程調整確定後の完了ページ',
        dropdown: {
          spir: 'Spirの日程調整完了ページを表示',
          external: 'ご指定の別サイトにリダイレクト'
        },
        externalConfirmationPageSetting: {
          urlInput: {
            label: 'リダイレクトする別ページのURL',
            placeholder: 'URLを入力'
          },
          passEventDetails: 'リダイレクト先のページにて日程調整結果の情報を扱えるようにする',
          passEventDetailsHelpText: `URLの末尾にパラメーターとして日程や参加者などの情報を追加することができます。</br>そのためSpir以外のWebサイトでも情報を表示したり扱う事ができます。`
        }
      }
    },
    list: {
      card: {
        action: {
          copyLink: 'URLをコピー',
          delete: '削除',
          edit: '編集',
          showCandidates: '候補を確認',
          showShareModal: '共有'
        },
        helpPublishSetting: '公開するには設定を変更してください。'
      },
      create: '空き時間URLを作成',
      help: {
        message: '空き時間URLは、条件に合致する空き時間から候補を抽出して共有する繰返し利用できるURLです。',
        usage: '使い方'
      },
      messages: {
        blank: {
          description:
            '空き時間URLは固定のURLであなたが設定した条件に合致する空き時間を候補として表示する日程調整機能です。右上の作成ボタンから作成しましょう。',
          title: '空き時間URLはありません'
        }
      }
    },
    messages: {
      error: {
        AVAILABILITY_UPDATED: '他の予定が入ったため確定できませんでした。再度URLにアクセスしてください。',
        PROCESS_TIMEOUT:
          'サーバーでの処理に時間がかかったため確定できませんでした。お手数ですがもう一度お試しください。',
        DISCONNECTED_ZOOM_INTEGRATION: 'Zoom連携が解除されています。再度連携を行ってください。',
        EXCEED_MAX_COUNT: '作成可能な空き時間URLの数は{count}件です。',
        EXCEED_MAX_PUBLISHED_COUNT: '公開可能な空き時間URLの数は{count}件です。',
        CUSTOM_TITLE_FOR_INDIVIDUAL_EVENTS_TEMPLATE_ITEM_INVALID:
          'フォームの質問項目が変更されたため、設定が無効になりました。「カレンダーに登録される予定のタイトル」の設定を再確認してください。',
        CUSTOM_TITLE_FOR_INDIVIDUAL_EVENTS_TEMPLATE_INVALID:
          'フォームの質問項目が変更されたため、設定が無効になりました。「カレンダーに登録される予定のタイトル」の設定を再確認してください。'
      }
    },
    span: '間隔',
    title: '空き時間URL',
    weekdays: { ...common.weekdays },
    weekPicker: '希望する時間帯をドラッグで選択'
  },
  buttons: {
    aboutDetail: '詳細をみる',
    aboutSpir: 'Spirについて',
    accountReconnect: 'アカウント再連携',
    add: '追加',
    addCalendar: '連携する{type}アカウントを選択',
    addCalendarSide: 'カレンダーを追加',
    addCalendarWith: '{type}カレンダー連携',
    allDay: '終日',
    back: common.back,
    backToHome: 'ホームで確認',
    backToTop: 'トップページへ',
    cancel: common.cancel,
    change: '変更',
    checkFromCalendar: 'カレンダーから確認',
    confirm: common.confirm,
    confirmCandidates: '予定を確定',
    confirmSchedule: common.confirmSchedule,
    confirmScheduleDetail: 'この候補で日程を確定する',
    connect: '連携',
    copyAvailabilityUrl: '空き時間URLをコピー',
    copyMailText: '候補をテキストでコピー',
    copyMailTextDetail: 'リンク付のテキストをコピーする',
    copyScheduleLink: '日程調整URLコピー',
    copyShareHTML: 'HTMLをコピー',
    copyShareURL: '{type}URLをコピー',
    copyShareURLDetail: 'URLリンクだけをコピーする',
    copyUrl: 'URLをコピー',
    create: '作成',
    createEdit: '{type}を作成・編集',
    createEvent: '予定作成',
    createFrom: '{type}から作成',
    delete: '削除',
    deleteAll: 'すべて削除',
    detail: '詳細',
    discard: '破棄',
    disconnect: '解除',
    edit: '編集する',
    editing: '編集',
    JoinOnlineMeeting: '{meetingType}に参加',
    loginWith: '{type}でログイン',
    next: '次へ',
    noAvailableCandidate: '参加可能な候補がない',
    ok: 'OK',
    preview: 'プレビュー',
    previewConfirmation: '調整相手への見え方を確認',
    privacyPolicy: 'プライバシーポリシー',
    proceedSelectCandidateDate: '候補の選択に進む',
    profile: 'プロフィール',
    removeCalendar: '連携解除',
    requestOrSuggestAlternativeCandidates: '代替日を依頼・提案',
    save: '保存',
    select: '選択',
    selectFromCalendar: 'カレンダーから選択',
    selectFromList: 'リストから選択',
    selectGoogleAccount: '連携するGoogle/Microsoftアカウントを選択',
    settings: '設定',
    share: '共有',
    shareCreatedLink: '{type}URLをシェア',
    showAll: 'その他({count}名)',
    showAuthModal: '自分の予定を表示',
    showAuthModalForPreview: '登録して予定を表示',
    showAuthModalFromCalendarListPanel: '自分の予定を表示',
    showConfirmation: '確定画面を確認',
    showMore: 'すべて表示',
    showOtherCandidates: '他の候補を見る',
    showScheduleDetailsForm: '詳細設定を追加',
    signOut: 'ログアウト',
    startOrLoginWith: '{type}ではじめる',
    startSpir: 'Spirをはじめる',
    startWith: '{type}で新規登録',
    submit: '登録',
    suggestAlternativeCandidates: '代替日を提案',
    terms: '利用規約',
    update: '保存',
    updateProfile: 'プロフィール入力',
    voteToAvailableCandidates: '参加可否を回答'
  },
  calendar: {
    month: '{month}月',
    year: '{year}年'
  },
  calendarList: {
    calendarColor: {
      applyingTarget: 'Spirのみに反映されます。',
      pickingColorButton: '保存',
      pickingColorNote: 'このカレンダーの背景色を選択してください。テキストの色は自動的に調整されます。',
      pickingColorTitle: 'カレンダーの色を選択'
    },
    teamCalendars: {
      changeCalendarConfirmBody:
        '主催者となっている未確定の日程調整が{scheduleCount}件あります。</br>メインカレンダーを変更すると、自分が主催者の日程調整は削除されます。</br>本当に変更しますか？',
      confirmChange: '本当に変更しますか？',
      title: common.teamCalendars
    }
  },
  checkbox: {
    dontShowNextTime: '次回以降表示しない'
  },
  confirms: {
    deleteCandidate: {
      body: '削除しますか？',
      header: '候補を削除'
    },
    headers: {
      pleaseInputEmail: 'メールアドレスを入力',
      pleaseSelectCalendar: 'カレンダーを選択してください'
    },
    link: {
      publishLater: '日程確定後、URLを発行'
    },
    update: {
      body: '保存しますか？',
      header: '変更した内容で更新します。'
    },
    voteIsExist: {
      body: '上書きしますか？',
      header: 'すでに投票されています。'
    }
  },
  createMode: {
    create: '作成',
    menus: {
      arrangement: {
        label: '候補を提案',
        labelWithSomeone: '候補を提案',
        tip: {
          line1: 'カレンダーから選択した候補を提案する',
          line2: '1回限りのURLを活用した調整です。'
        }
      },
      availabilitySharing: {
        label: '空き時間を共有',
        tip: {
          line1: '条件に合致する空き時間から候補を抽出して共有する',
          line2: '繰返し利用できるURLを活用した調整です。'
        }
      },
      event: {
        label: '予定を作成',
        labelByMySelf: '予定を作成',
        message: '新しく予定をカレンダーに登録する'
      }
    },
    sections: {
      event: '予定を作成',
      schedule: '日程を調整'
    },
    title: '調整を作成しよう'
  },
  description: {
    addCalendarForInAppBrowser: {
      message:
        'カレンダーの連携がされていないため、表示することが出来ません。スマホのブラウザ(Safari, Chromeなど)からカレンダーを連携して下さい。',
      title: '未対応のブラウザです'
    },
    availabilitySharing: {
      noCandidates: {
        message1: '選択できる候補がありません。',
        message2: '主催者にお問い合せください。'
      }
    },
    linkText: 'こちら',
    noAvailableCandidates:
      '現在、選択できる候補がありません。明日以降改めて確認いただくか、主催者にお問い合わせください。',
    preview: {
      caution: 'あなたの予定が第三者に閲覧されることはありません。',
      desc: 'Spirに登録するとあなたの予定をカレンダーに表示できます。',
      title: '自分の予定と候補を同時に確認しませんか？'
    },
    scheduleMark: {
      firstLine: 'カレンダーをドラッグ、または「追加」ボタンから候補を追加できます。',
      secondLine: '自動的にダブルブッキングが防止されます。'
    },
    signInLink: 'ログインは',
    signUp: 'カレンダーと連携して、\n簡単に日程調整を始めましょう。',
    signUpAfterConfirmed: {
      appeal:
        'Spirはカレンダーと連携して日程調整を簡単にできるサービスです。\n登録すると、候補の日時と自分の予定を重ねて確認でき、スムーズに日程を決めることができます。',
      description: '登録すると自分のカレンダーを\n確認しながら簡単に日程調整ができます。',
      header: 'Spirに登録しませんか？'
    },
    signUpFromConfirm: 'カレンダーと連携すると\n自分の予定と候補を重ねて表示出来ます。',
    signUpFromConfirmForInAppBrowser:
      'Spirを初めて利用される場合は、スマホのブラウザ（Safari, Chromeなど）から新規登録を行ってください。',
    signUpLink: '新規登録は',
    teamInvitedSignInInfo: {
      link: 'こちら',
      message: 'まだアカウントをお持ちでない方は{0}'
    },
    teamInvitedSignUpInfo: {
      link: 'こちら',
      message: 'すでにアカウントをお持ちの場合は{0}'
    },
    useDefaultBrowser: {
      signIn: {
        messageHtml:
          'このブラウザからは<strong>ログイン</strong>が出来ません。</br>スマホのブラウザ(Safari, Chromeなど)からログインを行って下さい。'
      },
      signUp: {
        messageHtml:
          'このブラウザからは<strong>新規登録</strong>が出来ません。</br>スマホのブラウザ(Safari, Chromeなど)からログインを行って下さい。'
      },
      messageHtml:
        'このブラウザからは<strong>ログイン/新規登録</strong>が出来ません。</br>スマホのブラウザ(Safari, Chromeなど)からログインを行って下さい。'
    }
  },
  displayItem: {
    DateWithTimezoneAndEditButtons: {
      cancel: 'キャンセル',
      readjustment: '再調整'
    }
  },
  event: {
    form: {
      panels: {
        others: {
          inputAdditional: '他の項目を設定'
        }
      },
      title: {
        label: '予定のタイトル',
        placeholder: 'タイトルを入力'
      }
    },
    list: {
      toast: {
        cannotShowDeletedMemberEvent: '削除されたメンバー主催のイベントは確認できません'
      }
    }
  },
  forms: {
    auth: {
      addCalendar: {
        explanation: {
          aboutDataHandling: {
            caption: 'データの取り扱いについて',
            description:
              'Spir上の予定作成や日程調整を除くカレンダー情報はSpir内に保存されません。また、第三者が許可なく連携したカレンダー情報を閲覧・アクセスすることはできません。'
          },
          aboutPermission: {
            caption: '権限が必要な理由',
            description:
              'Spirでは予定を見つつ候補を設定したり、確定した予定を登録するために、カレンダー情報の表示・編集・アクセスが必要となります。'
          }
        },
        selectCalendar: '連携するカレンダーを設定',
        title: '権限を許可して\n連携するカレンダーを選択してください'
      },
      noSupport: {
        title: 'このブラウザでは利用できません'
      },
      signIn: {
        title: 'ログイン'
      },
      signUp: {
        title: '新規登録'
      }
    },
    common: {
      arrangementType: {
        poll: '投票型',
        schedule: '確定型'
      },
      attendee: {
        label: '参加者',
        placeholder: '参加者を追加'
      },
      bufferTime: {
        label: '前後の確保時間',
        placeholder: '予定の前後に時間を確保できます'
      },
      candidate: {
        label: '候補'
      },
      collapseButtonTitle: {
        candidateTargetCalendarsSettings: {
          title: '抽出するカレンダーを設定'
        },
        notificationSettings: {
          title: '通知'
        },
        timezoneAndExceptions: {
          title: 'タイムゾーン・例外設定'
        }
      },
      description: {
        label: 'メモ',
        placeholder: 'メモを追加'
      },
      duration: {
        label: '打合せ時間',
        placeholder: '打合せ時間を選択'
      },
      location: {
        label: '場所',
        placeholder: '場所を追加'
      },
      maxNumPerDay: {
        label: '1日の予定数の上限'
      },
      meetingRoom: {
        label: '会議室',
        placeholder: '会議室を追加'
      },
      offsetFromStartDate: {
        help: '開始日から最大3ヶ月分の候補が抽出されます',
        label: '期間'
      },
      organizer: {
        label: '主催者'
      },
      sectionTitle: {
        attendee: '参加者',
        basicInfo: '基本情報',
        candidate: '候補',
        itemsAfterConfirmation: '日程確定時に表示するフォーム',
        others: 'その他情報', // TODO: QUESTIONNAIRE_FEATURE
        details: '詳細情報',
        targetType: '作成対象'
      },
      startDate: {
        label: '開始日'
      },
      targetCalendars: {
        label: '候補枠を抽出するカレンダー',
        placeholder: 'カレンダーを追加'
      },
      timeZone: {
        label: 'タイムゾーン設定'
      },
      title: {
        label: 'タイトル',
        placeholder: 'タイトルを入力'
      },
      visibility: {
        label: '公開設定',
        placeholder: '公開設定を選択'
      },
      transparencyStatus: {
        label: '予定あり・なし',
        placeholder: '外部向け表示設定を選択'
      }
    },
    condition: {
      bufferTime: '前後の確保時間',
      candidateCount: '{count}枠',
      candidateCountLabel: '該当候補：',
      error: {
        noRules: '希望する時間帯が選択されていません。'
      },
      isPublished: '公開',
      label: '候補の自動抽出条件',
      maxNumPerDay: '1日の予定数の上限(既存の予定数を含む)',
      period: '抽出期間'
    },
    event: {
      addAccount: 'アカウントを追加',
      addDescription: 'メモを追加',
      addLocation: '場所を追加',
      addMeetingRoom: '会議室を追加',
      allDay: '終日',
      date: 'イベント時間',
      deletedMark: '削除済み予定',
      duration: '{min}分',
      durationHour: '{hour}時間',
      title: 'タイトル',
      visibility: {
        default: 'デフォルトの公開設定',
        private: '非公開',
        public: '公開'
      },
      transparencyStatus: {
        free: '予定なし',
        tentative: '仮の予定',
        busy: '予定あり',
        oof: '休暇',
        workingElsewhere: '離席',
        unknown: '不明'
      }
    },
    sections: {
      arrangementTypeRadioButton: {
        poll: {
          description: '参加可否を投票してもらいあなたが日程確定する調整',
          title: '投票型の調整'
        },
        schedule: {
          description: '調整相手が候補から日程確定する調整',
          title: '確定型の調整'
        }
      },
      peekCandidates: {
        autoCandidateNotice: {
          tipMark: 'チェックしたカレンダーの\n予定を考慮して抽出します。',
          title:
            "自動で<a class='double-booking-link' href='{href}' target='_blank' rel='noopener noreferrer'>ダブルブッキング</a>が防止されます"
        },
        changeModeButton: {
          label: '候補を自動抽出'
        },
        list: {
          addButton: common.addPossibleDates,
          empty: '候補はありません'
        },
        title: '候補'
      },
      peekCandidatesForm: {
        header: '候補を自動抽出',
        runPeekButton: 'この候補を反映'
      }
    }
  },
  globalNavigation: {
    other: 'その他',
    arrangementsOnCalendar: 'カレンダー',
    close: '閉じる',
    createSchedule: '候補を提案',
    help: 'ヘルプ',
    home: 'ホーム',
    list: '調整一覧',
    menu: 'メニュー',
    notification: '通知',
    open: '開く',
    setting: '設定',
    support: 'サポート'
  },
  groupPoll: {
    candidate: '候補',
    candidateTitlePrefix: '[投票中]'
  },
  input: {
    placeholder: {
      mail: 'メールアドレスを入力',
      search: 'すべて'
    }
  },
  inviteMemberModal: {
    addMember: '招待するメンバーを追加',
    description: '招待メンバーのメールアドレスを入力してください',
    mailTextInput: {
      label: 'メールアドレス',
      placeholder: 'name@example.com'
    },
    sendMail: '招待メールを送信',
    title: 'メンバーを招待'
  },
  labels: {
    allDay: '終日',
    attendee: '参加者',
    attendees: '同席者',
    begin: '開始',
    calendarOnConfirm: '日程を登録するカレンダー',
    candidate: '候補',
    company: '会社名',
    create: '新規作成',
    date: '日付',
    dateTime: '日時',
    duration: '打合せ時間',
    email: 'メールアドレス',
    end: '終了',
    endDateTime: '終了日時',
    eula: '利用規約',
    event: '予定',
    holiday: {
      countries: {
        au: 'オーストラリア',
        br: 'ブラジル',
        ca: 'カナダ',
        cn: '中国',
        de: 'ドイツ',
        fr: 'フランス',
        gb: 'イギリス',
        hk: '香港',
        in: 'インド',
        jp: '日本',
        kr: '韓国',
        sg: 'シンガポール',
        th: 'タイ',
        tw: '台湾',
        us: 'アメリカ合衆国'
      }
    },
    inputSomething: '{attr}を入力',
    invalidAccount: '無効なアカウント',
    lastUpdatedAt: '最終更新',
    list: '{type}一覧',
    meetingId: 'ミーティングID',
    meetingRoom: '会議室',
    multiple: '他',
    myCalendars: 'マイカレンダー',
    name: '名前',
    newEvent: '新しいイベント',
    none: 'なし',
    notInputted: '未入力',
    notSet: '未設定',
    noVotes: '回答者0人',
    one_day: '1日',
    one_week: '7日',
    optional: '任意',
    organizer: '主催者',
    otherCalendars: '他のカレンダー',
    participant: '同席メンバー',
    participantsCount: '{count}人',
    password: 'パスワード',
    pattern: 'パターン',
    poll: '投票型の調整',
    poweredBy: 'Powered by',
    previewRequestConfirm: '調整相手の確定画面プレビュー',
    privacyPolicy: 'プライバシーポリシー',
    publish: '公開',
    rejected: '[取消]',
    requestConfirm: '日程調整',
    requestPoll: '投票依頼',
    schedule: '確定型の調整',
    selectAccount: 'アカウントを選択',
    spir: 'Spir',
    startDateTime: '開始日時',
    suggestAlternativeCandidates: '代替日を提案',
    three_days: '3日',
    time: {
      hour: '{hour}時',
      minute: '{min}分'
    },
    displayDuration: {
      hour: '{duration}時間',
      min: '{duration}分'
    },
    timePeriod: '時間',
    title: 'タイトル',
    today: '今日',
    url: 'URL',
    visibility: '公開設定',
    transparencyStatus: '予定あり・なし',
    vote: {
      no: '欠席',
      yes: '参加'
    },
    votedPeople: '投票者',
    voters: '回答者'
  },
  message: {
    addAttendee: 'あなた以外の同席者を追加する場合は、氏名とメールアドレスを入力して追加してください。（任意）',
    addedConfirmedEvent: 'カレンダーに登録しました',
    attendeeNoResultInfo: '追加したい参加者のEmailを入力してください',
    betterCommunication: '候補の共有時にコミュニケーションが スムーズに\nなります。',
    cancelToCheckEvents: '予定確認を解除',
    cannotAddCandidateInPollEdit: '候補の新規追加や編集はできません。',
    changeOnlineMtgUnavailable: 'カレンダーが変更されたため、Web会議の方法を変更しました。',
    commonSuccess: '正常に完了しました。',
    confirm: {
      attendee: {
        add: '追加',
        emailHelper: 'gmailアカウントを入力すると、予定をカレンダーに自動登録できます',
        emailPlaceholder: 'sample@spirinc.com',
        namePlaceholder: '株式会社Spir 山田 太郎',
        title: '他の参加者を招待する'
      },
      attendeeInputSubLabel: '他の参加者を招待する',
      calendarSelectLabel: 'カレンダーを選択し、確定してください。',
      calendarSelectSubLabel: '予定を登録するカレンダーを選ぶ',
      confirmWithPollCount: '参加可{yesCount}、参加不可{noCount}で確定しますか？',
      desc: 'カレンダーを選択し、確定してください。',
      doNotNeedToSendEmail: '※改めて主催者にメッセージを送付する必要はございません',
      notSignIn: 'あなたのメールアドレスを入力し、確定してください。',
      otherCalendar: {
        add: '追加',
        emailHelper: 'カレンダー招待と確定通知がメールアドレスに送信されます。',
        emailPlaceholder: 'sample@spirinc.com',
        title: '他のカレンダーに登録'
      },
      pleaseInputYourInfo: '調整相手にあなたのことを伝えて確定しましょう',
      pleaseInputYourInfoSubLabel: 'あなたの情報を入力する',
      sentToOrganizer: '主催者に日程投票が通知されました',
      sentToVotedUsers: '投票者に確定した日程が通知されました',
      title: '日程を確定しますか？',
      useAttendee: 'メールアドレスで招待',
      useOtherCalendar: '他のカレンダーに登録'
    },
    confirmBySpecificDate: 'この日程で確定しますか？',
    confirmCandidates: '{length}件の候補を確定',
    confirmDelete: '削除してもよろしいですか？',
    confirmDeleteAccountDescription:
      'アカウントを解除すると解除されるアカウントから作られたデータも利用できなくなります。\n・日程調整\n・日程投票\n・空き時間URL\nアカウントを解除しますか？',
    confirmDeleteAccountTitle: '連携を解除してもよろしいですか？',
    confirmDeleteCandidateTitle: 'この候補を削除してもよろしいですか？',
    confirmDeleteWholeTitle: '{type}を削除してもよろしいですか？',
    confirmDiscard: '編集中の内容を破棄しますか？',
    confirmedCandidate: '日程が確定されました',
    confirmedVote: '参加可能日の投票が完了しました',
    copiedToClipBoard: 'クリップボードにコピーされました',
    copiedToClipBoardFailure: 'クリップボードにコピーできませんでした',
    createdSchedule: '{type}を作成しました',
    deleteAccountFailByPrimaryCalendar:
      'このアカウントは、チームのメインカレンダーに使用されているので連携を解除することができません。',
    deleteCandidateConfirm: '{type}から選択した候補を削除します。<br>この操作は取り消せません。',
    deleteConfirm: '他の候補がないため, <b>{type}</b>を削除します。<br>この操作は取り消せません。',
    deleteLastCandidate: '他の候補がないため日程調整を削除しますがよろしいですか？',
    deleteOperationWarning: 'この操作は取り消せません。',
    disabledDueToAttendee: '同席メンバーがいるため無効です',
    doYouWantToUpdateProfile: 'プロフィールを充実させませんか？',
    dragToAddCandidates: 'ドラッグして候補を追加',
    emailChange: {
      failed: 'メールアドレスが変更できませんでした。もう一度やり直してください。',
      requireLoginAgain: '再ログインが必要です。',
      success: 'メールアドレスの認証メールを送信しました。'
    },
    error: {
      404: '有効なページがありません',
      accountAlreadyExists: 'このアカウントはすでに別のSpirアカウントで利用されています。',
      accountExistsInAnotherCredential:
        'このメールアドレスは別の認証方法で既にユーザー登録されているため、 <br>ユーザー登録した認証方法で再度ログインして下さい',
      addCalendar: {
        accountDuplicated: 'このアカウントはすでに別のSpirアカウントで利用されています。',
        failInGoogleAuth:
          'Google認証できません。Cookieをブロックしている場合は、Cookieをすべて受け入れるように設定を変更してください。',
        notEnoughScope:
          'アクセス権の付与が不足しているため連携に失敗しました。\nすべてのアクセス権の許可をしてください。',
        unknown: 'エラーが発生しました。'
      },
      arrangementType: {
        failedToFetchTeamRecord: 'チーム情報を取得できませんでした。'
      },
      atLeastOneCalendar: '1つ以上必要です。',
      auth: {
        accountDuplicated: 'このアカウントはすでに別のSpirアカウントで利用されています。',
        accountExistsInAnotherCredential: '他の連携方法で登録されています。',
        canceled: 'キャンセルしました',
        failInGoogleAuth:
          'Google認証できません。Cookieをブロックしている場合は、Cookieをすべて受け入れるように設定を変更してください。',
        notEnoughScope:
          'アクセス権の付与が不足しているため連携に失敗しました。\nすべてのアクセス権の許可をしてください。',
        noUser: 'ユーザーが登録されていません。',
        unknown: 'エラーが発生しました。'
      },
      candidateError: {
        cannotUpdateShortThenDuration: '打合せ時間より短い候補には変更できません。',
        endMustAfterStart: '開始時刻が終了時刻より後です。',
        intersection: '候補が重複しています。',
        mustBeBeforeNow: '過去の候補は選択できません。',
        durationExceedsLimit: '選択可能な候補期間は24時間未満です。'
      },
      checkForm: '入力内容を確認してください。',
      confirmed: '日程調整は確定済みです。',
      deleted: '日程調整が削除されています',
      duplicated: '同じ時間が選択されています。',
      exceedMacLength: '内容が長すぎたので以前の内容に戻しました。',
      invalid: '{attr}が有効ではありません',
      invalidInvitationCode: '無効な招待コードです',
      mustBeBeforeNow: '過去の候補は選択できません。',
      noCandidateInRange:
        '候補が更新されたため、確定することが出来ませんでした。再度URLにアクセスし、もう一度候補を選択してください。',
      noPoll: '日程投票がありません',
      noPrimaryCalendar: '有効なカレンダーを選択してください。',
      noSchedule: '日程調査がありません',
      notEnoughScope: '「すべてのカレンダーの予定の表示と編集」と「カレンダーを表示」にチェックを入れてください。',
      notSupported: '{value} はサポートされていません',
      onlineMeetingToolDisconnected: 'Zoomとの連携が切断されています。',
      pastEvent: '過去のイベントのため表示できません。',
      pleaseLogin: 'ユーザーがすでに存在します。ログインしてください。',
      required: '{attr}を入力してください。',
      serverError: '技術的な問題が発生しています',
      validation: {
        excluded: '{attr}が重複しています。',
        emailExcluded: '他の項目と{attr}が重複しています。',
        invalid: '{attr}が有効ではありません',
        max: '最大値は{length}です。',
        min: '最低限は{length}文字が必要です。',
        required: '{attr}を入力してください。',
        phoneNumber: '電話番号が正しくありません。',
        requiredAtLeastOneCheck: '選択してください',
        requiredExternalConfirmationPageUrl: 'URLを入力してください',
        httpsOnly: '入力できるURLはhttps形式のみとなります'
      },
      notFoundSlackCallbackState: 'Slack連携に関するデータが見つかりませんでした。',
      teamNotAvailable: 'チームを利用できません'
    },
    errorCommon: 'エラーが発生しました。',
    fromSelectedCalendars: '選択中のカレンダーから取得',
    havePlan: '予定あり',
    holidayExclusionDisconnect: {
      description: '解除後、左の編集エリアから再度設定できます。',
      title: '候補抽出を除外する「{country}」を解除してもいいですか？'
    },
    inputYourEmailAndConfirm: 'あなたのメールアドレスを記入し、確定してください。',
    meetingRoomsRefreshed: '主催者が変わったため、会議室がリセットされました。',
    noAttendee: 'まだ投票されていません',
    noPattern: '{type}はありません',
    noResult: '結果無し',
    notAllowedCalendar: '書き込みできないカレンダーです',
    noTitle: 'タイトルなし',
    onlineMtgUpdated: 'カレンダーが変更されたため、Web会議の方法を変更しました。',
    overMaxCandidate: '候補は{count}個まで作成できます。',
    pleaseConfirmFromBelowUrl: '以下のURLから候補を確定して下さい。',
    pleaseSelect: '選択してください',
    pleaseSelectAvailableCandidate: '参加可能な候補を選択',
    pleaseSelectCandidates: common.pleaseSelectCandidates,
    pleaseWaitForSync: 'カレンダーと同期中です。少々お待ちください。',
    pollOverview: {
      header: '日程投票の依頼が届いています',
      subHeader: '参加可能な候補を選択してください'
    },
    preview: {
      notAllowedConfirm: 'プレビューでは確定できません',
      notAllowedRequest: 'プレビューでは回答できません',
      notAllowedRequestAlternativeCandidates: 'プレビューでは代替日の依頼はできません'
    },
    profileUrlCopied: 'プロフィールリンクがクリップボードにコピーされました。',
    removedOverDurationCandidate: '打合せ時間より短い候補は削除されました。',
    removeOverDurationCandidate: {
      body: '選択した打合せ時間より短い候補が削除されます。',
      header: '打合せ時間を変更してもよろしいですか？'
    },
    scheduleOverview: {
      header: '日程調整が届いています',
      subHeader: '候補を選択してください'
    },
    searchNoResultsFound: '結果が見つかりません',
    selectCalendar: '登録するカレンダーを選択',
    selectDate: 'ドラッグして日時を選択',
    selectedCandidateIsInvalidPleaseSelectAgain:
      '選択した候補は無効になりました。改めて候補を選択して確定してください。',
    sentEmail: 'Emailを送信しました',
    shareTitle: 'Spirのリンクをシェア',
    success: {
      addedCalendar: 'カレンダーを追加しました。',
      created: '{type}を作成しました',
      deleted: '{type}を削除しました',
      deleteEvent: '予定を削除しました',
      saved: '保存しました',
      savedEvent: '予定が保存されました',
      savedSchedule: '日程調整が保存されました',
      updated: '{type}を変更しました'
    },
    tapToCheckEvents: 'タップして予定を確認',
    userSafari: 'URLをコピーし、Safariで開いてください',
    vote: {
      confirm: '参加可能日を確定しますか？',
      subTitle: '参加可{yesCount}件、参加不可{noCount}件で投票します。<br>連絡先を登録し、確定してください。'
    },
    youCanCreateByClick: '左上の「作成」から{type}を作成できます。',
    checkCandidatesOnCalendar: common.checkCandidatesOnCalendar,
    showOnlyCandidates: '候補だけを表示 (登録不要)'
  },
  modals: {
    addCalendar: {
      title: 'カレンダーを追加'
    },
    arrangementTypeChangeConfirmation: {
      body: 'この操作は取り消せません。',
      changeToPersonalPoll:
        'チームでは「投票型の調整」をご利用できないため、個人の「投票型の調整」に切り替わります。この操作は取り消せません。',
      changeToTeamSchedule:
        'チームでは「投票型の調整」をご利用できないため、チームの「確定型の調整」に切り替わります。この操作は取り消せません。',
      title: '調整タイプを変更してもよろしいですか？'
    },
    autoPeekCandidatesInfo: {
      body: "条件に該当する候補が自動抽出された状態で候補の提案が始まります。<br />手動での候補設定から始めたい場合は<a class='setting-page' href='{href}'>設定画面</a>から変更できます。",
      title: '候補を提案について'
    },
    cancelSchedule: {
      description: '主催者に予定をキャンセルする理由を伝えましょう。',
      name: 'キャンセル理由',
      placeholder: '記入',
      title: '予定をキャンセル'
    },
    signUp: {
      title: 'Spirに登録してカレンダーを表示'
    }
  },
  myInfo: {
    mine: 'あなた'
  },
  notFound: {
    availabilitySharing: {
      desc: 'あなたがアクセスしようとしたページが見つ\nかりませんでした。存在しないURL、または\n期限超過したURLにアクセスされている可能\n性があります。',
      title: 'お探しのページを\n表示できません'
    },
    default: {
      desc: ' ',
      title: 'お探しのページは存在しません'
    }
  },
  notifications: {
    daysAgo: '{elapsed}日前',
    GROUP_POLL_ADD_VOTE: '{username} さんが「{scheduleTitle}」に日程を投票しました',
    hoursAgo: '{elapsed}時間前',
    minutesAgo: '{elapsed}分前',
    monthsAgo: '{elapsed}ヶ月前',
    readMore: 'さらに読み込む',
    secondsAgo: '{elapsed}秒前',
    title: '通知',
    yearsAgo: '{elapsed}年前'
  },
  onlineMeetings: {
    googleMeet: 'Google Meet',
    joinTo: '{type}に参加',
    microsoftTeams: 'Microsoft Teams',
    none: 'なし',
    team: {
      NotSet: 'なし',
      Set: 'あり'
    },
    zoom: 'Zoom'
  },
  onlineMeetingToolModal: {
    accountLabel: '連携アカウント',
    annotation: '※ 連携アカウントを切り替える場合、連携を解除した後、別アカウントで再度連携してください。',
    title: '設定'
  },
  peekCandidates: {
    checkboxToSave: 'この条件を保存する',
    dateOptions: {
      custom: '特定日まで',
      customStart: '特定日から',
      five_days: '5日分',
      five_days_later: '5日後から',
      five_hours_later: '5時間後から',
      four_days: '4日分',
      four_days_later: '4日後から',
      four_hours_later: '4時間後から',
      four_weeks: '4週間分',
      four_weeks_later: '4週間後から',
      next_month: '翌月から（月初から）',
      next_week: '来週から（月曜日から）',
      now: '今すぐ',
      one_day: '1日分',
      one_hour_later: '1時間後から',
      one_week: '1週間分',
      one_week_later: '1週間後から',
      the_day_after_tomorrow: '明後日から',
      the_week_after_next: '再来週から（月曜日から）',
      three_days: '3日分',
      three_days_later: '3日後から',
      three_hours_later: '3時間後から',
      three_months: '3ヶ月分',
      three_weeks: '3週間分',
      three_weeks_later: '3週間後から',
      tomorrow: '明日から',
      two_days: '2日分',
      two_hours_later: '2時間後から',
      two_months: '2ヶ月分',
      two_weeks: '2週間分',
      two_weeks_later: '2週間後から'
    },
    messages: {
      canNotDragOrClickWhilePanelIsActive: '自動抽出中は操作できません。'
    }
  },
  personalTeamSelectDropdown: {
    personal: '個人',
    teams: 'チーム'
  },
  poll: {
    form: {
      panels: {
        others: {
          inputAdditional: '他の項目を設定'
        }
      },
      title: {
        label: '日程投票のタイトル',
        placeholder: 'タイトルを入力'
      }
    },
    list: {
      attendeeCount: {
        message: '回答者 {count}人'
      },
      duration: {
        message: '{minute}分'
      },
      durationHour: {
        message: '{hour}時間'
      },
      lastUpdate: {
        message: '最終更新：{datetime}'
      }
    }
  },
  profile: {
    avatarAndBg: 'Avatar & Background Image',
    company: '会社名',
    firstName: '名',
    introduction: '自己紹介',
    jobTitle: '職種/役職名',
    lastName: '姓',
    name: common.name,
    unsavedCancel: {
      body: '保存せずに進めますか?',
      header: '保存していない項目があります。'
    }
  },
  schedule: {
    form: {
      panels: {
        notification: {
          label: '通知を設定'
        },
        others: {
          inputAdditional: '他の項目を設定'
        }
      },
      title: {
        label: '日程調整のタイトル',
        placeholder: 'タイトルを入力'
      }
    },
    list: {
      attendeeCount: {
        message: '同席メンバー{count}人',
        messageWithAuthor: '主催者 {authorCount}人、同席メンバー {attendeesCount}人'
      },
      duration: {
        message: '{minute}分'
      },
      durationHour: {
        message: '{hour}時間'
      }
    }
  },
  scheduleCreationSettings: {
    auto: '自動',
    autoCandidateBtn: '候補を自動抽出',
    manual: '手動',
    modal: {
      addTimeRange: '追加',
      custom: 'カスタム',
      daysOfWeek: '候補の抽出曜日',
      duration: '打合せ時間',
      initialTime: '前後の確保時間',
      period: '候補の抽出期間',
      periodNextMonth: '今月末まで',
      periodNextWeekend: '来週末まで',
      periodThisWeekend: '今週末まで',
      timeRange: '時間帯',
      timeRangeEditCancel: 'キャンセル',
      timeRangeEditSave: '保存',
      timeRangeTitlePlaceholder: 'タイトルを入力',
      title: '条件設定'
    },
    settings: '条件',
    status: {
      count: '{count}枠',
      label: '該当候補'
    }
  },
  scheduleEventList: {
    add: '追加',
    attendee: '参加者',
    attendManagement: {
      accepted: '参加',
      declined: '不参加',
      label: '参加可否',
      tentative: '未定'
    },
    calendar: 'カレンダー',
    cancel: 'キャンセル',
    candidateDate: '候補',
    confirmInformation: '日程調整の情報',
    description: 'メモ',
    duration: '打合せ時間',
    empty: '候補が表示されます',
    endDateTime: '終了日時',
    location: '場所',
    meetingRoom: '会議室',
    onlineMeeting: 'Web会議',
    organizer: '主催者',
    placeholder: {
      addAccount: '参加者を追加',
      addParticipant: '同席メンバーを追加',
      calendar: 'カレンダーを選択',
      description: 'メモを追加',
      location: '場所を追加',
      title: 'タイトル無し'
    },
    save: '保存',
    startDateTime: '開始日時',
    time: '日時',
    title: 'タイトル'
  },
  scheduleSharingText: {
    candidatePeriod: '候補期間',
    confirmButton: '他の候補を確認',
    emptyCandidateMessage: '候補がありません',
    htmlIntroMessage: '確定したい日時の候補ボタンを押下してください。',
    meetingTime: '打合せ時間',
    plainIntroMessage: '以下のURLにアクセスして日時を確定してください。',
    remainCandidateCountMessage: '他に{count}日、候補があります',
    remainItemCountMessage: '...他{count}件',
    time: {
      hour: '時間',
      minute: '分'
    },
    intro:
      '以下のURLからご都合の良い日時をお選びいただくか、もしくは下記の候補日時の中からご返信いただけますと幸いです。'
  },
  scheduleTabs: {
    headers: {
      confirmed: '確定済',
      filter: '絞り込み',
      filterWithCount: '絞り込み({count})',
      tbc: '調整中'
    },
    listItem: {
      today: '今日'
    },
    calendarEvents: {
      summaryPrefix: {
        planning: '[調整中]',
        expired: '[期限超過]'
      }
    }
  },
  settings: {
    accountLink: {
      sectionTitle: 'アカウント連携'
    },
    arrangement: {
      items: {
        autoCandidate: {
          label: '提案作成時に候補を自動抽出する',
          tooltip:
            '条件に該当する候補が自動抽出された状態で候補の提案が始まります。\n手動での候補設定から始めたい場合はチェックを外して下さい。'
        }
      },
      title: '候補を提案'
    },
    calendar: {
      header: 'カレンダーの表示',
      timeSelector: {
        label: '時刻の表示形式',
        latin: 'pm 1:00',
        number: '13:00'
      },
      weekSelector: {
        label: '週の始まり',
        monday: '月曜日',
        saturday: '土曜日',
        sunday: '日曜日'
      }
    },
    emailChange: {
      currentEmail: '現在のメールアドレス',
      newEmail: '新しいメールアドレス',
      sectionTitle: 'メールアドレス変更'
    },
    header: {
      title: '設定'
    },
    integrations: {
      account: '{accountName}で連携中',
      accountLabel: '連携中のアカウント',
      messageForSignInToZoom: 'Zoomと連携しますか?',
      passwordLabel: 'パスワード設定',
      passwordOptionAuto: 'ミーティング作成時に自動発行',
      passwordOptionConformity: 'Zoomの設定に準拠',
      passwordOptionConformityNotes:
        'Zoomの組織設定でミーティングパスコード発行が必須とされていない場合は発行されません。',
      sectionTitle: '連携サービス',
      signInToZoom: 'Zoomにログイン',
      zoom: 'Zoom',
      zoomConnectionError: 'Zoomとの連携に失敗しました。'
    },
    localization: {
      en: {
        label: 'English',
        shortenLabel: 'EN'
      },
      ja: {
        label: '日本語',
        shortenLabel: '日本語'
      },
      label: '言語選択(Language)'
    },
    localizationAndTimeZone: {
      sectionTitle: '言語とタイムゾーン'
    },
    timeZone: 'タイムゾーン',
    translation: {
      label: '翻訳モード'
    }
  },
  shareTextModal: {
    arrangementType: {
      label: '調整用'
    },
    availabilitySharing: {
      copyURLSubTitle: 'URLで送る',
      copyURLText: '空き時間URLをコピー'
    },
    copyHTMLSubTitle: 'メールで送る',
    copyHTMLText: 'HTMLテキストをコピー',
    copyType: {
      mail: 'メール'
    },
    copyURLSubTitle: '{type}をURLで送る',
    copyURLText: '{type}URLをコピー',
    helpText: '調整相手に候補を共有しましょう。',
    scheduleTitleFromUnconfirmedList: '確定型の調整を共有',
    shareAvailabilitySharing: '空き時間URLを共有',
    sharingHTMLTip:
      'HTML化した候補日をメール上で直接見られる形で添付出来ます。添付したHTMLの候補日時をクリックすると、確定画面にアクセスできます。',
    sharingMethodText: '{type}で共有',
    sharingURLTip: '日程調整URLを調整相手に送り、確定画面にアクセスして候補日を選んでもらいます。',
    title: '候補をシェア',
    url: 'URL'
  },
  subscription: {
    error: {
      productOrPriceNotSelected: 'プランを選択してください'
    },
    price: {
      perDay: '/{day}日',
      perDays: '/{day}日',
      perMonth: '/{month}ヶ月',
      perMonths: '/{month}ヶ月',
      perWeek: '/{week}週間',
      perWeeks: '/{week}週間',
      perYear: '/年',
      yen: '円'
    },
    subscriptionSuccess: '{planName}に登録しました'
  },
  teamInvited: {
    completeSlide: {
      description: 'セットアップが完了しました。早速、Spirを利用しましょう。',
      next: 'Spirを使いはじめる',
      title: 'セットアップ完了'
    },
    errors: {
      etc: {
        description: ' ',
        title: 'エラーが発生しました。'
      },
      'joined-already': {
        description: ' ',
        title: 'すでに参加しているチームです。'
      },
      'token-expired': {
        description: 'お手数ですが管理者に招待の再送を依頼してください。',
        title: 'この招待URLは使用できません。'
      }
    },
    settingIntroSlide: {
      description:
        'チームプランでは、チームメンバーと会社のカレンダーだけではなく、プライベートや別の組織のカレンダーも柔軟に共有できます。カレンダーごとに閲覧権限を調整できるので、プライバシーを守りながら予定をチームに共有することができます。',
      next: '連携するカレンダーを選択',
      step: '2/3',
      title: 'チームに連携するカレンダーを設定'
    },
    settingSlide: {
      addCalendar: '連携するカレンダーを追加',
      next: '保存して次へ',
      step: '3/3'
    },
    signUpSlide: {
      signIn: {
        description: '既にSpirアカウントをお持ちの方は、 チームに加入させたいアカウントでログインしてください。',
        title: 'ログイン'
      },
      signUp: {
        description: 'まずはじめにSpirのアカウントを作成してください。',
        step: '1/3',
        title: 'アカウントを作成'
      }
    },
    startSlide: {
      description: {
        firstLine: 'あなたは{name}のチームのメンバーに招待されました。',
        secondLine: 'セットアップを行い、Spirの利用を開始しましょう。'
      },
      next: 'セットアップをはじめる',
      title: 'Spirへようこそ'
    }
  },
  teamMember: {
    button: {
      cancelInvite: '招待をキャンセル',
      copyUrl: '招待URLをコピーする',
      reinvite: '招待メールを再送'
    },
    deletedMember: '削除されたメンバー',
    memberStatus: {
      invalid: 'カレンダー連携が解除',
      invitationExpired: '招待期限切れ',
      invited: '招待中'
    },
    undecidedMember: '確定後に選出'
  },
  teamMenu: {
    authority: {
      'admin-description': 'すべてのアクションを実行できます。',
      administrator: '管理者',
      member: 'メンバー',
      'member-description': 'チーム設定、支払い設定以外のすべてのアクションを実行できます。'
    },
    member: {
      'delete-btn': 'チームから削除'
    },
    personal: {
      settingTitle: '個人'
    }
  },
  teamMyCalendar: {
    addCalendar: 'カレンダーの追加',
    mainCalendar: {
      description:
        'メンバーが代理で日程調整を行うこともできます。その際に予定が書き込まれるメインカレンダーを選択してください。',
      selectLabel: 'メインカレンダーを選択',
      title: 'メインカレンダー'
    },
    shareCalendar: {
      description:
        'あなたの連携しているカレンダーをチームメンバーにどのように共有するか設定してください。共有方法を3種類から選択することができます。',
      title: 'マイカレンダーの共有方法'
    },
    title: 'マイカレンダー',
    memberIsManagingRoom: {
      header: 'リソース登録者のため、メインカレンダーを変更できません',
      description: 'チーム設定のリソースから、リソース登録者を変更してください。',
      button: 'リソース管理画面へ',
      close: '閉じる'
    }
  },
  teamVisibilityMenu: {
    freeBusyOnly: {
      description: 'チームメンバーにはこのカレンダーの予定の時間枠のみが共有され、詳細は共有されません。',
      label: '時間枠のみ共有'
    },
    notShare: {
      description: 'チームメンバーにはこのカレンダーの予定は共有されません。',
      label: '共有しない'
    },
    readAll: {
      description: 'チームメンバーにはこのカレンダーの予定の内容をすべて共有します。',
      label: '詳細まで共有'
    }
  },
  timezone: {
    addSubTimezone: 'サブタイムゾーンを追加',
    displayTime: '表示時間',
    label: 'タイムゾーン',
    timeZoneModal: {
      code: 'タイムゾーン',
      currentLocation: '現在のロケーション',
      displayName: '表示名',
      title: 'タイムゾーンの変更'
    },
    titles: {
      primary: 'メインタイムゾーン',
      secondary: 'サブタイムゾーン1',
      tertiary: 'サブタイムゾーン2'
    },
    updateTimezone: {
      body: '<p class="update-timezone-body">設定中のタイムゾーン<span class="update-timezone-body__timezone">{userTimezone}</span>を<br>ローカルタイムゾーン<span class="update-timezone-body__timezone">{localTimezone}</span>に変更しますか？</p>',
      cancelButton: '変更しない',
      confirmButton: '変更する',
      header: '設定中のタイムゾーンがローカルのタイムゾーンと異なります。'
    }
  },
  tooltips: {
    delete: '削除する',
    edit: '編集する',
    share: '{type}URLをコピーする'
  },
  links: common.links
}
