<template>
  <div class="daily-view-body fc-body">
    <TimeAxis
      :eventDate="eventDate"
      :gridHeight="gridHeight"
      :isDisplayWeekDay="isDisplayWeekDay"
      v-if="isDisplayDay"
    />
    <Columns
      :selectable="selectable"
      :eventDate="eventDate"
      :columns="columns"
      :gridHeight="gridHeight"
      :events="events"
      :fullWidthEvents="fullWidthEvents"
    />
  </div>
</template>

<script lang="ts">
import { ResourceColumn, ResourceEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Columns from './columns/Index.vue'
import TimeAxis from './timeAxis/Index.vue'

@Component({
  components: {
    TimeAxis,
    Columns
  }
})
export default class ResourceViewBody extends Vue {
  // @ts-expect-error TS2564
  @Prop() eventDate: Date
  // @ts-expect-error TS2564
  @Prop() columns: ResourceColumn[]
  // @ts-expect-error TS2564
  @Prop() gridHeight: number
  // @ts-expect-error TS2564
  @Prop() selectable: boolean
  // @ts-expect-error TS2564
  @Prop() events: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop() fullWidthEvents: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop({ default: true }) isDisplayDay: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) isDisplayWeekDay: boolean
}
</script>

<style scoped lang="scss">
.daily-view-body {
  max-width: 100vw;
}
</style>
