<template>
  <p class="links">
    <span class="link-item">
      <a :href="$t('links.termsOfService')" target="_blank">{{ $t('labels.eula') }}</a>
    </span>
    <span class="link-item">
      <a :href="$t('links.privacyPolicy')" target="_blank">{{ $t('labels.privacyPolicy') }}</a>
    </span>
  </p>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthTermAndPolicy'
})
</script>

<style lang="scss" scoped>
.links {
  display: flex;
  align-items: center;
  .link-item {
    display: inline-block;
    &:first-child {
      padding-right: 16px;
    }
    a {
      font-size: 0.9em;
      color: $spir2_gray;
    }
  }
}
</style>
