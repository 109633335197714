<template>
  <CommonLayoutModal
    class="middle-width-modal"
    :showCloseButton="false"
    :showHeader="true"
    :showFooter="false"
    @close="$emit('close')"
  >
    <template v-slot:modalHeader>
      <div class="title">
        <div>{{ $t('timezone.timeZoneModal.title') }}</div>
      </div>
    </template>
    <template v-slot:modalBody>
      <ValidationObserver ref="validate" v-slot="{ invalid }">
        <TimezoneEdit :timeZones.sync="timeZonesForEdit" @updateTimezone="handleUpdatedTimezone" />

        <div class="mt-5 is-flex is-justify-content-flex-end">
          <Button @click="$emit('close')">{{ $t('buttons.cancel') }}</Button>
          <Button type="primary" :disabled="invalid || !isDirty" @click="selectTimezone">
            {{ $t('buttons.update') }}
          </Button>
        </div>
      </ValidationObserver>
    </template>
  </CommonLayoutModal>
</template>
<script lang="ts">
import TimezoneEdit from '@/components/organisms/TimezoneEdit.vue'
import Button from '@/components/ui/Button.vue'
import { TimeZones } from '@/models/data/userInfo'
import { cloneDeep } from 'lodash'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from '../CommonLayout.vue'

@Component({
  components: {
    ValidationObserver,
    CommonLayoutModal,
    TimezoneEdit,
    Button
  }
})
export default class TimeZoneSelectModal extends Vue {
  // @ts-expect-error TS2564
  @Prop() timeZones: TimeZones

  isDirty = false
  // @ts-expect-error TS2322
  timeZonesForEdit: TimeZones = null

  timeZoneForEdit = ''
  timeZoneDisplayNameForEdit = ''

  created() {
    this.timeZonesForEdit = cloneDeep(this.timeZones)
  }
  handleUpdatedTimezone() {
    this.isDirty = true
  }
  async selectTimezone() {
    this.$emit('updateTimezoneInfo', this.timeZonesForEdit)
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  color: $spir2_black;
}
</style>
