import axiosInstance from '@/lib/axios'
import {
  ScheduleActionConfirmerRequestPostRequest,
  ScheduleCancelPutRequest,
  ScheduleConfirmPostRequest,
  ScheduleConfirmPostRequestResponse,
  SchedulesGetResponse,
  SchedulesScheduleIdConfirmedEventGetResponse
} from '@spirinc/contracts'

function toCancelPath({ scheduleId }: { scheduleId: string }): string {
  return `schedules/${scheduleId}/cancel`
}
function toGuestCancelPath(data: { scheduleId: string }): string {
  return `public/${toCancelPath(data)}`
}

export const getPublicScheduleById = async (scheduleId: string): Promise<SchedulesGetResponse> => {
  return (await axiosInstance.get(`public/schedules/${scheduleId}`)).data
}

export const confirmAsGuest = async (
  scheduleId: string,
  payload: ScheduleConfirmPostRequest
): Promise<ScheduleConfirmPostRequestResponse> => {
  return (await axiosInstance.put(`public/schedules/${scheduleId}/confirm`, payload)).data
}

export const cancelAsSpirUser = async ({
  scheduleId,
  payload
}: {
  scheduleId: string
  payload: ScheduleCancelPutRequest
}): Promise<ScheduleConfirmPostRequestResponse> => {
  return (await axiosInstance.put(toCancelPath({ scheduleId }), payload)).data
}

export const cancelAsGuest = async ({
  scheduleId,
  payload
}: {
  scheduleId: string
  payload: ScheduleCancelPutRequest
}): Promise<ScheduleConfirmPostRequestResponse> => {
  return (await axiosInstance.put(toGuestCancelPath({ scheduleId }), payload)).data
}

function toConfirmerRequestPath({ scheduleId }: { scheduleId: string }): string {
  return `schedules/${scheduleId}/action/confirmerRequest`
}
type ConfirmerRequest = {
  scheduleId: string
  payload: ScheduleActionConfirmerRequestPostRequest
}

export const requestConfirmerRequestActionAsSpirUser = async ({ scheduleId, payload }: ConfirmerRequest) => {
  return (await axiosInstance.post(toConfirmerRequestPath({ scheduleId }), payload)).data
}

export const requestConfirmerRequestActionAsGuest = async ({ scheduleId, payload }: ConfirmerRequest) => {
  return (await axiosInstance.post(`public/${toConfirmerRequestPath({ scheduleId })}`, payload)).data
}

export const getPublicConfirmedEvent = async ({
  scheduleId
}: {
  scheduleId: string
}): Promise<SchedulesScheduleIdConfirmedEventGetResponse> => {
  return (await axiosInstance.get(`public/schedules/${scheduleId}/confirmedEvent`)).data
}
