// https://docs.google.com/spreadsheets/d/1nm00HS2oQd1RTGNojr8wKB9uE0Umermy0pNwk2kUc54/edit#gid=1502648130 -> https://csvjson.com/csv2json
import { DateTime } from 'luxon'
import en from './timezones/en.json'
import ja from './timezones/ja.json'

export const getTZInfoByKey = key => {
  const timezone = DateTime.local().setZone(key)
  return {
    key,
    existInMaster: false,
    group: 'Etc',
    utcOffset: timezone.offset,
    abbreviation: timezone.offsetNameShort,
    label: timezone.offsetNameLong,
    fullLabel: `${timezone.offsetNameShort.toUpperCase()} ${timezone.offsetNameLong}`,
    subLabel: '',
    fullLabelWithTime: '' // fixme
  }
}

export const localTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
export const checkValidTimezone = key => {
  const timezone = DateTime.local().setZone(key)
  return timezone.isValid
}
export type TimezoneDetail = {
  key: string
  abbreviation: string
  group: string
  label: string
  subLabel: string
  utcOffset: number
  fullLabel: string
  existInMaster: boolean
}

const addAdditionalInfoToMaster = (master, locale: string): TimezoneDetail[] => {
  const localNow = DateTime.local().setLocale(locale)
  const masterInfo: TimezoneDetail[] = master.map(master => {
    const timezone = localNow.setZone(master.key)
    const timezoneEn = localNow.setLocale('en').setZone(master.key)
    const abbreviation =
      master.group === 'US' || master.group === 'Pacific' ? timezoneEn.offsetNameShort : timezone.offsetNameShort
    return {
      ...master,
      existInMaster: true,
      utcOffset: timezone.offset,
      abbreviation,
      label: timezone.offsetNameLong,
      fullLabel: `${abbreviation} ${timezone.offsetNameLong}`
    }
  })
  if (!masterInfo.find(m => m.key === localTimezone())) {
    masterInfo.push(getTZInfoByKey(localTimezone()))
  }
  return masterInfo
}
export const timezoneGroups = ['Asia', 'US', 'Australia', 'Europe', 'Atlantic', 'Indian', 'Pacific', 'Africa', 'Etc']

export default {
  ja: addAdditionalInfoToMaster(ja, 'ja'),
  en: addAdditionalInfoToMaster(en, 'en')
}
