<template>
  <div class="meeting-room-info" v-if="showOnlineMeetingInfo">
    <div class="meeting-room-info__header">
      <a
        class="is-primary"
        :href="`${onlineMeeting.info.url}`"
        target="_blank"
        aria-label="Join Hangouts Meet (uip-ntct-bng)"
        >{{ $t('buttons.JoinOnlineMeeting', { meetingType: serviceName }) }}</a
      >
      <div class="meeting-room-info__header__copy">
        <b-button size="is-small" type="is-text" icon-left="content-copy" @click="copyLink"></b-button>
      </div>
    </div>
    <div class="meeting-room-info__body">
      <div class="meeting-room-info__property">
        <label> {{ $t('labels.url') }} </label>
        <p>
          <a :href="onlineMeeting.info.url" target="_blank">{{ onlineMeeting.info.url }}</a>
        </p>
      </div>
      <div class="meeting-room-info__property" v-if="this.onlineMeeting.info.formattedId">
        <label> {{ $t('labels.meetingId') }} </label>
        <p>{{ this.onlineMeeting.info.formattedId }}</p>
      </div>
      <div class="meeting-room-info__property" v-if="onlineMeeting.info.password">
        <label> {{ $t('labels.password') }} </label>
        <p>{{ onlineMeeting.info.password }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IOnlineMeeting, OnlineMeetingType } from '@/types/schedule'
import copy from 'clipboard-copy'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MeetingRoomInfo extends Vue {
  // @ts-expect-error TS2564
  @Prop() onlineMeeting: IOnlineMeeting

  private copyLink() {
    copy(this.onlineMeeting.info.url)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }

  private get serviceName() {
    if (this.onlineMeeting.type === OnlineMeetingType.googleMeet) {
      return this.$t('onlineMeetings.googleMeet')
    } else if (this.onlineMeeting.type === OnlineMeetingType.zoom) {
      return this.$t('onlineMeetings.zoom')
    } else if (this.onlineMeeting.type === OnlineMeetingType.microsoftTeams) {
      return this.$t('onlineMeetings.microsoftTeams')
    }
    return ''
  }
  get showOnlineMeetingInfo() {
    return this.onlineMeeting.type !== 'none' && this.onlineMeeting.info !== undefined
  }
}
</script>

<style scoped lang="scss">
.meeting-room-info {
  border: 1px solid $spir2_border;
  border-radius: 4px;
  box-shadow: none;
  &__header {
    padding: 6px 12px;
    display: flex;
    border-bottom: 1px solid $spir2_border;
    .is-primary {
      color: $spir2_black;
      line-height: 27px;
      font-size: 12px;
    }
    &__copy {
      margin-left: auto;
      &::v-deep {
        i.mdi.mdi-content-copy {
          color: $spir2_gray;
        }
      }
    }
  }
  &__body {
    padding: 6px 12px;
  }
  &__property {
    margin-bottom: 12px;
    label {
      color: $spir2_black;
    }
    p,
    a {
      color: $spir2_black;
      word-break: break-all;
    }
  }
  &__property:last-child {
    margin-bottom: 0px;
  }
}
</style>
