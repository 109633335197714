<template>
  <b-icon :icon="icon" :size="convertedSize.size" :custom-size="convertedSize.customSize" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ConvertedSizeForBuefy, convertToBuefyIconSizeOrCustomSize, MaterialDesignIconSize } from './misc'

export default defineComponent({
  name: 'MaterialDesignIcon',
  props: {
    icon: {
      type: String, // https://materialdesignicons.com/
      required: true
    },
    size: {
      type: String as PropType<MaterialDesignIconSize>,
      default: 'm'
    }
  },
  setup(props) {
    const convertedSize = computed((): ConvertedSizeForBuefy => convertToBuefyIconSizeOrCustomSize(props.size))
    return {
      convertedSize
    }
  }
})
</script>
