export function getPersonalConfirmPublicURLParam(data: { availabilitySharingId: string; organizerUserId: string }): {
  id: string
  type: 'private'
  authorUserId: string
} {
  return {
    id: data.availabilitySharingId,
    type: 'private', // Privateは古いがAnalytics関係なのでPersonalにはしない
    authorUserId: data.organizerUserId
  }
}

export function getTeamConfirmPublicURLParam(data: { availabilitySharingId: string; teamId: string }): {
  id: string
  type: 'team'
  teamId: string
} {
  return {
    id: data.availabilitySharingId,
    type: 'team',
    teamId: data.teamId
  }
}
