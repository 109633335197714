import { AllRouteNames, RouteNames } from '@/router'
import { PollStatus } from '@/types'
import { AfterConfirmQueryParams } from '@/types/schedule'
import { QueryParams } from '../queryParams'

export type PollForGuard = { id: string; isInvalid: boolean; status: PollStatus; isMine: boolean }
type NextQuery = { error: 'common' } | { page: typeof QueryParams.QUERY_NOT_FOUND_SCHEDULE } | AfterConfirmQueryParams
type GuardResult = { name: RouteNames; query: NextQuery } | null

function isNotFound(poll: PollForGuard, toRouteName: RouteNames): boolean {
  return poll.isInvalid || (!poll.isMine && toRouteName === 'EditPoll')
}

function toAfterConfirmQueryParams(poll: PollForGuard): AfterConfirmQueryParams {
  return {
    type: 'group-poll',
    't-or-p': 'private',
    id: poll.id
  }
}

export async function guardPoll(data: {
  to: RouteNames
  getEditingPoll: () => Promise<PollForGuard>
}): Promise<GuardResult | null> {
  try {
    const editingPoll = await data.getEditingPoll()
    if (isNotFound(editingPoll, data.to)) {
      return { name: AllRouteNames.NotFound, query: { page: QueryParams.QUERY_NOT_FOUND_SCHEDULE } }
    }
    if (editingPoll.status === 'confirmed') {
      return { name: AllRouteNames.AfterConfirm, query: toAfterConfirmQueryParams(editingPoll) }
    }
    return null
  } catch (e: any) {
    if (e.response?.status === 404) {
      return { name: AllRouteNames.NotFound, query: { page: QueryParams.QUERY_NOT_FOUND_SCHEDULE } }
    }
    // fixme: QueryParameterを送る側、受け取る側でルールがわかるように仕組みがあった方がわかりやすい。
    return { name: AllRouteNames.Main, query: { error: 'common' } }
  }
}
