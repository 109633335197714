export async function mountHubspot() {
  return new Promise(resolve => {
    const hubspotId = process.env.VUE_APP_HUBSPOT_ID
    const script: HTMLScriptElement = document.createElement('script')
    script.src = `//js.hs-scripts.com/${hubspotId}.js`
    script.async = true
    script.defer = true
    script.id = 'hs-script-loader'
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.getElementsByTagName('head')[0].appendChild(script)
  })
}
