import i18n from '@/i18n'

export default class ErrorModel {
  code: string
  message: string
  constructor(code?: string, message?: string) {
    this.code = code || 'default'
    this.message = message || 'default'
  }
  get errorMessageKey() {
    // i18n に該当エラーメッセージが存在するかチェック
    if (i18n.te(`message.apiError['${this.code}']['${this.message}']`)) {
      return `message.apiError['${this.code}']['${this.message}']`
    }
    return `message.apiError.default.default`
  }
}

export class CandidateError extends ErrorModel {
  messageKey: string
  option?: { [key: string]: string }
  constructor(messageKey: string, option?: { [key: string]: string }) {
    super()
    this.messageKey = messageKey
    this.option = option
  }
  get errorMessageKey() {
    return `message.error.candidateError.${this.messageKey}`
  }
  get errorMessageOption() {
    return this.option
  }
}
