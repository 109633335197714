import { getAllList } from '@/effects/teamAvailabilitySharing'
import * as availabilityAPI from '@/lib/api/availability'
import { deletePattern, updatePattern } from '@/lib/api/availabilityTeam'
import { TeamId } from '@/models'
import { AvailabilityModelForPrivate, AvailabilityModelForTeam, AvailabilitySummaryModelForTeam } from '@/models/data'
import store from '@/store'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'

type Types = 'team' | 'private'

const MODULE_NAME = 'AvailabilitySharingList'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class AvailabilitySharingList extends VuexModule {
  _privateList: { isLoading: boolean; list: AvailabilityModelForPrivate[] } = {
    isLoading: false,
    list: []
  }
  _teamList: { [key: string]: { isLoading: boolean; list: AvailabilitySummaryModelForTeam[] } } = {}

  get getList() {
    return (teamId?: string) => {
      return !teamId ? this._privateList : this._teamList[teamId]
    }
  }

  get privateList() {
    return this._privateList
  }

  get teamLists() {
    return this._teamList
  }

  @Action
  addNewTeamAvailabilitySharing({
    teamId,
    teamAvailabilitySharing
  }: {
    teamId: TeamId
    teamAvailabilitySharing: AvailabilityModelForTeam
  }) {
    const currentList = (AvailabilitySharingListModule.getList(teamId)?.list ?? []) as AvailabilitySummaryModelForTeam[]
    AvailabilitySharingListModule.SET_TEAM_LIST({
      teamId,
      list: [
        AvailabilitySummaryModelForTeam.createFromAvailabilityModelForTeam(teamAvailabilitySharing),
        ...currentList
      ]
    })
  }
  @Action
  async fetchPatterns(teamId?: string) {
    const type: Types = teamId ? 'team' : 'private'
    this.SET_LOADING({ loading: true, teamId })
    try {
      if (type === 'private') {
        const patterns = (await availabilityAPI.getList()).availabilitySharings
        const patternModels = patterns.map(p => new AvailabilityModelForPrivate(p))
        this.SET_PRIVATE_LIST(patternModels)
      } else {
        await getAllList({
          // @ts-expect-error TS2322
          teamId,
          onListAdd: data => {
            // @ts-expect-error TS2345
            const patternModels = data.list.map(p => AvailabilitySummaryModelForTeam.createFrom(teamId, p))
            this.ADD_TEAM_LIST({ teamId: data.teamId, list: patternModels })
          }
        })
      }
    } finally {
      this.SET_LOADING({ loading: false, teamId })
    }
  }
  @Action
  async deletePattern(pattern: AvailabilityModelForPrivate | AvailabilitySummaryModelForTeam) {
    const patternId = pattern.id
    let teamId
    if (pattern.type === 'team') {
      teamId = (pattern as AvailabilitySummaryModelForTeam).teamId
    }
    if (pattern.type === 'private') {
      await availabilityAPI.deletePattern(patternId)
      // @ts-expect-error TS2322
      this.UPDATE_PRIVATE_LIST({ id: patternId })
    } else {
      // @ts-expect-error TS2345
      await deletePattern(teamId, patternId)
      // @ts-expect-error TS2322
      this.UPDATE_TEAM_LIST({ id: patternId, teamId })
    }
  }
  @Action
  async togglePublish(pattern: AvailabilityModelForPrivate | AvailabilitySummaryModelForTeam) {
    const patternId = pattern.id
    let teamId
    if (pattern.type === 'team') {
      teamId = (pattern as AvailabilitySummaryModelForTeam).teamId
    }
    if (pattern.type === 'private') {
      const pattern = this._privateList.list.find(p => p.id === patternId)
      // @ts-expect-error TS2532
      pattern.isLoading = true
      try {
        // @ts-expect-error TS2322
        this.UPDATE_PRIVATE_LIST({ id: patternId, newModel: pattern })
        // @ts-expect-error TS2345
        const response = await availabilityAPI.updatePattern(patternId, { isPublished: !pattern.isPublished })
        const newModel = new AvailabilityModelForPrivate(response)
        // @ts-expect-error TS2322
        this.UPDATE_PRIVATE_LIST({ id: patternId, newModel })
      } catch (e) {
        // @ts-expect-error TS2532
        pattern.isLoading = false
        // @ts-expect-error TS2322
        this.UPDATE_PRIVATE_LIST({ id: patternId, newModel: pattern })
        throw e
      }
    } else {
      const pattern = this._teamList[teamId].list.find(p => p.id === patternId)
      try {
        if (!pattern) throw new Error('pattern must be found here')
        pattern.isLoading = true
        // @ts-expect-error TS2322
        this.UPDATE_TEAM_LIST({ id: patternId, teamId, newModel: pattern })
        // @ts-expect-error TS2345
        const response = await updatePattern(teamId, patternId, {
          isPublished: !pattern.isPublished
        })
        const newModel = AvailabilitySummaryModelForTeam.createFromAvailabilityModelForTeam(
          new AvailabilityModelForTeam(teamId, response)
        )
        // @ts-expect-error TS2322
        this.UPDATE_TEAM_LIST({ id: patternId, teamId, newModel })
      } catch (e) {
        // @ts-expect-error TS2532
        pattern.isLoading = false
        // @ts-expect-error TS2322
        this.UPDATE_TEAM_LIST({ id: patternId, teamId, newModel: pattern })
        throw e
      }
    }
  }
  @Action
  reset() {
    this.RESET_LIST()
  }
  @Mutation
  SET_PRIVATE_LIST(list: AvailabilityModelForPrivate[]) {
    this._privateList = {
      ...this._privateList,
      list
    }
  }
  @Mutation
  UPDATE_PRIVATE_LIST({ id, newModel }: { id: string; newModel?: AvailabilityModelForPrivate }) {
    const findIndex = this._privateList.list.findIndex(l => l.id === id)
    if (findIndex < 0) {
      return
    }
    if (newModel) {
      this._privateList.list[findIndex] = newModel
    } else {
      this._privateList.list.splice(findIndex, 1)
    }
    this._privateList.list = [...this._privateList.list]
  }
  @Mutation
  UPDATE_TEAM_LIST({
    teamId,
    id,
    newModel
  }: {
    teamId: string
    id: string
    newModel?: AvailabilitySummaryModelForTeam
  }) {
    const findIndex = this._teamList[teamId]?.list.findIndex(l => l.id === id)
    if (findIndex < 0) {
      return
    }
    if (!this._teamList[teamId]) {
      return
    }
    if (newModel) {
      this._teamList[teamId].list[findIndex] = newModel
    } else {
      this._teamList[teamId].list.splice(findIndex, 1)
    }
    this._teamList[teamId].list = [...this._teamList[teamId].list]
  }
  @Mutation
  RESET_LIST() {
    this._privateList = {
      isLoading: false,
      list: []
    }
    this._teamList = {}
  }
  @Mutation
  SET_TEAM_LIST({ teamId, list }: { teamId: string; list: AvailabilitySummaryModelForTeam[] }) {
    this._teamList[teamId] = {
      ...this._teamList[teamId],
      list
    }
  }
  @Mutation
  ADD_TEAM_LIST({ teamId, list }: { teamId: string; list: AvailabilitySummaryModelForTeam[] }) {
    const { isLoading: currentIsLoading, list: currentList } = this._teamList[teamId]
    const newList = currentList.concat(list)
    this._teamList[teamId] = {
      isLoading: currentIsLoading,
      list: newList.filter((model, i) => newList.findIndex(m => model.id === m.id) === i)
    }
  }
  @Mutation
  SET_LOADING({ loading, teamId }: { loading: boolean; teamId?: string }) {
    if (teamId) {
      const previousState = this._teamList[teamId] ?? { isLoading: false, list: [] }
      this._teamList = {
        ...this._teamList,
        [teamId]: {
          ...previousState,
          isLoading: loading
        }
      }
    } else {
      this._privateList = {
        ...this._privateList,
        isLoading: loading
      }
    }
  }
}

export const AvailabilitySharingListModule = getModule(AvailabilitySharingList)
