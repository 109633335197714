import i18n from '@/i18n'
import { TranslateResult } from 'vue-i18n'
import { FrontSupportCountryCode } from './../../types/frontSupportCountry'

export const holidayExclusion = {
  getI18nCountryName(code: FrontSupportCountryCode): TranslateResult {
    return i18n.t(`availabilitySharing.form.holidayException.country`, {
      country: i18n.t(`labels.holiday.countries.${code.toLowerCase()}`)
    })
  }
}
