<template>
  <div class="close-button-frame" :class="{ always: isAlwaysVisible }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PageheadCloseButtonFrame',
  props: {
    isAlwaysVisible: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped lang="scss">
.close-button-frame {
  width: 32px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: none;
    &.always {
      display: flex;
    }
  }
}
</style>
