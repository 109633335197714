import { UserInfoModel } from '@/models/data/userInfo'
import { SpirUser } from '@/types'

export const FromUserInfoModel = {
  convertToSpirUser(userInfo: UserInfoModel, overrideOption?: Partial<SpirUser>): SpirUser {
    return {
      // @ts-expect-error TS2322
      id: overrideOption?.id || userInfo.id,
      // @ts-expect-error TS2322
      name: overrideOption?.name || userInfo.fullName,
      fullName: overrideOption?.fullName || userInfo.fullName,
      photoURL: overrideOption?.photoURL || userInfo.photoURL
    }
  }
}
