import store from '@/store'
import type { ProfileRequest, V2MeGetResponse } from '@spirinc/contracts'
import axiosInstance from '../axios'

export const getProfile = (id?: string) => {
  if (store.getters['User/isSignIn']) {
    return axiosInstance.get(!id ? 'me' : `profile/${id}`)
  }
  return axiosInstance.get(!id ? 'me' : `public/profile/${id}`)
}

export const getV2Me = async () => {
  const { data } = await axiosInstance.get<V2MeGetResponse>('v2/me')
  return data
}

export const updateBasicProfile = (payload: ProfileRequest) => {
  return axiosInstance.put('me', payload)
}
