<template>
  <div class="name-with-icon">
    <div class="icon">
      <div class="inner-icon" :style="{ backgroundColor: memberColor }" />
    </div>
    <div class="name">{{ memberName }}</div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'NameWithColor',
  props: {
    memberColor: {
      type: String,
      required: true
    },
    memberName: {
      type: String,
      required: true
    }
  }
})
</script>
<style lang="scss" scoped>
.name-with-icon {
  display: flex;
  align-items: center;
  width: 100%;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .inner-icon {
    width: 12px;
    height: 12px;
    border-radius: 3px;
  }
  .name {
    color: $spir2_gray;
  }
}
</style>
