import config from '@/lib/config'
import { AuthErrorTypes } from '@/types/authError'

type GoogleIdentityCodeClientSpec = { requestCode: () => void }

export type ErrorCallback = (error: { type: 'popup_failed_to_open' | 'popup_closed' | 'unknown' }) => void

export declare const google: {
  accounts: {
    oauth2: {
      initCodeClient: (data: {
        client_id: string
        scope: string
        include_granted_scopes?: boolean
        redirect_uri?: string
        ux_mode: 'redirect' | 'popup'
        select_account?: boolean
        callback?: (response: { code?: string }) => void
        error_callback?: ErrorCallback
      }) => GoogleIdentityCodeClientSpec
    }
  }
}
declare global {
  interface Window {
    googleIdentityCodeClient: GoogleIdentityCodeClientSpec
  }
}
type GoogleAuthConfig = {
  scope: string
  prompt: string
  clientId: string
  redirectUri: string
  uxMode: 'redirect'
  includeGrantedScopes: boolean
}

export class GoogleIdentityCodeClient {
  private static instance: GoogleIdentityCodeClient
  public static getInstance(): GoogleIdentityCodeClient {
    if (!GoogleIdentityCodeClient.instance && process.env.VUE_APP_GOOGLE_CLIENT_ID) {
      GoogleIdentityCodeClient.instance = new GoogleIdentityCodeClient()
    }
    return GoogleIdentityCodeClient.instance
  }
  async getAuthCode(option: Partial<GoogleAuthConfig> = {}) {
    if (window['google'] === undefined) {
      const loadCompleted = new Promise(resolve => {
        const apiUrl = 'https://accounts.google.com/gsi/client'
        const script: HTMLScriptElement = document.createElement('script')
        script.src = apiUrl

        script.onload = () => {
          resolve(true)
        }
        document.getElementsByTagName('head')[0].appendChild(script)
      })
      await loadCompleted
    }
    if (process.env.VUE_APP_GOOGLE_CLIENT_ID === undefined) {
      throw new Error('VUE_APP_GOOGLE_CLIENT_ID is not defined')
    }
    const client = google.accounts.oauth2.initCodeClient({
      client_id: option.clientId ?? process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope: option.scope ?? config.google.scope,
      ux_mode: option.uxMode ?? 'redirect',
      redirect_uri: option.redirectUri ?? `${process.env.VUE_APP_SITE_URL}/callback-from-google`,
      ...(option.includeGrantedScopes !== undefined
        ? { include_granted_scopes: option.includeGrantedScopes }
        : undefined)
    })
    client.requestCode()
  }
}
export const googleIdentityCodeClient = GoogleIdentityCodeClient.getInstance()

type ParsedGoogleIdentityResultOk = {
  result: 'ok'
  code: string
}
type ParsedGoogleIdentityResultError = {
  result: 'error'
  errorCode: AuthErrorTypes
}
type ParsedGoogleIdentityResultCancel = {
  result: 'cancel'
}
export type ParseGoogleAuthResult =
  | ParsedGoogleIdentityResultOk
  | ParsedGoogleIdentityResultError
  | ParsedGoogleIdentityResultCancel

/**
 * requestCodeのリターンをParseする
 * @param searchString
 *   ?code=4%2F0AX4XfWhCq0nsU2Jx3xXJ2cSlJ5PkFQrMid0e9V4Zc9eRzQfw3Jm0AUtmSmpbAje5P6YP4w&scope=email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&authuser=0&prompt=consent
 *   ?error=access_denied
 * https://developers.google.com/identity/oauth2/web/reference/js-reference#CodeResponse
 * @returns ParseGoogleAuthResult
 */
export const getAuthcodeFromQueryParamOfGoogleIdentity = (searchString: string): ParseGoogleAuthResult => {
  const searchParams = new URLSearchParams(searchString)
  if (searchParams.get('error')) {
    return {
      result: 'cancel'
    }
  }
  const code = searchParams.get('code')
  const scope = searchParams.get('scope')
  if (!code || !scope) {
    return {
      result: 'error',
      errorCode: 'unknown'
    }
  }
  const mustBeScopeArray = config.google.scope.split(' ')
  const gotScopeArray = scope.split(' ')
  if (mustBeScopeArray.some(s => gotScopeArray.indexOf(s) < 0)) {
    return {
      result: 'error',
      errorCode: 'notEnoughScope'
    }
  }
  return {
    result: 'ok',
    code
  }
}
