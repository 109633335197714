<template>
  <div ref="resourceViewTimeAxis" :style="{ 'min-height': `${gridHeight}px` }" class="time-element"></div>
</template>

<script lang="ts">
import TimeAxisMixin from '@/components/calendar/TimeAxis'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class TimeAxisItem extends mixins(TimeAxisMixin) {
  // @ts-expect-error TS2564
  @Prop() dateToShow: Date
  // @ts-expect-error TS2564
  @Prop() gridHeight: string

  // @ts-expect-error TS2564
  $refs: {
    resourceViewTimeAxis: HTMLElement
  }
  mounted() {
    this.setLabel()
  }
  setLabel() {
    this.$refs.resourceViewTimeAxis.appendChild(this.timeAxisBody(this.dateToShow))
  }
}
</script>

<style scoped>
.time-element {
  margin-right: 2px;
}
</style>
