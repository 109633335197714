export const AllRouteNames = {
  NotFound: 'NotFound',
  ExternalNotFound: 'ExternalNotFound',
  SignIn: 'SignIn',
  AddCalendar: 'AddCalendar',
  ArrangementsOnCalendar: 'ArrangementsOnCalendar',
  SignInWithPassword: 'SignInWithPassword',
  SignUp: 'SignUp',
  SignUpWithStep: 'SignUpWithStep',
  SignOut: 'SignOut',
  AuthTeamsCreate: 'AuthTeamsCreate',
  AuthTeamsCreateFree: 'AuthTeamsCreateFree',
  AuthTeamIdStart: 'AuthTeamIdStart',
  AuthTeamIdStartFree: 'AuthTeamIdStartFree',
  SendPasswordResetEmail: 'SendPasswordResetEmail',
  Main: 'Main',
  CreateArrangement: 'CreateArrangement',
  CreatePoll: 'CreatePoll',
  EditPoll: 'EditPoll',
  VotePoll: 'VotePoll',
  VotePollGuest: 'VotePollGuest',
  CreateSchedule: 'CreateSchedule',
  EditSchedule: 'EditSchedule',
  ConfirmSchedule: 'ConfirmSchedule',
  ConfirmScheduleGuest: 'ConfirmScheduleGuest',
  ChangeRequestedSchedule: 'ChangeRequestedSchedule',
  SuggestAlternativeDate: 'SuggestAlternativeDate',
  CreateEvent: 'CreateEvent',
  EditEvent: 'EditEvent',
  ProfileView: 'ProfileView',
  ProfileDetails: 'ProfileDetails',
  ProfileEdit: 'ProfileEdit',
  CallbackFromMS: 'CallbackFromMS',
  CallbackMicrosoftIdentity: 'CallbackMicrosoftIdentity',
  CallbackFromGoogle: 'CallbackFromGoogle',
  CallbackGoogleIdentity: 'CallbackGoogleIdentity',
  CallbackSlack: 'CallbackSlack',
  CallbackMicrosoftProviderResourceGrant: 'CallbackMicrosoftProviderResourceGrant',
  Settings: 'Settings',
  AvailabilityList: 'AvailabilityList',
  AvailabilitySharingCreate: 'AvailabilitySharingCreate',
  PersonalAvailabilitySharingCreate: 'PersonalAvailabilitySharingCreate',
  PersonalAvailabilitySharingEdit: 'PersonalAvailabilitySharingEdit',
  AvailabilityConfirm: 'AvailabilityConfirm',
  AvailabilityConfirmGuest: 'AvailabilityConfirmGuest',
  CallbackFromZoom: 'CallbackFromZoom',
  Subscription: 'Subscription',
  AccountLink: 'AccountLink',
  ConfirmedList: 'ConfirmedList',
  UnconfirmedList: 'UnconfirmedList',
  TeamSettingRoot: 'TeamSettingRoot',
  TeamSettingMyCalendar: 'TeamSettingMyCalendar',
  TeamSettingMember: 'TeamSettingMember',
  TeamWebhookSettings: 'TeamWebhookSettings',
  TeamResourceSettings: 'TeamResourceSettings',
  TeamInfoSetting: 'TeamInfoSetting',
  TeamPlan: 'TeamPlan',
  TeamPlanPaymentInfo: 'TeamPlanPaymentInfo',
  TeamPlanOrder: 'TeamPlanOrder',
  TeamMemberInvited: 'TeamMemberInvited',
  TeamMemberTokenExpired: 'TeamMemberTokenExpired',
  TeamAvailabilitySharingCreate: 'TeamAvailabilitySharingCreate',
  TeamAvailabilitySharingEdit: 'TeamAvailabilitySharingEdit',
  TeamAvailabilitySharingConfirmedEventEditByMember: 'TeamAvailabilitySharingConfirmedEventEditByMember',
  TeamAvailabilitySharingConfirm: 'TeamAvailabilitySharingConfirm',
  TeamAvailabilitySharingConfirmGuest: 'TeamAvailabilitySharingConfirmGuest',
  TeamScheduleConfirm: 'TeamScheduleConfirm',
  TeamScheduleConfirmGuest: 'TeamScheduleConfirmGuest',
  TeamScheduleEdit: 'TeamScheduleEdit',
  ChangeRequestedTeamSchedule: 'ChangeRequestedTeamSchedule',
  AfterConfirm: 'AfterConfirm',
  ServiceUnavailable: 'ServiceUnavailable',
  Questionnaires: 'Questionnaires',
  TeamQuestionnaireDetail: 'TeamQuestionnaireDetail',
  ExternalTeamAvailabilitySharingConfirm: 'ExternalTeamAvailabilitySharingConfirm',
  ExternalTeamAvailabilitySharingConfirmGuest: 'ExternalTeamAvailabilitySharingConfirmGuest',
  ExternalAfterConfirm: 'ExternalAfterConfirm',
  TeamHome: 'TeamHome',
  TeamConfirmedList: 'TeamConfirmedList',
  TeamUnconfirmedList: 'TeamUnconfirmedList',
  TeamForms: 'TeamForms',
  TeamArrangementCreate: 'TeamArrangementCreate',
  TeamCalendar: 'TeamCalendar',
  TeamEventCreate: 'TeamEventCreate',
  TeamEventEdit: 'TeamEventEdit',
  TeamUnavailable: 'TeamUnavailable',
  PersonalHome: 'PersonalHome',
  PersonalConfirmedList: 'PersonalConfirmedList',
  PersonalUnconfirmedList: 'PersonalUnconfirmedList',
  PersonalArrangementCreate: 'PersonalArrangementCreate',
  PersonalCalendar: 'PersonalCalendar',
  PersonalEventCreate: 'PersonalEventCreate',
  PersonalEventEdit: 'PersonalEventEdit'
} as const
export type RouteNames = (typeof AllRouteNames)[keyof typeof AllRouteNames]
