<template>
  <b-steps
    label-position="bottom"
    mobile-mode="compact"
    class="spir-steps"
    :value="activeStep"
    :rounded="true"
    :has-navigation="false"
    @input="onInput"
  >
    <b-step-item
      v-for="(item, index) in items"
      headerClass="spir-step-item"
      :step="index + 1"
      :key="item.label"
      :label="item.label"
      :clickable="false"
    >
    </b-step-item>
  </b-steps>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'
import { StemItem } from './misc'
export default defineComponent({
  name: 'SpirStepper',
  props: {
    activeStep: {
      type: Number,
      default: 0
    },
    items: {
      type: Array as PropType<Array<StemItem>>
    },
    onInput: {
      type: Function,
      default: () => undefined
    }
  }
})
</script>

<style lang="scss">
.b-steps.spir-steps {
  .steps {
    .step-items {
      .step-item:not(:first-child)::before {
        background: linear-gradient(to left, $spir2_border 50%, $spir2_border 50%);
      }
    }
    .step-items {
      .step-item {
        width: 100px;
        .step-marker {
          height: 28px;
          width: 28px;
          background-color: white;
          border: 1px solid $spir2_border;
          color: $spir2_black;
          span {
            color: $spir2_gray;
            font-weight: 400;
          }
        }
        &.is-previous {
          .step-marker {
            background-color: white;
          }
        }
        .step-details {
          .step-title {
            color: $spir2_gray;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
          }
        }
        &.is-active {
          .step-marker {
            background-color: $spir2_primaryBlue;
            border-color: $spir2_primaryBlue;
            span {
              color: white;
              font-weight: 600;
            }
          }
          .step-details {
            .step-title {
              font-weight: 600;
              color: $spir2_primaryBlue;
            }
          }
        }
      }
    }
  }
}
</style>
