/**
 *
 * @param input title(1)
 * output: title(2)
 */
export const increaseNumberInTheString = (input: string): string => {
  if (!input) {
    return ''
  }
  const splitByNumber = input.split(/\((\d+)\)$/)
  const returnWithNextNumber = (baseTitle: string, currentNumber = 0) => {
    return `${baseTitle}(${currentNumber + 1})`
  }
  if (splitByNumber.length === 1) {
    return returnWithNextNumber(splitByNumber[0])
  }
  return returnWithNextNumber(splitByNumber[0], Number(splitByNumber[1]))
}
