import { RouteNames } from '@/router'
import UserModule from '@/store/modules/user'

const PUBLIC_PAGE_NAMES: RouteNames[] = [
  'ConfirmSchedule',
  'ConfirmScheduleGuest',
  'ChangeRequestedSchedule',
  'ProfileDetails',
  'VotePoll',
  'VotePollGuest',
  'AvailabilityConfirm',
  'AvailabilityConfirmGuest',
  'TeamAvailabilitySharingConfirm',
  'TeamAvailabilitySharingConfirmGuest',
  'TeamMemberInvited',
  'TeamMemberTokenExpired',
  'AfterConfirm',
  'TeamScheduleConfirm',
  'TeamScheduleConfirmGuest',
  'ChangeRequestedTeamSchedule',
  'ServiceUnavailable',
  'SuggestAlternativeDate'
]
const isPublic = (name: RouteNames) => {
  return PUBLIC_PAGE_NAMES.indexOf(name) >= 0
}
export default (to, _, next) => {
  const isSignedIn = UserModule.isSignIn
  if (isPublic(to.name) || isSignedIn) {
    next()
  } else {
    next({
      path: '/auth/signup',
      query: { redirect: to.fullPath }
    })
  }
}
