import { PollForGuard, guardPoll } from '@/lib/utils/pollRoutingGuard'
import { RouteNames } from '@/router'
import EditPollModule from '@/store/modules/editPoll'
import { NavigationGuardNext, Route } from 'vue-router'

export async function handleGroupPollGuard<T extends { name?: string; params: Route['params'] }>(data: {
  to: T
  next: NavigationGuardNext
}): Promise<void> {
  const toRoute: RouteNames = data.to.name as RouteNames
  const pollId = data.to.params.id
  async function getEditingPoll(): Promise<PollForGuard> {
    await EditPollModule.setPollAsEditingPoll({ pollId })
    const poll = EditPollModule.editingPoll
    // @ts-expect-error TS2322
    return { id: poll.id, isInvalid: poll.isInvalid, status: poll.status, isMine: EditPollModule.isMine }
  }
  const redirectInfo = await guardPoll({ to: toRoute, getEditingPoll })
  redirectInfo ? data.next({ name: redirectInfo.name, query: redirectInfo.query }) : data.next()
}

export async function guardGroupPoll(to: Route, _, next: NavigationGuardNext) {
  // @ts-expect-error TS2322
  await handleGroupPollGuard({ to, next })
}
