import React from 'react'
import { createRoot } from 'react-dom/client'
import { ReactPagesEntryPoint, type Locales } from '@/react-pages-wrapper'

export const render = ({ mountId, Component, locale }: { mountId: string; Component: React.FC; locale: Locales }) => {
  const mountElement = document.getElementById(mountId)
  if (!mountElement) throw new Error(`Could not find element with id ${mountId}`)
  const root = createRoot(mountElement)
  root.render(
    <ReactPagesEntryPoint locale={locale}>
      <Component />
    </ReactPagesEntryPoint>
  )
}
