import { TeamModule } from '@/store/modules/team'
import TeamRecordModule from '@/store/modules/teamRecord'
import { Location, NavigationGuardNext, Route } from 'vue-router'
import { AllRouteNames } from './route-names'

export function redirectFromListAvailability(to: Route | Location, from: Route | Location, next: NavigationGuardNext) {
  const segment = to.query?.['selected-segment']
  if (typeof segment === 'string' && segment !== 'personal') {
    next({ name: AllRouteNames.TeamHome, params: { id: segment } })
  } else {
    next({ name: AllRouteNames.PersonalHome })
  }
}

export function redirectFromListForms(to: Route | Location, from: Route | Location, next: NavigationGuardNext) {
  const teamId = to.query?.['team-id']
  if (typeof teamId === 'string') {
    next({ name: AllRouteNames.TeamForms, params: { id: teamId } })
  } else {
    const usableTeamId = TeamModule.myLoadedUsableTeams[0]?.id
    if (usableTeamId === undefined) {
      next({ name: AllRouteNames.Main })
    } else {
      next({ name: AllRouteNames.TeamForms, params: { id: usableTeamId } })
    }
  }
}

export function redirectFromConfirmedList(to: Route | Location, from: Route | Location, next: NavigationGuardNext) {
  const teamId = to.query?.['team-id']
  if (typeof teamId === 'string') {
    next({ name: AllRouteNames.TeamConfirmedList, params: { id: teamId } })
  } else {
    next({ name: AllRouteNames.PersonalConfirmedList })
  }
}

export function redirectFromUnconfirmedList(to: Route | Location, from: Route | Location, next: NavigationGuardNext) {
  const teamId = to.query?.['team-id']
  if (typeof teamId === 'string') {
    next({ name: AllRouteNames.TeamUnconfirmedList, params: { id: teamId } })
  } else {
    next({ name: AllRouteNames.PersonalUnconfirmedList })
  }
}

export async function redirectFromUnavailable(to: Route | Location, from: Route | Location, next: NavigationGuardNext) {
  const teamId = to.params?.['id']
  if (typeof teamId === 'string') {
    try {
      await TeamRecordModule.fetchTeam({ teamId })
      next({ name: AllRouteNames.TeamPlan, params: { id: teamId } })
    } catch (e) {
      next({ name: AllRouteNames.Main })
    }
  } else {
    next({ name: AllRouteNames.Main })
  }
}
