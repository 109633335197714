import { FullCalendarEvent } from '@/types/schedule'
import { addMinutes, differenceInMinutes } from 'date-fns'

const DEFAULT_CANDIDATE_SPAN = 30

export type StartAndEnd = { start: string; end: string }
export type StartAndEndDate<T extends StartAndEnd = StartAndEnd> = Omit<T, 'start' | 'end'> & { start: Date; end: Date }

function isStartAndEnd(t: { start }): t is StartAndEnd {
  return typeof t.start === 'string'
}

export const FromStartAndEnd = {
  convertToDuration<T extends StartAndEnd | StartAndEndDate>(target: T): number {
    const { start, end }: { start: Date; end: Date } = isStartAndEnd(target)
      ? { start: new Date(target.start), end: new Date(target.end) }
      : target
    return differenceInMinutes(end, start)
  },
  convertToMerged<T extends StartAndEnd = StartAndEnd, R = FullCalendarEvent>({
    temps,
    candidateSpan = DEFAULT_CANDIDATE_SPAN,
    mapper,
    duration
  }: {
    temps: T[]
    candidateSpan?: number
    mapper: (candidate: StartAndEndDate<T>) => R
    duration: number
  }): R[] {
    const returnValue: R[] = []
    let start: Date
    temps.forEach((candidate, index) => {
      if (!start) {
        start = new Date(candidate.start)
      }
      const endDate = new Date(candidate.end)
      if (!temps[index + 1]) {
        const r = mapper({ ...candidate, start, end: endDate })
        returnValue.push(r)
        return
      }
      const nextEnd = temps[index + 1].end
      const diffMin = differenceInMinutes(new Date(nextEnd), endDate)
      const compareNum = duration || candidateSpan
      if (diffMin <= compareNum) {
        return
      }
      const r = mapper({ ...candidate, start, end: endDate })
      returnValue.push(r)
      // @ts-expect-error TS2322
      start = null
    })
    return returnValue
  },
  convertToSplittedByDuration<T extends StartAndEndDate>({ temps, duration }: { temps: T[]; duration: number }): T[] {
    const result = temps.reduce((acc: T[], temp: T): T[] => {
      const diffMin = this.convertToDuration({ start: temp.start, end: temp.end })
      const n = Math.floor(diffMin / duration)
      const oneBlockSplitted = Array(n)
        .fill(null)
        .reduce(
          prev => {
            const end = addMinutes(prev.start, duration)
            const candidate = { ...temp, start: prev.start, end }
            return {
              candidates: prev.candidates.concat(candidate),
              start: end
            }
          },
          { candidates: [], start: temp.start }
        )
      return acc.concat(oneBlockSplitted.candidates)
    }, [])
    return result
  }
}
