import router from '@/router'
import { CandidateStatus, FullCalendarEvent, ScheduleSource } from '@/types'
import { addMinutes, isBefore } from 'date-fns'
import { getDurationFromFullCalendarEvent } from './durations'

// @ts-expect-error TS2322
export const getCandidateSource = (status: CandidateStatus = null, startDate?: Date): ScheduleSource => {
  if (startDate) {
    const now = new Date()
    if (isBefore(startDate, now)) {
      return 'expiredPoll'
    }
  }
  switch (status) {
    case null:
    case 'open':
    case 'suggestedByConfirmer':
    case 'suggestedByOrganizer':
    case 'deleted': // irregullar
      return 'candidate'
    default:
      return status
  }
}
// @ts-expect-error TS2322
export const isEditable = (status: CandidateStatus = null): boolean => {
  if (router.currentRoute.name === 'ConfirmSchedule') {
    return false
  }
  switch (status) {
    case 'rejected':
    case 'deleted':
    case 'expired':
    case 'expiredPoll':
      return false
  }
  return true
}

export const genNewCandidateFromTempEvent = ({
  event,
  duration
}: {
  event: FullCalendarEvent
  duration: number
}): { start: Date; end: Date } => {
  const durationOfDraggedEvent = getDurationFromFullCalendarEvent(event)
  const start = event.start
  const end = durationOfDraggedEvent < duration ? addMinutes(start, duration) : event.end
  return { start, end }
}
