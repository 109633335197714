<template>
  <div class="daily-view-header">
    <div v-if="!hideAllday" ref="resourceViewAllDayHeader" class="all-day-label" />
    <div class="row-body" id="daily-view-header" ref="headerLabels">
      <div class="wrapper column-header">
        <HeaderTitle
          v-for="column in columns"
          :key="column.columnKey"
          class="column daily-view-calendar-column"
          :title="column.title"
          :type="column.type"
          :hideAllday="hideAllday"
          :hasEvent="column.hasEvent"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TimeAxisMixin from '@/components/calendar/TimeAxis'
import { EventBus, EVENTS } from '@/lib/eventBus'
import { ResourceColumn, ResourceEvent } from '@/types'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Body from './Body.vue'
import HeaderTitle from './Title.vue'

@Component({
  components: {
    HeaderTitle,
    Body
  }
})
export default class ResourceViewHeader extends mixins(TimeAxisMixin) {
  // @ts-expect-error TS2564
  @Prop() columns: ResourceColumn[]
  // @ts-expect-error TS2564
  @Prop() gridHeight: string
  // @ts-expect-error TS2564
  @Prop() events: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop() hideAllday: boolean
  // @ts-expect-error TS2564
  @Prop() timezoneButtonAsLabel: boolean

  // @ts-expect-error TS2564
  $refs: {
    resourceViewAllDayHeader: HTMLElement
    headerLabels: HTMLElement
  }
  mounted() {
    EventBus.on(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
    this.setHeader()
  }
  beforeDestroy() {
    EventBus.off(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  syncScroll(srcElement) {
    if (this.$refs.headerLabels) {
      this.$refs.headerLabels.scroll({
        left: srcElement.scrollLeft
      })
    }
  }
  get allEvents() {
    return this.events.filter(event => !!event.allDay)
  }
  setHeader() {
    if (this.hideAllday || !this.$refs.resourceViewAllDayHeader) {
      return
    }
    this.$refs.resourceViewAllDayHeader.appendChild(this.timeAxisHeader(this.timezoneButtonAsLabel))
  }
  eventsByCalendar(columnKey) {
    return this.allEvents.filter(event => event.columnKey === columnKey)
  }
  handleCreateAllDayEvent(event, columnKey) {
    this.$emit('select', {
      columnKey,
      allDay: true,
      jsEvent: event
    })
  }
}
</script>

<style scoped lang="scss">
@import '../../../../styles/spacing-helper.scss';
.daily-view-header {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  max-width: 100vw;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08), 0px 1px 0px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}
.row-headar {
  display: flex;
  flex-direction: column;
  height: 100%;
  .all-day-label {
    display: flex;
    align-items: center;
    min-height: 45px;
  }
}
.row-body {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
  .wrapper {
    display: flex;
    flex-direction: row;
    min-height: 41px;
    .column {
      flex: 1;
    }
    &.column-body {
      height: 100%;
      min-height: 45px;
      max-height: 60px;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
</style>
