import { LocalStorage } from '@/models/localStorage/localStorage'

// Serverの更新の前に一時的にLocalStorageを利用する

const LOCAL_STORAGE_KEY_TEMPORARY = 'LOCAL_STORAGE_KEY_TEMPORARY'

export default class TempStorage extends LocalStorage {
  constructor() {
    super(LOCAL_STORAGE_KEY_TEMPORARY, ['language'])
  }
}
