<template>
  <div class="form-item-title-frame">
    <FormLabelTitle :title="title" :required="required" />
    <div v-if="tip.type !== 'none'" class="tip-frame">
      <TipMarkBase :pos="tip.position" :width="tip.width">
        <TipMarkParagraph v-for="message in tip.messages" :key="message">
          {{ message }}
        </TipMarkParagraph>
      </TipMarkBase>
    </div>
  </div>
</template>

<script lang="ts">
import { TipInfo } from '@/components/ui/form/tipInfo'
import { FormLabelTitle } from '@/components/ui/layouts/FormLabelTitle'
import TipMarkBase from '@/components/ui/tipMark/TipMarkBase.vue'
import TipMarkParagraph from '@/components/ui/tipMark/TipMarkParagraph.vue'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'FormItemTitle',
  components: {
    FormLabelTitle,
    TipMarkBase,
    TipMarkParagraph
  },
  props: {
    title: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    tip: {
      type: Object as PropType<TipInfo>,
      default: () => ({ type: 'none' })
    }
  }
})
</script>

<style scoped lang="scss">
.form-item-title-frame {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  .tip-frame {
    height: 16px;
    padding-left: 4px;
  }
}
</style>
