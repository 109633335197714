import { AvailabilityModelForPrivate, AvailabilityModelForTeam } from '@/models/data'
import { AvailabilitySharingsPostRequest, TeamTeamIdAvailabilitySharingsPostRequest } from '@spirinc/contracts'
import { increaseNumberInTheString } from '../increaseNumberInTheString'

type DuplicationConverter<T extends 'personal' | 'team'> = T extends 'personal'
  ? {
      model: AvailabilityModelForPrivate
      res: AvailabilitySharingsPostRequest
    }
  : T extends 'team'
    ? {
        model: AvailabilityModelForTeam
        res: TeamTeamIdAvailabilitySharingsPostRequest
      }
    : never

export const fromAvailabilityModel = {
  convertToDuplicationPayload<T extends 'personal' | 'team' = 'personal'>(
    model: DuplicationConverter<T>['model']
  ): DuplicationConverter<T>['res'] {
    const payload = model.parameterForDuplicate
    // @ts-expect-error TS2322
    return {
      ...payload,
      title: increaseNumberInTheString(payload.title),
      isPublished: model.isPublished
    }
  }
}
