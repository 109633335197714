<template>
  <div class="form-item-spacer" :class="spaceName">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'
type TopSpaceName = 't24' | 't16' | 't12' | 't8'
type BottomSpaceName = 'b24' | 'b16' | 'b12' | 'b8'
type VerticalSpaceName = 'v24' | 'v16' | 'v8'

type SpaceName = TopSpaceName | BottomSpaceName | VerticalSpaceName
export default defineComponent({
  name: 'FormItemSpacer',
  props: {
    spaceName: {
      type: String as PropType<SpaceName>,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.form-item-spacer {
  width: 100%;
  &.t24 {
    padding-top: 24px;
  }
  &.t16 {
    padding-top: 16px;
  }
  &.t12 {
    padding-top: 12px;
  }
  &.b24 {
    padding-bottom: 24px;
  }
  &.b16 {
    padding-bottom: 16px;
  }
  &.b12 {
    padding-bottom: 12px;
  }
  &.v24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  &.v16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &.t8 {
    padding-top: 8px;
  }
  &.b8 {
    padding-bottom: 8px;
  }
  &.v8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
</style>
