import { AfterConfirmQueryParams } from '@/types'

export function getPersonalAvailabilitySharingAfterConfirmParams(data: {
  availabilitySharingId: string
  confirmationId: string
}): AfterConfirmQueryParams {
  return {
    type: 'availability',
    'e-id': data.confirmationId,
    id: data.availabilitySharingId,
    't-or-p': 'private'
  }
}

export function getTeamAvailabilitySharingAfterConfirmParams(data: {
  teamId: string
  availabilitySharingId: string
  confirmationId: string
}): AfterConfirmQueryParams {
  return {
    type: 'availability',
    'e-id': data.confirmationId,
    id: data.availabilitySharingId,
    't-or-p': 'team',
    't-id': data.teamId
  }
}

export function getPersonalScheduleAfterConfirmParams(data: { scheduleId: string }): AfterConfirmQueryParams {
  return {
    type: 'schedule',
    't-or-p': 'private',
    id: data.scheduleId
  }
}
