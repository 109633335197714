import veeValidatorMessagesEn from 'vee-validate/dist/locale/en.json'
import veeValidatorMessagesJa from 'vee-validate/dist/locale/ja.json'
import { en } from './en'
import { ja } from './ja'

ja.message.error.validation = {
  ...veeValidatorMessagesJa.messages,
  ...ja.message.error.validation
}
en.message.error.validation = {
  ...veeValidatorMessagesEn.messages,
  ...en.message.error.validation
}
export default {
  ja,
  en
}
