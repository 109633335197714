import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { isSameDayByUserTimeZone } from '@/lib/utils/timezone'
import { Timezone } from '@/models/timezone'
import { DateTime, DateTimeOptions } from 'luxon'

export const parseISO = (text: string, options?: DateTimeOptions) => {
  return DateTime.fromISO(text, options).toJSDate()
}
export function minusDuration({ date, duration }: { date: Date; duration: luxon.DurationInput }): Date {
  return DateTime.fromJSDate(date).minus(duration).toJSDate()
}

export const formatEventTimes = (event: { start: Date; end: Date }, timeZone?: string) => {
  return `${spirDateFormat(event.start, spirDateFormatTypes.hourMin, {
    timeZone
  })} - ${spirDateFormat(event.end, spirDateFormatTypes.hourMin, { timeZone })}`
}

export const formatDateRange = <T extends { start: Date; end: Date }>(range: T, timezone?: Timezone) => {
  if (isSameDayByUserTimeZone(range.start, range.end)) {
    return `${spirDateFormat(range.start, spirDateFormatTypes.mdWeekday, { timeZone: timezone?.key })}`
  }
  return `${spirDateFormat(range.start, spirDateFormatTypes.mdWeekday, { timeZone: timezone?.key })} - ${spirDateFormat(
    range.end,
    spirDateFormatTypes.mdWeekday,
    { timeZone: timezone?.key }
  )}`
}
