<template>
  <div>
    <div class="label-wrapper">
      <div class="mr-2">
        <b-icon icon="account" custom-size="mdi-18px" class="label-icon" />
      </div>
      <label class="label">{{ $t('message.confirm.pleaseInputYourInfoSubLabel') }}</label>
    </div>
    <div class="input-wrapper">
      <WithValidationInput
        v-model="nameValue"
        :name="$t('labels.name')"
        rules="required|max:100"
        :placeholder="$t('message.confirm.attendee.namePlaceholder')"
        @input="updateNameValue"
      >
        <template v-slot:label>
          <label class="confirm-modal-item-label">{{ $t('labels.name') }}</label>
        </template>
      </WithValidationInput>
      <WithValidationInput
        v-model="emailValue"
        :name="$t('labels.email')"
        type="email"
        autocomplete="email"
        rules="required|email|max:100"
        :placeholder="$t('message.confirm.attendee.emailPlaceholder')"
        @input="updateEmailValue"
        :helpText="$t('message.confirm.otherCalendar.emailHelper')"
      >
        <template v-slot:label>
          <label class="confirm-modal-item-label">{{ $t('labels.email') }}</label>
        </template>
      </WithValidationInput>
    </div>
  </div>
</template>

<script lang="ts">
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    WithValidationInput
  }
})
export default class YourInfoForm extends Vue {
  nameValue = ''
  emailValue = ''

  updateNameValue(newValue) {
    this.$emit('changeName', newValue)
  }

  updateEmailValue(newValue) {
    this.$emit('changeEmail', newValue)
  }
}
</script>

<style scoped lang="scss">
.label-wrapper {
  display: flex;
  margin-bottom: 12px;
}
.label-icon {
  color: $spir2_black;
}
.input-wrapper {
  padding-left: 30px;
}
</style>
