<template>
  <PageheadFrame>
    <PageheadTitle :title="title" />
    <PageheadCloseButtonFrame :isAlwaysVisible="canCloseEvenUsingMobile">
      <PageheadCloseButton :onClick="handleClick" />
    </PageheadCloseButtonFrame>
  </PageheadFrame>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'
import PageheadCloseButton from './page/pagehead/PageheadCloseButton.vue'
import PageheadCloseButtonFrame from './page/pagehead/PageheadCloseButtonFrame.vue'
import PageheadFrame from './page/pagehead/PageheadFrame.vue'
import PageheadTitle from './page/pagehead/PageheadTitle.vue'

export default defineComponent({
  name: 'ClosingPageheadTitleBox',
  components: {
    PageheadFrame,
    PageheadTitle,
    PageheadCloseButton,
    PageheadCloseButtonFrame
  },
  props: {
    title: {
      type: String,
      required: true
    },
    // 通常モバイル時にはcloseボタンを出さないとのデザイン意図があったのが
    // モバイルでもcloseボタンを出したい場合があるため追加
    canCloseEvenUsingMobile: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function as PropType<() => Promise<void>>
    }
  },
  setup(props) {
    return {
      handleClick: async () => {
        // @ts-expect-error TS2722
        await props.onClose()
      }
    }
  }
})
</script>
