<template>
  <CommonLayoutModal
    v-if="editingEvent"
    @close="$parent.close()"
    :showCloseButton="false"
    :showFooter="showModalFooter"
  >
    <template v-slot:modalHeader v-if="isDeleted">
      <EventDeletedStatusChip />
    </template>
    <template v-slot:modalHeaderRightButton>
      <Button icon="close" type="text" class="modal-close-button" @click="$emit('close')" />
    </template>
    <template v-slot:modalBody>
      <EventFormModalBody :currentEvent="currentEvent" />
    </template>
    <template v-slot:modalFooter>
      <EventModalFooterFrame>
        <EventModalFooterOwnerFrame v-if="hasTeamInfo">
          <TeamEventModalOwner :memberId="memberId" :teamId="teamId" />
        </EventModalFooterOwnerFrame>
        <EventModalFooterActionsFrame v-if="showControls === true">
          <Button type="danger" @click="$emit('deleteItem', editingEvent)"> {{ $t('buttons.delete') }} </Button>
          <Button type="primary" @click="$emit('editEvent')"> {{ $t('buttons.editing') }} </Button>
        </EventModalFooterActionsFrame>
      </EventModalFooterFrame>
    </template>
  </CommonLayoutModal>
</template>

<script lang="ts">
import AttendeeWithProfileButton from '@/components/features/attendees/AttendeeWithProfileButton/index.vue'
import { EventDeletedStatusChip } from '@/components/features/events/EventDeletedStatusChip'
import { EventFormModalBody } from '@/components/features/events/EventFormModalBody'
import AttendeeItem from '@/components/molecules/AttendeeItem.vue'
import AttendeeStatus from '@/components/molecules/AttendeeStatus.vue'
import MemberAvatar from '@/components/molecules/MemberAvatar.vue'
import OnlineMeetingSelector from '@/components/molecules/OnlineMeetingSelector.vue'
import Button from '@/components/ui/Button.vue'
import HtmlDisplay from '@/components/ui/layouts/HtmlDisplay/index.vue'
import LocationRead from '@/components/ui/layouts/LocationRead/index.vue'
import { GoogleEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CommonLayoutModal from './CommonLayout.vue'
import { TeamEventModalOwner } from '@/components/features/teamEvents/TeamEventModalOwner'
import {
  EventModalFooterActionsFrame,
  EventModalFooterOwnerFrame,
  EventModalFooterFrame
} from '@/components/features/events/eventModalFooter'

@Component({
  components: {
    CommonLayoutModal,
    LocationRead,
    AttendeeItem,
    AttendeeWithProfileButton,
    HtmlDisplay,
    OnlineMeetingSelector,
    AttendeeStatus,
    MemberAvatar,
    Button,
    EventFormModalBody,
    EventDeletedStatusChip,
    TeamEventModalOwner,
    EventModalFooterActionsFrame,
    EventModalFooterOwnerFrame,
    EventModalFooterFrame
  }
})
export default class EventFormModal extends Vue {
  // @ts-expect-error TS2564
  @Prop() currentEvent: GoogleEvent
  // @ts-expect-error TS2564
  @Prop({ default: false }) hideControls: boolean

  // @ts-expect-error TS2322
  editingEvent: GoogleEvent = null

  get isDeleted() {
    const deleted = this.editingEvent.deleted ?? false
    return deleted
  }
  get showControls() {
    return !this.hideControls && this.editingEvent.writable && !this.isDeleted
  }

  get showModalFooter() {
    return this.showControls === true || this.hasTeamInfo
  }

  get hasTeamInfo() {
    return this.teamId !== undefined && this.memberId !== undefined
  }

  get teamId() {
    return this.currentEvent.teamInfo?.teamId
  }

  get memberId() {
    return this.currentEvent.teamInfo?.memberId
  }

  created() {
    this.editingEvent = {
      ...this.currentEvent
    }
  }
}
</script>

<style lang="scss" scoped>
.deleted-event-mark {
  color: $spir2_gray;
  @include inter_font_setting;
}
.modal-close-button {
  width: 32px;
}
</style>
