<template>
  <div class="user-badge">
    <avatar :class="{ 'mr-3': userName }" :src="photo" :username="userName" color="#fff" :size="iconWidth"></avatar>
    <span class="name" v-if="userName">{{ userName }}</span>
  </div>
</template>

<script lang="ts">
import Avatar from 'vue-avatar'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Avatar
  }
})
export default class UserIcon extends Vue {
  @Prop() userPhoto?: string
  // @ts-expect-error TS2564
  @Prop({ default: '' }) userName: string
  // @ts-expect-error TS2564
  @Prop({ default: 35 }) iconWidth: number

  get photo() {
    return this.userPhoto || require('@/assets/images/account.png')
  }
}
</script>

<style scoped lang="scss">
.user-badge {
  display: inline-flex;
  align-items: center;
  .name {
    font-weight: 600;
  }
}
</style>
