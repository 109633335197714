<template>
  <ConfirmModal
    :header="confirmDeleteTitle"
    :cancelBtn="$t('buttons.cancel')"
    :confirmBtn="$t('buttons.ok')"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <p v-html="confirmDeleteBody"></p>
  </ConfirmModal>
</template>

<script lang="ts">
import ConfirmModal from '@/components/modal/Confirm.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ConfirmModal
  }
})
export default class ConfirmDeleteModal extends Vue {
  @Prop({ default: null }) customMessage
  @Prop({ default: null }) customTitle

  get confirmDeleteTitle() {
    return this.customTitle || this.$t('message.confirmDelete').toString()
  }

  get confirmDeleteBody() {
    return this.customMessage || this.$t('message.deleteOperationWarning').toString()
  }
}
</script>
