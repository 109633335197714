<template>
  <FormItemLayout :icon="icon">
    <div class="form-item__body--label pab-8">
      <span :class="{ required: required }">
        {{ label }}
      </span>
      <b-tooltip v-if="help" :class="required ? 'ml-4' : 'ml-2'" type="is-black" :multilined="true">
        <b-icon icon="help-circle" class="help-icon" custom-size="mdi-18px" />
        <template v-slot:content>
          <p class="help-message">{{ help }}</p>
        </template>
      </b-tooltip>
    </div>
    <div class="form-item__body-content">
      <slot></slot>
    </div>
  </FormItemLayout>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormItemLayout from './FormItemLayout.vue'

@Component({
  components: {
    FormItemLayout
  }
})
export default class FormItem extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: null }) icon: string
  // @ts-expect-error TS2564
  @Prop({ default: null }) label: string
  // @ts-expect-error TS2564
  @Prop({ default: null }) help: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) required: boolean
}
</script>

<style scoped lang="scss">
.form-item__body--label {
  span {
    color: $spir_dark_primary;
    @include font_setting_12($font_weight: 400);
    position: relative;
    &.required::after {
      content: '*';
      position: absolute;
      top: -7px;
      right: -10px;
      color: $spir2_primaryBlue;
    }
  }

  .help-icon {
    color: $spir2_gray;
  }
  .help-message {
    text-align: left;
    white-space: pre-wrap;
  }
  display: flex;
  align-items: center;
}
</style>
