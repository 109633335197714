<template>
  <b-dropdown
    class="spir-dropdown"
    ref="dropdown"
    aria-role="list"
    :scrollable="true"
    :max-height="maxHeight"
    :value="value"
    :expanded="expanded"
    :mobile-modal="mobileModal"
    :position="buefyPos"
    :disabled="disabled"
    @change="handleChange"
    @active-change="handleActiveChange"
  >
    <template #trigger="{ active }">
      <slot name="trigger" :active="active" />
    </template>
    <slot name="dropdown" />
  </b-dropdown>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdown',
  props: {
    value: {
      type: [String, Number]
    },
    expanded: {
      type: Boolean,
      default: true
    },
    mobileModal: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    position: {
      type: String as PropType<'bottom' | 'top' | 'bottom-left'>,
      default: 'bottom'
    },
    maxHeight: {
      type: Number,
      default: 250
    }
  },
  setup(props, { emit }) {
    function handleChange(newValue: string) {
      emit('change', newValue)
    }
    function handleActiveChange(newValue: string) {
      emit('active-change', newValue)
    }
    const buefyPos = computed(() => {
      switch (props.position) {
        case 'top':
          return 'is-top-right'
        case 'bottom-left':
          return 'is-bottom-left'
        default:
          return 'is-bottom-right'
      }
    })

    return {
      buefyPos,
      handleChange,
      handleActiveChange
    }
  },
  methods: {
    toggle() {
      const dd: any = this.$refs.dropdown // eslint-disable-line
      dd.toggle()
    }
  }
})
</script>

<style lang="scss">
.spir-dropdown {
  &.is-disabled {
    button {
      background-color: $spir2_bgDisabled;
    }
  }
  .dropdown-content {
    border-radius: 6px;
    background: $spir_light_primary;
  }
}
</style>
