<template>
  <ReactPagesRenderer :Component="Component" />
</template>

<script lang="ts">
import { ReactPagesRenderer } from '@/components/functional/ReactPagesRenderer'
import { ui } from '@/react-pages-wrapper'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OffsetBottomObserver',
  components: {
    ReactPagesRenderer
  },
  setup(props) {
    const Component = () => ui.SnackbarPortal()
    return {
      Component
    }
  }
})
</script>
