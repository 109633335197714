<template>
  <div
    v-touch:start="touchStart"
    v-touch:touchhold="touchhold"
    @mousedown="mousedown"
    @contextmenu="preventContext"
    :style="{ 'min-height': `${gridHeight}px` }"
    class="resource-view-column"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DailyCalendarTimeGrid extends Vue {
  // @ts-expect-error TS2564
  @Prop() index: number
  // @ts-expect-error TS2564
  @Prop() gridHeight: string

  touchFlag = false
  touchStart() {
    this.touchFlag = true
  }
  touchhold(e) {
    const rect = e.target.getBoundingClientRect()
    const clientY = e.touches[0].clientY
    const offsetY = clientY - window.pageYOffset - rect.top
    const newEvent = {
      offsetY,
      clientY
    }
    this.$emit('mousedown', newEvent, this.index)
  }
  preventContext(e) {
    e.preventDefault()
  }
  mousedown(e) {
    if (this.touchFlag) {
      this.touchFlag = false
      return
    }
    if (e.button && e.button !== 0) {
      // left button click
      return
    }
    this.$emit('mousedown', e, this.index)
  }
}
</script>

<style scoped>
.resource-view-column {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  box-shadow: -1px -1px 0px #ebebeb;
  text-align: right;
  padding-right: 0.5rem;
  font-size: 0.85rem;
}
</style>
