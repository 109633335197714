<template>
  <div class="spir-form-container timezone-and-input-displayName columns">
    <TimeZoneSelector
      class="column is-8 is-full-mobile"
      :title="$t(`timezone.titles.${order}`)"
      :updateHeightWhenDropdownIsActive="true"
      :timeZone="timezoneInfoSynced.key"
      @onChangeTimeZone="onChangeTimeZone"
    />
    <WithValidationInput
      class="column is-3 is-full-mobile display-name"
      v-model="timezoneInfoSynced.displayName"
      rules="max:7"
      :vid="order"
      :label="$t('timezone.timeZoneModal.displayName')"
      :placeholder="timeZoneByKey.abbreviation"
      @input="updatedName"
    />
    <div class="column is-1 is-hidden-mobile delete-button">
      <b-button v-if="showDelete" @click="deleteTimezone" class="icon-button" icon-left="close"></b-button>
    </div>
  </div>
</template>

<script lang="ts">
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import TimeZoneSelector from '@/components/organisms/timezoneSelector/TimezoneSelector.vue'
import { TimeZoneInfo } from '@/models/data/userInfo'
import TimezoneModule from '@/store/modules/timezones'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TimeZoneSelector,
    WithValidationInput
  }
})
export default class TimezoneSelectorAndDisplayName extends Vue {
  // @ts-expect-error TS2564
  @PropSync('timezoneInfo', { type: Object }) timezoneInfoSynced: TimeZoneInfo
  // @ts-expect-error TS2564
  @Prop({ default: false }) showDelete: boolean
  // @ts-expect-error TS2564
  @Prop() order: string

  get timeZoneByKey() {
    return TimezoneModule.timezoneByKey({ key: this.timezoneInfoSynced.key })
  }
  onChangeTimeZone(newKey) {
    this.timezoneInfoSynced.key = newKey
    this.timezoneInfoSynced.displayName = ''
    this.$emit('updateTimezone')
  }
  updatedName() {
    this.$emit('updateTimezone')
  }
  deleteTimezone() {
    this.$emit('deleteTimezone')
  }
}
</script>

<style lang="scss">
.spir-form-container.timezone-and-input-displayName {
  label.label {
    font-weight: 400;
    color: $spir2_gray;
    margin-bottom: 0.5em;
  }
  .display-name {
    input.input {
      font-size: 12px;
    }
  }
  .delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    .icon-button {
      color: $spir2_gray;
    }
  }
}
</style>
