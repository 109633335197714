import { HubspotVisitorIdentificationPostResponse } from '@spirinc/contracts'
import axiosInstance from '../axios'

export async function createVisitorIdentificationToken(): Promise<
  { type: 'success'; value: { email: string; token: string } } | { type: 'error' }
> {
  try {
    const response = await axiosInstance.post<HubspotVisitorIdentificationPostResponse>(
      '/hubspot/visitor-identification'
    )
    return { type: 'success', value: response.data }
  } catch (e) {
    return { type: 'error' }
  }
}
