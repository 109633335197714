import i18n from '@/i18n'
import { configure, extend } from 'vee-validate'
import { email, excluded, max, min, required } from 'vee-validate/dist/rules'
import { httpsOnly, requiredAtLeastOneCheck } from './custom-validator'

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`message.error.validation.${values._rule_}`, { ...values, attr: field })
  }
})

function phoneNumberValidator(value) {
  return value?.isValid
}

extend('email', email)
extend('required', required)
extend('min', min)
extend('max', max)
extend('excluded', excluded)
extend('emailExcluded', excluded)
extend('phoneNumber', phoneNumberValidator)
extend('requiredAtLeastOneCheck', requiredAtLeastOneCheck)
extend('requiredExternalConfirmationPageUrl', required)
extend('httpsOnly', httpsOnly)
