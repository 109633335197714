<template>
  <AuthFormFrame :isNotSupported="isNotSupported">
    <template v-if="finalTitle">
      <AuthFormTitle :title="finalTitle" />
      <AuthFormSpacer spaceName="vb16" />
    </template>
    <template v-if="isNotSupported">
      <DescriptionForInAppBrowser :messageHtml="messageForInAppBrowser" />
    </template>
    <template v-else>
      <slot />
    </template>
    <AuthFormSpacer spaceName="vt16" />
    <AuthTermAndPolicy />
  </AuthFormFrame>
</template>

<script lang="ts">
import DescriptionForInAppBrowser from '@/components/atoms/DescriptionForInAppBrowser.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import { AuthFormFrame } from '@/components/features/auth/AuthFormFrame'
import { AuthFormTitle } from '@/components/features/auth/AuthFormTitle'
import { AuthTermAndPolicy } from '@/components/features/auth/AuthTermAndPolicy'
import i18n from '@/i18n'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthFormLayout',
  components: {
    AuthFormFrame,
    AuthFormTitle,
    AuthFormSpacer,
    DescriptionForInAppBrowser,
    AuthTermAndPolicy
  },
  props: {
    title: {
      type: String
    },
    isNotSupportedBrowser: {
      type: Function as PropType<() => boolean>,
      required: true
    },
    messageForInAppBrowser: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { title } = toRefs(props)
    const isNotSupported = computed(() => props.isNotSupportedBrowser())
    const finalTitle = computed(() => {
      if (!props.title) {
        return null
      }
      // @ts-expect-error TS2532
      return isNotSupported.value ? i18n.t('forms.auth.noSupport.title') : title.value
    })
    return {
      isNotSupported,
      finalTitle
    }
  }
})
</script>
