<template>
  <div>
    <div v-if="getUseOtherMail" class="other-email-field-wrapper">
      <div class="other-email-field">
        <p>{{ email }}</p>
        <b-button class="icon-button" custom-size="mdi-18px" icon-left="close" @click="handleReset"></b-button>
      </div>
    </div>
    <template v-if="!getUseOtherMail">
      <OtherCalendarRegistButton
        :ignoreEmails="ignoreEmails"
        :updateHeightWhenDropdownIsActive="true"
        @active-change="activeChanged"
        @confirm="handleConfirm"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import OtherCalendarRegistButton from './OtherCalendarRegistButton.vue'

@Component({
  components: {
    OtherCalendarRegistButton
  }
})
export default class OtherCalendarItem extends Vue {
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false
  useOtherMail = false
  email = ''

  get getUseOtherMail() {
    return this.useOtherMail
  }

  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm(email: string) {
    this.email = email
    this.useOtherMail = true
    this.activeChanged(false)
    this.$emit('confirm', email)
  }
  handleReset() {
    this.email = ''
    this.useOtherMail = false
    this.activeChanged(false)
    this.$emit('reset')
  }
}
</script>

<style scoped lang="scss">
.other-email-field-wrapper {
  width: 100%;
  padding-left: 30px;

  .other-email-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    color: $spir2_black;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.24);
  }
}
</style>
