<template>
  <div class="event-modal-footer-owner">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EventModalFooterOwnerFrame'
})
</script>

<style lang="scss" scoped>
.event-modal-footer-owner {
  &:first-child:not(:last-child) {
    padding-top: 12px;
  }
  &:first-child:last-child {
    padding-top: 16px;
  }
}
</style>
