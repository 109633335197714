<template>
  <div class="field" :class="{ updateHeight: updateHeight }">
    <label v-if="!noLabel" class="spir-label">{{ $t('scheduleEventList.organizer') }}</label>
    <b-dropdown
      v-if="currentCalendar"
      class="spir-input-select"
      aria-role="list"
      expanded
      :mobile-modal="false"
      :scrollable="true"
      :max-height="250"
      :disabled="disableCalendarPicker"
      @active-change="activeChanged"
    >
      <div class="input" slot="trigger" slot-scope="{ active }" :class="{ isOpen: active }">
        <b-icon
          class="mx-1"
          icon="checkbox-blank"
          size="is-small"
          :style="{ color: currentCalendar.backgroundColor }"
        ></b-icon>
        <span class="calendar-name">{{ currentCalendar.title }}</span>
        <span class="calendar-email mr-2">{{ currentCalendar.invitationAddress }}</span>
      </div>
      <div class="spir-input-select__account-wrapper" v-for="account in accountWithcalendars" :key="account.id">
        <UserBadge class="pl-3 py-1" :userPhoto="account.userPhoto" :userName="account.userName" :iconWidth="20" />
        <b-dropdown-item
          v-for="calendar in calendarByAccount(account.calendars)"
          :key="calendar.id"
          class="calendar-item"
          aria-role="listitem"
          @click="selectCalendar(account.accountId, calendar.id, account.email)"
        >
          <b-icon
            icon="check"
            custom-size="mdi-18px"
            class="active-icon"
            :class="{ isActive: isActive(account.accountId, calendar.id) }"
          ></b-icon>
          <b-icon
            class="mx-1"
            icon="checkbox-blank"
            size="is-small"
            :style="{ color: calendar.backgroundColor }"
          ></b-icon>
          <span class="calendar-name">{{ calendar.title }}</span>
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import UserBadge from '@/components/molecules/UserBadge.vue'
import CalendarsModule from '@/store/modules/calendars'
import { ICalendar } from '@/types'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    UserBadge
  }
})
export default class FormCalendarSelector extends Vue {
  // @ts-expect-error TS2564
  @Prop() accountId: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) noLabel: boolean
  @PropSync('calendarId', { type: String }) calendarIdSynced!: string
  @PropSync('accountId', { type: String }) accountIdSynced!: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) disableCalendarPicker: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) primaryCalendarOnly: boolean
  @Prop({ default: false }) updateHeightWhenDropdownIsActive

  isDropdownActive = false

  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }
  get accountWithcalendars() {
    return CalendarsModule.getAccountWithCalendars
  }
  get currentAccountWithCalendar() {
    return this.accountWithcalendars.find(a => a.accountId === this.accountIdSynced)
  }
  get currentCalendar() {
    return this.currentAccountWithCalendar?.calendars.find(c => c.id === this.calendarIdSynced)
  }
  calendarByAccount(calendars: ICalendar[]) {
    return calendars.filter(c => c.primary || (!this.primaryCalendarOnly && c.writable && c.type !== 'resource'))
  }

  isActive(accountId: string, calendarId: string) {
    return this.accountIdSynced === accountId && this.calendarIdSynced === calendarId
  }
  selectCalendar(accountId: string, calendarId: string, email: string) {
    this.accountIdSynced = accountId
    this.calendarIdSynced = calendarId
    this.$emit('input', email)
  }
  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
  }
}
</script>

<style scoped lang="scss">
.field {
  transition: min-height 0.15s ease-out;
  &.updateHeight {
    min-height: 200px;
    transition: min-height 0.25s ease-in;
  }
}
.spir-input-select {
  .input {
    .calendar-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: 1;
    }
    .calendar-email {
      flex: 1;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 12px;
      line-height: 1.4;
      color: $spir2_gray;
    }
  }
  &__account-wrapper {
    .calendar-item {
      display: flex;
      align-items: center;
      .calendar-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
      }
      .calendar-email {
        flex: 1;
        justify-content: flex-end;
        align-items: flex-end;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
      }
    }
    .active-icon {
      opacity: 0;
      &.isActive {
        opacity: 1;
      }
    }
  }
}
</style>
<style lang="scss">
.spir-input-select {
  a.dropdown-item {
    padding-right: 10px;
  }
}
</style>
