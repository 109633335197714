<template>
  <p class="html-display" v-html="sanitizedHtml" />
</template>

<script lang="ts">
import { text2link } from '@/lib/utils'
import sanitizeHtml from 'sanitize-html'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class HtmlDisplay extends Vue {
  // @ts-expect-error TS2564
  @Prop() description: string

  get descriptionWithLink() {
    return text2link(this.description)
  }
  get sanitizedHtml() {
    return sanitizeHtml(this.descriptionWithLink)
  }
}
</script>

<style scoped lang="scss">
.html-display {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
