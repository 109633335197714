import { ListType } from '@/types'
import mitt from 'mitt'

// for vue2 use event bus
const EventBus = mitt<{
  [EVENTS.EDIT_SCHEDULE]: { scheduleId: string; type: ListType; anotherTabWillOpen: boolean }
  [EVENTS.SHOW_EVENT]: { eventId: string; accountId: string; calendarId: string; moveDate: boolean }
  [EVENTS.INVALIDATE_QUERIES]: { queryKey: string[]; exact?: boolean }
  [event: string]: unknown
}>()
const EVENTS = {
  CHANGE_CALENDAR_VIEW: 'CHANGE_CALENDAR_VIEW',
  SHOW_SCHEDULE: 'SHOW_SCHEDULE',
  CONFIRM_POLL: 'CONFIRM_POLL',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  SHOW_EVENT: 'SHOW_EVENT',
  SHOW_CONFIRMED_SCHEDULE_EVENT: 'SHOW_CONFIRMED_SCHEDULE_EVENT',
  CLICKED_EVENT: 'CLICKED_EVENT',
  CREATE_EVENT: 'CREATE_EVENT',
  UPDATE_EVENT: 'UPDATE_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
  VALIDATE_SCHEDULE: 'VALIDATE_SCHEDULE',
  RESOURCE_VIEW_SCROLL: 'RESOURCE_VIEW_SCROLL',
  RESOURCE_TO_SCROLL: 'RESOURCE_TO_SCROLL',
  CALENDAR_EVENT_MOUSE_ENTER: 'CALENDAR_EVENT_MOUSE_ENTER',
  CALENDAR_EVENT_MOUSE_LEAVE: 'CALENDAR_EVENT_MOUSE_LEAVE',
  SHOW_AUTH_MODAL_FROM_OUTSIDE: 'SHOW_AUTH_MODAL_FROM_OUTSIDE',
  ADD_OR_EDIT_CANDIDATE: 'ADD_OR_EDIT_CANDIDATE',
  REMOVE_CANDIDATE: 'REMOVE_CANDIDATE',
  INVALIDATE_QUERIES: 'INVALIDATE_QUERIES'
} as const

export { EventBus, EVENTS }
