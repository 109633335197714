<template>
  <p class="link-text" :class="{ copy: !!onClick }" @click="handleClick">
    {{ title }}
  </p>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CopyBoxLinkText extends Vue {
  // @ts-expect-error TS2564
  @Prop({ required: true }) title: string
  // @ts-expect-error TS2564
  @Prop() onClick: () => void

  handleClick() {
    if (this.onClick) {
      this.onClick()
    }
  }
}
</script>

<style scoped lang="scss">
.link-text {
  color: $spir2_primaryBlue;
  &.copy:hover {
    cursor: pointer;
  }
}
</style>
