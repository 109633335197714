<template>
  <CommonLayoutModal :showHeader="false" :showFooter="false" @close="$emit('close')">
    <template v-slot:modalBody>
      <div class="spir-form-container">
        <div class="title">
          <div>{{ $t('timezone.timeZoneModal.title') }}</div>
        </div>
        <TimeZoneSelector
          :updateHeightWhenDropdownIsActive="true"
          :timeZone="timeZoneForEdit"
          @onChangeTimeZone="onChangeTimeZone"
        />
      </div>
      <div class="is-flex is-justify-content-flex-end pat-8">
        <Button @click="$emit('close')">{{ $t('buttons.cancel') }}</Button>
        <Button type="primary" @click="selectTimezone">{{ $t('buttons.update') }}</Button>
      </div>
    </template>
  </CommonLayoutModal>
</template>
<script lang="ts">
import TimeZoneSelector from '@/components/organisms/timezoneSelector/TimezoneSelector.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TimeZoneMaster from '../../../store/modules/timezones'
import CommonLayoutModal from '../CommonLayout.vue'
import Button from '@/components/ui/Button.vue'

@Component({
  components: {
    CommonLayoutModal,
    TimeZoneSelector,
    Button
  }
})
export default class TimeZoneKeySelectorModal extends Vue {
  // @ts-expect-error TS2564
  @Prop() timezoneKey: string
  // @ts-expect-error TS2564
  @Prop() isUpdateStore: boolean

  timeZoneForEdit = ''

  created() {
    this.timeZoneForEdit = this.timezoneKey
  }
  onChangeTimeZone(code: string) {
    this.timeZoneForEdit = code
  }
  async selectTimezone() {
    this.$emit('updateTimezoneInfo', this.timeZoneForEdit)
    if (this.isUpdateStore) {
      TimeZoneMaster.UpdateTimezoneForInterrupt(this.timeZoneForEdit)
    }
  }
}
</script>
<style lang="scss" scoped>
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.6;
  color: $spir2_black;
}
</style>
