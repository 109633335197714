import { LocalStorage } from '@/models/localStorage/localStorage'

const LOCAL_STORAGE_KEY_SCHEDULE_DEFAULT_VALUES = 'scheduleDefaultValues'

export default class ScheduleLocalStorage extends LocalStorage {
  constructor() {
    super(LOCAL_STORAGE_KEY_SCHEDULE_DEFAULT_VALUES, [
      'accountId',
      'calendarId',
      'duration',
      'visibility',
      'onlineMeeting'
    ])
  }
}
