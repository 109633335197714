<template>
  <ValidationObserver ref="validate" v-slot="{ invalid }">
    <PullDownForm>
      <template v-slot:formHeader>
        <b>{{ $t('message.confirm.otherCalendar.title') }}</b>
      </template>
      <template v-slot:formBody>
        <div>
          <WithValidationInput
            v-model="email"
            :name="$t('labels.email')"
            type="email"
            autocomplete="email"
            :rules="`required|email|max:100|emailExcluded:${ignoreEmails}`"
            :placeholder="$t('message.confirm.otherCalendar.emailPlaceholder')"
            :helpText="$t('message.confirm.otherCalendar.emailHelper')"
          >
            <template v-slot:label>
              <label class="confirm-modal-item-label">{{ $t('labels.email') }}</label>
            </template>
          </WithValidationInput>
        </div>
      </template>
      <template v-slot:formFooter>
        <div class="is-pulled-right">
          <Button size="small" @click="handleCancel">
            {{ cancelButtonTitle }}
          </Button>
          <Button type="primary" size="small" :disabled="disabled || invalid" @click="handleConfirm">
            {{ confirmButtonTitle }}
          </Button>
        </div>
      </template>
    </PullDownForm>
  </ValidationObserver>
</template>

<script lang="ts">
import WithValidationInput from '@/components/forms/WithValidationInput.vue'
import Button from '@/components/ui/Button.vue'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'
import PullDownForm from './PullDownForm.vue'

@Component({
  components: {
    PullDownForm,
    ValidationObserver,
    WithValidationInput,
    Button
  }
})
export default class OtherCalendarPullDownForm extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: null }) header: string
  // @ts-expect-error TS2564
  @Prop() body: string
  // @ts-expect-error TS2564
  @Prop({ default: '' }) cancelBtn: string
  // @ts-expect-error TS2564
  @Prop({ default: '' }) confirmBtn: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) disabled: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) closeWhenButtonClicked: boolean
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  email = ''

  get cancelButtonTitle() {
    return this.cancelBtn || this.$t('buttons.cancel').toString()
  }
  get confirmButtonTitle() {
    return this.confirmBtn || this.$t('buttons.confirm').toString()
  }
  reset() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validateRef = this.$refs.validate as any
    validateRef.reset()
    this.email = ''
  }
  handleCancel() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('cancel')
    this.reset()
  }
  handleConfirm() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('confirm', this.email)
    this.reset()
  }
}
</script>

<style scoped lang="scss"></style>
