export const SpirEnv = {
  local: 'local',
  development: 'development',
  development2: 'development2',
  staging: 'staging',
  production: 'production'
} as const

export type SpirEnv = keyof typeof SpirEnv

export function isProduction(): boolean {
  return SpirEnv.production === process.env.VUE_APP_MODE
}

export function isStaging(): boolean {
  return SpirEnv.staging === process.env.VUE_APP_MODE
}

export function isProductionLevelEnv(): boolean {
  return isAllowedByEnv([SpirEnv.production, SpirEnv.staging])
}

export function isAllowedByEnv(allowedEnvs: SpirEnv[]): boolean {
  return allowedEnvs.some(env => env === process.env.VUE_APP_MODE)
}

export function isDevelopmentLevelEnv(): boolean {
  return isAllowedByEnv([SpirEnv.local, SpirEnv.development, SpirEnv.development2])
}
