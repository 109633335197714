<template>
  <div class="sign-in-logo-buttons">
    <h5 class="sign-in-logo-buttons__title">{{ $t('forms.auth.signIn.title') }}</h5>
    <FormItemSpacer spaceName="t16" />
    <div class="sign-in-logo-buttons__row">
      <AuthIconButton type="google" @click="handleGoogleSignIn" />
      <AuthIconButton type="microsoft" @click="handleMicrosoftSignIn" />
      <AuthIconButton v-if="isEmailLoginEnabled" type="mail" @click="handleMailClick" />
    </div>
  </div>
</template>

<script lang="ts">
import { AuthIconButton } from '@/components/features/auth/AuthIconButton'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import { useSignInSetup } from '@/composables/auth/useSignInSetup'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SignInLogoButtonsBox',
  components: {
    AuthIconButton,
    FormItemSpacer
  },
  props: {
    onGoogleClick: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    },
    onMicrosoftClick: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    },
    onMailClick: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const { handleGoogleSignIn, handleMicrosoftSignIn, handleMailSignIn, isEmailLoginEnabled } = useSignInSetup({
      signInWithGoogle: props.onGoogleClick,
      signInWithMicrosoft: props.onMicrosoftClick
    })
    function handleMailClick() {
      handleMailSignIn()
      props.onMailClick()
    }
    return {
      handleGoogleSignIn,
      handleMicrosoftSignIn,
      handleMailClick,
      isEmailLoginEnabled
    }
  }
})
</script>

<style scoped lang="scss">
.sign-in-logo-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    color: $spir2_black;

    @include inter_font_setting($font_size: 12px, $line_height: 18px, $letter_spacing: 0.3px);
  }
  &__row {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}
</style>
