<template>
  <div class="daily-view-calendars-wrapper" id="daily-view-calendars" ref="calendarBody">
    <Header :columns="columns" :gridHeight="gridHeight" :events="events" @select="createEvent" :hideAllday="true" />
    <div class="daily-view-calendars">
      <CalendarColumn
        v-for="column in columns"
        class="daily-view-calendars__body"
        :key="column.columnKey"
        :column="column"
        :columnsLength="columns.length"
        :gridHeight="gridHeight"
        :eventDate="columnDate(column)"
        :events="eventsByCalendar(column.columnKey)"
        :fullWidthEvents="fullWidthEventsByCalendar(column.columnKey)"
        :selectable="selectable"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { addDays, startOfDay } from '@/lib/utils/timezone'
import { ResourceColumn, ResourceEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Header from '../../header/Index.vue'
import CalendarColumn from './CalendarColumn.vue'

@Component({
  components: {
    CalendarColumn,
    Header
  }
})
export default class DailyViewColumns extends Vue {
  // @ts-expect-error TS2564
  @Prop() columns: ResourceColumn[]
  // @ts-expect-error TS2564
  @Prop() gridHeight: string
  // @ts-expect-error TS2564
  @Prop() events: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop() fullWidthEvents: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop() eventDate: Date
  // @ts-expect-error TS2564
  @Prop() selectable: boolean

  mounted() {
    const calendarBody = this.$refs.calendarBody as HTMLElement
    if (calendarBody) {
      calendarBody.addEventListener('scroll', this.syncScroll)
    }
    EventBus.on(EVENTS.RESOURCE_TO_SCROLL, this.scrollToPrevHeight)
  }
  beforeDestroy() {
    const calendarBody = this.$refs.calendarBody as HTMLElement
    if (calendarBody) {
      calendarBody.removeEventListener('scroll', this.syncScroll)
    }
    EventBus.off(EVENTS.RESOURCE_TO_SCROLL, this.scrollToPrevHeight)
  }

  get allEvents() {
    return this.events.filter(event => !event.allDay)
  }
  columnDate(column: ResourceColumn) {
    if (!column.date) {
      return this.eventDate
    }
    return startOfDay(column.date)
  }
  syncScroll(e) {
    EventBus.emit(EVENTS.RESOURCE_VIEW_SCROLL, e.srcElement)
  }
  eventsByCalendar(columnKey) {
    return this.allEvents.filter(event => event.columnKey === columnKey)
  }
  fullWidthEventsByCalendar(columnKey) {
    return this.fullWidthEvents.filter(event => event.columnKey === columnKey)
  }
  scrollToPrevHeight(height) {
    const calendarBody = this.$refs.calendarBody as HTMLElement
    if (calendarBody) {
      calendarBody.scrollTo(0, height)
    }
  }

  createEvent({ columnKey, allDay, start, end, jsEvent }) {
    const payload = {
      columnKey,
      start,
      end,
      allDay,
      jsEvent
    }
    if (allDay) {
      payload.allDay = true
      payload.start = startOfDay(this.eventDate)
      payload.end = startOfDay(addDays(this.eventDate, 1))
    }
    this.$emit('createEvent', payload)
  }
}
</script>

<style scoped lang="scss">
.daily-view-calendars-wrapper {
  overflow: auto;
  width: 100%;
}
.daily-view-calendars {
  display: flex;
  flex-direction: row;
  .daily-view-calendars__body {
    flex: 1 1 0;
  }
}
</style>
