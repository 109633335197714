import store from '@/store'
import {
  AvailabilitySharingsAvailabilitySharingIdConfirmPostRequest,
  AvailabilitySharingsAvailabilitySharingIdConfirmPostResponse,
  AvailabilitySharingsAvailabilitySharingIdGetResponse,
  AvailabilitySharingsAvailabilitySharingIdPatchRequest,
  AvailabilitySharingsPeekCandidatesPostRequest,
  AvailabilitySharingsPeekCandidatesPostResponse,
  AvailabilitySharingsPostRequest,
  AvailabilitySharingsPostResponse,
  PublicAvailabilitySharingsAvailabilitySharingIdConfirmPostResponse,
  PublicAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse,
  PublicAvailabilitySharingsCandidatesGetResponse,
  PublicAvailabilitySharingsOverviewGetResponse
} from '@spirinc/contracts'
import axiosInstance from '../axios'

export const create = async (
  availability: AvailabilitySharingsPostRequest
): Promise<AvailabilitySharingsPostResponse> => {
  return (await axiosInstance.post('availability-sharings', availability)).data
}
export const getAvailability = async (id): Promise<AvailabilitySharingsAvailabilitySharingIdGetResponse> => {
  return (await axiosInstance.get(`availability-sharings/${id}`)).data
}
export const getCandidatesById = async (id): Promise<PublicAvailabilitySharingsCandidatesGetResponse> => {
  return (await axiosInstance.get(`public/availability-sharings/${id}/candidates`)).data
}
export const getList = async () => {
  return (await axiosInstance.get('availability-sharings')).data
}

export const deletePattern = id => {
  return axiosInstance.delete(`availability-sharings/${id}`)
}

export const fetchCandidatesByParam = async (
  availability: AvailabilitySharingsPeekCandidatesPostRequest
): Promise<AvailabilitySharingsPeekCandidatesPostResponse> => {
  return (await axiosInstance.post('availability-sharings/peek-candidates', availability)).data
}

export const updatePattern = async (
  id: string,
  payload: AvailabilitySharingsAvailabilitySharingIdPatchRequest
): Promise<AvailabilitySharingsAvailabilitySharingIdGetResponse> => {
  return (await axiosInstance.patch(`availability-sharings/${id}`, payload)).data
}

export const confirm = async (
  id: string,
  payload: AvailabilitySharingsAvailabilitySharingIdConfirmPostRequest
): Promise<
  | AvailabilitySharingsAvailabilitySharingIdConfirmPostResponse
  | PublicAvailabilitySharingsAvailabilitySharingIdConfirmPostResponse
> => {
  if (store.getters['User/isSignIn']) {
    return (await axiosInstance.post(`availability-sharings/${id}/confirm`, payload)).data
  }
  return (await axiosInstance.post(`public/availability-sharings/${id}/confirm`, payload)).data
}

export const getOverview = async (id: string): Promise<PublicAvailabilitySharingsOverviewGetResponse> => {
  return (await axiosInstance.get(`public/availability-sharings/${id}/overview`)).data
}
export const getConfirmedInfo = async (
  availabilityId: string,
  confirmedEventId: string
): Promise<PublicAvailabilitySharingsAvailabilitySharingIdConfirmedAvailabilitySharingConfirmIdGetResponse> => {
  return (await axiosInstance.get(`public/availability-sharings/${availabilityId}/confirmed/${confirmedEventId}`)).data
}
export const cancelConfirmedPublicURL = async (
  availabilityId: string,
  confirmedEventId: string,
  message: string
): Promise<AvailabilitySharingsAvailabilitySharingIdConfirmPostResponse> => {
  return (
    await axiosInstance.put(`public/availability-sharings/${availabilityId}/confirmed/${confirmedEventId}`, {
      action: 'cancel',
      message
    })
  ).data
}
