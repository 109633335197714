<template>
  <CommonLayoutModal class="online-meeting-tool-info" @close="$emit('close')" :showFooter="false">
    <template v-slot:modalHeader>
      {{ $t('onlineMeetingToolModal.title') }}
    </template>
    <template v-slot:modalBody>
      <label class="online-meeting-tool-info__account-name-label">
        {{ $t('onlineMeetingToolModal.accountLabel') }}
      </label>
      <div class="online-meeting-tool-info__account">
        <b-icon icon="check" custom-size="is-small" />
        <span class="online-meeting-tool-info__account__name">
          {{ accountName }}
        </span>
      </div>
      <b-button class="online-meeting-tool-info__disconnect-button" @click="$emit('onClickDisconnectionButton')">
        {{ $t('buttons.disconnect') }}
      </b-button>
      <p class="online-meeting-tool-info__annotation">
        {{ $t('onlineMeetingToolModal.annotation') }}
      </p>
    </template>
  </CommonLayoutModal>
</template>

<script lang="ts">
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    CommonLayoutModal
  }
})
export default class OnlineMeetingToolInfoModal extends Vue {
  // @ts-expect-error TS2564
  @Prop() accountName: string
}
</script>

<style scoped lang="scss">
.online-meeting-tool-info {
  &__account-name-label {
    font-size: 14px;
    font-weight: bold;
    color: $spir2_black;
  }
  &__account {
    margin-top: 8px;
    &__name {
      font-size: 14px;
      color: $spir2_black;
    }
  }
  &__disconnect-button {
    margin-top: 8px;
  }
  &__annotation {
    color: $spir2_gray;
    font-size: 12px;
    margin-top: 12px;
  }
  ::v-deep {
    .header-title {
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
