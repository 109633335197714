<template>
  <AuthLoginFormLayout :title="title">
    <AddCalendarDescription />
    <div class="pat-16">
      <AddCalendarButtons :addMicrosoftCalendar="addMicrosoftCalendar" :addGoogleCalendar="addGoogleCalendar" />
    </div>
  </AuthLoginFormLayout>
</template>

<script lang="ts">
import { AuthLoginFormLayout } from '@/components/features/auth/AuthLoginFormLayout'
import { defineComponent } from '@vue/composition-api'
import AddCalendarButtons from './AddCalendarButtons.vue'
import AddCalendarDescription from './AddCalendarDescription.vue'

export default defineComponent({
  name: 'AddCalendarFormBox',
  components: {
    AuthLoginFormLayout,
    AddCalendarDescription,
    AddCalendarButtons
  },
  props: {
    addMicrosoftCalendar: {
      type: Function,
      required: true
    },
    addGoogleCalendar: {
      type: Function,
      required: true
    },
    title: {
      type: String
    }
  }
})
</script>
