<template>
  <div class="online-meeting-selector">
    <label :class="labelClass">{{ $t('scheduleEventList.onlineMeeting') }}</label>
    <b-dropdown class="online-meeting-selector__selected-value" :disabled="readonly" expanded :mobile-modal="false">
      <div class="input" slot="trigger">
        <span class="service-name">{{ serviceName(selectedServiceSynced) }}</span>
      </div>
      <b-dropdown-item
        class="online-meeting-selector__popup-item"
        v-for="item in onlineMeetingTools"
        :key="item.serviceType"
        @click="onClickItem(item)"
      >
        <b-icon
          icon="check"
          custom-size="is-small"
          v-bind:style="{ visibility: isSelected(item) ? 'visible' : 'hidden' }"
        />
        <span class="online-meeting-selector__popup-item__service-name"> {{ serviceName(item.serviceType) }} </span>
      </b-dropdown-item>
      <b-dropdown-item
        class="online-meeting-selector__popup-item zoom-item"
        @click="onClickItem(zoomInfo)"
        :class="{ disabled: !zoomInfo.isConnected }"
      >
        <b-icon
          icon="check"
          custom-size="is-small"
          v-bind:style="{ visibility: isSelected(zoomInfo) ? 'visible' : 'hidden' }"
        />
        <span class="online-meeting-selector__popup-item__service-name"> {{ serviceName(zoomInfo.serviceType) }} </span>
        <ZoomSignInButton v-if="!zoomInfo.isConnected" />
        <div
          v-else
          class="online-meeting-selector__popup-item__setting-icon"
          @click.stop="onClickSettingIcon(zoomInfo)"
        >
          <b-icon icon="cog" />
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import ZoomSignInButton from '@/components/atoms/ZoomSigninButton.vue'
import OnlineMeetingToolInfoModal from '@/components/modal/OnlineMeetingToolInfo.vue'
import UserModule from '@/store/modules/user'
import Vue from 'vue'
import { Component, Prop, PropSync } from 'vue-property-decorator'
import { OnlineMeetingType } from '../../types'

interface IOnlineMeetingItemParams {
  serviceType: OnlineMeetingType
  isConnected?: boolean
  accountName?: string
}

@Component({
  components: {
    ZoomSignInButton
  }
})
export default class OnlineMeetingSelector extends Vue {
  @PropSync('selectedService', { default: OnlineMeetingType.none }) selectedServiceSynced!: OnlineMeetingType
  @Prop() readonly
  // @ts-expect-error TS2564
  @Prop() availableOnlineMeetings: OnlineMeetingType[]
  // @ts-expect-error TS2564
  @Prop({ default: 'spir-label' }) labelClass: string
  private get onlineMeetingTools(): IOnlineMeetingItemParams[] {
    const tools: IOnlineMeetingItemParams[] = [
      {
        serviceType: OnlineMeetingType.none
      }
    ]
    this.availableOnlineMeetings.map(onlineMeeting => {
      tools.push({
        serviceType: onlineMeeting
      })
    })
    return tools
  }
  get zoomInfo() {
    return {
      serviceType: OnlineMeetingType.zoom,
      isConnected: UserModule.isConnectedZoom,
      accountName: UserModule.integrations.zoomInfo?.email
    }
  }

  private serviceName(serviceType: OnlineMeetingType) {
    return this.$te(`onlineMeetings.${serviceType}`)
      ? this.$t(`onlineMeetings.${serviceType}`)
      : this.$t('onlineMeetings.none')
  }

  private buttonState(isConnected: boolean) {
    if (isConnected) {
      return {
        type: '',
        title: this.$t('buttons.disconnect')
      }
    }

    return {
      type: 'is-primary',
      title: this.$t('buttons.connect')
    }
  }

  private isSelected(item: IOnlineMeetingItemParams) {
    return item.serviceType === this.selectedServiceSynced
  }

  private canSelect(item: IOnlineMeetingItemParams) {
    // NOTE: 連携情報の無いサービスは常に選択可能
    return item.isConnected == null ? true : item.isConnected
  }

  private onClickItem(item: IOnlineMeetingItemParams) {
    if (!this.canSelect(item)) {
      return
    }
    this.selectedServiceSynced = item.serviceType
    this.$emit('input')
  }

  private onClickSettingIcon(item: IOnlineMeetingItemParams) {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: OnlineMeetingToolInfoModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        accountName: item.accountName
      },
      events: {
        onClickDisconnectionButton: async () => {
          await UserModule.disconnectZoom()
          if (this.selectedServiceSynced === OnlineMeetingType.zoom) {
            this.selectedServiceSynced = OnlineMeetingType.none
          }
          modal.close()
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.online-meeting-selector {
  position: relative;
  &__selected-value {
    cursor: pointer;
    .input:hover {
      border-color: #b5b5b5;
    }
    &--is-readonly {
      cursor: default;
    }
    &.is-disabled {
      .input {
        border-bottom: 0px !important;
      }
    }
    .service-name {
      color: $spir2_black;
    }
  }

  &__popup-list {
    width: 240px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    padding: 4px 0;
    position: absolute;
    z-index: 5;
  }
  &__popup-item {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    &__service-name {
      margin-left: 10px;
      border-radius: 4px;
      font-size: 14px;
    }
    .button,
    &__setting-icon {
      margin-left: auto;
    }
    &__setting-icon:hover {
      opacity: 0.5;
    }
    &--can-select {
      cursor: pointer;
    }
    &.zoom-item {
      &.disabled {
        cursor: not-allowed;
        .online-meeting-selector__popup-item__service-name {
          opacity: 0.5;
        }
      }
    }
  }
  &__popup-item--can-select:hover {
    background-color: #ddd;
  }
}
</style>
