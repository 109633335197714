import { getAllDayStartDate, getAlldayEndDate, getDefaultEvent } from '@/lib/utils'
import store from '@/store'
import CalendarModule from '@/store/modules/calendars'
import { GoogleEvent, IOnlineMeeting, LOCAL_STORAGE_KEY_EVENT_DEFAULT_VALUES } from '@/types'
import { cloneDeep } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import CalendarsModule from './calendars'
import EventModule from './event'
import EventsModule from './events'
import TempEventModule from './tempEvent'

if (store.state['EditEvent']) {
  store.unregisterModule('EditEvent')
}
@Module({
  dynamic: true,
  name: 'EditEvent',
  namespaced: true,
  store
})
class EditEvent extends VuexModule {
  // @ts-expect-error TS2322
  _editingEvent: GoogleEvent = null

  get isExist() {
    return !!this.editingEvent
  }
  get editingEvent(): GoogleEvent {
    return this._editingEvent
  }
  @Mutation
  SET_EDITING_EVENT(event: GoogleEvent) {
    this._editingEvent = event
  }

  @Action
  update(payload: GoogleEvent) {
    const newEvent = {
      ...this.editingEvent,
      ...payload
    }
    this.SET_EDITING_EVENT(newEvent)
  }
  @Action
  startCreatingNewEvent() {
    const newEvent = getDefaultEvent({
      writableCalendars: CalendarsModule.writableCalendars,
      // @ts-expect-error TS2322
      tempEvent: TempEventModule.getTempEvent,
      // LocalStorageに入ってる前回選択した値
      getSelectedEventValues: (): { calendarId: string; accountId: string; onlineMeeting?: IOnlineMeeting } =>
        // @ts-expect-error TS2345
        JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY_EVENT_DEFAULT_VALUES)),
      // @ts-expect-error TS2322
      getSelectedCalendar: (data: { accountId: string; calendarId: string }) => CalendarModule.getCalendar(data)
    })

    TempEventModule.RESET()
    this.SET_EDITING_EVENT(newEvent)
    CalendarsModule.visibleCalendarIfNotVisible({
      accountId: newEvent.accountId,
      calendarId: newEvent.calendarId
    })
  }

  @Action
  async setEventAsEditingEvent({ eventId, accountId, calendarId }) {
    let event = EventsModule.getEventById(eventId, accountId, calendarId)
    if (!event) {
      const result = await EventModule.getEvent({ eventId, accountId, calendarId })
      event = result.event
    }
    const newEvent = cloneDeep(event)
    this.SET_EDITING_EVENT(newEvent)
  }

  @Action
  async saveEditingEventObject(saveHandler: (event: GoogleEvent) => Promise<void>) {
    const editingEvent = cloneDeep(this._editingEvent)
    // @ts-expect-error TS2345
    editingEvent.start = getAllDayStartDate(new Date(this.editingEvent.start), this.editingEvent.allDay).toISOString()
    // @ts-expect-error TS2345
    editingEvent.end = getAlldayEndDate(new Date(this.editingEvent.end), this.editingEvent.allDay).toISOString()
    await saveHandler(editingEvent)
    await CalendarsModule.visibleCalendarIfNotVisible({
      accountId: editingEvent.accountId,
      calendarId: editingEvent.calendarId
    })
    this.initEditingEvent()
  }
  @Action
  initEditingEvent() {
    // @ts-expect-error TS2345
    this.SET_EDITING_EVENT(null)
  }
}

export default getModule(EditEvent)
