import { FromSubscriptionStateDto } from '@/lib/utils/converters/fromSubscriptionStateDto'
import { parseISO } from '@/lib/utils/date'
import { Plan, TeamId, TeamInvitation, TeamMember, TeamOverview } from '@/models/team'
import {
  MemberCalendar,
  MemberCalendarResponse,
  PublicTeamsTeamIdGetResponse,
  TeamCalendar as TeamCalendarResponse,
  TeamsTeamIdGetResponse,
  TeamsTeamIdMembersSelfGetResponse
} from '@spirinc/contracts'

export class TeamDetailModel {
  id: TeamId
  companyName?: string
  contactPersonName?: string
  phoneNumber?: string
  name: string
  photoUrl?: string
  members: TeamMember[]
  invitations: TeamInvitation[]
  readonly plan: Plan
  readonly trialEndDate: Date | undefined
  invitedMemberCount: number

  constructor(data: TeamsTeamIdGetResponse) {
    this.id = data.id
    this.companyName = data.companyName
    this.phoneNumber = data.phoneNumber
    this.name = data.name
    this.photoUrl = data.photoUrl
    this.members = data.members ?? []
    this.invitations = data.invitations ?? []

    if (data.plan.type === 'free') {
      this.plan = { type: data.plan.type, start: data.plan.start, planId: data.plan.planId, active: data.plan.active }
    } else {
      this.plan = {
        type: data.plan.type,
        state: FromSubscriptionStateDto.convertToTeamSubscriptionState(data.plan.state),
        active: data.plan.active
      }
    }
    this.trialEndDate = data.trialEndDate ? parseISO(data.trialEndDate) : undefined
    this.invitedMemberCount = data.invitedMemberCount
    this.contactPersonName = data.contactPersonName
  }

  get numOfInvitedMembers(): number {
    return (
      (this.members ?? []).filter(member => member.status !== 'deleted').length +
      (this.invitations ?? []).filter(invitation => invitation.status === 'invited')?.length
    )
  }
  get overview(): TeamOverview {
    return {
      id: this.id,
      name: this.name,
      photoUrl: this.photoUrl
    }
  }
}

export class PublicTeamDetailModel {
  id: string
  name: string
  photoUrl?: string

  constructor(data: PublicTeamsTeamIdGetResponse) {
    this.id = data.id
    this.name = data.name
    this.photoUrl = data.photoUrl
  }
}

export class TeamMemberSelfModel {
  id: string
  userId: string
  fullName?: string
  photoUrl?: string
  authority: 'administrator' | 'member'
  status: 'active' | 'invalid' | 'deleted' | 'invitationExpired' | 'invited'
  calendars: Array<MemberCalendarResponse>

  constructor(data: TeamsTeamIdMembersSelfGetResponse) {
    this.id = data.id
    // @ts-expect-error TS2322
    this.userId = data.userId
    this.fullName = data.fullName || ''
    this.photoUrl = data.photoUrl || ''
    this.authority = data.authority
    this.status = data.status
    this.calendars = data.calendars
  }
}

export type MemberCalendarCheckboxItem = MemberCalendar & { isLoading?: boolean }

export class TeamMemberCalendar {
  id: string
  name: string
  photoUrl?: string
  self: TeamsTeamIdMembersSelfGetResponse
  memberCalendars: Array<MemberCalendarCheckboxItem>
  constructor(data: TeamCalendarResponse) {
    this.id = data.id
    this.name = data.name
    this.photoUrl = data.photoUrl
    this.self = data.self
    this.memberCalendars = data.memberCalendars
  }
}

export type FeatureUsableMarkedTeamCalendar = {
  calendar: TeamMemberCalendar
  usable: boolean
}
