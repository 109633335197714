import validator from 'validator'

export function requiredAtLeastOneCheck(value) {
  return Object.values(value).some(v => v)
}

const isHttpsUrl = url => {
  return validator.isURL(url, { protocols: ['https'], require_protocol: true })
}
export function httpsOnly(value) {
  return isHttpsUrl(value)
}
