<template>
  <popper
    trigger="hover"
    v-bind:disabled="hideTooltip"
    :options="{
      placement: 'top',
      modifiers: [{ preventOverflow: { padding: 12 } }]
    }"
  >
    <div class="popper">
      {{ fullName }}
    </div>

    <div class="avatar-wrapper" slot="reference">
      <b-skeleton v-if="isLoading" circle width="24px" height="24px"></b-skeleton>
      <avatar v-else :src="photoURL" :username="fullName" color="#fff" :size="iconSize"></avatar>
      <b-icon
        v-if="!hideAttendanceIcon"
        size="is-small"
        class="attendance-icon"
        :class="[getStyleClass]"
        :icon="attendanceIcon"
      />
    </div>
  </popper>
</template>

<script lang="ts">
import { SpirAttendeeStatus } from '@/types'
import Avatar from 'vue-avatar'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import { Component, Prop, Vue } from 'vue-property-decorator'

// FIXME: This component is depend on `b-xxx`. We should remove dependency of buefy in `features/` dir.
// src/components/ui/spir/SpirAvatarWithTooltip/SpirAvatarWithTooltip.vue に置き換えるべき
@Component({
  components: {
    Avatar,
    Popper
  }
})
export default class AttendeeIcon extends Vue {
  @Prop() photoURL
  @Prop() fullName
  @Prop({ default: SpirAttendeeStatus.ACCEPTED }) attendanceStatus
  @Prop({ default: false }) hideAttendanceIcon
  @Prop({ default: 'is-top' }) toolTipPosition
  // @ts-expect-error TS2564
  @Prop({ default: false }) isLoading: boolean
  // @ts-expect-error TS2564
  @Prop({ default: 24 }) iconSize: number

  get hideTooltip() {
    return !this.fullName || this.fullName === ''
  }

  get getStyleClass() {
    return this.attendanceStatus
  }

  get attendanceIcon() {
    switch (this.attendanceStatus) {
      case SpirAttendeeStatus.DECLINED:
        return 'block-helper'
      case SpirAttendeeStatus.ACCEPTED:
        return 'check'
      case SpirAttendeeStatus.TENTATIVE:
        return 'help'
    }
    return 'help'
  }
}
</script>

<style lang="scss">
.avatar-wrapper {
  .attendance-icon {
    i.mdi:before {
      color: #5f6368;
      font-size: 10px;
    }
    &.accepted,
    &.declined {
      i.mdi:before {
        color: white;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.avatar-wrapper {
  display: flex;
  align-items: flex-end;
  .attendance-icon {
    border-radius: 50%;
    margin-left: -12px;
    margin-bottom: -1px;
    height: 1.2rem;
    width: 1.2rem;
    font-size: 12px;
    background-color: #ebebeb;
    font-weight: 800;
    border: 1px solid white;
    &.accepted {
      background-color: $spir2_primaryBlue;
    }
    &.declined {
      background-color: $spir2_cautionRed;
    }
  }
}
</style>
