<template>
  <SpirModalFrame>
    <div class="add-calendar-modal">
      <div class="add-calendar-modal__header">
        <ClosingPageheadTitleBox
          :canCloseEvenUsingMobile="isShowCloseButton"
          :title="$t('modals.addCalendar.title')"
          :onClose="closeModal"
        />
        <Divider />
      </div>
      <div class="add-calendar-modal__body">
        <ListItemSpacer :bottom="true">
          <LoadingSpinner :fullPage="true" :active="isLoading" loaderId="signUpLoading" />
          <AddCalendarBox
            :addGoogleCalendar="handleAddGoogleCalendar"
            :addMicrosoftCalendar="handleAddMicrosoftCalendar"
          />
        </ListItemSpacer>
      </div>
    </div>
  </SpirModalFrame>
</template>

<script lang="ts">
import AddCalendarBox from '@/components/auth/addCalendar/AddCalendarFormBox.vue'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import Divider from '@/components/ui/layouts/Divider/index.vue'
import ListItemSpacer from '@/components/ui/layouts/ListItemSpacer/index.vue'
import { LoadingSpinner } from '@/components/ui/layouts/LoadingSpinner'
import { SpirModalFrame } from '@/components/ui/spir/SpirModalFrame'
import { useGetAuthcode } from '@/composables/auth/useGetAuthcode'
import { defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthAddCalendarModal',
  components: {
    Divider,
    ClosingPageheadTitleBox,
    SpirModalFrame,
    ListItemSpacer,
    AddCalendarBox,
    LoadingSpinner
  },
  props: {
    closeModal: {
      type: Function as PropType<() => void>
    },
    isShowCloseButton: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { getAuthCode } = useGetAuthcode()
    const isLoading = ref(false)
    const handleAddGoogleCalendar = () => {
      return getAuthCode({ type: 'google' })
    }
    const handleAddMicrosoftCalendar = () => {
      return getAuthCode({ type: 'microsoft' })
    }
    return {
      isLoading,
      handleAddMicrosoftCalendar,
      handleAddGoogleCalendar
    }
  }
})
</script>

<style lang="scss" scoped>
.add-calendar-modal {
  display: flex;
  flex-direction: column;
  overflow: auto;
  &__body {
    overflow-y: scroll;
  }
}
</style>
