<template>
  <NameWithIcon
    v-if="memberColor !== undefined && memberName !== undefined"
    :memberColor="memberColor"
    :memberName="memberName"
  />
</template>
<script lang="ts">
import { defineComponent, computed } from '@vue/composition-api'
import NameWithIcon from './_NameWithIcon.vue'
import TeamCalendarModule from '@/store/modules/teamCalendar'

export default defineComponent({
  name: 'TeamEventModalOwner',
  props: {
    teamId: {
      type: String,
      required: true
    },
    memberId: {
      type: String,
      required: true
    }
  },
  components: {
    NameWithIcon
  },
  setup(props) {
    const memberCalendar = computed(
      ():
        | {
            fullName?: string
            backgroundColor: string
          }
        | undefined => TeamCalendarModule.memberCalendarByTeamAndMemberId(props.teamId, props.memberId)
    )
    return {
      memberColor: memberCalendar.value?.backgroundColor,
      memberName: memberCalendar.value?.fullName
    }
  }
})
</script>
