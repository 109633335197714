import { AvailabilitySharingSpirUserConfirmer } from '@/models/availabilitySharing'
import { SpirDecisionMaker } from '@/models/decisionMaker'
import { ScheduleSpirUserConfirmer } from '@/models/schedule'
import { UserInfoModel } from '../userInfo'
import { ConfirmerType } from './confirmer'
import { GuestConfirmer } from './guestUser'

export class SpirUserConfirmer implements ConfirmerType {
  readonly type: 'user' = 'user' as const
  readonly email: string
  readonly timeZone: string
  private readonly user: UserInfoModel

  constructor({ email, user }: { email: string; user: UserInfoModel }) {
    this.email = email
    this.user = user
    this.timeZone = user.timeZones.primary.key
  }

  get userId() {
    return this.user.id
  }

  get language() {
    return this.user.language
  }

  get isSpirUser(): boolean {
    return true
  }

  get fullName(): string {
    // @ts-expect-error TS2322
    return this.user.fullName
  }

  getEmail() {
    return this.email
  }

  getId() {
    return this.userId
  }

  toScheduleConfirmer(): ScheduleSpirUserConfirmer {
    return this.toDecisionMaker()
  }

  toAvailabilitySharingConfirmer(): AvailabilitySharingSpirUserConfirmer {
    return this.toDecisionMaker()
  }

  toDecisionMaker(): SpirDecisionMaker {
    return {
      type: 'spir',
      email: this.getEmail(),
      user: {
        // @ts-expect-error TS2322
        id: this.user.id,
        // @ts-expect-error TS2322
        fullName: this.user.fullName,
        timezone: { key: this.timeZone },
        language: this.user.language
      }
    }
  }
}

export type Confirmer = SpirUserConfirmer | GuestConfirmer
