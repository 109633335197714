import { TeamDetailModel, TeamMemberSelfModel } from '@/models/data/team'
import { hubspotService } from './hubspot'
import { userFlowService } from './userflow'

class SendUserInfoToExternalServices {
  signedInUser() {
    hubspotService.setUser()
  }
  unSignedInUser() {
    hubspotService.unsetUser()
  }
  signedOut() {
    hubspotService.unsetUser()
  }
  onSelectedTeam(team: TeamDetailModel, myInfo: TeamMemberSelfModel) {
    userFlowService.sendSelectedJoiningTeamInfo(team, myInfo)
  }
}
export const sendUserInfoToExternalServices = new SendUserInfoToExternalServices()
