import type { Plan } from '@/models'
import { ERROR_CODE } from '@spirinc/message-catalog'
import { APIError } from '../sdk/error'

export function isTeamSubscriptionCanceledError(e: unknown): boolean {
  return e instanceof APIError && e.code === ERROR_CODE.SUBSCRIPTION_CANCELED
}

export function isActive(plan: Plan): boolean {
  return !(plan.type === 'paid' && plan.state.status === 'canceled')
}
