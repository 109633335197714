<template>
  <div>
    <div class="attendee-field-wrapper">
      <div v-for="(item, index) in getItems" :key="index" class="attendee-field">
        <div class="attendee-field-head">
          <b-icon icon="account-circle" class="mr-2 account-icon"></b-icon>
          <div>
            <p class="name">{{ item.name }}</p>
            <p class="email">{{ item.email }}</p>
          </div>
        </div>
        <b-button class="icon-button" custom-size="mdi-18px" icon-left="close" @click="handleDelete(index)"></b-button>
      </div>
    </div>
    <AttendeeInvitationButton
      :ignoreEmails="ignoreEmails"
      :updateHeightWhenDropdownIsActive="true"
      @active-change="activeChanged"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import AttendeeInvitationButton from './AttendeeInvitationButton.vue'

type Item = {
  email: string
  name: string
}

@Component({
  components: {
    AttendeeInvitationButton
  }
})
export default class AttendeerItem extends Vue {
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false
  items: Item[] = []

  get getIsDropdownActive() {
    return this.isDropdownActive
  }
  get getItems() {
    return this.items
  }

  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm(item: Item) {
    this.items = [...this.items, item]
    this.activeChanged(false)
    this.$emit('confirm', item)
  }
  handleDelete(index: number) {
    this.items = [...this.items.slice(0, index), ...this.items.slice(index + 1, this.items.length)]
    this.activeChanged(false)
    this.$emit('delete', index)
  }
}
</script>

<style scoped lang="scss">
.attendee-field-wrapper {
  width: 100%;
  padding-left: 30px;

  .attendee-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    color: $spir2_black;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 8%);
    margin-bottom: 10px;

    .attendee-field-head {
      display: flex;
      align-items: center;
      padding: 9px 0px;

      .name {
        font-size: 14px;
        line-height: 1.4;
      }

      .email {
        font-size: 12px;
        line-height: 1;
        color: $spir2_gray;
      }
    }
  }
}
</style>
