<template>
  <div>
    <label class="spir-label">{{ $t('scheduleEventList.attendManagement.label') }}</label>
    <div class="field has-addons attend-status">
      <b-radio-button
        v-model="attendeeStatus"
        native-value="accepted"
        type="is-success"
        class="radio"
        size="is-small"
        @input="updateAttendee"
      >
        <span>{{ $t('scheduleEventList.attendManagement.accepted') }}</span>
      </b-radio-button>
      <b-radio-button
        v-model="attendeeStatus"
        native-value="declined"
        type="is-success"
        class="radio"
        size="is-small"
        @input="updateAttendee"
      >
        <span>{{ $t('scheduleEventList.attendManagement.declined') }}</span>
      </b-radio-button>
      <b-radio-button
        v-model="attendeeStatus"
        native-value="tentative"
        type="is-success"
        class="radio"
        size="is-small"
        @input="updateAttendee"
      >
        {{ $t('scheduleEventList.attendManagement.tentative') }}
      </b-radio-button>
    </div>
  </div>
</template>

<script lang="ts">
import { IAttendee, SpirAttendeeStatus } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AttendeeStatus extends Vue {
  // @ts-expect-error 2564
  @Prop({ required: true }) eventContext: {
    accountId: string
    calendarId: string
    eventId: string
    attendee: Omit<IAttendee, 'responseStatus'> & { responseStatus: SpirAttendeeStatus }
  }
  // @ts-expect-error 2564
  @Prop({ required: true }) updateAttendeeStatus: (args: {
    accountId: string
    calendarId: string
    eventId: string
    value: Exclude<SpirAttendeeStatus, 'needsAction'>
  }) => Promise<void>

  get attendee() {
    return this.eventContext.attendee
  }

  updateAttendee(status: Exclude<SpirAttendeeStatus, 'needsAction'>) {
    return this.updateAttendeeStatus({
      accountId: this.eventContext.accountId,
      calendarId: this.eventContext.calendarId,
      eventId: this.eventContext.eventId,
      value: status
    })
  }

  get attendeeStatus() {
    return this.attendee.responseStatus
  }
  set attendeeStatus(value) {
    if (this.attendee) {
      if (value === SpirAttendeeStatus.ACCEPTED.valueOf()) {
        this.attendee.responseStatus = SpirAttendeeStatus.ACCEPTED
      } else if (value === SpirAttendeeStatus.DECLINED.valueOf()) {
        this.attendee.responseStatus = SpirAttendeeStatus.DECLINED
      } else if (value === SpirAttendeeStatus.TENTATIVE.valueOf()) {
        this.attendee.responseStatus = SpirAttendeeStatus.TENTATIVE
      } else {
        this.attendee.responseStatus = SpirAttendeeStatus.NEEDS_ACTION
      }
    }
  }
}
</script>

<style scoped lang="scss">
.attend-status {
  padding: 0.4rem 0;

  .radio {
    margin-right: 0.25rem;
  }
}

::v-deep {
  .b-radio {
    color: $spir2_black;
    border-radius: 5px !important;
  }

  .b-radio[disabled] {
    color: $spir2_black;
    border-color: $spir2_border;
    opacity: 1;
  }

  .b-radio[disabled].is-success {
    color: $spir2_white;
  }
}
</style>
