<template>
  <div class="modal-card spir-modal">
    <section class="spir-modal-frame modal-card-body">
      <slot />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirModalFrame'
})
</script>

<style scoped lang="scss">
.spir-modal-frame.modal-card-body {
  border-radius: 8px;
  border: 1px solid $spir2_border;
}
</style>
