<template>
  <h4 class="pagehead-title" :class="size">
    {{ title }}
  </h4>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    size: {
      type: String as PropType<'s' | 'm'>,
      default: 'm'
    },
    title: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.pagehead-title {
  @include inter_font_setting($font_weight: 600, $font_size: 20px, $line_height: 150%, $letter_spacing: -0.8px);
  color: $spir2_black;
  @include long_text_truncation;
  &.s {
    @include inter_font_setting($font_weight: 600, $font_size: 16px, $line_height: 24px, $letter_spacing: -0.6px);
  }
}
</style>
