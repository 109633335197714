/*
  link: notion https://www.notion.so/spir/Replace-ImageUploader-8626106570b1420581f3529ff728f442#47f123ab328845c29bbd5d414a0d5c8c
  xs => is-small
  s => mdi-18px
  m => mdi-24px
  l => mdi-36px
  xl => mdi-48px
*/
export type MaterialDesignIconSize = 'xs' | 's' | 'm' | 'l' | 'xl'

export type ConvertedSizeForBuefy = {
  size?: 'is-small'
  customSize?: 'mdi-18px' | 'mdi-24px' | 'mdi-36px' | 'mdi-48px'
}

export function convertToBuefyIconSizeOrCustomSize(size: MaterialDesignIconSize): ConvertedSizeForBuefy {
  switch (size) {
    case 'xs':
      return { size: 'is-small' }
    case 's':
      return { customSize: 'mdi-18px' }
    case 'm':
      return { customSize: 'mdi-24px' }
    case 'l':
      return { customSize: 'mdi-36px' }
    case 'xl':
      return { customSize: 'mdi-48px' }
    default:
      return { customSize: 'mdi-24px' }
  }
}
