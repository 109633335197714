<template>
  <div class="h-spacer" :class="[{ 'top-spacer': isTopSpacerRequired }, topPx, { 'bottom-spacer': bottom }]">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ListItemSpacer',
  props: {
    top: {
      type: [Boolean, String] as PropType<boolean | '16' | '8' | '4'>,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    let topPx = 'pt16'
    let isTopSpacerRequired = false
    if (typeof props.top === 'string') {
      if (props.top === '16' || props.top === '8' || props.top === '4') {
        isTopSpacerRequired = true
        topPx = `pt${props.top}`
      }
    } else {
      isTopSpacerRequired = props.top
    }

    return {
      topPx,
      isTopSpacerRequired
    }
  }
})
</script>

<style lang="scss">
.h-spacer {
  padding-left: 16px;
  padding-right: 16px;
}
.top-spacer {
  &.pt16 {
    padding-top: 16px;
  }
  &.pt8 {
    padding-top: 4px;
  }
  &.pt4 {
    padding-top: 4px;
  }
}
.bottom-spacer {
  padding-bottom: 16px;
}
</style>
