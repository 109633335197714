export const KB = 1000

type CheckFileSizeArgs = {
  file: File
  sizeLimitKB: number
  onFileSizeExceed: () => void
  onFileChange: (file: File) => void
}
export function checkFileSize({ file, sizeLimitKB, onFileSizeExceed, onFileChange }: CheckFileSizeArgs) {
  if (file.size > sizeLimitKB * KB) {
    onFileSizeExceed()
  } else {
    onFileChange(file)
  }
}
