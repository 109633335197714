<template>
  <button :disabled="disabled" @click="handleClick" class="icon-wrap" :class="{ disabled: disabled }">
    <slot />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class ButtonToWrapIcon extends Vue {
  // @ts-expect-error TS2564
  @Prop({ required: true }) onClick: () => void
  // @ts-expect-error TS2564
  @Prop({ default: false }) disabled: boolean
  handleClick() {
    this.onClick()
  }
}
</script>

<style scoped lang="scss">
.icon-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &.disabled:hover {
    cursor: not-allowed;
  }
}
</style>
