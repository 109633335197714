export const PrioritizedOnlineMeetingTypeValues = ['googleMeet', 'zoom', 'microsoftTeams'] as const
export type PrioritizedOnlineMeetingType = (typeof PrioritizedOnlineMeetingTypeValues)[number]

export type PrioritizedOnlineMeeting = {
  type: PrioritizedOnlineMeetingType
}

export const OnlineMeetingTypeValues = ['none', ...PrioritizedOnlineMeetingTypeValues] as const
export type OnlineMeetingType = (typeof OnlineMeetingTypeValues)[number]

export type OnlineMeeting = {
  type: OnlineMeetingType
  // info?: {...} // TODO: 必要になったときに整理して実装する
}
