<template>
  <div class="user-badge-wrapper" @click="handleClick">
    <UserIcon :userPhoto="userPhoto" :userName="userName" :iconWidth="iconWidth" />
    <div class="is-pointer">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import UserIcon from '@/components/atoms/UserIcon.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    UserIcon
  }
})
export default class UserBadge extends Vue {
  @Prop() userPhoto?: string
  @Prop() userName?: string
  @Prop({ default: 35 }) iconWidth?: number

  handleClick(event) {
    this.$emit('clicked')
    event.stopPropagation()
  }
}
</script>

<style scoped lang="scss">
.user-badge-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
