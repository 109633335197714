<template>
  <h1 class="auth-form-title">{{ title }}</h1>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthFormTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
})
</script>

<style scoped lang="scss">
.auth-form-title {
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.8px;
  font-weight: 600;
  color: $spir2_black;
  text-align: center;
  white-space: pre-line;
}
</style>
