<template>
  <div class="card-box-for-html">
    <CopyBoxBodyFrame>
      <LoadingSpinner :active="isLoading" :fullPage="false" />
      <CopyBoxHeader
        :title="boxHeaderTitle"
        :buttonTitle="buttonTitle"
        :tip="$t('shareTextModal.sharingHTMLTip')"
        :onClick="copyHTMLText"
      />
      <div class="language-and-timezone-frame">
        <CopyBoxLanguageAndTimeZone
          :timeZone="timeZone"
          :language="language"
          :onLanguageUpdate="onLanguageUpdate"
          :onTimeZoneChange="onTimeZoneChange"
        />
      </div>
      <iframe :srcdoc="htmlMailText" />
    </CopyBoxBodyFrame>
  </div>
</template>

<script lang="ts">
import CopyBoxBodyFrame from '@/components/copyToShare/copyBoxParts/CopyBoxBodyFrame.vue'
import CopyBoxHeader from '@/components/copyToShare/copyBoxParts/CopyBoxHeader.vue'
import CopyBoxLanguageAndTimeZone from '@/components/copyToShare/copyBoxParts/CopyBoxLanguageAndTimeZone.vue'
import { LoadingSpinner } from '@/components/ui/layouts/LoadingSpinner'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { copyOrShareHTMLAndText } from '@/lib/utils/functions'
import { FrontSupportLanguage } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxForHtml',
  components: {
    LoadingSpinner,
    CopyBoxBodyFrame,
    CopyBoxHeader,
    CopyBoxLanguageAndTimeZone
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      required: true
    },
    boxHeaderTitle: {
      type: String,
      required: true
    },
    htmlText: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    timeZone: {
      type: String,
      required: true
    },
    language: {
      type: String as PropType<FrontSupportLanguage>,
      required: true
    },
    onLanguageUpdate: {
      type: Function as PropType<(lang: FrontSupportLanguage) => Promise<void>>,
      required: true
    },
    onAnalyticsSend: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    },
    onTimeZoneChange: {
      type: Function as PropType<(code: string) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { openSuccessTopToast } = useToast()
    const htmlMailText = computed((): string => {
      return props.isLoading ? '' : props.htmlText
    })

    async function copyHTMLText() {
      await props.onAnalyticsSend()
      if (copyOrShareHTMLAndText(props.htmlText, props.text) === 'clipboard') {
        openSuccessTopToast({
          message: i18n.t('message.copiedToClipBoard').toString()
        })
      }
    }

    function handleTimeZoneChange(code: string) {
      props.onTimeZoneChange(code)
    }
    return {
      htmlMailText,
      copyHTMLText,
      handleTimeZoneChange
    }
  }
})
</script>

<style scoped lang="scss">
.card-box-for-html {
  .language-and-timezone-frame {
    padding: 12px;
    box-shadow: 0px 1px 0px $spir2_black_08;
  }
  iframe {
    width: 100%;
  }
}
</style>
