<template>
  <div class="spir-dropdown-section">
    <slot name="title">
      <label class="spir-dropdown-section__label" v-if="title">{{ title }}</label>
    </slot>
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdownSection',
  props: {
    title: {
      type: String
    }
  }
})
</script>
<style scoped lang="scss">
.spir-dropdown-section {
  padding: 8px 12px;
  &__label {
    padding: 4px 0px;
    font-size: 12px;
  }
}
</style>
