<template>
  <button class="pagehead-close-button" @click="handleClick">
    <InlineSvg class="close" :src="require('@/assets/icons/close_icon.svg')" width="12" height="12" />
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'
import InlineSvg from 'vue-inline-svg'

export default defineComponent({
  components: {
    InlineSvg
  },
  props: {
    onClick: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    return {
      handleClick: async () => {
        await props.onClick()
      }
    }
  }
})
</script>

<style scoped lang="scss">
.pagehead-close-button {
  position: relative;
  border: none;
  padding: 0;
  width: 11.67px;
  background-color: $spir2_white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &::v-deep {
    svg.close {
      path {
        fill: $spir2_gray;
      }
    }
  }
}
</style>
