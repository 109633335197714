import axiosInstance from '../axios'

export const connectZoom = async (authCode: string) => {
  return axiosInstance.post(`integrations/zoom`, { authCode: authCode })
}

export const fetchIntegrations = async (refreshingZoom: boolean) => {
  return (await axiosInstance.get('integrations', { params: { refreshingZoom: refreshingZoom } })).data
}

export const disconnectZoom = async () => {
  return axiosInstance.delete('integrations/zoom')
}
