<template>
  <b-upload :value="file" :accept="accept" @input="onFileChange">
    <slot />
  </b-upload>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'FileUploader',
  props: {
    acceptTypes: {
      type: Array as PropType<string[]>,
      default: () => ['image/x-png', 'image/jpeg', 'image/png']
    },
    onFileChange: {
      type: Function as PropType<(file: File) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const file = ref(null)
    const accept = computed(() => props.acceptTypes.join(', '))
    return {
      accept,
      file
    }
  }
})
</script>
