import { getCurrentInstance } from '@vue/composition-api'
import { BuefyNamespace } from 'buefy'

export const useBuefy = (): BuefyNamespace => {
  const inst = getCurrentInstance()
  if (inst) {
    return inst.proxy.$buefy as BuefyNamespace
  }
  // @ts-expect-error TS2322
  return undefined
}
