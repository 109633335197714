import i18n from '@/i18n'
import { getCandidateSource, isEditable } from '@/lib/utils/candidate'
import { hasIntersectedEvents } from '@/lib/utils/event'
import { CandidateError } from '@/models/data/error'
import { Candidate, CandidateStatus, FullCalendarEvent, ScheduleStatus, isActiveStatus } from '@/types'
import {
  addMinutes,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  isBefore,
  parseISO
} from 'date-fns'
import { sortBy } from 'lodash'
import { nanoid } from 'nanoid'

export const MAX_CANDIDATE_COUNT = 50
export const MSEC_TO_DELETE = 800 // 一部のMobile端末で、候補作成後すぐにTouchEventが発生して候補が削除される。それをとめるためにこの変数値以内でのDeleteCandidateは無視する

type AddCandidateArgs = {
  candidates: readonly Candidate[]
  duration: number
  payload: {
    start: Date
    end: Date
    candidateId?: string
  }
}

export const CommonCandidatesControls = {
  // カレンダー表示対象。表示はRejectedも表示する
  getCandidatesToDisplayOnCalendar(data: { candidates: Candidate[] }): Candidate[] {
    return sortBy(data.candidates, 'start').filter(c => c.status !== 'deleted')
  },
  isUnderDuration(data: { start: string; end: string; duration: number }): boolean {
    return differenceInMinutes(parseISO(data.end), parseISO(data.start)) < data.duration
  },
  getActiveCandidates(candidates: readonly Candidate[]): Candidate[] {
    // @ts-expect-error TS2345
    return candidates.filter(c => isActiveStatus(c.status))
  },
  separateUnderNewDurationCandidatesOrNot(data: { candidates: readonly Candidate[]; duration: number }): {
    remained: Candidate[]
    removed: Candidate[]
  } {
    const { candidates, duration } = data
    const { remained, removed } = candidates.reduce(
      (acc, candidate) => {
        if (this.isUnderDuration({ start: candidate.start, end: candidate.end, duration })) {
          // @ts-expect-error TS2345
          acc.removed.push(candidate)
        } else {
          // @ts-expect-error TS2345
          acc.remained.push(candidate)
        }
        return acc
      },
      { remained: [], removed: [] }
    )
    return { remained, removed }
  },
  addCandidate(data: AddCandidateArgs): Candidate[] {
    const { payload, duration, candidates } = data
    if (isBefore(payload.start, new Date())) {
      throw new CandidateError('mustBeBeforeNow')
    }
    if (isBefore(payload.end, payload.start)) {
      throw new CandidateError('endMustAfterStart')
    }
    if (differenceInHours(payload.end, payload.start) >= 24) {
      throw new CandidateError('durationExceedsLimit')
    }
    if (differenceInMinutes(payload.end, payload.start) < duration) {
      if (payload.candidateId) {
        throw new CandidateError('cannotUpdateShortThenDuration')
      }
      payload.end = addMinutes(payload.start, duration)
    }

    const activeCandidates = this.getActiveCandidates(data.candidates)

    const hasIntersections = hasIntersectedEvents(
      { id: payload.candidateId, start: payload.start, end: payload.end },
      // @ts-expect-error TS2345
      activeCandidates.map(c => ({ id: c.id, start: new Date(c.start), end: new Date(c.end) }))
    )
    if (hasIntersections) {
      throw new CandidateError('intersection')
    }
    if (payload.candidateId) {
      return data.candidates.map(candidate => {
        if (candidate.id === payload.candidateId) {
          return {
            ...candidate,
            start: payload.start.toISOString(),
            end: payload.end.toISOString(),
            updateDate: new Date()
          }
        } else {
          return candidate
        }
      })
    } else {
      const newCandidate = {
        id: nanoid(),
        start: payload.start.toISOString(),
        end: payload.end.toISOString(),
        addedDate: new Date()
      }
      return candidates.concat(newCandidate)
    }
  },
  removeCandidate({
    candidates,
    candidateId,
    now = new Date()
  }: {
    candidates: readonly Candidate[]
    candidateId: string
    now: Date
  }): Candidate[] {
    const newCandidates = candidates.filter(candidate => {
      // 対象候補が追加されてMSEC_TO_DELETE未満の場合は削除しない
      if (candidate.id !== candidateId) {
        return true
      } else {
        const addedDate = candidate.addedDate
        return addedDate && differenceInMilliseconds(now, addedDate) < MSEC_TO_DELETE
      }
    })
    return newCandidates
  }
}

export const TeamCandidatesControls = {
  getEditingEventByCalendarFormat(data: {
    candidates: Candidate[]
    title: string
    status: ScheduleStatus
  }): FullCalendarEvent[] {
    const candidatesToDisplay = CommonCandidatesControls.getCandidatesToDisplayOnCalendar({
      candidates: data.candidates
    })
    return candidatesToDisplay.map((c: Candidate) => {
      const title = ((): string => {
        if (data.status === ScheduleStatus.confirmed) {
          return data.title
        }
        if (c.status === CandidateStatus.rejected) {
          return i18n.t('labels.rejected').toString()
        }
        return i18n.t('labels.candidate').toString()
      })()
      const fullCalendarEvent: FullCalendarEvent = {
        title: title,
        // @ts-expect-error TS2322
        id: c.id,
        start: parseISO(c.start),
        end: parseISO(c.end),
        extendedProps: {
          source: getCandidateSource(c.status),
          canConfirm: !c.addedDate && !c.updateDate, // 確定可能な候補は編集されていない候補
          underDuration: c.underDuration,
          status: c.status
        },
        editable: isEditable(c.status)
      }
      return fullCalendarEvent
    })
  },
  addCandidate(data: AddCandidateArgs): Candidate[] {
    const activeCandidates = CommonCandidatesControls.getActiveCandidates(data.candidates)
    if (activeCandidates.length === MAX_CANDIDATE_COUNT) {
      throw new CandidateError('overMaxCandidate', { maxCount: MAX_CANDIDATE_COUNT.toString() })
    }
    return CommonCandidatesControls.addCandidate({
      payload: data.payload,
      duration: data.duration,
      candidates: data.candidates
    })
  },
  removeCandidateById(data: { candidateId: string; candidates: Candidate[] }): Candidate[] {
    const newCandidates = data.candidates.filter(candidate => candidate.id !== data.candidateId)
    return newCandidates
  }
}
