<template>
  <div>
    <template v-for="order of timeZoneOrders">
      <TimezoneSelectorAndDisplayName
        v-if="timezoneInfoSynced[order.key]"
        :key="order.key"
        :order="order.key"
        :showDelete="order.canDelete"
        :timezoneInfo.sync="timezoneInfoSynced[order.key]"
        @updateTimezone="handleUpdateTimezone"
        @deleteTimezone="deleteTimezone(order.key)"
      />
    </template>
    <button v-if="!canNotAddNewTimezone" class="add-button is-hidden-mobile" @click="addTimezone">
      {{ $t('timezone.addSubTimezone') }}
    </button>
  </div>
</template>

<script lang="ts">
import TimezoneSelectorAndDisplayName from '@/components/organisms/timezoneSelector/TimezoneSelectorAndDisplayName.vue'
import { localTimezone } from '@/lib/timezone'
import { TimeZones } from '@/models/data/userInfo'
import { Component, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TimezoneSelectorAndDisplayName
  }
})
export default class TimezoneEdit extends Vue {
  // @ts-expect-error TS2564
  @PropSync('timeZones', { type: Object }) timezoneInfoSynced: TimeZones
  timeZoneOrders = [
    {
      key: 'primary',
      canDelete: false
    },
    {
      key: 'secondary',
      canDelete: true
    },
    {
      key: 'tertiary',
      canDelete: true
    }
  ]
  get canNotAddNewTimezone() {
    return !!this.timezoneInfoSynced.tertiary
  }
  handleUpdateTimezone() {
    this.$emit('updateTimezone')
  }
  deleteTimezone(objectKey) {
    delete this.timezoneInfoSynced[objectKey]
    // 削除後なので、tertiaryが存在するパターンはsecondaryが削除された場合のみ。
    if (this.timezoneInfoSynced.tertiary) {
      this.timezoneInfoSynced.secondary = this.timezoneInfoSynced.tertiary
      delete this.timezoneInfoSynced['tertiary']
    }
    this.timezoneInfoSynced = {
      ...this.timezoneInfoSynced
    }
    this.$emit('updateTimezone')
  }
  addTimezone() {
    if (this.timezoneInfoSynced.secondary) {
      this.timezoneInfoSynced = {
        ...this.timezoneInfoSynced,
        tertiary: {
          key: localTimezone()
        }
      }
    } else {
      this.timezoneInfoSynced = {
        ...this.timezoneInfoSynced,
        secondary: {
          key: localTimezone()
        }
      }
    }
    this.$emit('updateTimezone')
  }
}
</script>

<style scoped lang="scss">
.add-button {
  border: none;
  background: $spir2_white;
  padding: 0;
  color: $spir2_primaryBlue;

  @include inter_font_setting($font_weight: 600);
  &:hover {
    cursor: pointer;
  }
}
</style>
