<template>
  <div class="header">
    <div class="title-frame">
      <p class="title">{{ title }}</p>
      <TipMarkBase :width="200"> {{ tip }} </TipMarkBase>
    </div>
    <Button type="primary" size="small" @click="handleClick">{{ buttonTitle }}</Button>
  </div>
</template>

<script lang="ts">
import Button from '@/components/ui/Button.vue'
import TipMarkBase from '@/components/ui/tipMark/TipMarkBase.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxHeader',
  components: {
    TipMarkBase,
    Button
  },
  props: {
    title: {
      type: String,
      required: true
    },
    buttonTitle: {
      type: String,
      required: true
    },
    tip: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    return {
      handleClick: props.onClick
    }
  }
})
</script>

<style scoped lang="scss">
.header {
  padding: 12px;
  box-shadow: 0px 1px 0px $spir2_black_08;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-frame {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    .title {
      margin: 0;
      color: $spir2_black;

      @include inter_font_setting($font_weight: 600, $font_size: 12px, $line_height: 18px, $letter_spacing: 0.3px);
    }
  }
}
</style>
