import { startOfDay } from '@/lib/utils/timezone'
import { FullCalendarEvent } from '@/types'
import { differenceInMinutes, isAfter, isBefore, isEqual } from 'date-fns'
const ONE_DAY_MIN = 1440

export interface EventPosition {
  myDepth: number
  maxDepth: number
  widthTimes: number
  node: FullCalendarEvent
}

class EventTree {
  node?: FullCalendarEvent
  parent: EventTree
  children: EventTree[]
  childDepth?: number
  depth: number
  // @ts-expect-error TS2322
  constructor(node: FullCalendarEvent = null, parent: EventTree = null, child: EventTree = null, depth = 0) {
    this.depth = depth
    this.node = node
    this.parent = parent
    this.children = child ? [child] : []
  }
  isBetweenMyNode(start: Date, nextDepth?: number): boolean {
    if (nextDepth && nextDepth < this.depth) {
      return false
    }
    // @ts-expect-error TS2532
    return isEqual(start, this.node.start) || (isAfter(start, this.node.start) && isBefore(start, this.node.end))
  }
  searchGChild(node: FullCalendarEvent) {
    for (const child of this.children) {
      if (child.isBetweenMyNode(node.start)) {
        return child
      }
      const response = child.searchGChild(node)
      if (response) {
        return response
      }
    }
    return null
  }
  insertNode(node: FullCalendarEvent) {
    let canIInsertAsChild = true
    const nextDepth = this.depth + 1
    for (const child of this.children) {
      if (child.isBetweenMyNode(node.start, nextDepth)) {
        canIInsertAsChild = false
        child.insertNode(node)
        break
      }
    }
    if (canIInsertAsChild) {
      const gChild = this.searchGChild(node)
      this.children.push(new EventTree(node, this, gChild, nextDepth))
    }
  }
  /*
    We have to know tree's depth in order to display in the element.
  */
  getChildDepth() {
    if (this.children.length === 0) {
      this.childDepth = 0
      return this.childDepth
    }
    let maxChildDepth = 0
    this.children.forEach(child => {
      const childDepth = child.getChildDepth() + 1
      if (childDepth > maxChildDepth) {
        maxChildDepth = childDepth
      }
    })
    this.childDepth = maxChildDepth
    return this.childDepth
  }
  /*
    Calculate position of node
    nodeList is reference
  */
  getWidthTimes(maxDepth: number, myDepth: number) {
    if (maxDepth !== myDepth && this.children.length === 0) {
      return maxDepth - myDepth + 1
    }
    return 1
  }
  get myTopPosition() {
    // @ts-expect-error TS2532
    const startDate = startOfDay(this.node.start)
    // @ts-expect-error TS2532
    return (differenceInMinutes(this.node.start, startDate) / ONE_DAY_MIN) * 100
  }
  get myHeight() {
    // @ts-expect-error TS2532
    return (differenceInMinutes(this.node.end, this.node.start) / ONE_DAY_MIN) * 100
  }
  draw(nodeList: EventPosition[], maxDepth?: number, myDepth?: number): EventPosition[] {
    if (!this.node) {
      // root node
      this.children.forEach(child => {
        child.draw(nodeList)
      })
      return nodeList
    }
    // don't push if there is same node already
    // @ts-expect-error TS2532
    if (nodeList.some(node => node.node.id === this.node.id)) {
      return nodeList
    }

    // @ts-expect-error TS2532
    const nodeMaxDepth = maxDepth || this.childDepth + 1 // to first nodes, thier childDepth is their max depth
    const nodeMyDepth = myDepth || 1 // to first node, depth is 1

    const myPosition: EventPosition = {
      widthTimes: this.getWidthTimes(nodeMaxDepth, nodeMyDepth),
      myDepth: nodeMyDepth,
      maxDepth: nodeMaxDepth,
      node: this.node
    }
    nodeList.push(myPosition)
    this.children.forEach(child => {
      child.draw(nodeList, nodeMaxDepth, nodeMyDepth + 1)
    })
    return nodeList
  }
}
export class EventsForCalendar {
  // @ts-expect-error TS2564
  eventTree: EventTree
  events: FullCalendarEvent[]
  constructor(events: FullCalendarEvent[]) {
    this.events = events
  }
  eventArrayForCalendar() {
    this.eventTree = new EventTree()
    this.events.forEach(event => {
      this.eventTree.insertNode(event)
    })
    this.eventTree.getChildDepth()
    return this.eventTree.draw([])
  }
  insertNode(event: FullCalendarEvent) {
    this.events.push(event)
  }
}
