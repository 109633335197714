<template>
  <button
    class="auth-icon-button"
    :class="{ 'icon-only': isIconOnly, centered: centered }"
    :disabled="disabled"
    @click="handleClick"
  >
    <i :class="type" />
    <div class="button-title" v-if="!isIconOnly">
      <slot />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthIconButton',
  props: {
    type: {
      type: String as PropType<'google' | 'microsoft' | 'mail'>,
      required: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, slots }) {
    return {
      isIconOnly: !slots.default,
      handleClick: () => emit('click')
    }
  }
})
</script>

<style scoped lang="scss">
.auth-icon-button {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: $spir2_black;
  background-color: $spir2_white;
  background-size: 40px 40px;
  border: 1px solid $spir2_border;
  border-radius: 6px;
  &[disabled] {
    background-color: $spir2_white;
    border-color: $spir2_border;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.5;
    cursor: not-allowed;
  }
  cursor: pointer;
  letter-spacing: 0.5px;
  padding-left: 12px;
  padding-right: 12px;
  &.icon-only {
    padding: 8px;
    width: auto;
  }
  i {
    top: 1px;
    width: 18px;
    height: 18px;
    background-size: 18px 18px !important;
    &.google {
      background: $spir2_white url('../../../../assets/images/google-logo.png') 50% 50% no-repeat;
    }
    &.microsoft {
      background: $spir2_white url('../../../../assets/images/microsoft-logo.png') 50% 50% no-repeat;
    }
    &.mail {
      background: $spir2_white url('../../../../assets/images/mail-logo.png') 50% 50% no-repeat;
    }
  }
  &.centered {
    justify-content: center;
  }
  .button-title {
    font-weight: 600;
    padding-left: 8px;
  }
}
</style>
