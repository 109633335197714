import { FrontSupportLanguage } from '@/types'
import axiosInstance from '../axios'

export const createUser = async (payload: { timeZone: string; language: FrontSupportLanguage }) => {
  return axiosInstance.post(`user`, payload)
}

export const login = async () => {
  return axiosInstance.get(`user`)
}
