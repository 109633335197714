<template>
  <SpirDropdown
    :value="value"
    :expanded="expanded"
    :mobile-modal="mobileModal"
    :disabled="disabled"
    :position="position"
    @change="handleChange"
  >
    <template #trigger="{ active }">
      <SpirDropdownButton :fieldId="fieldId" :expanded="expanded" :active="active" :size="size">
        <slot name="trigger" />
      </SpirDropdownButton>
    </template>
    <template #dropdown>
      <slot name="dropdown" />
    </template>
  </SpirDropdown>
</template>
<script lang="ts">
import { defineComponent, PropType } from '@vue/composition-api'
import SpirDropdown from './SpirDropdown.vue'
import SpirDropdownButton from './SpirDropdownButton.vue'

export default defineComponent({
  name: 'SpirButtonDropdown',
  components: {
    SpirDropdown,
    SpirDropdownButton
  },
  props: {
    fieldId: {
      type: String
    },
    position: {
      type: String as PropType<'bottom' | 'top' | 'bottom-left'>,
      default: 'bottom'
    },
    value: {
      type: String
    },
    expanded: {
      type: Boolean,
      default: true
    },
    mobileModal: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large' | 'x-large'>,
      default: 'medium'
    }
  },
  setup(props, { emit }) {
    function handleChange(newValue: string) {
      emit('change', newValue)
    }

    return {
      handleChange
    }
  }
})
</script>
