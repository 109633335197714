<template>
  <p class="timezone-label" :class="size">
    <b-icon v-if="showIcon" icon="earth" size="is-small"></b-icon>
    {{ selectedTimeZoneLabel }}
  </p>
</template>

<script lang="ts">
import { useTimeZoneIntervalLabel } from '@/composables/useTimeZoneIntervalLabel'
import { TimezoneDetail } from '@/lib/timezone'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

// FIXME: This component is depend on `b-icon`. We should remove dependency of buefy in `features/` dir.
export default defineComponent({
  name: 'TimezoneLabel',
  props: {
    timezoneInfo: {
      type: Object as PropType<TimezoneDetail>,
      required: true
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    timezoneLabel: {
      type: String
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'small'
    }
  },
  setup(props) {
    const { timezoneInfo } = toRefs(props)
    const timeZoneKey = computed(() => timezoneInfo.value.key)
    const { timeZoneIntervalLabel } = useTimeZoneIntervalLabel({ timeZoneKey })
    const selectedTimeZoneLabel = computed(() => {
      return `${props.timezoneInfo.fullLabel}(${timeZoneIntervalLabel.value})`
    })
    return {
      selectedTimeZoneLabel
    }
  }
})
</script>

<style scoped lang="scss">
.timezone-label {
  color: $spir2_black;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  display: block;
  &.medium {
    @include inter_font_setting($font_weight: 400);
  }
  &.small {
    @include inter_font_setting($font_weight: 400, $font_size: 12px, $line_height: 18px, $letter_spacing: 0.3px);
  }
}
</style>
