<template>
  <div>
    <div v-if="subTitle" class="modal-input__field border-bottom">
      <p class="modal-input__sub-title">
        {{ subTitle }}
      </p>
    </div>
    <div v-if="formattedDate" class="modal-input__field pb-0">
      <p class="mb-1">{{ formattedDate }}</p>
      <TimezoneLabel :showIcon="true" :timezoneInfo="userTimezoneInfo" />
    </div>
  </div>
</template>

<script lang="ts">
import TimezoneLabel from '@/components/features/timezones/TimezoneLabel/index.vue'
import TimezoneModule from '@/store/modules/timezones'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TimezoneLabel
  }
})
export default class ShowConfirmedDate extends Vue {
  // @ts-expect-error TS2564
  @Prop() subTitle: string
  // @ts-expect-error TS2564
  @Prop() formattedDate: string

  get userTimezoneInfo() {
    return TimezoneModule.userTimezoneInfo
  }
}
</script>

<style scoped lang="scss"></style>
