<template>
  <FormItem class="form-item-input" :required="required" :icon="icon" :label="label" :help="help">
    <Input v-bind="$attrs" :rules="rules" :label="label" @input="e => $emit('input', e)"> </Input>
  </FormItem>
</template>

<script lang="ts">
import FormItem from '@/components/newForm/atoms/FormItem.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Input from '../atoms/Input.vue'

@Component({
  components: {
    Input,
    FormItem
  }
})
export default class FormInputfield extends Vue {
  // @ts-expect-error TS2564
  @Prop() required: boolean
  // @ts-expect-error TS2564
  @Prop() icon: string
  // @ts-expect-error TS2564
  @Prop() label: string
  // @ts-expect-error TS2564
  @Prop({ default: null }) help: string
  // @ts-expect-error TS2564
  @Prop() rules: string
}
</script>

<style lang="scss"></style>
