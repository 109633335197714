<template>
  <div class="spir-form-container">
    <div class="field">
      <label class="spir-label"> {{ $t('scheduleEventList.title') }} </label>
      <input
        class="input spir-input no-border"
        :class="{ readOnly: readOnly }"
        ref="inputTitle"
        :value="editingEvent.summary || `(${$t('message.noTitle')})`"
        type="text"
        :placeholder="$t('forms.event.title')"
        :disabled="readOnly"
      />
    </div>
    <div class="field" v-if="!isDeleted && hasEventInfo && myAttendee !== undefined">
      <AttendeeStatus
        :updateAttendeeStatus="updateAttendeeStatus"
        :eventContext="{
          accountId: editingEvent.accountId,
          calendarId: editingEvent.calendarId,
          eventId: editingEvent.id,
          attendee: myAttendee
        }"
      />
    </div>
    <div class="field">
      <label class="spir-label">{{ $t('scheduleEventList.time') }}</label>
      <p>{{ dateRangeDisplay }}</p>
    </div>
    <div class="field" v-if="showInputField('location')">
      <label class="spir-label">{{ $t('scheduleEventList.location') }}</label>
      <LocationRead :location="editingEvent.location" />
    </div>
    <div v-if="showInputField('onlineMeeting')" class="field online-meeting-field pab-8">
      <OnlineMeetingSelector :selectedService="onlineMeetingType" :readonly="readOnly" :availableOnlineMeetings="[]" />
      <MeetingRoomInfo v-if="editingEvent.onlineMeeting" :onlineMeeting="editingEvent.onlineMeeting" />
    </div>
    <div class="field" v-if="hasAttendee">
      <label class="spir-label">{{ $t('labels.attendee') }}</label>
      <div class="attendees">
        <AttendeeWithProfileButton
          v-for="attendee of attendees"
          :key="attendee.email"
          :attendeeInfo="attendee"
          @clickProfile="handleClickProfile"
        />
      </div>
    </div>
    <div class="field" v-if="hasRoom">
      <label class="spir-label">{{ $t('labels.meetingRoom') }}</label>
      <ul>
        <li v-for="(room, index) in currentRoom" :key="index">
          {{ room.fullName }}
        </li>
      </ul>
    </div>
    <div v-if="organizerName" class="field">
      <label class="spir-label">{{ $t('labels.organizer') }}</label>
      <input
        class="input spir-input no-border"
        :class="{ readOnly: readOnly }"
        :value="organizerName"
        :disabled="readOnly"
      />
    </div>
    <div class="field" v-if="showInputField('description')">
      <label class="spir-label">{{ $t('scheduleEventList.description') }}</label>
      <HtmlDisplay :description="editingEvent.description" />
    </div>
    <div class="field" v-if="showInputField('visibility')">
      <label class="spir-label">{{ $t('labels.visibility') }}</label>
      <b-select
        :label="$t('labels.visibility')"
        v-model="editingEvent.visibility"
        :class="{ readOnly: readOnly }"
        :disabled="readOnly"
        expanded
      >
        <option v-for="lock in lockOptions" :value="lock.value" :key="lock.value">{{ lock.title }}</option>
      </b-select>
    </div>
    <div class="field" v-if="showInputField('transparencyStatus')">
      <label class="spir-label">{{ $t('labels.transparencyStatus') }}</label>
      <b-select
        :label="$t('labels.transparencyStatus')"
        v-model="editingEvent.transparencyStatus"
        :class="{ readOnly: readOnly }"
        :disabled="readOnly"
        expanded
      >
        <option v-for="status in statusOptions" :value="status.value" :key="status.value">
          {{ status.title }}
        </option>
      </b-select>
    </div>
  </div>
</template>

<script lang="ts">
import AttendeeWithProfileButton from '@/components/features/attendees/AttendeeWithProfileButton/index.vue'
import AttendeeItem from '@/components/molecules/AttendeeItem.vue'
import AttendeeStatus from '@/components/molecules/AttendeeStatus.vue'
import MeetingRoomInfo from '@/components/molecules/MeetingRoomInfo.vue'
import MemberAvatar from '@/components/molecules/MemberAvatar.vue'
import OnlineMeetingSelector from '@/components/molecules/OnlineMeetingSelector.vue'
import Button from '@/components/ui/Button.vue'
import HtmlDisplay from '@/components/ui/layouts/HtmlDisplay/index.vue'
import LocationRead from '@/components/ui/layouts/LocationRead/index.vue'
import { updateAttendeeStatus as updateAttendeeStatusAPI } from '@/effects/event'
import eventDate from '@/filters/eventDate'
import { SignalType } from '@/lib/analytics/LogEntry'
import CalendarsModule from '@/store/modules/calendars'
import EventForModalModule from '@/store/modules/eventForModal'
import EventsModule from '@/store/modules/events'
import { GoogleEvent } from '@/types'
import { OnlineMeetingType, SpirAttendeeStatus } from '@/types/schedule'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    LocationRead,
    AttendeeItem,
    AttendeeWithProfileButton,
    HtmlDisplay,
    MeetingRoomInfo,
    OnlineMeetingSelector,
    AttendeeStatus,
    MemberAvatar,
    Button
  }
})
export default class EventFormModalBody extends Vue {
  // @ts-expect-error TS2564
  @Prop() currentEvent: GoogleEvent

  // @ts-expect-error TS2322
  editingEvent: GoogleEvent = null
  lockOptions = []
  statusOptions = []

  get attendees() {
    return EventForModalModule.getAttendees
  }
  get readOnly() {
    return true
  }
  get organizerName() {
    return this.currentEvent.organizer?.displayName || this.currentEvent.organizer?.email || this.calendarName
  }
  get calendarName() {
    if (this.currentEvent && this.currentEvent.accountId && this.currentEvent.calendarId) {
      return this.getCalendarName(this.currentEvent.accountId, this.currentEvent.calendarId)
    }
    return null
  }
  get isDeleted() {
    const deleted = this.editingEvent.deleted ?? false
    return deleted
  }

  get hasEventInfo() {
    return (
      this.editingEvent.accountId !== undefined &&
      this.editingEvent.calendarId !== undefined &&
      this.editingEvent.id !== undefined &&
      this.myAttendee?.responseStatus !== undefined
    )
  }

  get myAttendee() {
    if (this.editingEvent.accountId === undefined || this.editingEvent.calendarId === undefined) {
      return undefined
    }

    const calendar = CalendarsModule.getCalendar({
      accountId: this.editingEvent.accountId,
      calendarId: this.editingEvent.calendarId
    })
    if (!calendar?.invitationAddress) {
      return undefined
    }
    return this.editingEvent.attendees?.find(e => e.email == calendar.invitationAddress)
  }

  get onlineMeetingType() {
    return this.editingEvent.onlineMeeting ? this.editingEvent.onlineMeeting.type : OnlineMeetingType.none
  }
  get dateRangeDisplay() {
    return eventDate(this.editingEvent.start, this.editingEvent.end, this.editingEvent.allDay)
  }
  getCalendarName(accountId, calendarId) {
    return CalendarsModule.getCalendarName({ accountId, calendarId })
  }
  showInputField(fieldName) {
    return !this.readOnly || !!this.currentEvent[fieldName]
  }
  created() {
    this.editingEvent = {
      ...this.currentEvent
    }
    // @ts-expect-error TS2322
    this.lockOptions = ['default', 'private', 'public'].map(s => ({
      title: this.$t(`forms.event.visibility.${s}`).toString(),
      value: s
    }))

    // @ts-expect-error TS2322
    this.statusOptions = ['free', 'tentative', 'busy', 'oof', 'workingElsewhere', 'unknown'].map(s => ({
      title: this.$t(`forms.event.transparencyStatus.${s}`).toString(),
      value: s
    }))
  }
  async updateAttendeeStatus(args: {
    accountId: string
    calendarId: string
    eventId: string
    value: Exclude<SpirAttendeeStatus, 'needsAction'>
  }) {
    const result = await updateAttendeeStatusAPI({
      accountId: args.accountId,
      calendarId: args.calendarId,
      eventId: args.eventId,
      responseStatus: args.value
    })
    if (result.status === 'failure') {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        duration: 2000,
        message: this.$t('message.errorCommon').toString()
      })
    } else {
      await EventsModule.fetchEvents({
        targetCalendars: [
          {
            accountId: args.accountId,
            calendarId: args.calendarId
          }
        ]
      })
    }
  }
  get hasAttendee(): boolean {
    return this.attendees.length > 0
  }
  get hasRoom(): boolean {
    // @ts-expect-error TS2322
    return this.editingEvent.resources && this.editingEvent.resources.length > 0
  }
  get currentRoom() {
    return this.editingEvent.resources
  }
  async handleClickProfile() {
    await this.$analytics.send(SignalType.SEE_PROFILE_ON_EVENT_MODAL)
  }
}
</script>

<style lang="scss" scoped>
.input[disabled] {
  color: $spir2_gray;
  -webkit-text-fill-color: $spir2_gray;
}
.input.readOnly {
  color: $spir2_black;
  -webkit-text-fill-color: $spir2_black;
}
.spir-input.no-border {
  border-bottom: none;
}
.readOnly {
  &::v-deep select {
    color: $spir2_black;
    -webkit-text-fill-color: $spir2_black;
  }
}
</style>
