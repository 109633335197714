import { useAuthSessionStorage } from '@/composables/auth/useAuthSessionStorage'
import { useSendSignal } from '@/composables/useSendSignal'
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithRedirect } from 'firebase/auth'

export const useAuthSignUp = () => {
  const { sendSignUpTopSignal } = useSendSignal()
  const { setSession } = useAuthSessionStorage()

  async function callGoogleSignUp(): Promise<void> {
    setSession({ action: 'SignUp', type: 'Google' })
    const googleProvider = new GoogleAuthProvider()
    googleProvider.setCustomParameters({
      prompt: 'select_account'
    })
    await signInWithRedirect(getAuth(), googleProvider)
  }

  async function signUpWithGoogle(): Promise<void> {
    sendSignUpTopSignal('google')
    await callGoogleSignUp()
  }

  async function callMicrosoftSignUp(): Promise<void> {
    setSession({ action: 'SignUp', type: 'Microsoft' })
    const msProvider = new OAuthProvider('microsoft.com')
    msProvider.setCustomParameters({
      prompt: 'select_account'
    })
    await signInWithRedirect(getAuth(), msProvider)
  }

  async function signUpWithMicrosoft(): Promise<void> {
    sendSignUpTopSignal('microsoft')
    await callMicrosoftSignUp()
  }

  return {
    callGoogleSignUp,
    callMicrosoftSignUp,
    signUpWithGoogle,
    signUpWithMicrosoft
  }
}
