import axiosInstance from '@/lib/axios'
import { AccountsAccountIdCalendarsCalendarIdEventsEventIdAttendeesStatusPatchRequest } from '@spirinc/contracts'

export async function updateAttendeeStatus(data: {
  accountId: string
  calendarId: string
  eventId: string
  responseStatus: 'declined' | 'tentative' | 'accepted'
}): Promise<void> {
  const payload: AccountsAccountIdCalendarsCalendarIdEventsEventIdAttendeesStatusPatchRequest = {
    responseStatus: data.responseStatus,
    sendUpdateEventNotification: true
  }
  await axiosInstance.post(
    `/accounts/${data.accountId}/calendars/${data.calendarId}/events/${data.eventId}/attendee-status`,
    payload
  )
  return
}
