const AUTH_ACTION_KEY = 'spir-auth-action'
const INVITATION_CODE = 'spir-invitation-code'

export type AuthAction = 'SignUp' | 'SignIn' | 'SignInOrSignUp' | 'AddCalendar' | 'AddCalendarToGrantProviderResource'
export type AuthFrom =
  | 'TopPage'
  | 'Schedule'
  | 'GroupPoll'
  | 'PublicUrl'
  | 'TeamMemberInvited'
  | 'BeforeAuth'
  | 'SignUpInAuthTeamsCreate'
  | 'SignUpInAuthTeamsCreateFree'
  | 'TeamResourceSettings'
export type AuthType = 'Google' | 'Microsoft'

export class SessionStorage {
  public static getAuthAction(): {
    action: AuthAction
    from: AuthFrom
    type: AuthType
    id?: string
    redirectFrom?: string
    fromScreenName: string
  } | null {
    const json = sessionStorage.getItem(AUTH_ACTION_KEY)
    return json ? JSON.parse(json) : null
  }
  public static setAuthAction(
    value: {
      action: AuthAction
      from: AuthFrom
      type: AuthType
      id?: string
      redirectFrom?: string
      fromScreenName: string
    } | null
  ) {
    if (value !== null) {
      sessionStorage.setItem(AUTH_ACTION_KEY, JSON.stringify({ ...value }))
    } else {
      sessionStorage.removeItem(AUTH_ACTION_KEY)
    }
  }
}
