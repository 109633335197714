<template>
  <div class="modal-card spir-modal">
    <section class="modal-card-body">
      <div v-if="showHeader" class="modal-header">
        <b-button v-if="showCloseButton" class="icon-button" icon-left="close" @click="$emit('close')" />
        <div class="header-title" :class="{ 'has-icon': showCloseButton }">
          <slot name="modalHeader" />
        </div>
        <div class="header-button right">
          <slot name="modalHeaderRightButton" />
        </div>
      </div>
      <Divider v-if="showHeaderBorder" />
      <div class="modal-body">
        <slot name="modalBody" />
      </div>
      <div class="modal-footer" v-if="showFooter">
        <slot name="modalFooter" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import Divider from '@/components/ui/layouts/Divider/index.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Divider
  }
})
export default class CommonLayoutModal extends Vue {
  @Prop() rightButtonTitle?: string
  // @ts-expect-error TS2564
  @Prop({ default: true }) showFooter: boolean
  @Prop({ default: true }) showCloseButton?: boolean
  @Prop() leftButtonTitle?: string
  @Prop({ default: false }) rightButtonDisabled?: boolean
  @Prop({ default: false }) enableDelete?: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) showHeader: boolean
  @Prop({ default: false }) showHeaderBorder?: boolean
}
</script>
