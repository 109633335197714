<template>
  <div class="pulldown-form">
    <section class="pulldown-form-inner">
      <div v-if="showHeader" class="pulldown-form-header">
        <slot name="formHeader"></slot>
      </div>
      <div class="pulldown-form-body">
        <slot name="formBody"></slot>
      </div>
      <div v-if="showFooter" class="pulldown-form-footer is-clearfix">
        <slot name="formFooter"></slot>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PullDownForm extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: true }) showFooter: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) showHeader: boolean
}
</script>

<style scoped lang="scss">
.pulldown-form {
  width: 100%;

  .pulldown-form-inner {
    width: 100%;
    .pulldown-form-header {
      width: 100%;
      padding: 12px;
      font-size: 12px;
      line-height: 1.2;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    .pulldown-form-body {
      width: 100%;
      padding: 12px;
    }

    .pulldown-form-footer {
      width: 100%;
      padding-right: 12px;
      padding-bottom: 10px;
    }
  }
}
</style>
