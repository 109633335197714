import { useTimeAxis } from '@/composables/calendar/useTimeAxis'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TimeAxisMixin',
  setup() {
    const { timezoneForDisplay, timeAxisHeader, timeAxisBody } = useTimeAxis()
    return { timezoneForDisplay, timeAxisHeader, timeAxisBody }
  }
})
