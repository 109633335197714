<template>
  <SpirStepper :activeStep="activeStep" :items="items" />
</template>

<script lang="ts">
import SpirStepper from '@/components/ui/spir/SpirStepper/index.vue'
import { useTranslation } from '@/composables/useTranslation'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AuthSignUpFormStepper',
  components: {
    SpirStepper
  },
  props: {
    activeStep: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const i18n = useTranslation()
    const items = computed(() =>
      ['features.auth.AuthSignUpFormStepper.items.1st', 'features.auth.AuthSignUpFormStepper.items.2nd'].map(key => ({
        label: i18n.t(key).toString()
      }))
    )
    return {
      items
    }
  }
})
</script>
