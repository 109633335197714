import * as availabilityAPI from '@/lib/api/availability'
import { Timezone } from '@/models'
import { AvailabilityModelForPrivate, PatternCandidateModel, UpdateResult } from '@/models/data'
import { LanguageCode } from '@/models/language'
import AvailabilityStorage from '@/models/localStorage/Availability'
import store from '@/store'
import { FullCalendarEvent, Holiday } from '@/types'
import { cloneDeep } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import { AvailabilitySharingListModule } from './availabilitySharingList'
import TimezoneModule from './timezones'

const MODULE_NAME = 'EditAvailability'

const savedAvailability = new AvailabilityStorage()
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class EditAvailability extends VuexModule {
  _editModel: AvailabilityModelForPrivate | null = null
  _patternCandidate: PatternCandidateModel | null = null
  _isLoading = false
  _isCandidateLoading = false
  _isDirty = false

  get getIsLoading() {
    return this._isLoading
  }
  get getIsCandidateLoading() {
    return this._isCandidateLoading
  }
  get getModel(): AvailabilityModelForPrivate {
    // @ts-expect-error TS2322
    return this._editModel
  }
  get isDirty() {
    return this._isDirty
  }
  get candidatesInfo() {
    return this._patternCandidate
  }
  get candidatesByConditionAsCalendarFormat(): FullCalendarEvent[] {
    // confirmer
    if (!this.candidatesInfo) {
      return []
    }
    // @ts-expect-error TS2531
    return this._patternCandidate.candidates
  }
  get candidatesByMerged(): FullCalendarEvent[] {
    if (!this.candidatesInfo) {
      return []
    }
    // @ts-expect-error TS2531
    return this._patternCandidate.mergedCandidates
  }
  get showCalendars(): { accountId: string; calendarId: string }[] | undefined {
    return this.getModel?.showCalendars
  }
  @Action({ commit: 'SET_MODEL' })
  newAvailability({ language, timezone }: { language?: LanguageCode; timezone?: Timezone }) {
    const newModel = new AvailabilityModelForPrivate()
    if (language === 'ja' && timezone?.key === 'Asia/Tokyo') {
      newModel.countries = [{ code: 'JP' }]
    }
    return newModel
  }
  @Action({ commit: 'SET_MODEL' })
  async fetchAvailability(id: string) {
    this.SET_LOADING(true)
    try {
      // todo: team,Private 判断
      const availability = await availabilityAPI.getAvailability(id)
      const newModel = new AvailabilityModelForPrivate(availability)
      return newModel
    } catch (e) {
      return null
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action({ commit: 'SET_CANDIDATES' })
  async fetchCandidatesByParam() {
    if (!this.getModel.isValidToFetchCandidates) {
      return Promise.resolve(null)
    }
    try {
      this.SET_CANDIDATE_LOADING(true)
      const response = await availabilityAPI.fetchCandidatesByParam(this.getModel.parameterForFetchCandidates)
      this.SET_HOLIDAYS(response.holidays)
      return {
        ...response,
        candidates: response.timespans
      }
    } catch (e) {
      return null
    } finally {
      this.SET_CANDIDATE_LOADING(false)
    }
  }
  @Action
  async create(): Promise<string> {
    try {
      this.SET_LOADING(true)
      // @ts-expect-error TS2531
      const response = await availabilityAPI.create(this._editModel.parameterForCreate)
      savedAvailability.saveToLocalStorage(this._editModel)
      AvailabilitySharingListModule.fetchPatterns()
      this.reset()
      return response.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async update(): Promise<string> {
    try {
      this.SET_LOADING(true)
      // @ts-expect-error TS2531
      const response = await availabilityAPI.updatePattern(this._editModel.id, this._editModel.parameterForCreate)
      savedAvailability.saveToLocalStorage(this._editModel)
      await AvailabilitySharingListModule.fetchPatterns()
      this.reset()
      return response.id
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  updateModel(newModel: AvailabilityModelForPrivate): UpdateResult[] {
    newModel.updateEndDate()
    let result: UpdateResult[] = []
    if (newModel instanceof AvailabilityModelForPrivate) {
      result = newModel.updateAndValidation(this.getModel as AvailabilityModelForPrivate)
    }
    this.SET_MODEL(newModel)
    this.SET_DIRTY(true)
    return result
  }
  @Action
  reset() {
    this.SET_MODEL(null)
    this.SET_CANDIDATES(null)
    this.SET_DIRTY(false)
  }
  @Mutation
  SET_DIRTY(dirtyFlag: boolean) {
    this._isDirty = dirtyFlag
  }
  @Mutation
  SET_MODEL(model: AvailabilityModelForPrivate | null) {
    this._editModel = cloneDeep(model)
    TimezoneModule.UpdateTimezoneForInterrupt(model?.timeZone)
  }
  @Mutation
  SET_LOADING(isLoading) {
    this._isLoading = isLoading
  }
  @Mutation
  SET_CANDIDATES(patternCandidate) {
    if (patternCandidate) {
      this._patternCandidate = new PatternCandidateModel(patternCandidate)
    } else {
      this._patternCandidate = null
    }
  }
  @Mutation
  SET_CANDIDATE_LOADING(loadingStatus: boolean) {
    this._isCandidateLoading = loadingStatus
  }
  @Mutation
  SET_HOLIDAYS(holidays: Holiday[]) {
    if (this._editModel) {
      const newObject = cloneDeep(this._editModel)
      newObject.holidays = holidays
      this._editModel = newObject
    }
  }
}

export default getModule(EditAvailability)
