<template>
  <h5 class="form-label-title">
    <span v-html="title" /><span v-if="required" class="form-label-title__required">*</span>
  </h5>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FormLabelTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style scoped lang="scss">
.form-label-title {
  position: relative;

  color: $spir2_black;
  margin: 0;
  @include font_12_600($line_height: 160%);
  &__required {
    color: $spir2_cautionRed;
    margin-left: 4px;
  }
}
</style>
