import { FrontSupportLanguage } from '@/types'
import type { PersonalCalendar, PersonalCalendarPatchRequest, TeamCalendar } from '@spirinc/contracts'
import axiosInstance from '../axios'

const resource = (accountId: string, calendarId: string) => {
  const encodedAccountId = encodeURIComponent(accountId)
  const encodedCalendarId = encodeURIComponent(calendarId)
  return `accounts/${encodedAccountId}/calendars/${encodedCalendarId}`
}

export const register = (authCode: string, type = 'google', timeZone: string, language: FrontSupportLanguage) => {
  return axiosInstance.post(
    `accounts/${type}`,
    { clientType: 'web', authCode },
    { headers: { 'x-request-user-timezone': timeZone, 'x-request-user-language': language } }
  )
}

export const getCalendars = async (): Promise<{
  personal: PersonalCalendar[]
  teams: TeamCalendar[]
}> => {
  return (await axiosInstance.get('calendars')).data
}

export const getDailyViewCalendars = async (): Promise<PersonalCalendar[]> => {
  return (await axiosInstance.get('/accounts/calendars/daily-view')).data
}

export const updateCalendar = async (
  accountId: string,
  calendarId: string,
  payload: PersonalCalendarPatchRequest['payload']
) => {
  return (await axiosInstance.patch(resource(accountId, calendarId), { payload })).data
}

export const getCalendarEvents = async (accountId: string, calendarId: string, timeMin: string, timeMax: string) => {
  return (await axiosInstance.get(`${resource(accountId, calendarId)}/events`, { params: { timeMin, timeMax } })).data
}
