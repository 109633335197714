<template>
  <div class="media language-item">
    <div v-if="isShowIcon" class="media-left">
      <b-icon class="left-icon" size="is-small" icon="translate" />
    </div>
    <div class="media-content">
      <p class="language-title">
        {{ languageLabel }}
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { LanguageSelectItem } from '@/components/ui/spir/types'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'LanguageItem',
  props: {
    languageItem: {
      type: Object as PropType<LanguageSelectItem>,
      required: true
    },
    isShorten: {
      type: Boolean,
      default: false
    },
    isShowIcon: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const { languageItem, isShorten } = toRefs(props)
    return {
      languageLabel: computed(() => (isShorten.value ? languageItem.value.shortenLabel : languageItem.value.label))
    }
  }
})
</script>
<style scoped lang="scss"></style>
