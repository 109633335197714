import { getCurrentInstance } from '@vue/composition-api'
import { IVueI18n } from 'vue-i18n'

export const useTranslation = (): IVueI18n => {
  const inst = getCurrentInstance()
  if (inst) {
    return inst.proxy.$i18n as IVueI18n
  }
  // @ts-expect-error TS2322
  return undefined
}
