import { Stripe, StripeCardElement, StripeCardElementChangeEvent, loadStripe } from '@stripe/stripe-js'

export type StripeLike = {
  elements: Partial<Stripe['elements']>
  confirmCardSetup: Stripe['confirmCardSetup']
}
export type StripeLoader<T extends StripeLike = Stripe> = (params: ThisParameterType<typeof loadStripe>) => Promise<T>

export type SpirStripe = {
  mount: (data: { selector: string }) => void
  onChange: (cb: (event: StripeCardElementChangeEvent) => void) => void
  confirmSetup: (data: { clientSecret: string }) => ReturnType<Stripe['confirmCardSetup']>
}

export type SpirStripeLoader = () => Promise<SpirStripe>

export class SpirStripePayment implements SpirStripe {
  #stripe: Stripe
  #cardElement: StripeCardElement

  static async load({ loader }: { loader: StripeLoader }): Promise<SpirStripePayment> {
    const stripe = await loader(process.env.VUE_APP_STRIPE_API_PUBLIC_KEY)
    const elements = stripe.elements()
    return new SpirStripePayment(stripe, elements.create('card', { hidePostalCode: true }))
  }

  constructor(stripe: Stripe, cardElement: StripeCardElement) {
    this.#stripe = stripe
    this.#cardElement = cardElement
  }

  mount({ selector }: { selector: string }) {
    this.#cardElement.mount(selector)
  }
  onChange(cb: (event: StripeCardElementChangeEvent) => void) {
    this.#cardElement.on('change', cb)
  }
  confirmSetup({ clientSecret }: { clientSecret: string }): ReturnType<Stripe['confirmCardSetup']> {
    return this.#stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: this.#cardElement
      }
    })
  }
}
