import { BNoticeComponent, BNoticeConfig } from 'buefy/types/components'
import { useBuefy } from './useBuefy'

type ToastArgs = { message: string; duration?: number; indefinite?: boolean }

type ReturnTypes = {
  openPrimaryTopToast: (data: ToastArgs) => void
  openPrimaryBottomToast: (data: ToastArgs) => void
  openDangerBottomToast: ({ message, duration }: ToastArgs) => void
  openDangerTopToast: (data: ToastArgs) => void
  openSuccessTopToast: (data: ToastArgs) => void
  openSuccessBottomToast: (data: ToastArgs) => BNoticeComponent
}

export const useToast = (): ReturnTypes => {
  const buefy = useBuefy()
  function openToast(config: BNoticeConfig | string): BNoticeComponent {
    return buefy.toast.open(config)
  }

  function openPrimaryTopToast({ message, duration = 2000 }: ToastArgs): void {
    openToast({
      type: 'is-primary',
      position: 'is-top',
      message: message,
      duration: duration
    })
  }

  function openPrimaryBottomToast({ message, duration = 2000 }: ToastArgs): void {
    openToast({
      type: 'is-primary',
      position: 'is-bottom',
      message: message,
      duration: duration
    })
  }

  function openDangerBottomToast({ message, duration = 2000 }: ToastArgs): void {
    openToast({
      type: 'is-danger',
      position: 'is-bottom',
      message: message,
      duration: duration
    })
  }

  function openDangerTopToast(data: ToastArgs): void {
    openToast({
      type: 'is-danger',
      position: 'is-top',
      message: data.message
    })
  }

  function openSuccessTopToast({ message, duration = 2000 }: ToastArgs): void {
    openToast({
      type: 'is-success',
      position: 'is-top',
      message,
      duration
    })
  }

  function openSuccessBottomToast({ message, duration = 2000 }: ToastArgs): BNoticeComponent {
    return openToast({
      type: 'is-success',
      position: 'is-bottom',
      message,
      duration
    })
  }

  return {
    openPrimaryTopToast,
    openPrimaryBottomToast,
    openDangerBottomToast,
    openDangerTopToast,
    openSuccessTopToast,
    openSuccessBottomToast
  }
}
