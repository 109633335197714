<template>
  <div class="event-modal-footer-frame">
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EventModalFooterFrame'
})
</script>

<style lang="scss">
.spir-modal.modal-card .modal-card-body div.modal-footer:has(.event-modal-footer-frame) {
  padding: 0px;
  box-shadow: none;
  display: block;
  flex-direction: none;
  flex: none;
}
</style>

<style lang="scss" scoped>
.event-modal-footer-frame {
  border-top: 1px solid $spir2_border;
  display: flex;
  flex-direction: column;
  padding: 0px 16px 16px;
}
</style>
