<template>
  <p class="tip-mark-paragraph">
    <slot />
  </p>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({})
</script>

<style lang="scss">
.tip-mark-paragraph {
  @include inter_font_setting($font_size: 12px, $line_height: 130%, $letter_spacing: normal);
}
</style>
