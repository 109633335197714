<template>
  <div @click="eventClicked" class="allday-event" :style="eventStyle">
    <span>{{ event.title }}</span>
  </div>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { FullCalendarEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AllDayEvent extends Vue {
  // @ts-expect-error TS2564
  @Prop() event: FullCalendarEvent

  get eventStyle() {
    return {
      backgroundColor: this.event.backgroundColor,
      color: this.event.textColor || '#ffffff'
    }
  }
  get amIActive() {
    return this.event.extendedProps && this.event.extendedProps.source === 'editingEvent'
  }
  eventClicked(e) {
    if (!this.amIActive) {
      EventBus.emit(EVENTS.CLICKED_EVENT, { event: this.event })
    }
    e.stopPropagation()
    return false
  }
}
</script>

<style scoped lang="scss">
.allday-event {
  width: 100%;
  background-color: brown;
  font-size: 12px;
  padding: 2px;
  border-radius: 5px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
