import { SpirUser } from '@/types'
import { OrganizerInfo } from '../../../types/schedule'

export const FromOrganizerInfo = {
  convertToSpirUser(info: OrganizerInfo, overrideOption?: Partial<SpirUser>): SpirUser {
    return {
      // @ts-expect-error TS2322
      id: overrideOption?.id || info.userId,
      fullName: overrideOption?.fullName || info?.fullName,
      // @ts-expect-error TS2322
      name: overrideOption?.name || info?.fullName,
      // @ts-expect-error TS2322
      photoURL: overrideOption?.photoURL || info.photoURL
    }
  }
}
