<template>
  <div class="timezone-input-with-full-label">
    <div class="timezone-input-with-full-label__field" :class="{ updateHeight: updateHeight }">
      <label class="timezone-category label">{{ title }}</label>
      <TimezoneCommon
        :timeZone="timeZone"
        @activeChanged="handleActiveChanged"
        @onChangeTimeZone="handleChangeTimeZone"
      >
        <template v-slot:selectorLabel>
          <TimezoneLabel class="input timezone-label-wrapper" :timezoneInfo="selectedTimeZone" />
        </template>
      </TimezoneCommon>
    </div>
    <div class="help">
      <span>{{ selectedTimeZone ? selectedTimeZone.subLabel : '' }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import TimeZoneModule, { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TimezoneLabel from '../../features/timezones/TimezoneLabel/index.vue'
import TimezoneCommon from './Common.vue'

@Component({
  components: {
    TimezoneCommon,
    TimezoneLabel
  }
})
export default class FullTimezoneSelector extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: '' }) timeZone: string
  @Prop({ default: false }) updateHeightWhenDropdownIsActive
  @Prop() title
  isDropdownActive = false

  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }
  get selectedTimeZone(): TimezoneDetailWithTimeLabel {
    return TimeZoneModule.timezoneByKey({ key: this.timeZone })
  }

  handleChangeTimeZone(key: string) {
    this.$emit('onChangeTimeZone', key)
  }
  handleActiveChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
  }
}
</script>

<style scoped lang="scss">
.timezone-input-with-full-label {
  .timezone-category.label {
    margin-bottom: 8px;
    color: $spir2_black;

    @include inter_font_setting($font_size: 12px, $line_height: 18px, $letter_spacing: 0.3px);
  }
  &__field {
    transition: min-height 0.15s ease-out;
    &.updateHeight {
      min-height: 250px;
      transition: min-height 0.25s ease-in;
    }
  }
  .timezone-label-wrapper {
    display: block;
  }
  .help {
    color: $spir2_gray;
  }
}
</style>
