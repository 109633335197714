<template>
  <div class="register-button-field" :class="{ updateHeight: updateHeight }">
    <b-dropdown
      ref="dropdown"
      expanded
      :mobile-modal="false"
      :scrollable="true"
      :max-height="250"
      :canClose="false"
      @active-change="activeChanged"
    >
      <template #trigger="{ active }">
        <span v-if="!active" class="register-button">{{ $t('message.confirm.useOtherCalendar') }}</span>
      </template>
      <div v-if="getIsDropdownActive">
        <OtherCalendarPullDownForm
          :ignoreEmails="ignoreEmails"
          :confirmBtn="$t('message.confirm.otherCalendar.add')"
          @close="toggle"
          @confirm="handleConfirm"
        />
      </div>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import OtherCalendarPullDownForm from './OtherCalendarPullDownForm.vue'

@Component({
  components: {
    OtherCalendarPullDownForm
  }
})
export default class OtherCalendarRegistButton extends Vue {
  @Prop({ default: true }) updateHeightWhenDropdownIsActive
  @Prop({ default: () => [] }) ignoreEmails?: string[]

  isDropdownActive = false

  get getIsDropdownActive() {
    return this.isDropdownActive
  }
  get updateHeight() {
    return this.updateHeightWhenDropdownIsActive && this.isDropdownActive
  }

  toggle() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropdownRef = this.$refs.dropdown as any
    dropdownRef.toggle()
  }
  activeChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
    this.$emit('active-change', dropdownStatus)
  }
  handleConfirm(email: string) {
    this.$emit('confirm', email)
  }
}
</script>

<style scoped lang="scss">
.register-button-field {
  &::v-deep {
    .dropdown-content {
      box-shadow: none;
      background-color: $spir2_bg;
    }
  }
}
</style>
