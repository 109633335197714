import { frontSupportLanguages } from '@/types'
import VCalendar from 'v-calendar'
import Vue from 'vue'

Vue.use(VCalendar, {
  locales: {
    [frontSupportLanguages.ja]: {
      masks: {
        title: `YYYY年 MMM` // 言語によって不要だったりするのでi18nに寄せにくい。
      }
    },
    [frontSupportLanguages.en]: {
      masks: {
        title: `MMM YYYY`
      }
    }
  }
})
