<template>
  <div class="all-event-wrapper">
    <AllDayEvent v-for="event of allDayEvents" :key="event.id" :event="event" />
  </div>
</template>

<script lang="ts">
import { FullCalendarEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AllDayEvent from './AllDayEvent.vue'

@Component({
  components: {
    AllDayEvent
  }
})
export default class DailyCalendarAllDay extends Vue {
  // @ts-expect-error TS2564
  @Prop() allDayEvents: FullCalendarEvent[]
}
</script>

<style scoped>
.all-event-wrapper {
  padding-right: 12px;
  width: 100%;
}
</style>
