<template>
  <div class="field has-addons field-location">
    <div class="field-content">
      <EditorContent :editor="formattedSelectedLocationEditor" class="location-name" />
    </div>
    <div class="button-frame">
      <MapOpenButton :location="location" :mapOpenButtonType="mapOpenButtonType" :disabled="!showMapBtn" />
    </div>
  </div>
</template>

<script lang="ts">
import MapOpenButton from '@/components/ui/layouts/MapOpenButton/index.vue'
import { text2link } from '@/lib/utils'
import { Editor, EditorContent } from 'tiptap'
import { Link } from 'tiptap-extensions'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    EditorContent,
    MapOpenButton
  }
})
export default class LocationRead extends Vue {
  // @ts-expect-error TS2564
  @Prop() location: string
  // @ts-expect-error TS2564
  @Prop({ default: 'text' }) mapOpenButtonType: 'text' | 'icon'

  get isMapOpenButtonTextType(): boolean {
    return this.mapOpenButtonType === 'text'
  }

  get showMapBtn() {
    return this.formattedSelectedLocationEditor.getHTML().indexOf('<a') === -1
  }

  get formattedSelectedLocationEditor() {
    return new Editor({
      content: text2link(this.location),
      extensions: [new Link()],
      editable: false
    })
  }
}
</script>
<style scoped lang="scss">
.field-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 8px;
  box-shadow: none;
  .field-content {
    flex: 1;
    .location-name {
      color: $spir2_black;
      overflow: hidden;
      flex-grow: 0;
      padding-right: 3px;
      width: calc(100% - 52px);
      white-space: nowrap;
    }
  }
}
</style>
