<template>
  <div
    class="daily-view-calendar-body daily-view-calendar-column"
    ref="dailyViewCalendarBody"
    v-touch:moving="doDrag"
    @touchmove="disableScroll"
    @mousemove="doDrag"
  >
    <FullWidthEvent
      v-if="newEvent"
      :oneSlotPixel="oneSlotPixel"
      :parentWidth="mySize.width"
      :parentHeight="mySize.height"
      :event="newEvent"
    />
    <template v-if="fullWidthEvents && fullWidthEvents.length > 0">
      <FullWidthEvent
        v-for="(event, i) in fullWidthEvents"
        :key="i"
        :draggable="true"
        :oneSlotPixel="oneSlotPixel"
        :parentWidth="mySize.width"
        :parentHeight="mySize.height"
        :event="event"
      />
    </template>

    <Events
      :oneSlotPixel="oneSlotPixel"
      :events="eventsForDisplay"
      :parentHeight="mySize.height"
      :parentWidth="mySize.width"
    />
    <div class="daily-view-time-axis">
      <TimeGrid
        class="daily-view-time-axis--item"
        v-for="(n, i) in 24"
        :key="n"
        :index="i"
        :gridHeight="gridHeight"
        :withBorder="true"
        @mousedown="startDrag"
      ></TimeGrid>
    </div>
  </div>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { ResourceColumn, ResourceEvent } from '@/types'
import { DEFAULT_SLOT_DURATION } from '@/types/constants'
import { addMinutes, differenceInMinutes } from 'date-fns'
import elementResizeDetectorMaker from 'element-resize-detector'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Events from './Events.vue'
import { EventPosition, EventsForCalendar } from './EventTree'
import FullWidthEvent from './FullWidthEvent.vue'
import TimeGrid from './TimeGrid.vue'

const erd = elementResizeDetectorMaker()

@Component({
  components: {
    TimeGrid,
    Events,
    FullWidthEvent
  }
})
export default class DailyViewCalendarColumn extends Vue {
  // @ts-expect-error TS2564
  @Prop() gridHeight: string
  // @ts-expect-error TS2564
  @Prop() events: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop() columnsLength: number
  // @ts-expect-error TS2564
  @Prop() eventDate: Date
  @Prop() column: ResourceColumn
  // @ts-expect-error TS2564
  @Prop() selectable: boolean
  // @ts-expect-error TS2564
  @Prop() fullWidthEvents: ResourceEvent[]

  // @ts-expect-error TS2322
  eventTree: EventsForCalendar = null
  eventsForDisplay: EventPosition[] = []

  // for draging
  newEvent: {
    initTime: Date
    start: Date
    end: Date
    dragStartY: number
  } | null = null

  mySize: { height: number; width: number } = { height: 0, width: 0 }
  @Watch('events')
  resetEventTree() {
    this.eventTree = new EventsForCalendar(this.events)
    this.eventsForDisplay = this.eventTree.eventArrayForCalendar()
  }
  @Watch('columnsLength')
  resetMySize() {
    this.setSize()
  }
  get oneHourSlot() {
    return 60 / DEFAULT_SLOT_DURATION
  }
  get isClickable(): boolean {
    return !!this.column.writable
  }
  get calendarHeightPixel(): number {
    return Number(this.gridHeight) * 24
  }
  get oneSlotPixel(): number {
    return Number(this.gridHeight) / this.oneHourSlot
  }
  setSize() {
    this.$nextTick(() => {
      const calendarBody = this.$refs.dailyViewCalendarBody as HTMLElement
      this.mySize = {
        height: calendarBody.scrollHeight,
        width: calendarBody.clientWidth
      }
    })
  }
  mounted() {
    this.setSize()
    window.addEventListener('mouseup', this.stopDrag)
    window.addEventListener('touchend', this.stopDrag)
    const calendarBody = this.$refs.dailyViewCalendarBody as HTMLElement
    erd.listenTo(calendarBody, this.setSize)
    this.eventTree = new EventsForCalendar(this.events)
    this.eventsForDisplay = this.eventTree.eventArrayForCalendar()
  }

  beforeDestroy() {
    window.removeEventListener('mouseup', this.stopDrag)
    window.removeEventListener('touchend', this.stopDrag)
    const calendarBody = this.$refs.dailyViewCalendarBody as HTMLElement
    erd.removeListener(calendarBody, this.setSize)
  }
  // For Drag
  /*
    index: hour
  */
  startDrag(event, index) {
    if (!this.selectable) {
      return
    }
    if (!this.isClickable) {
      this.$buefy.toast.open({
        type: 'is-danger',
        position: 'is-bottom',
        message: `${this.$t('message.notAllowedCalendar')}`
      })
      return
    }
    const offset = index * this.oneHourSlot * this.oneSlotPixel
    const startPositionBy30Min = Math.floor((offset + event.offsetY) / this.oneSlotPixel)
    const startTime = addMinutes(this.eventDate, startPositionBy30Min * DEFAULT_SLOT_DURATION)
    this.newEvent = {
      start: startTime,
      initTime: startTime,
      end: addMinutes(startTime, DEFAULT_SLOT_DURATION),
      dragStartY: event.clientY
    }
  }
  doDrag(e) {
    if (!this.newEvent) {
      return
    }
    let clientY = 0
    if (e.type === 'touchmove') {
      clientY = e.touches[0].clientY
    } else {
      clientY = e.clientY
    }
    const dragOffset = clientY - this.newEvent.dragStartY
    const offsetMin = Math.round(dragOffset / this.oneSlotPixel) * DEFAULT_SLOT_DURATION
    if (offsetMin === 0) {
      return
    }
    if (offsetMin < 0) {
      this.newEvent.start = addMinutes(this.newEvent.initTime, offsetMin)
      this.newEvent.end = this.newEvent.initTime
    } else {
      this.newEvent.start = this.newEvent.initTime
      this.newEvent.end = addMinutes(this.newEvent.initTime, offsetMin)
    }
    e.stopPropagation()
    e.preventDefault()
    return false
  }
  disableScroll(e) {
    if (!this.newEvent) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
  }
  stopDrag(e) {
    if (!this.eventTree || !this.newEvent || !this.newEvent.end || !this.newEvent.start) {
      this.newEvent = null
      return
    }
    if (differenceInMinutes(this.newEvent.end, this.newEvent.start) < DEFAULT_SLOT_DURATION) {
      this.newEvent = null
      return
    }
    const payload = {
      columnKey: this.column.columnKey,
      start: this.newEvent.start,
      end: this.newEvent.end,
      jsEvent: e
    }
    EventBus.emit(EVENTS.CREATE_EVENT, payload)
    this.newEvent = null
  }
}
</script>

<style scoped lang="scss">
.daily-view-calendar-body {
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
  .daily-view-event {
    position: absolute;
    top: 20px;
    height: 30px;
    border: 1px solid red;
  }
  .daily-view-time-axis {
    display: flex;
    flex-direction: column;
    .daily-view-time-axis--item {
      flex-grow: 1;
    }
  }
}
</style>
