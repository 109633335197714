import { AvailabilitySharingGuestUserConfirmer } from '@/models/availabilitySharing'
import { GuestDecisionMaker } from '@/models/decisionMaker'
import { ScheduleGuestUserConfirmer } from '@/models/schedule'
import { FrontSupportLanguage } from '@/types'
import { ConfirmerType } from './confirmer'
export class GuestConfirmer implements ConfirmerType {
  readonly type: 'guest' = 'guest' as const
  readonly email: string
  readonly name: string
  readonly timeZone: string
  readonly language: FrontSupportLanguage

  constructor({
    email,
    name,
    timeZone,
    language
  }: {
    email: string
    name: string
    timeZone: string
    language: FrontSupportLanguage
  }) {
    this.email = email
    this.name = name
    this.timeZone = timeZone
    this.language = language
  }

  get isSpirUser(): boolean {
    return false
  }

  getLanguage() {
    return this.language
  }

  getEmail() {
    return this.email
  }

  getName() {
    return this.name
  }

  toScheduleConfirmer(): ScheduleGuestUserConfirmer {
    return this.toDecisionMaker()
  }

  toAvailabilitySharingConfirmer(): AvailabilitySharingGuestUserConfirmer {
    return this.toDecisionMaker()
  }

  toDecisionMaker(): GuestDecisionMaker {
    return {
      type: 'guest',
      email: this.getEmail(),
      name: this.getName(),
      timezone: { key: this.timeZone },
      language: this.getLanguage()
    }
  }
}
