import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { isSameDayByUserTimeZone } from '@/lib/utils/timezone'
import { FullCalendarEvent } from '@/types'

export default function (candidates: FullCalendarEvent[]): string {
  if (candidates.length === 0) {
    return ''
  }
  const firstDate = candidates[0].start
  const lastDate = candidates[candidates.length - 1].end
  if (isSameDayByUserTimeZone(firstDate, lastDate)) {
    return `${spirDateFormat(firstDate, spirDateFormatTypes.mdWeekday)}`
  }
  return `${spirDateFormat(firstDate, spirDateFormatTypes.mdWeekday)} - ${spirDateFormat(
    lastDate,
    spirDateFormatTypes.mdWeekday
  )}`
}
