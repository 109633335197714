<template>
  <hr class="divider" :class="{ dark: darkness === 'dark' }" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Divider extends Vue {
  // TODO: layoutで縦ボーダーか横ボーダーか切り替えられるようにする
  // @ts-expect-error TS2564
  @Prop({ default: 'horizontal' }) layout: 'horizontal' | 'vertical'
  // @ts-expect-error TS2564
  @Prop({ default: 'normal' }) darkness: 'normal' | 'dark'
}
</script>

<style scoped lang="scss">
.divider {
  height: 1px;
  margin: 0px;
  background-color: $spir2_border;
}
</style>
