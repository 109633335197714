<template>
  <div class="event-modal-footer-actions">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EventModalFooterActionsFrame'
})
</script>

<style lang="scss" scoped>
.event-modal-footer-actions {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 12px;
  & > button.button:nth-of-type(n + 2) {
    margin: 0;
  }
  &:first-child:not(:last-child) {
    padding-top: 16px;
  }
  &:first-child:last-child {
    padding-top: 16px;
  }
  &:last-child {
    padding-top: 0px;
  }
}
</style>
