<template>
  <div
    class="app-updated-available-snackbar-container"
    :class="[{ 'is-mobile': isMobile, 'is-hight-position': isPageWithActionButton }, translateClass]"
  ></div>
</template>

<script lang="ts">
import { useBuefy } from '@/composables/useBuefy'
import { useTranslation } from '@/composables/useTranslation'
import { setupAppUpdateAvailableHandler } from '@/lib/setupServiceWorkerUpdate'
import { isMobile } from '@/lib/utils'
import { AllRouteNames } from '@/router'
import { computed, defineComponent } from '@vue/composition-api'
import { useRoute } from 'vue2-helpers/vue-router'

export default defineComponent({
  name: 'AppUpdateAvailableSnackbar',
  setup() {
    const i18n = useTranslation()
    const buefy = useBuefy()
    const route = useRoute()

    setupAppUpdateAvailableHandler(updateApp => {
      buefy.snackbar.open({
        container: '.app-updated-available-snackbar-container',
        message: i18n.t('app.updateAvailableMessage').toString(),
        position: 'is-bottom',
        indefinite: true,
        actionText: i18n.t('app.update').toString(),
        onAction: () => updateApp()
      })
    })

    const isPageWithActionButton = computed(() => {
      const pagesWithActionButton = [AllRouteNames.ArrangementsOnCalendar]
      return pagesWithActionButton.some(routeName => routeName === route.name)
    })
    const translateClass = computed(() => {
      const fabRegisteredPages = [
        AllRouteNames.PersonalHome,
        AllRouteNames.PersonalCalendar,
        AllRouteNames.PersonalUnconfirmedList,
        AllRouteNames.TeamHome,
        AllRouteNames.TeamCalendar,
        AllRouteNames.TeamUnconfirmedList,
        AllRouteNames.TeamForms
      ]
      const fab = fabRegisteredPages.some(routeName => routeName === route.name)
      if (fab) return 'fab'
      const eventFormPages = [AllRouteNames.PersonalArrangementCreate, AllRouteNames.TeamArrangementCreate]
      const eventForm = eventFormPages.some(routeName => routeName === route.name)
      return eventForm ? 'event-form' : ''
    })

    return {
      isMobile: isMobile(),
      isPageWithActionButton,
      translateClass
    }
  }
})
</script>

<style scoped lang="scss">
.app-updated-available-snackbar-container.is-mobile::v-deep .notices {
  // snackbarはmodalなどよりは下層に配置
  z-index: 20;
}

.app-updated-available-snackbar-container::v-deep .notices .snackbar {
  min-width: 375px;
  border-radius: 8px;
  > .text {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
  }
  > .action {
    order: 1;
    padding: 8px 8px 8px 0;
    margin-left: auto;
  }
}

.app-updated-available-snackbar-container::v-deep .notices .snackbar .action .button {
  height: 24px;
  padding: 3px 8px;
  background-color: $spir2_white;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
}

.app-updated-available-snackbar-container.is-mobile::v-deep .notices .snackbar {
  margin: 0 auto 73px;
  min-width: 343px;
  width: calc(100vw - 32px);
}
.app-updated-available-snackbar-container.is-mobile.is-hight-position::v-deep .notices .snackbar {
  margin-bottom: 128px;
}
.app-updated-available-snackbar-container.is-mobile.fab::v-deep .notices .snackbar {
  margin-bottom: calc(var(--bottom-nav-height) + var(--fab-size) + 32px);
}
.app-updated-available-snackbar-container.is-mobile.event-form::v-deep .notices .snackbar {
  margin-bottom: 212px; // (148 + 48 + 16px) 簡易フォームと予定モードの切り替えボタンの高さ
}
</style>
