<template>
  <ConfirmModal :header="this.$t('message.confirm.title')" @close="$emit('close')" @confirm="$emit('confirm')">
    <div class="confirm-item">
      {{ voteCount }}
    </div>
    <div class="confirm-item">
      <label class="label">{{ $t('labels.dateTime') }}</label>
      <span>{{ showDateRange }}</span>
    </div>
  </ConfirmModal>
</template>

<script lang="ts">
import { CandidateForVote } from '@/store/modules/vote'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ConfirmModal from './Confirm.vue'

@Component({
  components: {
    ConfirmModal
  }
})
export default class PollConfirm extends Vue {
  // @ts-expect-error TS2564
  @Prop() item: CandidateForVote

  get voteCount() {
    return this.$t('message.confirm.confirmWithPollCount', { yesCount: this.item.yesCount, noCount: this.item.noCount })
  }
  get showDateRange() {
    // @ts-expect-error TS2532
    return this.$options.filters['oneDateRange']({ startDate: this.item.start, endDate: this.item.end })
  }
}
</script>

<style scoped lang="scss">
.confirm-item {
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.4px;
    color: $spir2_gray;
  }
}
</style>
