import { AuthAddCalendarModal } from '@/components/features/auth/AuthAddCalendarModal'
import ConfirmModal from '@/components/modal/Confirm.vue'
import ConfirmDeleteModal from '@/components/modal/ConfirmDelete.vue'
import EventFormModal from '@/components/modal/EventForm.vue'
import OnlineMeetingToolInfoModal from '@/components/modal/OnlineMeetingToolInfo.vue'
import PollConfirmModal from '@/components/modal/PollComfirm.vue'
import ScheduleDetailModal from '@/components/modal/ScheduleDetailModal.vue'
import ConfirmAvailability from '@/components/modal/confirm/ConfirmAvailability.vue'
import ConfirmSchedule from '@/components/modal/confirm/ConfirmSchedule.vue'
import ShareTextModal from '@/components/modal/shareText/ShareTextModal.vue'
import TimeZoneKeySelectorModal from '@/components/modal/timezone/TimeZoneKeySelectorModal.vue'
import DayRangeSelectOnWeeklyView from '@/components/patterns/modals/DayRangeSelectOnWeeklyView.vue'
import AutoPeekCandidatesInfoModal from '@/components/ui/domain/modal/arrangement/AutoPeekCandidatesInfoModal.vue'
import { useToast } from '@/composables/useToast'
import i18n from '@/i18n'
import { holidayExclusion } from '@/lib/utils'
import {
  AvailabilitySharingCandidate,
  AvailabilitySharingConfirmer,
  AvailabilitySharingConfirmerAttendee
} from '@/models/availabilitySharing'
import { ConfirmationForm, ConfirmationFormData } from '@/models/confirmationForm'
import { Confirmer, PollModel, ScheduleModel, ScheduleModelTeam } from '@/models/data'
import { EventModel } from '@/models/data/event'
import { ScheduleConfirmer, ScheduleConfirmerAttendee } from '@/models/schedule'
import { AvailabilityConfirm, ResourceEvent } from '@/types'
import { FrontSupportCountryCode } from '@/types/frontSupportCountry'
import { DayOfTheWeekRules } from '@spirinc/contracts'
import { getCurrentInstance, ref } from '@vue/composition-api'
import { BModalComponent, BModalConfig } from 'buefy/types/components'
import { CandidateForVote } from './../store/modules/vote'

import { useBuefy } from './useBuefy'

type ModalFunctionArg<Arg, Return = void> = (data: Arg) => Return
type ModalConfig = Omit<BModalConfig, 'parent'>

type ReturnTypes = {
  openModal: ModalFunctionArg<ModalConfig, BModalComponent>
  openScheduleConfirmationModal: ModalFunctionArg<{ handleOpen: () => void }>
  openUnconfirmedDeleteModal: ModalFunctionArg<{ title: string; handleDelete: () => Promise<void> }>
  openScheduleShareModal: ModalFunctionArg<ScheduleModel | ScheduleModelTeam>
  openEventFormModal: ModalFunctionArg<{ currentEvent: EventModel }>
  openDiscardItemConfirmationModal: ModalFunctionArg<{ resolve: (result: boolean) => void }>
  openDeleteItemModal: ModalFunctionArg<{
    customTitle?: string
    customMessage?: string
    resolve: (result: boolean) => void
  }>
  // eslint-disable-next-line
  openSpecifiedBodyModal: ModalFunctionArg<{ BodyComponent: any; events; props: any }, BModalComponent>
  openDiscardConfirmationModal: ModalFunctionArg<{ confirm: () => void; cancel: () => void }>
  openRemoveHolidayExceptionConfirmationModal: ModalFunctionArg<{
    countryCode: FrontSupportCountryCode
    confirm: () => void
  }>
  openTargetCalendarToggleModal: ModalFunctionArg<{ props: { header: string; body: string }; confirm: () => void }>
  openTimeZoneKeySelectorModalModal: ModalFunctionArg<{
    props: { timezoneKey: string; isUpdateStore: boolean }
    confirm: (timezone: string) => void
  }>
  openOnlineMeetingToolInfoModalModal: ModalFunctionArg<{
    props: { accountName: string }
    onClickDisconnectionButton: () => void
  }>
  openSignInToZoomConfirmationModal: ModalFunctionArg<{ confirm: () => void }>
  openWeekdayConditionPickModal: ModalFunctionArg<{
    props: { dayOfTheWeekRules: DayOfTheWeekRules; calendarEvents: ResourceEvent[] }
    confirm: (dayOfTheWeekRules: DayOfTheWeekRules) => void
  }>
  openPollConfirmModal: ModalFunctionArg<{ candidateForVote: CandidateForVote; confirm: () => void }>
  openConfirmModalInPoll: ModalFunctionArg<{ title: string; body: string; confirm: () => void }>
  openSyncedDetailModal: ModalFunctionArg<{ model: ScheduleModelTeam | ScheduleModel | PollModel }>
  openArrangementTypeChangeConfirmModal: ModalFunctionArg<{ body?: string; confirm: () => void; cancel?: () => void }>
  openOverDurationCandidatesRemovingConfirmModal: ModalFunctionArg<{ confirm: () => void; cancel: () => void }>
  openAutoPeekCandidatesInfoModal: ModalFunctionArg<{
    toggle: (dontShowNextTimeChecked: boolean) => void
    close: () => void
  }>
  openAddCalendarModal(): void
  openRemoveCalendarModal(data: { customTitle: string; customMessage: string; onDeleteAccount: () => void })
  openAvailabilitySharingConfirmModal(data: {
    candidate: AvailabilitySharingCandidate
    confirmationForm?: ConfirmationForm
    cancelButtonTitle?: string
    confirm: (
      confirmer: AvailabilitySharingConfirmer,
      confirmerAttendees?: AvailabilitySharingConfirmerAttendee[],
      confirmationFormData?: ConfirmationFormData
    ) => void
  })
  openScheduleConfirmModal<T extends { start: Date; end: Date }>(data: {
    isOrganizer: boolean
    candidate: T
    cancelButtonTitle?: string
    confirm: (confirmer: ScheduleConfirmer, confirmerAttendees?: ScheduleConfirmerAttendee[]) => void
  })
}

export const useModal = (): ReturnTypes => {
  const currentInstance = getCurrentInstance()
  const buefy = useBuefy()
  const { openDangerTopToast } = useToast()

  function openModal(args: ModalConfig): BModalComponent {
    return buefy.modal.open({
      parent: currentInstance?.proxy,
      ...args
    })
  }
  function openScheduleConfirmationModal(data: { handleOpen: () => void }): void {
    openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        header: i18n.t('alternativeCandidates.confirmationModal.title').toString(),
        body: i18n.t('alternativeCandidates.confirmationModal.body').toString(),
        confirmBtn: i18n.t('buttons.showConfirmation').toString()
      },
      events: {
        confirm: data.handleOpen
      }
    })
  }

  function openUnconfirmedDeleteModal(data: { title: string; handleDelete: () => Promise<void> }): void {
    openModal({
      component: ConfirmDeleteModal,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props: {
        title: data.title
      },
      events: {
        confirm: data.handleDelete
      }
    })
  }

  function openRemoveCalendarModal(data: {
    customTitle: string
    customMessage: string
    onDeleteAccount: () => Promise<void>
  }) {
    const modal = openModal({
      component: ConfirmDeleteModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        customTitle: data.customTitle,
        customMessage: data.customMessage
      },
      events: {
        close: () => {
          modal.close()
        },
        confirm: () => {
          modal.close()
          data.onDeleteAccount()
        }
      }
    })
  }

  function openScheduleShareModal(schedule: ScheduleModel | ScheduleModelTeam): void {
    openModal({
      component: ShareTextModal,
      hasModalCard: true,
      canCancel: true,
      props: { schedule }
    })
  }

  function openEventFormModal(data: { currentEvent: EventModel }): void {
    openModal({
      component: EventFormModal,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props: {
        currentEvent: data.currentEvent,
        hideControls: true
      }
    })
  }

  function openDiscardItemConfirmationModal(data: { resolve: (result: boolean) => void }) {
    const modal = openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: i18n.t('message.confirmDiscard'),
        body: i18n.t('message.deleteOperationWarning'),
        confirmBtn: i18n.t('buttons.discard')
      },
      events: {
        confirm: async () => {
          data.resolve(true)
          modal.close()
        },
        cancel: () => data.resolve(false)
      }
    })
  }

  function openDeleteItemModal(data: {
    customTitle?: string
    customMessage?: string
    resolve: (result: boolean) => void
  }) {
    const modal = openModal({
      component: ConfirmDeleteModal,
      hasModalCard: true,
      props: {
        customMessage: data.customMessage,
        customTitle: data.customTitle
      },
      events: {
        confirm: async () => {
          data.resolve(true)
          modal.close()
        }
      }
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function openSpecifiedBodyModal(data: { BodyComponent: any; events; props: any }): BModalComponent {
    const modal = openModal({
      component: data.BodyComponent,
      hasModalCard: true,
      canCancel: ['escape', 'outside'],
      props: data.props,
      events: {
        ...data.events
      }
    })
    return modal
  }

  function openRemoveHolidayExceptionConfirmationModal(data: {
    countryCode: FrontSupportCountryCode
    confirm: () => void
  }): void {
    const whichCountryHoliday = holidayExclusion.getI18nCountryName(data.countryCode)
    const modal = openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: i18n.t('message.holidayExclusionDisconnect.title', {
          country: whichCountryHoliday
        }),
        body: i18n.t('message.holidayExclusionDisconnect.description'),
        confirmBtn: i18n.t('buttons.disconnect')
      },
      events: {
        close: () => {
          modal.close()
        },
        confirm: async () => {
          data.confirm()
          modal.close()
        }
      }
    })
  }

  function openDiscardConfirmationModal(data: { confirm: () => void; cancel: () => void }) {
    openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: i18n.t('message.confirmDiscard'),
        body: i18n.t('message.deleteOperationWarning'),
        confirmBtn: i18n.t('buttons.discard')
      },
      events: {
        cancel: data.cancel,
        confirm: () => {
          data.confirm()
        }
      }
    })
  }

  function openTargetCalendarToggleModal(data: { props: { header: string; body: string }; confirm: () => void }) {
    openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: data.props,
      events: {
        confirm: data.confirm
      }
    })
  }

  function openTimeZoneKeySelectorModalModal(data: {
    props: { timezoneKey: string; isUpdateStore: boolean }
    confirm: (timezone: string) => void
  }) {
    const modal = openModal({
      component: TimeZoneKeySelectorModal,
      hasModalCard: true,
      canCancel: true,
      props: data.props,
      events: {
        close: () => {
          modal.close()
        },
        updateTimezoneInfo: (timezone: string) => {
          data.confirm(timezone)
          modal.close()
        }
      }
    })
  }

  function openOnlineMeetingToolInfoModalModal(data: {
    props: { accountName: string }
    onClickDisconnectionButton: () => void
  }) {
    const modal = openModal({
      component: OnlineMeetingToolInfoModal,
      hasModalCard: true,
      canCancel: false,
      props: data.props,
      events: {
        onClickDisconnectionButton: async () => {
          await data.onClickDisconnectionButton()
          modal.close()
        }
      }
    })
  }

  function openSignInToZoomConfirmationModal(data: { confirm: () => void }) {
    const modal = openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: i18n.t('settings.integrations.signInToZoom'),
        body: i18n.t('settings.integrations.messageForSignInToZoom')
      },
      events: {
        close: () => {
          modal.close()
        },
        confirm: () => {
          data.confirm()
          modal.close()
        }
      }
    })
  }

  function openWeekdayConditionPickModal(data: {
    props: { dayOfTheWeekRules: DayOfTheWeekRules; calendarEvents: ResourceEvent[] }
    confirm: (dayOfTheWeekRules: DayOfTheWeekRules) => void
  }) {
    const modal = openModal({
      component: DayRangeSelectOnWeeklyView,
      hasModalCard: true,
      props: data.props,
      events: {
        confirm: (dayOfTheWeekRules: DayOfTheWeekRules) => {
          if (Object.keys(dayOfTheWeekRules).length === 0) {
            openDangerTopToast({ message: i18n.t('forms.condition.error.noRules').toString() })
          } else {
            data.confirm(dayOfTheWeekRules)
            modal.close()
          }
        }
      }
    })
  }

  function openPollConfirmModal(data: { candidateForVote: CandidateForVote; confirm: () => void }) {
    openModal({
      component: PollConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        item: data.candidateForVote
      },
      events: {
        confirm: data.confirm
      }
    })
  }
  function openConfirmModalInPoll(data: { title: string; body: string; confirm: () => void }) {
    openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        header: data.title,
        body: data.body
      },
      events: {
        confirm: data.confirm
      }
    })
  }
  function openSyncedDetailModal(data: { model: ScheduleModelTeam | ScheduleModel | PollModel }) {
    openModal({
      component: ScheduleDetailModal,
      hasModalCard: true,
      props: {
        schedule: data.model
      }
    })
  }
  function openArrangementTypeChangeConfirmModal(data: { body?: string; confirm: () => void; cancel?: () => void }) {
    openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        header: i18n.t('modals.arrangementTypeChangeConfirmation.title'),
        body: data.body || i18n.t('modals.arrangementTypeChangeConfirmation.body')
      },
      events: {
        confirm: data.confirm,
        // @ts-expect-error TS2322
        cancel: data.cancel
      }
    })
  }
  function openOverDurationCandidatesRemovingConfirmModal(data: { confirm: () => void; cancel: () => void }) {
    openModal({
      component: ConfirmModal,
      hasModalCard: true,
      canCancel: true,
      props: {
        header: i18n.t('message.removeOverDurationCandidate.header'),
        body: i18n.t('message.removeOverDurationCandidate.body')
      },
      events: {
        confirm: data.confirm,
        cancel: data.cancel
      }
    })
  }
  function openAutoPeekCandidatesInfoModal(data: {
    toggle: (dontShowNextTimeChecked: boolean) => void
    close: () => void
  }) {
    const checked = ref(false)
    openModal({
      component: AutoPeekCandidatesInfoModal,
      hasModalCard: true,
      canCancel: ['escape', 'x', 'outside'],
      onCancel: () => data.close(),
      props: {
        dontShowNextTimeChecked: checked.value,
        onDontShowNextTimeCheckToggle: () => {
          checked.value = !checked.value
          data.toggle(checked.value)
        }
      },
      events: {
        close: data.close
      }
    })
  }

  function openAddCalendarModal() {
    const modal = openModal({
      component: AuthAddCalendarModal,
      hasModalCard: true,
      canCancel: false,
      props: {
        showCloseButton: true,
        closeModal: () => {
          modal.close()
        }
      }
    })
  }

  function openAvailabilitySharingConfirmModal(data: {
    candidate: AvailabilitySharingCandidate
    confirmationForm?: ConfirmationForm
    cancelButtonTitle?: string
    confirm: (
      confirmer: AvailabilitySharingConfirmer,
      confirmerAttendees?: AvailabilitySharingConfirmerAttendee[],
      confirmationFormData?: ConfirmationFormData
    ) => void
  }) {
    openModal({
      component: ConfirmAvailability,
      hasModalCard: true,
      events: {
        confirm: (availabilityConfirm: AvailabilityConfirm, confirmer: Confirmer) => {
          const availabilitySharingConfirmer = confirmer.toAvailabilitySharingConfirmer()
          const availabilitySharingConfirmerAttendees = availabilityConfirm.attendees
          const confirmationFormData = availabilityConfirm.formData
          data.confirm(availabilitySharingConfirmer, availabilitySharingConfirmerAttendees, confirmationFormData)
        }
      },
      props: {
        candidateStartDate: data.candidate.start,
        candidateEndDate: data.candidate.end,
        confirmationForm: data.confirmationForm,
        cancelBtn: data.cancelButtonTitle
      }
    })
  }

  function openScheduleConfirmModal<T extends { start: Date; end: Date }>(data: {
    isOrganizer: boolean
    candidate: T
    cancelButtonTitle?: string
    confirm: (confirmer: ScheduleConfirmer, confirmerAttendees?: ScheduleConfirmerAttendee[]) => void
  }) {
    openModal({
      component: ConfirmSchedule,
      hasModalCard: true,
      events: {
        register: ({ confirmer, attendees }: { confirmer: Confirmer; attendees: ScheduleConfirmerAttendee[] }) => {
          const scheduleConfirmer = confirmer.toScheduleConfirmer()
          const scheduleConfirmerAttendees = attendees
          data.confirm(scheduleConfirmer, scheduleConfirmerAttendees)
        }
      },
      props: {
        isOrganizer: data.isOrganizer,
        candidateStartDate: data.candidate.start,
        candidateEndDate: data.candidate.end,
        cancelBtn: data.cancelButtonTitle
      }
    })
  }
  return {
    openModal,
    openScheduleConfirmationModal,
    openUnconfirmedDeleteModal,
    openScheduleShareModal,
    openEventFormModal,
    openDiscardItemConfirmationModal,
    openDeleteItemModal,
    openSpecifiedBodyModal,
    openRemoveHolidayExceptionConfirmationModal,
    openDiscardConfirmationModal,
    openTargetCalendarToggleModal,
    openTimeZoneKeySelectorModalModal,
    openOnlineMeetingToolInfoModalModal,
    openSignInToZoomConfirmationModal,
    openWeekdayConditionPickModal,
    openPollConfirmModal,
    openConfirmModalInPoll,
    openSyncedDetailModal,
    openArrangementTypeChangeConfirmModal,
    openOverDurationCandidatesRemovingConfirmModal,
    openAutoPeekCandidatesInfoModal,
    openAddCalendarModal,
    openRemoveCalendarModal,
    openAvailabilitySharingConfirmModal,
    openScheduleConfirmModal
  }
}
