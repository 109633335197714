<template>
  <b-loading :is-full-page="fullPage" :active="active" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

// const transaction = Sentry.startTransaction({ name: "show-loader" });
// const span = transaction.startChild({ op: "functionX" });
@Component
export default class LoadingSpinner extends Vue {
  // @ts-expect-error TS2564
  @Prop() active: boolean
  // @ts-expect-error TS2564
  @Prop() fullPage: boolean
  // @ts-expect-error TS2564
  @Prop() loaderId: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) disableTracing: boolean
}
</script>

<style scoped></style>
