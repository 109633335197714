import Vue from 'vue'
import candidateDates from './candidateDates'
import dateSpan from './dateSpan'
import oneDateRange from './oneDateRange'
import showDurationOfCandidates from './showDurationOfCandidates'

Vue.filter('candidateDates', candidateDates)
Vue.filter('oneDateRange', oneDateRange)
Vue.filter('showDurationOfCandidates', showDurationOfCandidates)
Vue.filter('dateSpan', dateSpan)
