import { findOrganizerAndAttendees } from '@/lib/utils/converters/fromPriorityGroups'
import { Attendee } from '@/types'
import {
  OnlineMeeting,
  PrioritizedOnlineMeetings,
  PublicAvailabilitySharingsOverviewGetResponse,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse
} from '@spirinc/contracts'
import { QuestionId } from '../questionnaire'
import { ConfirmationForm } from './availability'
import { decodeAttendee } from './availabilityForConfirm'

/**
 * @deprecated
 * use src/models/availabilitySharing.ts/PreConfirmAvailabilitySharing
 */
export class AvailabilityOverviewModel {
  title: string
  duration: number
  onlineMeeting: OnlineMeeting
  location?: string
  description?: string
  start: string
  end: string
  organizer?: Attendee
  attendees: Attendee[]
  candidateDescription?: string
  timeZone: string
  confirmationForm?: ConfirmationForm
  startTimeIncrements: number
  constructor(data: PublicAvailabilitySharingsOverviewGetResponse) {
    this.title = data.title
    this.duration = data.duration
    this.onlineMeeting = data.onlineMeeting
    this.location = data.location
    this.description = data.description
    this.start = data.start
    this.end = data.end
    if (data.organizer) {
      this.organizer = decodeAttendee(data.organizer)
    }
    if (data.attendees && data.attendees.length > 0) {
      this.attendees = data.attendees.map(a => decodeAttendee(a))
    } else {
      this.attendees = []
    }
    this.timeZone = data.timeZone
    this.candidateDescription = data.candidateDescription
    this.confirmationForm = data.confirmationForm
    this.startTimeIncrements = data.startTimeIncrements
  }
}

/**
 * @deprecated
 * use src/models/teamAvailabilitySharing.ts/PreConfirmTeamAvailabilitySharingOverview
 */
export class AvailabilityOverviewModelForTeam {
  title: string
  duration: number
  location?: string
  description?: string
  start: string
  end: string
  organizer?: Attendee
  attendees: Attendee[]
  candidateDescription?: string
  timeZone: string
  prioritizedOnlineMeetings: PrioritizedOnlineMeetings
  confirmationForm?: ConfirmationForm
  startTimeIncrements: number
  questionnaireId?: QuestionId
  inviteCalendar: boolean

  constructor(data: PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdOverviewGetResponse) {
    this.title = data.title
    this.duration = data.duration
    this.location = data.location
    this.description = data.description
    this.start = data.start
    this.end = data.end
    const { organizer, attendees } = findOrganizerAndAttendees({
      priorityGroups: data.priorityGroups,
      potentialAttendingMembers: data.potentialAttendingMembers
    })
    if (organizer) {
      this.organizer = decodeAttendee(organizer)
    }
    this.attendees = attendees.map(a => decodeAttendee(a))
    this.timeZone = data.timeZone
    this.candidateDescription = data.candidateDescription
    this.confirmationForm = data.confirmationForm
    this.prioritizedOnlineMeetings = data.prioritizedOnlineMeetings
    this.startTimeIncrements = data.startTimeIncrements
    this.questionnaireId = data.formId
    this.inviteCalendar = data.inviteCalendar
  }
}
