import { Mergeable } from './mergeable'

export type CandidateByCondition = {
  end: string
  start: string
}
export class PatternCandidateModel extends Mergeable {
  count: number
  start: string
  end: string

  constructor(data: { count: number; start: string; end: string; candidates: CandidateByCondition[] }) {
    super(data.candidates)
    this.count = data.count
    this.start = data.start
    this.end = data.end
  }
}
