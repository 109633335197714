export default {
  google: {
    scope:
      'profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly',
    prompt: 'consent'
  },
  microsoft: {
    client_id: process.env.VUE_APP_MICROSOFT_CLIENT_ID,
    response_type: 'code',
    response_mode: 'query',
    prompt: 'select_account',
    scope: ['offline_access', 'user.read', 'calendars.readWrite'].join(' '),
    redirect_uri: `${window.location.origin}/callback-from-ms`
  },
  phrase: {
    projectId: '843fb1a4e515a03414bb4b4cc0c61d44'
  },
  integrations: {
    slack: {
      scope: ['channels:read', 'groups:read', 'chat:write', 'chat:write.public', 'team:read'],
      redirectUri: `${window.location.origin}/callbacks/slack`,
      clientId: process.env.VUE_APP_SLACK_CLIENT_ID
    }
  }
}
