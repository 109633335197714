<template>
  <pre class="introduction">{{ profile.introduction }}</pre>
</template>

<script lang="ts">
import { IProfileBasic } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProfileBasicViewer extends Vue {
  @Prop({ default: {} })
  // @ts-expect-error TS2564
  profile: IProfileBasic
}
</script>
<style lang="scss">
.modal {
  .introduction {
    max-height: 400px;
  }
}
</style>
<style scoped lang="scss">
.introduction {
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: none;
  padding: 0.5rem;
  font-size: 1rem;
  color: $spir2_black;
}
</style>
