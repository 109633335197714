import { TeamModule } from '@/store/modules/team'
import type { NavigationGuardNext } from 'vue-router'
import { AllRouteNames } from './route-names'

export async function redirectToFirstWorkspace(next: NavigationGuardNext) {
  const teams = TeamModule.myLoadedUsableTeams
  if (teams.length === 0) {
    next({ name: AllRouteNames.PersonalHome })
  } else {
    next({ name: AllRouteNames.TeamHome, params: { id: teams[0].id } })
  }
}
