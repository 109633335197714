import { useBuefy } from './useBuefy'

type ReturnTypes = {
  openAlertDialog: (data: { message: string }) => void
}

export const useDialog = (): ReturnTypes => {
  const buefy = useBuefy()

  function openAlertDialog(data: { message: string }) {
    buefy.dialog.alert(data.message)
  }

  return {
    openAlertDialog
  }
}
