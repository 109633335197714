<template>
  <SpirButtonDropdown
    :position="position"
    :value="value"
    :mobileModal="false"
    @change="handleChange"
    :size="size"
    :expanded="expanded"
  >
    <template #trigger>
      <LanguageItem v-if="selectedLanguage" :languageItem="selectedLanguage" :isShorten="isShortenSelectedValue" />
    </template>
    <template #dropdown>
      <SpirDropdownSection>
        <SpirDropdownItem
          v-for="languageOption in languageOptions"
          :key="languageOption.key"
          :value="languageOption.key"
        >
          <LanguageItem :isShowIcon="false" :languageItem="languageOption" />
        </SpirDropdownItem>
      </SpirDropdownSection>
    </template>
  </SpirButtonDropdown>
</template>
<script lang="ts">
import SpirButtonDropdown from '@/components/ui/spir/SpirDropdown/SpirButtonDropdown.vue'
import SpirDropdownItem from '@/components/ui/spir/SpirDropdown/SpirDropdownItem.vue'
import SpirDropdownSection from '@/components/ui/spir/SpirDropdown/SpirDropdownSection.vue'
import { useLanguageList } from '@/composables/useLanguageList'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'
import LanguageItem from './_LanguageItem.vue'

export default defineComponent({
  name: 'LanguageDropdown',
  components: {
    SpirButtonDropdown,
    SpirDropdownSection,
    SpirDropdownItem,
    LanguageItem
  },
  props: {
    value: {
      type: [String, Number] as PropType<string | number>
    },
    position: {
      type: String as PropType<'bottom' | 'top' | 'bottom-left'>,
      default: 'bottom'
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'medium'
    },
    isShortenSelectedValue: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)
    const { languageOptions } = useLanguageList()
    // @ts-expect-error TS2532
    const selectedLanguage = computed(() => languageOptions.find(lo => lo.key === value.value))
    function handleChange(newValue: string) {
      emit('input', newValue)
    }
    return {
      languageOptions,
      selectedLanguage,
      handleChange
    }
  }
})
</script>
