import { TeamPlanStateModule } from '@/store/modules/teamSubscriptionState'
import { Location, NavigationGuardNext, Route } from 'vue-router'
import { AllRouteNames } from '.'

export function teamUsableGuard(to: Route | Location, from: Route | Location, next: NavigationGuardNext) {
  const teamId = to.params?.id
  if (teamId === undefined) {
    next({ name: AllRouteNames.Main })
    return
  }
  if (TeamPlanStateModule.usable(teamId)) {
    next()
  } else {
    next({ name: AllRouteNames.TeamPlan, params: { id: teamId } })
  }
}
