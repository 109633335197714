<template>
  <SpirModalFrame>
    <div class="guest-registration-modal">
      <div class="guest-registration-modal__header">
        <ClosingPageheadTitleBox :canCloseEvenUsingMobile="true" :title="title" :onClose="handleClose" />
        <Divider />
      </div>
      <div class="guest-registration-modal__body">
        <template v-if="isNotSupportedForSignUp() && isNotSupportedForLogin()">
          <DescriptionForInAppBrowser :messageHtml="$t('description.useDefaultBrowser.messageHtml')" />
          <FormItemSpacer spaceName="t24">
            <div class="guest-registration-modal__authTermAndPolicy_wrapper">
              <AuthTermAndPolicy />
            </div>
          </FormItemSpacer>
        </template>

        <template v-else-if="isNotSupportedForSignUp()">
          <DescriptionForInAppBrowser :messageHtml="$t('description.useDefaultBrowser.signUp.messageHtml')" />
          <FormItemSpacer spaceName="t24">
            <Divider />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t24">
            <SignInLogoButtonsBox
              :onGoogleClick="handleSignInToGoogle"
              :onMicrosoftClick="handleSignInToMicrosoft"
              :onMailClick="handleClose"
            />
          </FormItemSpacer>
          <AuthFormSpacer spaceName="vt16" />
          <FormItemSpacer spaceName="v8">
            <Divider />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t24">
            <div class="guest-registration-modal__authTermAndPolicy_wrapper">
              <AuthTermAndPolicy />
            </div>
          </FormItemSpacer>
        </template>

        <template v-else-if="isNotSupportedForLogin()">
          <AuthSignUpFormStepper :activeStep="0" />
          <AuthButtons :onSignUpButtonClick="handleSignUpButtonClick" />
          <FormItemSpacer spaceName="t24">
            <Divider />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t24">
            <DescriptionForInAppBrowser :messageHtml="$t('description.useDefaultBrowser.signIn.messageHtml')" />
          </FormItemSpacer>
          <AuthFormSpacer spaceName="vt16" />
          <FormItemSpacer spaceName="v8">
            <Divider />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t24">
            <div class="guest-registration-modal__authTermAndPolicy_wrapper">
              <AuthTermAndPolicy />
            </div>
          </FormItemSpacer>
        </template>

        <template v-else>
          <AuthSignUpFormStepper :activeStep="0" />
          <AuthButtons :onSignUpButtonClick="handleSignUpButtonClick" />
          <FormItemSpacer spaceName="t24">
            <Divider />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t24">
            <SignInLogoButtonsBox
              :onGoogleClick="handleSignInToGoogle"
              :onMicrosoftClick="handleSignInToMicrosoft"
              :onMailClick="handleClose"
            />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t16" />
          <FormItemSpacer spaceName="v8">
            <Divider />
          </FormItemSpacer>
          <FormItemSpacer spaceName="t24">
            <div class="guest-registration-modal__authTermAndPolicy_wrapper">
              <AuthTermAndPolicy />
            </div>
          </FormItemSpacer>
        </template>
      </div>
    </div>
  </SpirModalFrame>
</template>

<script lang="ts">
import DescriptionForInAppBrowser from '@/components/atoms/DescriptionForInAppBrowser.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import { AuthButtons, SignUpTypes } from '@/components/features/auth/AuthButtons'
import { AuthSignUpFormStepper } from '@/components/features/auth/AuthSignUpFormStepper'
import { AuthTermAndPolicy } from '@/components/features/auth/AuthTermAndPolicy'
import ClosingPageheadTitleBox from '@/components/ui/ClosingPageheadTitleBox.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import Divider from '@/components/ui/layouts/Divider/index.vue'
import { SpirModalFrame } from '@/components/ui/spir/SpirModalFrame'
import { useAuthSignIn } from '@/composables/auth/useAuthSignInWithRedirect'
import { useAuthSignUp } from '@/composables/auth/useAuthSignUp'
import { isNotSupportedForLogin, isNotSupportedForSignUp } from '@/lib/utils/functions'
import { defineComponent } from '@vue/composition-api'
import SignInLogoButtonsBox from './SignInLogoButtonsBox.vue'

export default defineComponent({
  name: 'AuthGuestRegistrationModal',
  components: {
    AuthButtons,
    Divider,
    AuthSignUpFormStepper,
    ClosingPageheadTitleBox,
    SpirModalFrame,
    FormItemSpacer,
    SignInLogoButtonsBox,
    DescriptionForInAppBrowser,
    AuthFormSpacer,
    AuthTermAndPolicy
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { signUpWithGoogle, signUpWithMicrosoft } = useAuthSignUp()
    const { signInWithMicrosoft, signInWithGoogle } = useAuthSignIn()
    const handleSignUpButtonClick = (type: SignUpTypes) => {
      switch (type) {
        case 'google': {
          return signUpWithGoogle()
        }
        case 'microsoft': {
          return signUpWithMicrosoft()
        }
      }
    }
    const handleSignInToGoogle = () => {
      return signInWithGoogle()
    }
    const handleSignInToMicrosoft = () => {
      return signInWithMicrosoft()
    }
    function handleClose() {
      emit('close')
    }
    return {
      handleSignUpButtonClick,
      handleSignInToGoogle,
      handleSignInToMicrosoft,
      handleClose,
      isNotSupportedForLogin,
      isNotSupportedForSignUp
    }
  }
})
</script>

<style scoped lang="scss">
.guest-registration-modal {
  &__body {
    padding: 24px;
  }
  &__introduction {
    color: $spir2_black;

    @include inter_font_setting;
  }
  &__authTermAndPolicy_wrapper {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
}
</style>
