export const activeLayerNames = {
  none: 'none',
  temp: 'temp',
  candidate: 'candidate',
  registered: 'registered'
} as const
type ActiveLayerName = keyof typeof activeLayerNames

type NormalMode = { type: 'normal' }
type LayerMode = { type: 'layer'; activeLayerName: ActiveLayerName }

export type CalendarBodyMode = NormalMode | LayerMode
