import { GroupPollVotingRequester } from '@/models'
import { GuestConfirmer, SpirUserConfirmer } from '@/models/data'
import { UserInfoModel } from '@/models/data/userInfo'
import { FrontSupportLanguage } from '@/types'

export function toRequesterAtVoting(data: {
  isSignIn: boolean
  email: string
  name: string
  userInfo: UserInfoModel
  timezoneInfo: { key: string; locale?: string }
  language: FrontSupportLanguage
}): GroupPollVotingRequester {
  const confirmer = data.isSignIn
    ? new SpirUserConfirmer({ email: data.email, user: data.userInfo })
    : new GuestConfirmer({
        email: data.email,
        name: data.name,
        timeZone: data.timezoneInfo.key,
        language: data.language
      })
  return confirmer.toDecisionMaker()
}
