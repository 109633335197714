import { SignalRequestSender } from '@/lib/analytics/signal'
import { getCurrentInstance } from '@vue/composition-api'

export const useAnalytics = (): SignalRequestSender => {
  const inst = getCurrentInstance()
  if (inst) {
    return inst.proxy.$analytics as SignalRequestSender
  }
  // @ts-expect-error TS2322
  return undefined
}
