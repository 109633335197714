<template>
  <vue-draggable-resizable
    axis="y"
    class-name-handle="handle-style"
    :class-name="myClasses"
    :x="eventForDisplay.left"
    :y="eventForDisplay.top"
    :w="eventForDisplay.width"
    :h="eventForDisplay.height"
    :style="eventStyle"
    :prevent-deactivation="true"
    :active="amIActive"
    :handles="['tm', 'bm']"
    :grid="[1, oneSlotPixel]"
    :draggable="draggable"
    :resizable="resizable"
    @resizing="onResizing"
    @dragging="onDragging"
    @resizestop="onResizestop"
    @dragstop="onDragstop"
  >
    <div class="event-body" @click="clicked">
      <p>{{ formatTime }}</p>
      <p class="event-title">{{ event.node.title }}</p>
    </div>
  </vue-draggable-resizable>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { addDays, startOfDay } from '@/lib/utils/timezone'
import { DEFAULT_SLOT_DURATION } from '@/types/constants'
import { addMinutes, differenceInMinutes, isEqual } from 'date-fns'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EventPosition } from './EventTree'

interface IEventPosition {
  left: number
  top: number
  height: number
  width: number
}
@Component
export default class CalendarEvent extends Vue {
  // @ts-expect-error TS2564
  @Prop() event: EventPosition
  // @ts-expect-error TS2564
  @Prop() parentHeight: number
  // @ts-expect-error TS2564
  @Prop() parentWidth: number
  // @ts-expect-error TS2564
  @Prop() oneSlotPixel: number

  eventForDisplay: IEventPosition = {
    left: 0,
    top: 0,
    height: 0,
    width: 0
  }
  // @ts-expect-error TS2322
  startDate: Date = null
  // @ts-expect-error TS2322
  endDate: Date = null
  @Watch('event', { deep: true })
  resetPositionByEvent() {
    this.resetEventPosition()
  }
  @Watch('parentWidth')
  resetPosition() {
    this.resetEventPosition()
  }
  created() {
    this.startDate = this.event.node.start
    this.endDate = this.event.node.end
    this.resetEventPosition()
  }
  resetEventPosition() {
    this.eventForDisplay = {
      left: Math.round((this.event.myDepth - 1) * this.oneDepthWidth),
      width: Math.round(this.event.widthTimes * this.oneDepthWidth),
      top: this.getPixelOfStartTime,
      height: this.getPixelOfEndTime - this.getPixelOfStartTime
    }
    this.startDate = this.event.node.start
    this.endDate = this.event.node.end
  }
  heightByPixel(percentHeight) {
    return Math.round((this.parentHeight * parseInt(percentHeight)) / 100)
  }
  widthByPixel(percentWidth) {
    return Math.round((this.parentWidth * parseInt(percentWidth)) / 100)
  }

  get draggable() {
    return !!this.event.node.editable
  }
  get resizable() {
    return !!this.event.node.editable
  }
  get myClasses() {
    const classes = this.event.node.classNames ? this.event.node.classNames.join(' ') : ''
    return 'calendar-event ' + classes
  }
  get startOfStartDay() {
    return startOfDay(this.startDate)
  }
  get startOfTomorrowOfStartDay() {
    return startOfDay(addDays(this.startDate, 1))
  }
  get oneDepthWidth() {
    return this.parentWidth / this.event.maxDepth
  }
  get getStartOfDay() {
    return startOfDay(this.startDate)
  }
  get onePixcelMin() {
    return this.parentHeight / 1440
  }
  get getPixelOfStartTime() {
    return Math.round(differenceInMinutes(this.startDate, this.getStartOfDay) * this.onePixcelMin)
  }
  get getPixelOfEndTime() {
    return Math.round(differenceInMinutes(this.endDate, this.getStartOfDay) * this.onePixcelMin)
  }
  get amIActive() {
    return (
      this.event.node.extendedProps &&
      (this.event.node.extendedProps.source === 'editingEvent' || this.event.node.extendedProps.source === 'candidate')
    )
  }
  get formatTime() {
    return `${spirDateFormat(this.startDate, spirDateFormatTypes.hourMin)} - ${spirDateFormat(
      this.endDate,
      spirDateFormatTypes.hourMin
    )}`
  }
  get eventStyle() {
    return {
      backgroundColor: this.event.node.backgroundColor,
      borderColor: `${this.event.node.borderColor}`,
      border: this.event.node.border ? `${this.event.node.border}` : 'initial',
      color: this.event.node.textColor || '#ffffff'
    }
  }
  onResizestop() {
    this.updateEvent()
  }
  onResizing(x, y, width, height) {
    const topOffset = y - this.eventForDisplay.top
    const heightOffset = height - this.eventForDisplay.height
    if (topOffset === 0 && heightOffset === 0) {
      return
    }
    // when resized to above of top
    if (y < 0) {
      this.startDate = this.startOfStartDay
      this.eventForDisplay.top = 0
      return
    }
    if (y + height > this.parentHeight) {
      this.endDate = this.startOfTomorrowOfStartDay
      this.eventForDisplay.height = this.parentHeight - y
      return
    }
    this.eventForDisplay.top = y
    this.eventForDisplay.height = height
    if (topOffset !== 0) {
      const unitCount = Math.round(topOffset / this.oneSlotPixel)
      this.startDate = addMinutes(this.startDate, unitCount * DEFAULT_SLOT_DURATION)
    } else {
      const unitCount = Math.round(heightOffset / this.oneSlotPixel)
      this.endDate = addMinutes(this.endDate, unitCount * DEFAULT_SLOT_DURATION)
    }
  }
  onDragging(left, top) {
    const topOffset = top - this.eventForDisplay.top
    if (topOffset === 0) {
      return
    }
    // when draged to above of top
    if (top < 0) {
      this.startDate = this.startOfStartDay
      this.eventForDisplay.top = 0
      return
    }
    this.eventForDisplay.top = top
    const unitCount = Math.round(topOffset / this.oneSlotPixel)
    this.startDate = addMinutes(this.startDate, unitCount * DEFAULT_SLOT_DURATION)
    // when draged to below of bottom
    if (top + this.eventForDisplay.height > this.parentHeight) {
      this.endDate = this.startOfTomorrowOfStartDay
      this.eventForDisplay.height = this.parentHeight - top
    } else {
      this.endDate = addMinutes(this.endDate, unitCount * DEFAULT_SLOT_DURATION)
    }
  }
  onDragstop() {
    this.updateEvent()
  }
  updateEvent() {
    if (isEqual(this.event.node.start, this.startDate) && isEqual(this.event.node.end, this.endDate)) {
      EventBus.emit(EVENTS.CLICKED_EVENT, {
        event: this.event.node
      })
    } else {
      EventBus.emit(EVENTS.UPDATE_EVENT, {
        event: {
          ...this.event.node,
          start: this.startDate,
          end: this.endDate
        }
      })
    }
  }
  clicked(e: MouseEvent) {
    EventBus.emit(EVENTS.CLICKED_EVENT, {
      event: this.event.node,
      jsEvent: e,
      el: e.currentTarget
    })
    return false
  }
}
</script>

<style scoped lang="scss">
.calendar-event {
  position: absolute;
  border: 1px solid $spir2_white;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
}
.declined-event {
  text-decoration-line: line-through;
}
.event-body {
  overflow: hidden;
  height: 100%;
  .event-title {
    overflow: hidden;
    word-break: break-word;
  }
}
</style>
<style lang="scss">
.handle-style {
  position: absolute;
  background-color: $spir2_primaryBlue;
  border: 1px solid white;
  border-radius: 50%;
  height: 14px;
  width: 14px;
}
.handle-style-tm {
  top: -8px;
  right: 12px;
  cursor: n-resize;
}
.handle-style-bm {
  bottom: -8px;
  left: 6px;
  cursor: s-resize;
}
</style>
