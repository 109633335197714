import { getDefaultSelectedCalendar } from '@/lib/utils'
import { LocalStorage } from '@/models/localStorage/localStorage'
import router from '@/router'
import {
  Candidate,
  CandidateStatus,
  FullCalendarEvent,
  ICalendar,
  ICandidateBase,
  IOnlineMeeting,
  ISchedulePollCommon,
  ITypeCalendarListForUI,
  OnlineMeetingType,
  ScheduleSource,
  VISIBILITY
} from '@/types'
import { addMinutes, isAfter, isBefore, parseISO } from 'date-fns'
import { flatten } from 'lodash'
import { nanoid } from 'nanoid'
import CalendarsModule from '../modules/calendars'
import UserModule from '../modules/user'

const getDefaultOnlineMeetingType = (calendar: ICalendar, previousOnlineMeeting?: IOnlineMeeting): IOnlineMeeting => {
  let defaultOnlineMeetingType: OnlineMeetingType

  if (previousOnlineMeeting?.type === OnlineMeetingType.none) {
    defaultOnlineMeetingType = OnlineMeetingType.none
  } else if (previousOnlineMeeting?.type === OnlineMeetingType.zoom && UserModule.isConnectedZoom) {
    // zoomが過去に使われていた場合、カレンダーの設定によらず接続が生きていればzoomにする
    defaultOnlineMeetingType = OnlineMeetingType.zoom
  } else if (!previousOnlineMeeting || !calendar.availableOnlineMeetings.includes(previousOnlineMeeting.type)) {
    // 初回利用 or 前回のが使えない場合
    if (UserModule.isConnectedZoom) {
      defaultOnlineMeetingType = OnlineMeetingType.zoom
    } else if (calendar.availableOnlineMeetings.length > 0) {
      defaultOnlineMeetingType = calendar.availableOnlineMeetings[0]
    } else {
      defaultOnlineMeetingType = OnlineMeetingType.none
    }
  } else {
    defaultOnlineMeetingType = previousOnlineMeeting.type
  }
  return {
    type: defaultOnlineMeetingType
  }
}

const getNewOnlineMeetingType = (
  calendar: ICalendar,
  previousOnlineMeeting?: IOnlineMeeting
): { hasUpdated: boolean; onlineMeeting: IOnlineMeeting } => {
  const onlineMeeting = getDefaultOnlineMeetingType(calendar, previousOnlineMeeting)
  const hasUpdated = previousOnlineMeeting?.type !== onlineMeeting.type
  return { hasUpdated, onlineMeeting }
}

const DefaultSchedule = <T extends ISchedulePollCommon>(
  localStorageClass: LocalStorage,
  targetCalendars: ITypeCalendarListForUI[],
  tempEvent?: FullCalendarEvent
): T => {
  let savedDefaultValue = localStorageClass.loadFromLocalStorage()
  savedDefaultValue = {
    duration: 60,
    visibility: VISIBILITY.DEFAULT,
    ...savedDefaultValue
  }
  let defaultIds: { accountId: string; calendarId: string } = {
    // @ts-expect-error TS2322
    accountId: null,
    // @ts-expect-error TS2322
    calendarId: null
  }
  if (tempEvent && tempEvent.extendedProps?.calendarId) {
    defaultIds = {
      // @ts-expect-error TS2322
      accountId: tempEvent.extendedProps.accountId,
      calendarId: tempEvent.extendedProps.calendarId
    }
  } else {
    const defaultCalendar = getDefaultSelectedCalendar(targetCalendars, savedDefaultValue)
    defaultIds = {
      accountId: defaultCalendar.accountId,
      calendarId: defaultCalendar.calendarId
    }
  }
  const selectedCalendar = CalendarsModule.getCalendar({
    accountId: defaultIds.accountId,
    calendarId: defaultIds.calendarId
  })

  return {
    ...savedDefaultValue,
    ...defaultIds,
    title: '',
    location: '',
    candidates: [],
    description: '',
    // @ts-expect-error TS2345
    onlineMeeting: getDefaultOnlineMeetingType(selectedCalendar, savedDefaultValue?.onlineMeeting),
    attendees: []
  }
}

// @ts-expect-error TS2322
const getCandidateSource = (status: CandidateStatus = null, startDate?: Date): ScheduleSource => {
  if (startDate) {
    const now = new Date()
    if (isBefore(startDate, now)) {
      return 'expiredPoll'
    }
  }
  switch (status) {
    case null:
    case 'open':
    case 'suggestedByConfirmer':
    case 'suggestedByOrganizer':
    case 'deleted': // irregullar
      return 'candidate'
    default:
      return status
  }
}
// @ts-expect-error TS2322
const isEditable = (status: CandidateStatus = null): boolean => {
  if (router.currentRoute.name === 'ConfirmSchedule') {
    return false
  }
  switch (status) {
    case 'rejected':
    case 'deleted':
    case 'expired':
    case 'expiredPoll':
      return false
  }
  return true
}
function inactiveCandidate(c: Candidate) {
  return c.isExpired || c.status === 'expired' || c.status === 'rejected'
}
const getNewCandidates = (candidates: Array<Candidate>) => {
  return candidates.filter(c => !inactiveCandidate(c))
}
const getInactiveCandidates = (candidates: Array<Candidate>) => {
  return candidates.filter(c => inactiveCandidate(c))
}
/**
 * 候補日をDurationに合わせて分割する。
 * ex)
 *   candidate: start: 12:00, end: 16:00
 *   duration: 90
 *   result: candidate1: 12:00 ~ 13:30
 *           candidate2: 13:30 ~ 15:00
 * @param candidate
 * @param duration
 */
const separateCandidateByDuration = (
  candidate: { start: Date; end: Date },
  duration: number
): Array<{ start: Date; end: Date }> => {
  const returnValue: Array<{ start: Date; end: Date }> = []
  let startDate = candidate.start
  let newCandidateEnd = addMinutes(candidate.start, duration)
  while (!isAfter(newCandidateEnd, candidate.end)) {
    returnValue.push({
      start: startDate,
      end: newCandidateEnd
    })
    startDate = newCandidateEnd
    newCandidateEnd = addMinutes(newCandidateEnd, duration)
  }
  return returnValue
}
/**
 * FormによってDurationが更新された場合の挙動
 * @param candidates
 * @param currentDuration
 * @param newDuration
 */
const adjustCandidatesByDuration = (candidates: ICandidateBase[], newDuration: number): Array<ICandidateBase> => {
  return flatten(
    candidates.map(c => {
      return separateCandidateByDuration({ start: parseISO(c.start), end: parseISO(c.end) }, newDuration).map(s => {
        return {
          start: s.start.toISOString(),
          end: s.end.toISOString(),
          id: nanoid()
        }
      })
    })
  )
}

export {
  getCandidateSource,
  isEditable,
  getNewCandidates,
  getInactiveCandidates,
  DefaultSchedule,
  separateCandidateByDuration,
  adjustCandidatesByDuration,
  getDefaultOnlineMeetingType,
  getNewOnlineMeetingType
}
