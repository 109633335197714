import { TogglableFeatures } from '@/types/feature'
import { computed } from '@vue/composition-api'
import { isEnabled } from 'vue-feature-flipping'
import { useRoute, useRouter } from 'vue2-helpers/vue-router'
import { useSendSignal } from '../useSendSignal'

export const useSignInSetup = (data: {
  signInWithGoogle: () => Promise<void>
  signInWithMicrosoft: () => Promise<void>
}) => {
  const { sendSignInSignal } = useSendSignal()
  const router = useRouter()
  const route = useRoute()
  function handleMailSignIn() {
    const redirect = (route.query.redirect || '/') as string
    router.push({ name: 'SignInWithPassword', query: { redirect } })
  }

  async function handleGoogleSignIn() {
    sendSignInSignal('google')
    await data.signInWithGoogle()
  }

  async function handleMicrosoftSignIn() {
    sendSignInSignal('microsoft')
    await data.signInWithMicrosoft()
  }
  return {
    handleGoogleSignIn,
    handleMicrosoftSignIn,
    handleMailSignIn,
    isEmailLoginEnabled: computed(() => {
      return (
        process.env.VUE_APP_MODE === 'development' ||
        process.env.VUE_APP_MODE === 'local' ||
        isEnabled(TogglableFeatures.accountLink.featureName)
      )
    })
  }
}
