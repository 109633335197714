import { useSendSignal } from '@/composables/useSendSignal'
import { SignalType } from '@/lib/analytics/LogEntry'
import config from '@/lib/config'
import { googleIdentityCodeClient } from '@/lib/googleIdentity'
import { CalendarProviders } from '@/models/user'
import { AllRouteNames } from '@/router'
import { LOCAL_STORAGE_KEY_FOR_CALLBACK_MS } from '@/types'
import queryString from 'query-string'
import { useRoute } from 'vue2-helpers/vue-router'
import { useAuthSessionStorage } from './useAuthSessionStorage'

export const useGetAuthcode = () => {
  const route = useRoute()
  const { sendSignal } = useSendSignal()
  const { setSession } = useAuthSessionStorage()

  async function getAuthCode(data: { type: CalendarProviders }) {
    const { type } = data
    await sendSignal(SignalType.CLICK_ADD_CALENDAR, { type: data.type })
    if (type === 'google') {
      setSession({ action: 'AddCalendar', type: 'Google' })
      return await googleIdentityCodeClient.getAuthCode()
    }
    if (type === 'microsoft') {
      setSession({ action: 'AddCalendar', type: 'Microsoft' })
      const parameter = queryString.stringify(config.microsoft)
      // when turn back from MS login page after redirecting, we will lose schedule id, so save id to local storage
      if (route.name !== AllRouteNames.AddCalendar) {
        window.sessionStorage.setItem(LOCAL_STORAGE_KEY_FOR_CALLBACK_MS, route.fullPath)
      }
      window.location.search = parameter
      window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' + parameter
    }
  }
  return {
    getAuthCode
  }
}
