import Router, { RouteNames } from '@/router'
import axios, { AxiosError, AxiosHeaders } from 'axios'
import { getAuth } from 'firebase/auth'
import { createAxiosDefaults, getCustomHeaders, sendAxiosErrorToSentry } from './axios-config'
import { APIError } from './sdk/error'

const axiosInstance = axios.create(createAxiosDefaults)
axiosInstance.interceptors.request.use(
  async config => {
    const firebaseAuth = getAuth()
    const user = firebaseAuth.currentUser
    if (user === null) {
      return config
    }
    const idToken = await user.getIdToken()
    const customHeaders = getCustomHeaders({ firebaseUser: { uid: user.uid, idToken } })
    const headers = new AxiosHeaders(config.headers)
    headers.set(customHeaders)
    config.headers = headers
    return config
  },
  (error: AxiosError) => {
    sendAxiosErrorToSentry(error)
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(null, async error => {
  sendAxiosErrorToSentry(error)
  if (error.response && error.response.status === 403) {
    const signOutRouteName: RouteNames = 'SignOut'
    if (Router.app.$route.name !== signOutRouteName) {
      Router.push({ name: signOutRouteName })
    }
  }
  // if there is data, make it to API Error
  if (error.response && error.response.data) {
    return Promise.reject(new APIError(error.response.status, error.response.data, error.response))
  }
  return Promise.reject(error)
})

export default axiosInstance
