<template>
  <b-button
    :class="['spir-ui-button', { 'spir-ui-icon-button': !!icon }, type, size]"
    :type="buefyType"
    :size="buefySize"
    :disabled="disabled"
    :loading="loading"
    :outlined="buefyOutlined"
    :icon-left="buefyIconLeft"
    :native-type="nativeType"
    :expanded="expanded"
    @click="handleClick"
  >
    <slot />
  </b-button>
</template>

<script lang="ts">
import { useAnalytics } from '@/composables/useAnalytics'
import { SignalType } from '@/lib/analytics'
import { computed, defineComponent, PropType, toRefs } from '@vue/composition-api'

const type = ['basic', 'primary', 'danger', 'text'] as const
export type ButtonType = (typeof type)[number]

const size = [
  'small',
  'medium',
  'mobile-friendly-small',
  'mobile-friendly-medium',
  'mobile-friendly-large',
  'large'
] as const
export type ButtonSize = (typeof size)[number]

const icon = [
  'plus',
  'eye',
  'delete',
  'close',
  'setting',
  'up',
  'down',
  'account-multiple-plus',
  'account-multiple',
  'dots-horizontal'
] as const
export type ButtonIcon = (typeof icon)[number]

export type Analytics = {
  signal: SignalType
  payload?: { [key: string]: string | number | boolean }
}

/**
 * https://www.figma.com/file/dNOPuiscfO6H4VEfKJ7wvo/Spir-Components?node-id=172%3A0
 */
export default defineComponent({
  name: 'Button',
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'basic'
    },
    size: {
      type: String as PropType<ButtonSize>,
      default: 'medium'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String as PropType<ButtonIcon | undefined>
    },
    expanded: {
      type: Boolean,
      default: false
    },
    analytics: {
      type: [String, Object] as PropType<SignalType | Analytics>
    },
    nativeType: {
      type: String,
      default: 'button'
    }
  },
  setup(props, context) {
    const { type, size, analytics } = toRefs(props)
    const analyticsSender = useAnalytics()

    const buefyType = computed(() => {
      switch (type.value) {
        case 'basic':
          return ''
        case 'primary':
          return 'is-primary'
        case 'danger':
          return 'is-danger'
        case 'text':
          return 'is-text'
        default:
          return ''
      }
    })
    const buefySize = computed(() => {
      switch (size.value) {
        case 'small':
          return 'is-small'
        case 'medium':
          return ''
        default:
          return ''
      }
    })
    const buefyIconLeft = computed(() => {
      switch (props.icon) {
        case 'plus':
          return 'plus'
        case 'eye':
          return 'eye'
        case 'delete':
          return 'delete'
        case 'close':
          return 'close'
        case 'setting':
          return 'cog'
        case 'up':
          return 'chevron-up'
        case 'down':
          return 'chevron-down'
        case 'account-multiple-plus':
          return 'account-multiple-plus'
        case 'account-multiple':
          return 'account-multiple'
        case 'dots-horizontal':
          return 'dots-horizontal'
        default:
          return ''
      }
    })
    const buefyOutlined = computed(() => {
      return props.type === 'danger'
    })

    const handleClick = event => {
      // @ts-expect-error TS2532
      if (typeof analytics.value === 'string') {
        // @ts-expect-error TS2532
        analyticsSender.send(analytics.value)
        // @ts-expect-error TS2532
      } else if (typeof analytics.value === 'object') {
        // @ts-expect-error TS2532
        analyticsSender.send(analytics.value.signal, analytics.value.payload)
      }
      context.emit('click', event)
    }

    return {
      buefyType,
      buefySize,
      buefyIconLeft,
      buefyOutlined,
      handleClick
    }
  }
})
</script>
<style lang="scss" scoped>
.spir-ui-button {
  height: 32px;
  font-weight: 700;
  border-radius: 4px;
  &:focus,
  &:focus:not(:active) {
    box-shadow: none;
  }
  &:focus-visible {
    outline: none;
  }
  &.primary.is-primary {
    color: $spir2_white;
    background-color: $spir2_primaryBlue;
    &:active,
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: $spir2_primaryDarkBlue;
    }
    &:disabled {
      border: none;
      background-color: $spir2_bgDisabled;
      color: $spir2_disabled;
    }
    &:focus:not(:active) {
      box-shadow: none;
    }
  }
  &.basic {
    border: 1px solid $spir2_border;
    color: $spir2_primaryBlue;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      color: $spir2_primaryDarkBlue;
      background-color: $spir2_bg;
    }
    &:disabled {
      color: $spir2_disabled;
      background-color: $spir2_bgDisabled;
    }
  }
  &.text {
    color: $spir2_primaryBlue;
    background-color: transparent;
    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      color: $spir2_primaryBlue;
      background-color: $spir2_bg;
    }
    &:disabled {
      color: $spir2_disabled;
      background-color: transparent;
    }
  }
  &.danger.is-danger.is-outlined {
    color: $spir2_cautionRed;
    border: 1px solid $spir2_border;
    background-color: $spir2_white;
    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      color: $spir2_cautionDarkRed;
      background-color: $spir2_bg;
    }
    &:disabled {
      color: $spir2_disabled;
      background-color: $spir2_bgDisabled;
    }
    &:focus:not(:active) {
      box-shadow: none;
    }
  }
  &.is-small.small {
    height: 24px;
    border-radius: 4px;
    font-size: 12px;
  }
  &.large {
    height: 48px;
    @include font_setting_16($font_weight: 600);
  }
  &.mobile-friendly-small {
    @include font_14_600($line_height: 160%);
    @include for_size(mobile_only) {
      height: 40px;
    }
  }
  &.mobile-friendly-medium {
    @include for_size(mobile_only) {
      height: 48px;
      @include font_setting_16($font_weight: 600);
    }
  }
  &.mobile-friendly-large {
    height: 40px;
    @include font_14_600($line_height: 160%);
    @include for_size(mobile_only) {
      height: 48px;
      @include font_setting_16($font_weight: 600);
    }
  }
}
.spir-ui-icon-button {
  ::v-deep span.icon {
    font-size: 1.5em;
    &:first-child:not(:last-child) {
      margin-right: 0;
    }
  }
}
</style>
