<template>
  <div class="form-item-layout">
    <b-icon v-if="!!icon" :icon="icon" class="form-item-layout__icon" type="is-secondary" />
    <div class="form-item-layout__body">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class FormItemLayout extends Vue {
  @Prop({ default: null }) icon
  // @ts-expect-error TS2564
  @Prop({ default: null }) label: string
}
</script>

<style scoped lang="scss">
.form-item-layout {
  min-height: 42px;
  display: flex;
  &__icon {
    height: 20px;
    width: 20px;
    margin-right: 12px;
  }
  &__body {
    flex-grow: 1;
    align-self: stretch;
    min-width: 0;
  }
}
</style>
