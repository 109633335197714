import { common } from './common'
export const layouts = {
  PersonalTeamSelectDropdown: {
    deactivatedText: common.teamSuspended
  },
  DeleteConfirmationModal: {
    footerButtons: {
      cancel: 'Cancel',
      confirm: 'Delete'
    }
  }
}
