import { FullCalendarEvent, Holiday } from '@/types'

function toTempHolidayIdentification(id: string, timestamp: number): string {
  return `${id}${timestamp}`
}

function isAlreadySaved(arr: string[], id: string): boolean {
  const found = arr.find(arrId => arrId === id)
  return !!found
}

export const FromHolidays = {
  convertToFullCalendarEvents(holidays: Holiday[]): FullCalendarEvent[] {
    if (!holidays) {
      return []
    }
    const { events } = holidays.reduce(
      (acc: { events: FullCalendarEvent[]; uniqIdDates: string[] }, holiday: Holiday) => {
        const base = {
          id: holiday.id,
          allDay: true,
          title: holiday.name,
          editable: false
        }
        const date: FullCalendarEvent = {
          ...base,
          start: new Date(holiday.date),
          end: new Date(holiday.date),
          extendedProps: {
            source: 'holidayExclude',
            countryCode: holiday.country.code
          }
        }
        const dateId = toTempHolidayIdentification(holiday.id, date.start.getTime())
        if (!isAlreadySaved(acc.uniqIdDates, dateId)) {
          acc.uniqIdDates.push(dateId)
          acc.events.push(date)
        }
        if (holiday.date !== holiday.observed) {
          const observed: FullCalendarEvent = {
            ...base,
            start: new Date(holiday.observed),
            end: new Date(holiday.observed),
            extendedProps: {
              source: 'holidayObservedExclude',
              countryCode: holiday.country.code
            }
          }
          const observedDateId = toTempHolidayIdentification(holiday.id, observed.start.getTime())
          if (!isAlreadySaved(acc.uniqIdDates, observedDateId)) {
            acc.uniqIdDates.push(observedDateId)
            acc.events.push(observed)
          }
        }
        return acc
      },
      { events: [], uniqIdDates: [] }
    )
    return events
  }
}
