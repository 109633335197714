<template>
  <div id="app" class="full-page-loader">
    <img src="@/assets/images/logo_new_circle.svg" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppForLoading'
})
</script>

<style scoped lang="scss">
.full-page-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 200px;
  }
}
</style>
