/**
 * https://github.com/eliep/vue-avatar/blob/master/src/Avatar.vue#L10
 */
const getInitials = (username: string) => {
  const parts = username.split(/[ -]/)
  let initials = ''

  for (let i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0)
  }

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }

  initials = initials.substring(0, 3).toUpperCase()

  return initials
}

/**
 * https://github.com/eliep/vue-avatar/blob/master/src/Avatar.vue#L72
 */
const BACKGROUND_COLORS = [
  '#F44336',
  '#FF4081',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  /* '#FFEB3B' , */ '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B'
]
const getRandomBackgroundColor = (seed, colors) => {
  return colors[seed % colors.length]
}

export const generateAvatarFallback = (name: string) => {
  const fallbackText = getInitials(name)
  const fallbackBackgroundColor = getRandomBackgroundColor(name.length, BACKGROUND_COLORS)
  return {
    fallbackText,
    fallbackTextColor: '#fff',
    fallbackBackgroundColor
  }
}
