<template>
  <div>
    <div class="modal-input__field">
      <div class="label-wrapper">
        <div class="mr-2">
          <b-icon icon="calendar" class="label-icon" />
        </div>
        <label class="label">{{ $t('message.confirm.calendarSelectSubLabel') }}</label>
      </div>
      <div class="calendar-selector-wrapper">
        <CalendarSelector2
          v-if="selectedCalendarSynced && !getUseOtherMail && !getIsOtherMailDropdownActive"
          :accountId.sync="selectedCalendarSynced.accountId"
          :calendarId.sync="selectedCalendarSynced.calendarId"
          :noLabel="true"
          :disableCalendarPicker="getUseOtherMail"
          :updateHeightWhenDropdownIsActive="true"
          class="pt-0"
          @input="updatedEmailCondition"
        />
      </div>
      <OtherCalendarItem
        :ignoreEmails="ignoreEmails"
        :updateHeightWhenDropdownIsActive="true"
        @confirm="confirmOtherCalendar"
        @reset="resetOtherMail"
        @active-change="otherMailActiveChange"
      />
    </div>
  </div>
</template>

<script lang="ts">
import CalendarSelector2 from '@/components/forms/CalendarSelector2.vue'
import OtherCalendarItem from '@/components/forms/OtherCalendarItem.vue'
import CalendarsModule from '@/store/modules/calendars'
import ProfileModule from '@/store/modules/profile'
import { ITypeCalendarListForUI } from '@/types'
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator'
import { SelectedCalendar } from '../data/defaultAccountAndCalendarId'

@Component({
  components: {
    CalendarSelector2,
    OtherCalendarItem
  }
})
export default class EmailOrCalendarSelect extends Vue {
  // @ts-expect-error TS2564
  @Prop() formattedDate: string
  // @ts-expect-error TS2564
  @Prop({ default: true }) showForm: boolean
  // @ts-expect-error TS2564
  @Prop({ default: null }) subTitle: string
  @Prop({ default: () => [] }) ignoreEmails?: string[]
  @PropSync('email', { type: String }) emailSynced!: string
  @PropSync('name', { type: String }) nameSynced!: string
  @PropSync('selectedCalendar', { type: Object }) selectedCalendarSynced!: SelectedCalendar

  isOtherMailDropdownActive = false
  useOtherMail = false
  otherMail = ''
  selectedCalendarIndex = 0

  mounted() {
    this.setEmailFromCalendar()
    this.setNameFromProfile()
  }
  @Watch('useOtherMail')
  updatedOtherMail(newValue) {
    if (newValue) {
      this.emailSynced = this.otherMail
      this.nameSynced = this.otherMail
    } else {
      this.setEmailFromCalendar()
      this.setNameFromProfile()
    }
  }
  get getSubTitle() {
    return !this.showForm ? '' : this.subTitle || this.$t('message.confirm.pleaseInputYourInfo')
  }
  get writableButNotGroupCalendars(): ITypeCalendarListForUI[] {
    return this.writableCalendars.filter(c => c.type === 'normal')
  }
  get writableCalendars() {
    return CalendarsModule.writableCalendars
  }
  get fullName() {
    return ProfileModule.userInfo.fullName ?? ''
  }
  get getIsOtherMailDropdownActive() {
    return this.isOtherMailDropdownActive
  }
  get getUseOtherMail() {
    return this.useOtherMail
  }
  confirmOtherCalendar(email: string) {
    this.useOtherMail = true
    this.otherMail = email
    this.updatedEmailCondition(email)
    this.updatedNameCondition(email)
  }
  resetOtherMail() {
    this.useOtherMail = false
    this.otherMail = ''
  }
  otherMailActiveChange(status: boolean) {
    this.isOtherMailDropdownActive = status
  }
  setEmailFromCalendar() {
    if (this.selectedCalendarSynced) {
      const calendar = this.writableButNotGroupCalendars.find(
        c =>
          c.accountId === this.selectedCalendarSynced.accountId &&
          c.calendarId === this.selectedCalendarSynced.calendarId
      )
      // @ts-expect-error TS2322
      this.emailSynced = calendar.invitationAddress
    }
  }
  setNameFromProfile() {
    this.nameSynced = this.fullName
  }
  updatedEmailCondition(email: string) {
    this.emailSynced = email
  }
  updatedNameCondition(name: string) {
    this.nameSynced = name
  }
}
</script>
<style scoped lang="scss">
.label-wrapper {
  display: flex;
  margin-bottom: 12px;

  .label {
    color: $spir2_gray;
    font-size: 12px;
    font-weight: 700;
  }

  .label-icon {
    height: 20px;
    width: 20px;
    margin-right: 12px;
    color: $spir2_black;
  }
}
.modal-input__field {
  padding-top: 0px;
  padding-bottom: 0px;
}
.form-wrapper {
  padding-left: 30px;
}
.calendar-selector-wrapper {
  padding-left: 30px;
}
</style>
