import { PollModel } from '@/models/data/poll'
import { IPoll, PollStatus } from '@/types'
import qs from 'qs'
import axiosInstance from '../axios'
import { changeToPublicPath } from './util'

export type PollList = {
  nextToken?: string
  limit: number
  groupPolls: IPoll[]
}

export const create = async (poll: PollModel) => {
  return (await axiosInstance.post('group-polls', poll)).data
}
export const update = async (poll: PollModel) => {
  const id = poll.id
  const payload = poll
  delete payload.id
  return (await axiosInstance.put(`group-polls/${id}`, payload)).data
}
export const deletePoll = async (pollId: string) => {
  return (await axiosInstance.delete(`group-polls/${pollId}`)).data
}
export type GetPollListPayload = {
  nextToken?: string
  limit?: number
  start?: string
  end?: string
  status?: PollStatus[]
}
export const getList = async (payload: GetPollListPayload): Promise<PollList> => {
  const queryParams = { ...payload }
  return (
    await axiosInstance.get('group-polls', {
      params: queryParams,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  ).data
}
export const getPollById = async (pollId): Promise<IPoll> => {
  return (await axiosInstance.get(changeToPublicPath(`group-polls/${pollId}`))).data
}
export const confirm = async (pollId: string, candidateId: string): Promise<IPoll> => {
  return (
    await axiosInstance.post(`group-polls/${pollId}/confirm`, {
      candidateId
    })
  ).data
}
export const removeCandidates = async (pollId: string, candidates: Array<string>): Promise<any> => {
  return Promise.all(
    candidates.map(cId => {
      return axiosInstance.delete(`group-polls/${pollId}/candidates/${cId}`)
    })
  )
}
