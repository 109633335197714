import TimeZoneKeySelectorModal from '@/components/modal/timezone/TimeZoneKeySelectorModal.vue'
import TimeZoneSelectModal from '@/components/modal/timezone/TimeZoneSelectModal.vue'
import { APIError } from '@/lib/sdk/error'
import { TimeZones } from '@/models/data/userInfo'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import { ERROR_CODE } from '@spirinc/message-catalog'
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class UpdateAllTimezoneMixin extends Vue {
  get timezoneInfo() {
    return TimezoneModule.userTimezoneInfo
  }
  get userTimezones() {
    return ProfileModule.myProfile?.timeZones
  }
  get timeZoneDisplayName() {
    return this.timezoneInfo.abbreviation
  }
  openModal() {
    if (this.userTimezones) {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: TimeZoneSelectModal,
        hasModalCard: true,
        canCancel: true,
        props: {
          timeZones: this.userTimezones
        },
        events: {
          close: () => {
            modal.close()
          },
          updateTimezoneInfo: (timeZones: TimeZones) => {
            this.updateTimezone(timeZones)
            modal.close()
          }
        }
      })
    } else {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: TimeZoneKeySelectorModal,
        hasModalCard: true,
        canCancel: true,
        props: {
          timezoneKey: this.timezoneInfo.key
        },
        events: {
          close: () => {
            modal.close()
          },
          updateTimezoneInfo: (timeZoneKey: string) => {
            TimezoneModule.updatePrimaryTimezone(timeZoneKey)
            modal.close()
          }
        }
      })
    }
  }
  async updateTimezone(timeZones: TimeZones) {
    try {
      await TimezoneModule.updateTimezone(timeZones)
      this.$buefy.toast.open({
        type: 'is-primary',
        position: 'is-top',
        message: this.$t('message.success.saved').toString()
      })
      this.$emit('close')
    } catch (e) {
      if (e instanceof APIError) {
        if (e.code === ERROR_CODE.ILLEGAL_ARGUMENT) {
          this.$buefy.toast.open({
            type: 'is-danger',
            position: 'is-top',
            message: this.$t('message.error.notSupported', { value: timeZones.primary.key }).toString()
          })
        } else if (e.status >= 500) {
          this.$buefy.toast.open({
            type: 'is-danger',
            position: 'is-top',
            message: this.$t('message.error.serverError').toString()
          })
        }
      }
    }
  }
}
