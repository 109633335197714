import ProfileModule from '@/store/modules/profile'
import { FrontSupportLanguage } from '@/types'
import { ComputedRef, computed } from '@vue/composition-api'

type LanguageSetting = {
  currentLanguage: ComputedRef<FrontSupportLanguage>
  handleChangeLanguage: Function
}
export const useLanguageSetting = (): LanguageSetting => {
  const handleChangeLanguage = (newLanguage: FrontSupportLanguage) => {
    ProfileModule.updateLanguage(newLanguage)
  }
  const currentLanguage = computed(() => ProfileModule.getLanguage)
  return {
    currentLanguage,
    handleChangeLanguage
  }
}
