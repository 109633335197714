<template>
  <ConfirmWithEmailAndName
    :formattedDate="formattedDate"
    :isOrganizer="isOrganizer"
    :isSignIn="isSignIn"
    :confirmData.sync="confirmerInfo"
    :cancelBtn="cancelBtn"
    @cancel="$emit('close')"
    @close="$emit('close')"
    @register="handleConfirm"
  >
    <div class="mt-5 mb-2">
      <ConfirmationForms
        v-if="hasConfirmationForm"
        :confirmationForm="confirmationForm"
        :formData.sync="availabilityConfirm.formData"
      />
      <AttendeeInput :ignoreEmails="emails" @confirm="addAttendee" @delete="deleteAttendee" />
    </div>
  </ConfirmWithEmailAndName>
</template>

<script lang="ts">
import oneDateRange from '@/filters/oneDateRange'
import { GuestConfirmer, SpirUserConfirmer } from '@/models/data'
import { ConfirmationForm } from '@/models/data/availability'
import CalendarsModule from '@/store/modules/calendars'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import UserModule from '@/store/modules/user'
import { AvailabilityConfirm } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { defaultAccountAndCalendarId, saveSelectedCalendar, SelectedCalendar } from './data/defaultAccountAndCalendarId'
import AttendeeInput from './molecules/AttendeeInput.vue'
import ConfirmWithEmailAndName from './molecules/ConfirmWithEmailAndName.vue'
import ConfirmationForms from './organisms/ConfirmationForms.vue'

@Component({
  components: {
    AttendeeInput,
    ConfirmWithEmailAndName,
    ConfirmationForms
  }
})
export default class ConfirmAvailabilityModal extends Vue {
  // @ts-expect-error TS2564
  @Prop() candidateStartDate: Date
  // @ts-expect-error TS2564
  @Prop() candidateEndDate: Date
  // @ts-expect-error TS2564
  @Prop({ default: false }) isOrganizer: boolean
  // @ts-expect-error TS2564
  @Prop() confirmationForm: ConfirmationForm
  // @ts-expect-error TS2564
  @Prop() cancelBtn: string

  availabilityConfirm: AvailabilityConfirm = {
    attendees: []
  }
  confirmerInfo: { email: string; name?: string } = {
    email: '',
    name: ''
  }
  // @ts-expect-error TS2322
  selectedCalendar: SelectedCalendar = null
  created() {
    if (this.isSignIn) {
      this.selectedCalendar = defaultAccountAndCalendarId()
      if (this.selectedCalendar) {
        const selectedCalendar = CalendarsModule.getCalendar({
          accountId: this.selectedCalendar.accountId,
          calendarId: this.selectedCalendar.calendarId
        })
        if (selectedCalendar) {
          // @ts-expect-error TS2322
          this.confirmerInfo.email = selectedCalendar.invitationAddress
        }
      }
    }
    // confirmation formが設定されてる場合は初期化
    if (this.confirmationForm) {
      this.availabilityConfirm['formData'] = {}
      Object.keys(this.confirmationForm.properties).map(key => {
        if (this.confirmationForm.properties[key].isActive) {
          this.availabilityConfirm['formData'][key] = undefined
        }
      })
    }
  }
  get hasConfirmationForm() {
    return this.availabilityConfirm.formData && Object.keys(this.availabilityConfirm.formData).length > 0
  }
  get formattedDate() {
    return oneDateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  handleConfirm() {
    if (this.selectedCalendar) {
      saveSelectedCalendar(this.selectedCalendar)
      CalendarsModule.visibleCalendarIfNotVisible(this.selectedCalendar)
    }
    const confirmer = UserModule.isSignIn
      ? new SpirUserConfirmer({ email: this.confirmerInfo.email, user: ProfileModule.myProfile })
      : new GuestConfirmer({
          email: this.confirmerInfo.email,
          // @ts-expect-error TS2322
          name: this.confirmerInfo.name,
          timeZone: TimezoneModule.userTimezoneInfo.key,
          language: ProfileModule.getLanguage
        })
    this.$emit('confirm', this.availabilityConfirm, confirmer)
  }
  handleCancel() {
    this.$emit('close')
  }

  deleteAttendee(index: number) {
    this.availabilityConfirm.attendees = [
      ...this.availabilityConfirm.attendees.slice(0, index),
      ...this.availabilityConfirm.attendees.slice(index + 1, this.availabilityConfirm.attendees.length)
    ]
  }
  addAttendee(attendee: { email: string; name: string }) {
    this.availabilityConfirm.attendees = [...this.availabilityConfirm.attendees, attendee]
  }

  get emails() {
    const emails = this.availabilityConfirm.attendees.map(attendee => attendee.email)
    if (this.confirmerInfo.email) {
      emails.push(this.confirmerInfo.email)
    }
    return emails
  }
}
</script>
