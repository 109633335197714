import { LanguageSelectItem } from '@/components/ui/spir/types'
import { frontSupportLanguages } from '@/types'
import { useTranslation } from './useTranslation'

type LanguageList = {
  languageOptions: LanguageSelectItem[]
}

export const useLanguageList = (): LanguageList => {
  const i18n = useTranslation()
  const languageOptions: LanguageSelectItem[] = [
    {
      label: i18n.t('settings.localization.ja.label').toString(),
      shortenLabel: i18n.t('settings.localization.ja.shortenLabel').toString(),
      key: frontSupportLanguages.ja
    },
    {
      label: i18n.t('settings.localization.en.label').toString(),
      shortenLabel: i18n.t('settings.localization.en.shortenLabel').toString(),
      key: frontSupportLanguages.en
    }
  ]
  return {
    languageOptions
  }
}
