<template>
  <div class="card-box-for-url">
    <CopyBoxBodyFrame>
      <CopyBoxHeader
        :title="boxHeaderTitle"
        :buttonTitle="buttonTitle"
        :tip="$t('shareTextModal.sharingURLTip')"
        :onClick="copyURLText"
      />
      <div class="body">
        <CopyBoxLinkText :title="url" :onClick="copyURLText" />
      </div>
    </CopyBoxBodyFrame>
  </div>
</template>

<script lang="ts">
import CopyBoxBodyFrame from '@/components/copyToShare/copyBoxParts/CopyBoxBodyFrame.vue'
import CopyBoxHeader from '@/components/copyToShare/copyBoxParts/CopyBoxHeader.vue'
import CopyBoxLinkText from '@/components/copyToShare/copyBoxParts/CopyBoxLinkText.vue'
import { useToast } from '@/composables/useToast'
import { useTranslation } from '@/composables/useTranslation'
import { copyClipboard } from '@/lib/utils/functions'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxForUrl',
  components: {
    CopyBoxHeader,
    CopyBoxBodyFrame,
    CopyBoxLinkText
  },
  props: {
    boxHeaderTitle: {
      type: String,
      required: true
    },
    buttonTitle: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    onAnalyticsSend: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const i18n = useTranslation()
    const { openSuccessTopToast } = useToast()
    async function copyURLText() {
      await props.onAnalyticsSend()
      copyClipboard(props.url)
      openSuccessTopToast({
        message: i18n.t('message.copiedToClipBoard').toString()
      })
    }
    return {
      copyURLText
    }
  }
})
</script>

<style scoped lang="scss">
.card-box-for-url {
  .header-frame {
    padding: 12px;
    box-shadow: 0px 1px 0px $spir2_black_08;
  }
  .body {
    padding: 12px;
    font-size: 12px;
    line-height: 130%;
    max-height: 230px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
