<template>
  <div>
    <div>
      <p v-html="messageHtml"></p>
    </div>
    <div class="copy-url">
      <Button
        class="mobile-friendly-button-frame"
        type="primary"
        size="mobile-friendly-medium"
        @click="copyUrl"
        v-if="isIOS"
      >
        {{ $t('buttons.copyUrl') }}
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import Button from '@/components/ui/Button.vue'
import { isIOS } from '@/lib/utils'
import copy from 'clipboard-copy'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Button
  }
})
export default class DescriptionForInAppBrowser extends Vue {
  // @ts-expect-error TS2564
  @Prop() messageHtml: string

  copyUrl() {
    copy(location.href)
    this.$buefy.toast.open({
      message: this.$t('message.copiedToClipBoard').toString(),
      type: 'is-success'
    })
  }

  // AndroidのWebViewでクリップボードcopyが権限エラーで動かないことに対応して
  get isIOS() {
    return isIOS()
  }
}
</script>

<style scoped lang="scss">
.description-for-in-app {
  text-align: left;
  font-size: 1rem;
  color: $spir2_black;
  white-space: pre-line;
}
.copy-url {
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>
