export const QueryParams = {
  QUERY_PARAM_CONFIRMED_DATE: 'confirmed-date',
  QUERY_PARAM_CONFIRMED_ID: 'confirmed-id',
  QUERY_PARAM_SCHEDULE_ID: 'schedule-id',
  CANDIDATE_ID: 'candidate-id',
  QUERY_SHOW_CALENDAR: 'show-calendar',
  QUERY_NOT_FOUND_AVAILABILITY: 'availability',
  QUERY_NOT_FOUND_SCHEDULE: 'schedule',
  QUERY_PARAM_CONFIRM_START: 'confirm-start',
  QUERY_PARAM_TIMEZONE: 'timezone',
  TEAM_ID: 'team-id',
  INVITATION_ERROR_CODE: 'joined-already',
  ERROR_CODE: 'error-code',
  FILTER_DURATION: 'filter-duration',
  FILTER_MEMBER_ID: 'filter-member-id',
  FILTER_TITLE: 'filter-title',
  TEMPLATE_ID: 'template-id',
  SELECTED_SEGMENT: 'selected-segment',
  UTM_SOURCE: 'utm_source'
} as const

export type QueryParams = (typeof QueryParams)[keyof typeof QueryParams]
