import { BREAK_POINT_FOR_LARGE_TABLET, BREAK_POINT_FOR_RESPONSIVE } from '@/lib/utils/style'
import { AppInitMode } from '@/models'
import store, { removePersistence } from '@/store'
import UserModule from '@/store/modules/user'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import CalendarControlModule from './calendarControl'
import CalendarsModule from './calendars'
import './dailyView'
import EventModule from './event'
import EventsModule from './events'
import './listPanel'
import ListPanelModule from './listPanel'
import './profile'
import ProfileModule from './profile'
import './settings'
import TeamCalendarModule from './teamCalendar'
import TeamRecordModule from './teamRecord'
import TeamScheduleListModule from './teamScheduleList'

const MODULE_NAME = 'App'

export enum SidePanelTab {
  CALENDAR_LIST = 'calendarList',
  SCHEDULE_LIST = 'scheduleList'
}
export interface AppState {
  isLoading: boolean
  isQuickViewVisible: boolean
  windowWidth: number
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class App extends VuexModule {
  private loading = false
  private pageLoading = false
  private quickViewVisible = false
  private windowWidth = window.innerWidth

  // hard private fieldを使ったら TypeError: attempted to get private field on non-instance になった。
  get isQuickViewVisible() {
    return this.quickViewVisible
  }
  get isLoading() {
    return this.loading
  }
  get isPageLoading() {
    return this.pageLoading
  }
  get isNarrow(): boolean {
    if (this.windowWidth === 0) {
      return false
    }
    return this.windowWidth < BREAK_POINT_FOR_RESPONSIVE
  }
  get isLargeTabletWidth() {
    return this.windowWidth < BREAK_POINT_FOR_LARGE_TABLET
  }
  @Action
  async initApp(data?: { mode: AppInitMode }) {
    const mode = data?.mode ?? 'all'
    if (mode === 'empty') return
    await Promise.all([
      ProfileModule.initProfileModule({ mode }),
      mode === 'all' || mode === 'authTeamsCreate' ? CalendarsModule.initCalendars() : Promise.resolve(),
      mode === 'all' ? UserModule.fetchIntegrations() : Promise.resolve()
    ])
    if (mode === 'all') {
      this.context.dispatch('DailyView/fetchCalendarsForDailyView', null, { root: true })
    }
  }
  @Action
  async pollingEventsAndSchedules() {
    await CalendarControlModule.fetchEvents({
      hideLoader: true,
      sequential: true
    })
    await TeamScheduleListModule.fetchAllList()
  }
  @Action
  resetAllModules() {
    window.sessionStorage.clear()
    window.localStorage.clear()
    return Promise.all([
      UserModule.RESET_STATE(),
      CalendarsModule.RESET_STATE(),
      EventsModule.RESET_STATE(),
      EventModule.RESET_STATE(),
      ProfileModule.RESET_STATE(),
      ListPanelModule.RESET_STATE(),
      TeamCalendarModule.RESET_STATE(),
      TeamRecordModule.RESET_STATE(),
      removePersistence()
    ])
  }
  @Action
  closeQuickView() {
    this.ON_CLOSE_QUICK_VIEW()
  }

  @Action
  setWindowWidth() {
    this.SET_WINDOW_WIDTH(window.innerWidth)
  }

  @Mutation
  SET_LOADING_STATUS(loadingStatus: boolean) {
    this.loading = loadingStatus
  }
  @Mutation
  ON_CLOSE_QUICK_VIEW() {
    this.quickViewVisible = false
  }
  @Mutation
  ON_SHOW_QUICK_VIEW() {
    this.quickViewVisible = true
  }
  @Mutation
  SET_WINDOW_WIDTH(width) {
    this.windowWidth = width
  }
  @Mutation
  SET_PAGE_LOADING(isPageLoading: boolean) {
    this.pageLoading = isPageLoading
  }
}

export default getModule(App)
