<template>
  <div class="add-calendar-description">
    <AddCalendarDescriptionItem
      v-for="item of items"
      :key="item.icon"
      :icon="item.icon"
      :caption="item.caption"
      :description="item.description"
    />
    <img v-if="authProvider == 'google'" :src="googleImage" alt="Sign up with Google" />
    <img v-else-if="authProvider == 'microsoft'" :src="msImage" alt="Sign up with Microsoft" />
  </div>
</template>

<script lang="ts">
import { useTranslation } from '@/composables/useTranslation'
import ProfileModule from '@/store/modules/profile'
import { computed, defineComponent } from '@vue/composition-api'
import AddCalendarDescriptionItem from './AddCalendarDescriptionItem.vue'

export default defineComponent({
  props: {
    authProvider: {
      type: String
    }
  },
  components: {
    AddCalendarDescriptionItem
  },
  setup() {
    const i18n = useTranslation()
    const currentLanguage = computed(() => ProfileModule.getLanguage)
    const items = [
      {
        icon: 'help-circle',
        caption: i18n.t('forms.auth.addCalendar.explanation.aboutPermission.caption'),
        description: i18n.t('forms.auth.addCalendar.explanation.aboutPermission.description')
      },
      {
        icon: 'security',
        caption: i18n.t('forms.auth.addCalendar.explanation.aboutDataHandling.caption'),
        description: i18n.t('forms.auth.addCalendar.explanation.aboutDataHandling.description')
      }
    ]
    return {
      items,
      googleImage: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/images/signup_google.png`)),
      msImage: computed(() => require(`@/assets/byLanguage/${currentLanguage.value}/images/signup_microsoft.png`))
    }
  }
})
</script>

<style scoped lang="scss">
.add-calendar-description {
  border: 1px solid $spir2_border;
  border-radius: 6px;
  background-color: $spir2_bg;
  padding: 16px;
  & > :not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
