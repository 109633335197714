<template>
  <ConfirmWithEmailAndName
    :subTitle="subTitle"
    :formattedDate="formattedDate"
    :isOrganizer="isOrganizer"
    :isSignIn="isSignIn"
    :confirmData.sync="confirmData"
    :cancelBtn="cancelBtn"
    @cancel="$emit('close')"
    @close="$emit('close')"
    @register="register"
  >
    <div class="mt-5 mb-2">
      <AttendeeInput :ignoreEmails="emails" @confirm="addAttendee" @delete="deleteAttendee" />
    </div>
  </ConfirmWithEmailAndName>
</template>

<script lang="ts">
import dateRange from '@/filters/dateRange'
import oneDateRange from '@/filters/oneDateRange'
import { GuestConfirmer, SpirUserConfirmer } from '@/models/data'
import { ScheduleConfirmerAttendee } from '@/models/schedule'
import ProfileModule from '@/store/modules/profile'
import TimezoneModule from '@/store/modules/timezones'
import UserModule from '@/store/modules/user'
import { isSameDay } from 'date-fns'
import { Component, Prop, Vue } from 'vue-property-decorator'
import AttendeeInput from './molecules/AttendeeInput.vue'
import ConfirmWithEmailAndName from './molecules/ConfirmWithEmailAndName.vue'

export type ConfirmData = {
  email: string
  name: string
  attendees: ScheduleConfirmerAttendee[]
}

@Component({
  components: {
    ConfirmWithEmailAndName,
    AttendeeInput
  }
})
export default class ConfirmSchedule extends Vue {
  // @ts-expect-error TS2564
  @Prop() candidateStartDate: Date
  // @ts-expect-error TS2564
  @Prop() candidateEndDate: Date
  // @ts-expect-error TS2564
  @Prop() isOrganizer: boolean
  // @ts-expect-error TS2564
  @Prop() cancelBtn: string

  confirmData: ConfirmData = {
    email: '',
    name: '',
    attendees: []
  }
  get formattedDate() {
    if (this.candidateStartDate && this.candidateEndDate) {
      if (isSameDay(this.candidateStartDate, this.candidateEndDate)) {
        return oneDateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
      } else {
        return dateRange({ startDate: this.candidateStartDate, endDate: this.candidateEndDate })
      }
    }

    return null
  }
  get isSignIn() {
    return UserModule.isSignIn
  }
  get subTitle() {
    if (this.isSignIn) {
      return this.$t('message.confirm.desc').toString()
    }
    return this.$t('message.confirm.notSignIn').toString()
  }
  get emails() {
    const emails = this.confirmData.attendees.map(attendee => attendee.email)
    if (this.confirmData.email) {
      emails.push(this.confirmData.email)
    }
    return emails
  }
  addAttendee(attendee: { email: string; name: string }) {
    this.confirmData.attendees = [...this.confirmData.attendees, attendee]
  }
  deleteAttendee(index: number) {
    this.confirmData.attendees = [
      ...this.confirmData.attendees.slice(0, index),
      ...this.confirmData.attendees.slice(index + 1, this.confirmData.attendees.length)
    ]
  }
  register() {
    const confirmer = this.isSignIn
      ? new SpirUserConfirmer({ email: this.confirmData.email, user: ProfileModule.myProfile })
      : new GuestConfirmer({
          email: this.confirmData.email,
          name: this.confirmData.name,
          timeZone: TimezoneModule.userTimezoneInfo.key,
          language: ProfileModule.getLanguage
        })
    this.$emit('register', {
      confirmer,
      attendees: this.confirmData.attendees
    })
    this.$emit('close')
  }
}
</script>

<style scoped></style>
