<template>
  <b-tooltip class="spir-tooltip" :position="position" multilined>
    <div class="tip-icon">
      <b-icon icon="help" custom-class="tip" />
    </div>
    <template #content>
      <div class="content-frame" :style="{ width: `${width}px` }">
        <slot />
      </div>
    </template>
  </b-tooltip>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { TipPosition } from './tipMark'

function toBuefyPosition(pos: TipPosition): 'is-right' | 'is-bottom' | 'is-top' {
  switch (pos) {
    case 'bottom':
      return 'is-bottom'
    case 'top':
      return 'is-top'
    default:
      return 'is-right'
  }
}
export default defineComponent({
  props: {
    pos: {
      type: String as PropType<TipPosition>,
      default: 'right'
    },
    width: {
      type: Number,
      default: 260
    }
  },
  setup(props) {
    const position = computed(() => toBuefyPosition(props.pos))
    return {
      position
    }
  }
})
</script>

<style lang="scss">
// For buefy
.button .icon:first-child:last-child {
  margin-left: 0;
  margin-right: 0;
}
.spir-tooltip {
  &.b-tooltip.is-multiline.is-medium {
    .tooltip-content {
      background-color: $spir2_black;
      border-radius: 6px;
      padding: 0;
      width: auto;
      .content-frame {
        display: inline-block;
        text-align: start;
        padding: 4px 8px;
      }
    }
  }
  .tip-icon {
    position: relative;
    width: 16px;
    height: 16px;
    border: 1px solid $spir2_border;
    border-radius: 8px;
    &:hover {
      background-color: $spir2_primaryDarkBlue;
      .tip:before {
        color: $spir2_white;
      }
    }
    .icon {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .tip {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        font-size: 10px;
        color: $spir2_gray;
        position: absolute;
      }
    }
  }
}
</style>
