import { AppInitMode } from '@/models'

export const getNextPath = ({
  isSignIn,
  isRedirected = null,
  redirect = '/'
}: {
  isSignIn: boolean
  isRedirected: 'ok' | 'error' | 'noUser' | 'invalidInvitationCode' | 'userExists' | null
  redirect?: string
}): { redirect: string | undefined } => {
  if (isSignIn || isRedirected === 'ok') {
    return { redirect }
  } else {
    return { redirect: undefined }
  }
}

export function selectAppInitMode(path: string): AppInitMode {
  switch (path) {
    case '/auth/teams/create':
      return 'authTeamsCreate'
    case '/callbacks/slack':
      return 'empty'
    default:
      return 'all'
  }
}
