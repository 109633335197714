<template>
  <div class="auth-buttons">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({})
</script>

<style scoped lang="scss">
.auth-buttons {
  min-width: 239px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
