<template>
  <AuthButtonsFrame>
    <div class="auth-buttons-wrapper">
      <AuthIconButton type="google" @click="onSignUpButtonClick('google')">
        <span>{{ $t('features.auth.AuthButtons.title', { type: 'Google' }) }}</span>
      </AuthIconButton>
      <AuthFormSpacer spaceName="vb8" />
      <AuthIconButton type="microsoft" @click="onSignUpButtonClick('microsoft')">
        <span>{{ $t('features.auth.AuthButtons.title', { type: 'Microsoft' }) }}</span>
      </AuthIconButton>
    </div>
  </AuthButtonsFrame>
</template>

<script lang="ts">
import AuthButtonsFrame from '@/components/auth/AuthButtonsFrame.vue'
import AuthFormSpacer from '@/components/auth/AuthFormSpacer.vue'
import { AuthIconButton } from '@/components/features/auth/AuthIconButton'
import { defineComponent, PropType } from '@vue/composition-api'
import { SignUpTypes } from './misc'

export default defineComponent({
  name: 'AuthButtons',
  components: {
    AuthButtonsFrame,
    AuthFormSpacer,
    AuthIconButton
  },
  props: {
    onSignUpButtonClick: {
      type: Function as PropType<(type: SignUpTypes) => void>
    }
  }
})
</script>

<style scoped lang="scss">
.auth-buttons-wrapper {
  width: 200px;
}
</style>
