import * as pollAPI from '@/lib/api/poll'
import { GetPollListPayload } from '@/lib/api/poll'
import { PollModel } from '@/models/data/poll'
import store from '@/store'
import { IPoll, PollStatus } from '@/types'
import { uniqBy } from 'lodash'
import Vue from 'vue'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import UserModule from './user'

export const TARGET_GROUP_POLL_LIST_STATUSES: PollStatus[] = ['open', 'expired']
export const PAGE_LIMIT = 1000
const MODULE_NAME = 'PollList'

export type GetPollListFunction = (payload: GetPollListPayload) => Promise<pollAPI.PollList>
@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class PollList extends VuexModule {
  private limit = 0
  private pollModelList: PollModel[] = []
  private confirmedPollModelList: PollModel[] = []
  private isPollListLoading = false
  private nextToken: null | string = null
  private isConfirmedPollListLoading = false

  get getPollModelList(): PollModel[] {
    return this.pollModelList
  }
  get getIsPollListLoading() {
    return this.isPollListLoading
  }
  get getIsConfirmedPollListLoading() {
    return this.isConfirmedPollListLoading
  }
  @Action
  async fetchPollList(option?: { hideLoader: boolean }) {
    if (!UserModule.isSignIn) {
      return
    }
    try {
      if (!option?.hideLoader) {
        this.ON_POLL_LOADING(true)
      }
      const params: { status: PollStatus[]; limit: number; nextToken?: string } = {
        status: TARGET_GROUP_POLL_LIST_STATUSES,
        limit: PAGE_LIMIT
      }
      if (this.nextToken) {
        params['nextToken'] = this.nextToken
      }
      const pollResponse = await pollAPI.getList(params)
      this.ON_POLL_LIST(pollResponse)
    } finally {
      this.ON_POLL_LOADING(false)
    }
  }
  @Action
  async fetchConfirmedPollList(option?: { isPast: boolean; getList?: GetPollListFunction }) {
    if (!UserModule.isSignIn) {
      return
    }
    try {
      this.ON_CONFIRMED_POLL_LOADING(true)
      const status: PollStatus[] = ['confirmed']
      let start, end
      if (option?.isPast) {
        start = '2000-01-01T00:00:00Z'
        end = new Date().toISOString()
      } else {
        start = new Date().toISOString()
        end = '2100-01-01T00:00:00Z'
      }
      const callGetApi = option?.getList ?? pollAPI.getList
      const polls = await callGetApi({ status, start, end })
      this.ON_CONFIRMED_POLL_LIST(polls)
    } finally {
      this.ON_CONFIRMED_POLL_LOADING(false)
    }
  }
  @Action
  async fetchPoll({ pollId, hideLoading }: { pollId: string; hideLoading?: boolean }) {
    if (!hideLoading) {
      this.ON_POLL_LOADING(true)
    }
    try {
      const response = await pollAPI.getPollById(pollId)
      this.fetchPollList()
      return new PollModel(response)
    } finally {
      this.ON_POLL_LOADING(false)
    }
  }
  @Action
  async deletePoll(pollId) {
    this.ON_POLL_LOADING(true)
    try {
      await pollAPI.deletePoll(pollId)
      this.fetchPollList()
      this.ON_DELETE_POLL(pollId)
    } finally {
      this.ON_POLL_LOADING(false)
    }
  }
  @Action
  getPollByPollId(pollId: string): PollModel {
    const polls: PollModel[] = this.getPollModelList
    // @ts-expect-error TS2322
    return polls.find(s => s.id === pollId)
  }
  @Mutation
  ON_POLL_LIST(pollResponse: pollAPI.PollList) {
    this.limit = pollResponse.limit
    this.nextToken = pollResponse.nextToken || null
    this.pollModelList = pollResponse.groupPolls.map(p => new PollModel(p))
  }
  @Mutation
  ON_CONFIRMED_POLL_LIST(pollResponse: pollAPI.PollList) {
    const newList = [...this.confirmedPollModelList, ...pollResponse.groupPolls.map(p => new PollModel(p))]
    this.confirmedPollModelList = uniqBy(newList, 'id')
  }
  @Mutation
  ON_ADD_UPDATE_POLL(poll: PollModel) {
    const findIndex = this.pollModelList.findIndex(s => s.id === poll.id)
    if (findIndex >= 0) {
      Vue.set(this.pollModelList, findIndex, poll) // make reactive
    } else {
      this.pollModelList.push(poll)
    }
  }
  @Mutation
  ON_DELETE_POLL(pollId: string) {
    const findIndex = this.pollModelList.findIndex(s => s.id === pollId)
    if (findIndex >= 0) {
      this.pollModelList.splice(findIndex, 1)
    }
  }
  @Mutation
  ON_POLL_LOADING(loading: boolean) {
    this.isPollListLoading = loading
  }
  @Mutation
  ON_CONFIRMED_POLL_LOADING(loading: boolean) {
    this.isConfirmedPollListLoading = loading
  }
  @Mutation
  SET_POLL_LIST(groupPolls: IPoll[]) {
    this.pollModelList = groupPolls.map(p => new PollModel(p))
  }

  @Mutation
  RESET_STATE() {
    this.pollModelList = []
    this.confirmedPollModelList = []
    this.isPollListLoading = false
    this.isConfirmedPollListLoading = false
    this.nextToken = null
  }
}

export default getModule(PollList)
