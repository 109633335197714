<template>
  <div>
    <template v-for="key in orderedForms">
      <component
        v-if="propertyByKey(key)"
        :key="key"
        :value="formDataSynced[key]"
        :is="formType(key)"
        :label="propertyByKey(key).description"
        :dscription="propertyByKey(key).description"
        :icon="propertyByKey(key).icon"
        :required="isRequired(key)"
        @input="newValue => updatedProperty(key, newValue)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import TextArea from '@/components/newForm/confirmationForms/TextArea.vue'
import { ConfirmationForm } from '@/models/data/availability'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component({
  components: {
    TextArea
  }
})
export default class ConfirmationForms extends Vue {
  @PropSync('formData', { type: Object }) formDataSynced!: any
  // @ts-expect-error TS2564
  @Prop() confirmationForm: ConfirmationForm

  get orderedForms() {
    return this.confirmationForm['x-spir-properties-order']
  }
  propertyByKey(key) {
    return this.confirmationForm.properties[key]
  }
  formType(key) {
    const property = this.confirmationForm.properties[key]
    if (!property) {
      return null
    }
    switch (property.widget) {
      case 'textarea':
        return TextArea
      default:
        return TextArea
    }
  }
  isRequired(key) {
    return this.confirmationForm.required.indexOf(key) >= 0
  }
  formDataByKey(key) {
    return this.formDataSynced[key]
  }
  updatedProperty(key, newValue) {
    this.$set(this.formDataSynced, key, newValue) // make reactive
  }
}
</script>

<style scoped></style>
