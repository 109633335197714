type UpdateTitleSuggestion = {
  newTitle: string
  titles: string[]
  threshold?: number
}
function updateTitlesWithThreshold({ newTitle, titles, threshold = 5 }: UpdateTitleSuggestion): string[] {
  const currentTitles = new Set(titles)
  if (currentTitles.has(newTitle)) {
    return titles
  }
  const newTitles = titles.length >= threshold ? titles.slice(1, threshold) : titles
  newTitles.push(newTitle)
  return newTitles
}

function isArrayItem(item: string): boolean {
  // @ts-expect-error TS2322
  return item && item.length >= 2 && item[0] === '[' && item[item.length - 1] === ']'
}

class TitleSuggestionLocalStorageAccess {
  static load(key: string): string[] {
    const item = window.localStorage.getItem(key)
    // @ts-expect-error TS2345
    return isArrayItem(item) ? JSON.parse(item) : []
  }
  static save(key: string, props: UpdateTitleSuggestion): string[] {
    const newSuggestions = updateTitlesWithThreshold(props)
    window.localStorage.setItem(key, JSON.stringify(newSuggestions))
    return newSuggestions
  }
}

class BaseTitleSuggestionLocalStorage {
  #suggestions: string[]
  #key: string
  constructor(key: string) {
    this.#key = key
    this.#suggestions = TitleSuggestionLocalStorageAccess.load(this.#key)
  }
  get suggestions(): string[] {
    return this.#suggestions
  }
  save(title: string) {
    const newSuggestions = TitleSuggestionLocalStorageAccess.save(this.#key, {
      newTitle: title,
      titles: this.#suggestions
    })
    this.#suggestions = newSuggestions
  }
}

class MultiTitleSuggestionLocalStorageAccess {
  static load(key: string): { [id: string]: string[] } {
    const item = window.localStorage.getItem(key)
    try {
      // @ts-expect-error TS2345
      return JSON.parse(item) || {}
    } catch (e) {
      return {}
    }
  }
  static save(
    key: string,
    { id, title, groups }: { id: string; title: string; groups: { [id: string]: string[] } }
  ): string[] {
    const currentTitles = groups[id] || []
    const newSuggestions = updateTitlesWithThreshold({ newTitle: title, titles: currentTitles })
    const newGroups = { ...groups, [id]: newSuggestions }
    window.localStorage.setItem(key, JSON.stringify(newGroups))
    return newSuggestions
  }
}
class BaseMultiTitleSuggestionLocalStorage {
  #groups: { [id: string]: string[] }
  #key: string
  constructor(key: string) {
    this.#key = key
    this.#groups = MultiTitleSuggestionLocalStorageAccess.load(this.#key)
  }
  get(id: string): string[] {
    return this.#groups[id] || []
  }
  save({ id, title }: { id: string; title: string }) {
    const newSuggestions = MultiTitleSuggestionLocalStorageAccess.save(this.#key, { id, title, groups: this.#groups })
    this.#groups[id] = newSuggestions
  }
}

export class TitleSuggestionsLocalStorage {
  #personalSchedule: BaseTitleSuggestionLocalStorage
  #personalPoll: BaseTitleSuggestionLocalStorage
  #teamSchedules: BaseMultiTitleSuggestionLocalStorage
  constructor() {
    this.#personalSchedule = new BaseTitleSuggestionLocalStorage('PRIVATE_SCHEDULE_TITLE_SUGGESTIONS')
    this.#personalPoll = new BaseTitleSuggestionLocalStorage('PRIVATE_POLL_TITLE_SUGGESTIONS')
    this.#teamSchedules = new BaseMultiTitleSuggestionLocalStorage('TEAM_SCHEDULE_TITLE_SUGGESTIONS')
  }
  get privateScheduleTitleSuggestions(): string[] {
    return this.#personalSchedule.suggestions
  }
  get privatePollTitleSuggestions(): string[] {
    return this.#personalPoll.suggestions
  }
  saveToPrivateScheduleTitleSuggestion(title: string) {
    this.#personalSchedule.save(title)
  }
  saveToPrivatePollTitleSuggestion(title: string) {
    this.#personalPoll.save(title)
  }
  getTeamScheduleTitleSuggestions(id: string): string[] {
    return this.#teamSchedules.get(id)
  }
  saveToTeamScheduleTitleSuggestion({ id, title }: { id: string; title: string }) {
    this.#teamSchedules.save({ id, title })
  }
}

export class AvailabilitySharingTitleSuggestionsLocalStorage {
  #personalPrivateTitle: BaseTitleSuggestionLocalStorage
  #personalCandidateTitle: BaseTitleSuggestionLocalStorage
  #teamPrivateTitles: BaseMultiTitleSuggestionLocalStorage
  #teamCandidateTitles: BaseMultiTitleSuggestionLocalStorage
  constructor() {
    this.#personalPrivateTitle = new BaseTitleSuggestionLocalStorage(
      'PRIVATE_AVAILABILITY_SHARING_PRIVATE_TITLE_SUGGESTIONS'
    )
    this.#personalCandidateTitle = new BaseTitleSuggestionLocalStorage(
      'PRIVATE_AVAILABILITY_SHARING_CANDIDATE_TITLE_SUGGESTIONS'
    )
    this.#teamPrivateTitles = new BaseMultiTitleSuggestionLocalStorage(
      'TEAM_AVAILABILITY_SHARING_PRIVATE_TITLE_SUGGESTIONS'
    )
    this.#teamCandidateTitles = new BaseMultiTitleSuggestionLocalStorage(
      'TEAM_AVAILABILITY_SHARING_CANDIDATE_TITLE_SUGGESTIONS'
    )
  }
  get personalPrivateTitleSuggestions(): string[] {
    return this.#personalPrivateTitle.suggestions
  }
  get personalCandidateTitleSuggestions(): string[] {
    return this.#personalCandidateTitle.suggestions
  }
  saveToPersonalPrivateTitle(title: string) {
    this.#personalPrivateTitle.save(title)
  }
  saveToPersonalCandidateTitle(title: string) {
    this.#personalCandidateTitle.save(title)
  }
  getTeamPrivateTitleSuggestions(id: string): string[] {
    return this.#teamPrivateTitles.get(id)
  }
  getTeamCandidateTitleSuggestions(id: string): string[] {
    return this.#teamCandidateTitles.get(id)
  }
  saveToTeamPrivateTitle({ id, title }: { id: string; title: string }) {
    this.#teamPrivateTitles.save({ id, title })
  }
  saveToTeamCandidateTitle({ id, title }: { id: string; title: string }) {
    this.#teamCandidateTitles.save({ id, title })
  }
}
