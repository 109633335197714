<template>
  <div class="calendar-header" :class="{ 'has-event': hasEvent }">
    <b-icon v-if="type" :icon="icon" custom-size="mdi-24px" slot="trigger" />
    <div class="header-title-wrapper">
      <p class="title-text">{{ title }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { CalendarType } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class DailyViewCalendarHeader extends Vue {
  // @ts-expect-error TS2564
  @Prop() title: string
  // @ts-expect-error TS2564
  @Prop() type: CalendarType // todo
  // @ts-expect-error TS2564
  @Prop({ default: false }) hasEvent: boolean
  get icon() {
    if (this.type === 'normal') {
      return 'account-outline'
    }
    return 'office-building'
  }
}
</script>

<style scoped lang="scss">
.calendar-header {
  min-width: 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border-bottom: 1px solid #eeeeee;
  .icon {
    margin-right: 5px;
  }
  .header-title-wrapper {
    min-width: 0;
    .title-text {
      @include long_text_truncation;
    }
  }
  &.has-event {
    .header-title-wrapper {
      width: 30px;
      height: 24px;
      background: $spir2_primaryBlue;
      border-radius: 12px;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
