import { PreVoteGroupPollOverview } from '@/models'
import { PollModel } from '@/models/data'

export const FromPollModel = {
  convertToPreVoteGroupPollOverview(poll: PollModel): PreVoteGroupPollOverview {
    return {
      title: poll.title,
      description: poll.description,
      location: poll.location,
      onlineMeeting: poll.onlineMeeting
    }
  }
}
