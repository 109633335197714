import {
  TeamAvailabilitySharingListResponseItem,
  TeamTeamIdAvailabilitySharingsPostRequest,
  TeamTeamIdAvailabilitySharingsPostResponse,
  TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdPatchRequest,
  TeamsTeamIdAvailabilitySharingsGetResponseItem
} from '@spirinc/contracts'
import axiosInstance from '../axios'

export const create = async (
  teamId: string,
  availability: TeamTeamIdAvailabilitySharingsPostRequest
): Promise<TeamTeamIdAvailabilitySharingsPostResponse> => {
  return (await axiosInstance.post(`teams/${teamId}/availability-sharings`, availability)).data
}

export const getList = async (
  teamId: string,
  query?: string
): Promise<{
  limit: number
  nextToken?: string
  availabilitySharings: TeamAvailabilitySharingListResponseItem[]
}> => {
  if (query) {
    return (await axiosInstance.get(`teams/${teamId}/availability-sharings?${query}`)).data
  }
  return (await axiosInstance.get(`teams/${teamId}/availability-sharings`)).data
}
export const deletePattern = (teamId: string, id: string) => {
  return axiosInstance.delete(`teams/${teamId}/availability-sharings/${id}`)
}
export const updatePattern = async (
  teamId: string,
  id: string,
  payload: Partial<TeamsTeamIdAvailabilitySharingsAvailabilitySharingIdPatchRequest>
) => {
  return (await axiosInstance.patch(`teams/${teamId}/availability-sharings/${id}`, payload)).data
}

export const getAvailability = async (
  teamId: string,
  id: string
): Promise<TeamsTeamIdAvailabilitySharingsGetResponseItem> => {
  return (await axiosInstance.get(`teams/${teamId}/availability-sharings/${id}`)).data
}
