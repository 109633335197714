<template>
  <div>
    <ProfileHeader
      :id="profile.id"
      :fullName="profile.fullName"
      :headLine="profile.headLine"
      :photoURL.sync="profile.photoURL"
      :backgroundPhotoURL.sync="profile.backgroundPhotoURL"
    />

    <ProfileBasicViewer class="wrapper" :profile="profile" />
  </div>
</template>

<script lang="ts">
import ProfileBasicViewer from '@/components/profile/ProfileBasicViewer.vue'
import ProfileHeader from '@/components/profile/ProfileHeader.vue'
import { IProfileBasic } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    ProfileHeader,
    ProfileBasicViewer
  }
})
export default class ProfileContent extends Vue {
  // @ts-expect-error TS2564
  @Prop() profile: IProfileBasic
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .wrapper {
    padding: 10px 15px;
  }
}
</style>
