<template>
  <vue-draggable-resizable
    v-if="render"
    axis="y"
    class-name="new-event"
    :z="30"
    :x="0"
    :w="parentWidth"
    :y="startPixcel"
    :h="height"
    :prevent-deactivation="true"
    :active="false"
    :resizable="false"
    :draggable="draggable"
    :grid="[1, oneSlotPixel]"
    @dragstop="onDragstop"
  >
    <p>{{ formatTime }}</p>
  </vue-draggable-resizable>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { spirDateFormat, spirDateFormatTypes } from '@/lib/utils/dateFormat'
import { startOfDay } from '@/lib/utils/timezone'
import { DEFAULT_SLOT_DURATION } from '@/types/constants'
import { addMinutes, differenceInMinutes } from 'date-fns'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class NewEvent extends Vue {
  // @ts-expect-error TS2564
  @Prop() parentHeight: number
  // @ts-expect-error TS2564
  @Prop() parentWidth: number
  // @ts-expect-error TS2564
  @Prop() oneSlotPixel: number
  // @ts-expect-error TS2564
  @Prop({ default: false }) draggable: boolean
  // @ts-expect-error TS2564
  @Prop() event: {
    initTime: Date
    start: Date
    end: Date
    dragStartY: number
  }

  render = true // in order to revert

  @Watch('event')
  resetComponent() {
    this.refresh()
  }

  get height() {
    const diff = differenceInMinutes(this.event.end, this.event.start)
    return Math.round((diff / DEFAULT_SLOT_DURATION) * this.oneSlotPixel)
  }
  get formatTime() {
    return `${spirDateFormat(this.event.start, spirDateFormatTypes.hourMin)} - ${spirDateFormat(
      this.event.end,
      spirDateFormatTypes.hourMin
    )}`
  }
  get startPixcel() {
    const diffMinFromStartDay = differenceInMinutes(this.event.start, startOfDay(this.event.start))
    return Math.round((diffMinFromStartDay / DEFAULT_SLOT_DURATION) * this.oneSlotPixel)
  }
  refresh() {
    this.render = false
    this.$nextTick(() => {
      this.render = true
    })
  }
  onDragstop(left, top) {
    const topOffset = top - this.startPixcel
    if (topOffset === 0) {
      return
    }
    // when draged to above of top
    if (top < 0) {
      return
    }
    const unitCount = Math.round(topOffset / this.oneSlotPixel)
    const startDate = addMinutes(this.event.start, unitCount * DEFAULT_SLOT_DURATION)
    const endDate = addMinutes(this.event.end, unitCount * DEFAULT_SLOT_DURATION)
    EventBus.emit(EVENTS.UPDATE_EVENT, {
      event: {
        ...this.event,
        start: startDate,
        end: endDate
      },
      revert: () => {
        // this.setStartPixel()
        this.render = false
        this.refresh()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.new-event {
  width: 100%;
  position: absolute;
  background-color: #194e7c;
  z-index: 10;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
  color: white;
}
</style>
