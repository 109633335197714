<template>
  <span class="status-chip">{{ $t('forms.event.deletedMark') }}</span>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'EventDeletedStatusChip'
})
</script>

<style scoped lang="scss">
.status-chip {
  height: 24px;
  border-radius: 9999px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  @include font_12_700;
  background-color: $spir2_cautionLightRed;
  color: $spir2_cautionRed;
  border: 1px solid $spir2_cautionRedHover;
}
</style>
