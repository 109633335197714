<template>
  <div class="copy-card">
    <slot />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CopyBoxBodyFrame extends Vue {}
</script>

<style scoped lang="scss">
.copy-card {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  margin-bottom: 24px;
}
</style>
