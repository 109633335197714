<template>
  <div class="daily-view-time-axis" ref="axisLabels">
    <div class="day-label">
      <div class="day-label__weekday" v-if="isDisplayWeekDay">
        <span class="week-day">{{ weekDay }}</span>
        <span class="day-number" :class="{ today: amIToday }">{{ dayNumber }}</span>
      </div>
    </div>
    <Item v-for="(n, i) in 24" :key="n" :dateToShow="dateToShow(i)" :gridHeight="gridHeight"></Item>
  </div>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { spirDateFormatCustom, spirDateFormatCustomTypes } from '@/lib/utils/dateFormat'
import { isToday } from '@/lib/utils/timezone'
import { addHours } from 'date-fns'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Item from './Item.vue'

@Component({
  components: {
    Item
  }
})
export default class TimeAxis extends Vue {
  // @ts-expect-error TS2564
  @Prop() gridHeight: string
  // @ts-expect-error TS2564
  @Prop() eventDate: Date
  // @ts-expect-error TS2564
  @Prop() isDisplayWeekDay: boolean

  // Memo: I couldn't make scrollable element without below code..
  mounted() {
    EventBus.on(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  beforeDestroy() {
    EventBus.off(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  get amIToday() {
    return isToday(this.eventDate)
  }
  get weekDay() {
    return spirDateFormatCustom(this.eventDate, spirDateFormatCustomTypes.ccc)
  }
  get dayNumber() {
    return spirDateFormatCustom(this.eventDate, spirDateFormatCustomTypes.d)
  }
  syncScroll(srcElement) {
    const headerLables = this.$refs.axisLabels as HTMLElement
    if (headerLables) {
      headerLables.scrollTo({
        top: srcElement.scrollTop
      })
    }
  }
  dateToShow(index: number) {
    return addHours(this.eventDate, index)
  }
}
</script>

<style scoped lang="scss">
.daily-view-time-axis {
  min-width: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  text-align: right;
  flex-shrink: 0;
}

.day-label {
  height: 45px;
  border-bottom: 1px solid #eeeeee;
  flex-shrink: 0;
  position: sticky;
  background: white;
  z-index: 1;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__weekday {
    width: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .week-day {
      font-size: 12px;
    }
    .day-number {
      font-size: 20px;
      font-weight: 700;
      line-height: 18px;
      color: $spir2_black;
      padding: 2px 6px;
      &.today {
        background: $spir2_primaryBlue;
        border-radius: 12px;
        color: white;
      }
    }
  }
}
</style>
