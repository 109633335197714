<template>
  <div class="pagehead-frame">
    <div class="pagehead-contents">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/composition-api'

export default defineComponent({})
</script>

<style scoped lang="scss">
.pagehead-frame {
  position: relative;
  height: 64px;
  padding: 0 16px;
  width: 100%;
  display: flex;
  align-items: center;
  .pagehead-contents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
