import { updateVote, vote } from '@/effects/groupPoll'
import i18n from '@/i18n'
import { GroupPollVotingRequester } from '@/models'
import store from '@/store'
import { FullCalendarEvent, ScheduleSource } from '@/types'
import { CandidateVote, PollAnswer } from '@/types/poll'
import { isBefore, parseISO } from 'date-fns'
import { sortBy } from 'lodash'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import EditPollModule from './editPoll'
import UserModule from './user'

export type CandidateForVote = CandidateVote & {
  yesCount: number
  noCount: number
  isDisabled: boolean
}
export type VoteStatusProps = Pick<CandidateForVote, 'votes' | 'yesCount' | 'noCount'>

export type IVote = {
  candidateId: string
  answer: PollAnswer
}

const toggleAnswer = (answer: PollAnswer): PollAnswer => {
  if (answer === 'yes') {
    return 'no'
  }
  return 'yes'
}

const MODULE_NAME = 'Vote'

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Vote extends VuexModule {
  private votes: IVote[] = []
  private isLoading = false
  private maxYesCount = 0
  private currentVotes: CandidateForVote[] = []

  get isAllAnswerNo() {
    return !this.votes.some(v => v.answer === 'yes')
  }
  get getVotes() {
    return this.votes
  }
  get myVotes() {
    return this.votes
  }
  get getIsLoading() {
    return this.isLoading
  }
  get getCandidatesAsCalendarFormat(): FullCalendarEvent[] {
    return EditPollModule.getEditingEventByCalendarFormat.map(event => {
      const myAnswer = this.votes.find(v => v.candidateId === event.id)
      const source: ScheduleSource =
        // @ts-expect-error TS2532
        event.extendedProps.source === 'expiredPoll'
          ? 'expiredPoll'
          : myAnswer && myAnswer.answer === 'yes'
            ? 'pollAnswerYes'
            : 'pollAnswerNo'
      return {
        ...event,
        title: i18n.t('groupPoll.candidate').toString(),
        extendedProps: { ...event.extendedProps, source, editable: false },
        editable: false
      }
    })
  }
  get getCurrentVotes() {
    return this.currentVotes
  }
  get getMaxYesCount() {
    return this.maxYesCount
  }
  @Action
  initVotes() {
    const myId = UserModule.currentUser?.uid
    // @ts-expect-error TS2532
    const didIVote = myId ? EditPollModule.editingPoll.attendees.find(a => a.id === myId) : false
    const myVotes: IVote[] = []
    let maxYesCount = 0 // Yesが一番多いところに色を変えるために必要
    // @ts-expect-error TS2322
    const currentVotes: CandidateForVote[] = sortBy(EditPollModule.getExistCandidates, ['start']).map(
      (c: CandidateVote) => {
        myVotes.push({
          // @ts-expect-error TS2322
          candidateId: c.id,
          // @ts-expect-error TS2532
          answer: didIVote ? c.votes.find(v => v.attendee.id === myId).answer : 'no'
        })
        // @ts-expect-error TS2532
        const yesCount = c.votes.filter(v => v.answer === 'yes').length
        // @ts-expect-error TS2532
        const noCount = c.votes.filter(v => v.answer === 'no').length
        if (yesCount > maxYesCount) {
          maxYesCount = yesCount
        }
        return {
          id: c.id,
          start: parseISO(c.start),
          end: parseISO(c.end),
          yesCount,
          noCount,
          votes: c.votes,
          isDisabled: isBefore(parseISO(c.start), new Date())
        }
      }
    )
    this.SET_VOTES(myVotes)
    this.SET_CURRENT_VOTES(currentVotes)
    this.SET_MAX_YES_COUNT(maxYesCount)
  }
  @Action
  updateAnswer({ candidateId, newAnswer }: { candidateId: string; newAnswer?: PollAnswer }) {
    const voteIndexByCandidateId = this.votes.findIndex(v => v.candidateId === candidateId)
    if (voteIndexByCandidateId >= 0) {
      const newVotes = [...this.votes]
      newVotes[voteIndexByCandidateId].answer = newAnswer
        ? newAnswer
        : toggleAnswer(newVotes[voteIndexByCandidateId].answer)
      this.SET_VOTES(newVotes)
    }
  }
  @Action
  async vote({ voter, requestVoting }: { voter: GroupPollVotingRequester; requestVoting: typeof vote }) {
    this.SET_LOADING(true)
    try {
      const pollId = EditPollModule.editingPoll.id
      // @ts-expect-error TS2322
      await requestVoting({ pollId, voter, votes: this.getVotes })
      // @ts-expect-error TS2322
      EditPollModule.setPollAsEditingPoll({ pollId })
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Action
  async updateVote({
    voter,
    requestVoteUpdate
  }: {
    voter: GroupPollVotingRequester
    requestVoteUpdate: typeof updateVote
  }) {
    this.SET_LOADING(true)
    try {
      const pollId = EditPollModule.editingPoll.id
      // @ts-expect-error TS2322
      await requestVoteUpdate({ pollId, voter: voter, votes: this.getVotes })
      // @ts-expect-error TS2322
      EditPollModule.setPollAsEditingPoll({ pollId: pollId })
    } finally {
      this.SET_LOADING(false)
    }
  }
  @Mutation
  SET_VOTES(votes) {
    this.votes = votes
  }
  @Mutation
  SET_LOADING(flag: boolean) {
    this.isLoading = flag
  }
  @Mutation
  SET_CURRENT_VOTES(currentVotes: CandidateForVote[]) {
    this.currentVotes = currentVotes
  }
  @Mutation
  SET_MAX_YES_COUNT(maxCount: number) {
    this.maxYesCount = maxCount
  }
}

export default getModule(Vote)
