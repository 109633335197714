import { render, staticRenderFns } from "./AuthAddCalendarModal.vue?vue&type=template&id=3794d3b4&scoped=true&"
import script from "./AuthAddCalendarModal.vue?vue&type=script&lang=ts&"
export * from "./AuthAddCalendarModal.vue?vue&type=script&lang=ts&"
import style0 from "./AuthAddCalendarModal.vue?vue&type=style&index=0&id=3794d3b4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_@vue+compiler-sfc@3.2.39_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_tokc7palmetupskgeg32hwtjve/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3794d3b4",
  null
  
)

export default component.exports