import { getDefaultSelectedCalendar } from '@/lib/utils'
import { LocalStorage } from '@/models/localStorage/localStorage'
import CalendarModule from '@/store/modules/calendars'

const LOCAL_STORAGE_KEY_CONFIRM = 'LOCAL_STORAGE_KEY_CONFIRM'

class LocalStorageForConfirm extends LocalStorage {
  constructor() {
    super(LOCAL_STORAGE_KEY_CONFIRM, ['accountId', 'calendarId'])
  }
}

const localStorage = new LocalStorageForConfirm()

export type SelectedCalendar = {
  accountId: string
  calendarId: string
}
export const defaultAccountAndCalendarId = (): SelectedCalendar => {
  const savedIds = localStorage.loadFromLocalStorage()
  const targetCalendars = CalendarModule.primaryCalendars
  return getDefaultSelectedCalendar(targetCalendars, savedIds)
}
export const saveSelectedCalendar = (selectedIds: SelectedCalendar) => {
  localStorage.saveToLocalStorage(selectedIds)
}
