import { Attendee, FullCalendarEvent } from '@/types'
import {
  PrioritizedOnlineMeetings,
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse
} from '@spirinc/contracts'
import { Mergeable } from './mergeable'

export type AttendeeForAvailability = {
  fullName: string
  photoURL?: string
  userId?: string
  accountId: string
  calendarId: string
  email: string
  organizer?: boolean
}

export const decodeAttendee = (attendee: AttendeeForAvailability): Attendee => {
  return {
    id: attendee.userId,
    fullName: attendee.fullName,
    photoURL: attendee.photoURL,
    email: attendee.email,
    organizer: attendee.organizer
  }
}

/**
 * @deprecated
 * use src/models/availabilitySharing.ts/PreConfirmAvailabilitySharing
 */
export type ConfirmAvailabilityModelData =
  PublicTeamsTeamIdAvailabilitySharingsAvailabilitySharingIdCandidatesGetResponse & {
    id: string
    teamId?: string
  }
export class ConfirmAvailabilityModel extends Mergeable {
  id?: string
  teamId?: string
  title: string
  duration: number
  location?: string
  description?: string
  start: string
  end: string
  // @ts-expect-error TS2564
  candidates: FullCalendarEvent[]
  candidateDescription?: string
  timeZone: string
  prioritizedOnlineMeetings: PrioritizedOnlineMeetings
  startTimeIncrements: number

  constructor(data: ConfirmAvailabilityModelData) {
    super(data.candidates)
    this.teamId = data.teamId
    this.id = data.id
    this.title = data.title || ''
    this.duration = data.duration || 30
    this.location = data.location
    this.description = data.description
    this.start = data.start
    this.end = data.end
    this.candidateDescription = data.candidateDescription
    this.prioritizedOnlineMeetings = data.prioritizedOnlineMeetings
    this.timeZone = data.timeZone
    this.startTimeIncrements = data.startTimeIncrements
  }

  get isTeamAvailability() {
    return !!this.teamId
  }
}
