import '@/plugins/v-calendar'
import router from '@/router'
import store from '@/store'
import UserModule from '@/store/modules/user'
import VueGtm from '@gtm-support/vue2-gtm'
import '@mdi/font/css/materialdesignicons.css'
import * as Sentry from '@sentry/vue'
import '@spirinc/react-pages/dist/style.css'
import { VueQueryPlugin } from '@tanstack/vue-query'
import VueCompositionAPI from '@vue/composition-api'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import IdleVue from 'idle-vue'
import 'intl-tel-input/build/css/intlTelInput.css'
import 'ress/dist/ress.min.css'
import Vue from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import FeatureFlipping from 'vue-feature-flipping'
import Storage from 'vue-ls'
import VueMeta from 'vue-meta'
import VShortKey from 'vue-shortkey'
import Vue2TouchEvents from 'vue2-touch-events'
import App from './App.vue'
import AppForLoading from './AppForLoading.vue'
import './filters'
import i18n from './i18n'
import { SignalType } from './lib/analytics'
import { AnalyticsRequestSender } from './lib/analytics/signal'
import firebaseInit from './lib/firebase'
import { mountHubspot } from './lib/hubspot'
import { queryClient } from './lib/queryClient'
import { extractTeamIdFromURL, shouldSendManyTraces } from './lib/sentry'
import './plugins/vee-validator'
import './registerServiceWorker'
import './styles/main.scss'

async function main() {
  Vue.use(VueMeta)

  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  })
  Vue.use(Storage)
  const eventsHub = new Vue()
  Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    idleTime: 1000 * 60 * 30, // 30min
    store
  })

  await firebaseInit.init()

  await mountHubspot()

  Vue.use(VueCompositionAPI)
  Vue.prototype.$analytics = new AnalyticsRequestSender()
  Vue.prototype.$signal = SignalType

  Vue.config.productionTip = false
  Vue.use(VShortKey, { prevent: ['input', 'textarea', '*[contenteditable="true"]'] })
  if (process.env.VUE_APP_MODE !== 'production') {
    Vue.config.devtools = true
  }
  Vue.use(Vue2TouchEvents, {
    disableClick: true,
    touchHoldTolerance: 500
  })
  Vue.use(Buefy, {
    defaultStatusIcon: false
  })
  Vue.component('vue-draggable-resizable', VueDraggableResizable)
  Vue.use(VueQueryPlugin, { queryClient })

  Vue.use(FeatureFlipping)
  if (process.env.VUE_APP_MODE !== 'local') {
    const apiUrl = process.env.VUE_APP_API_URL || ''

    Sentry.init({
      Vue: Vue,
      dsn: 'https://b83796c8d5e64b0cbaa1abc2d275fef4@o329011.ingest.sentry.io/1844005',
      trackComponents: true,
      tracePropagationTargets: [apiUrl],
      integrations: [
        Sentry.browserTracingIntegration({
          router,
          routeLabel: 'path',
          beforeStartSpan(context) {
            const teamId = extractTeamIdFromURL(new URL(location.href)) ?? undefined
            return { ...context, attributes: { ...context.attributes, 'team-id': teamId } }
          }
        }),
        Sentry.replayIntegration()
      ],
      environment: process.env.VUE_APP_MODE,
      tracesSampler(samplingContext) {
        const teamId = samplingContext.attributes?.['team-id']
        if (typeof teamId === 'string' && shouldSendManyTraces(teamId)) {
          return 0.5
        }
        return process.env.VUE_APP_MODE === 'production' ? 0.02 : 0.5
      },
      tracingOptions: {
        trackComponents: true
      },
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: process.env.VUE_APP_MODE === 'production' ? 0.01 : 0,
      replaysOnErrorSampleRate: process.env.VUE_APP_MODE === 'production' ? 1.0 : 0,
      ignoreErrors: [
        'Network Error',
        'Fetch client failed to connect to a network.',
        'Firebase: A network AuthError',
        'Firebase: Error (auth/network-request-failed)',
        'Request failed with status code 40', // ignore 40x errors
        'The database connection is closing.',
        'Avoided redundant navigation to current location',
        'Redirected when going from',
        'socket hang up',
        'Navigation cancelled from',
        'Navigation aborted from ',
        'Remote Config: The config fetch request timed out.',
        'read ECONNRESET',
        'AbortError'
      ],
      denyUrls: [
        /https:\/\/static\.cloudflareinsights\.com\/beacon\.min\.js/, // ignore TypeError: Illegal invocation
        /https:\/\/apis\.google\.com\/_\/scs\/abc-static\/_\/js/,
        /https:\/\/js\.hs-analytics\.net\/analytics/,
        /https:\/\/www\.googletagmanager\.com/,
        /https:\/\/js\.userflow\.com/
      ]
    })
  }

  new Vue({
    render: h => h(AppForLoading)
  }).$mount('#app')
  UserModule.checkRedirectFromSignIn().then(() => {
    UserModule.firebaseInit().then(() => {
      new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
      }).$mount('#app')
    })
  })
}

main()
