<template>
  <b-dropdown-item class="spir-dropdown-item" :value="value" :disabled="disabled" paddingless>
    <div class="item-container" :class="containerClass">
      <div class="check-status" v-if="selectedCheckMark">
        <b-icon class="check-icon" custom-size="mdi-18px" type="is-success" icon="check" />
      </div>
      <div class="item-container__body">
        <slot />
      </div>
    </div>
  </b-dropdown-item>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SpirDropdownItem',
  props: {
    value: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectedCheckMark: {
      type: Boolean,
      default: true
    },
    checkPosition: {
      type: String as PropType<'left' | 'right'>,
      default: 'left'
    }
  },
  setup(props) {
    const isLeftCheck = computed(() => props.selectedCheckMark && props.checkPosition === 'left')
    const isRightCheck = computed(() => props.selectedCheckMark && props.checkPosition === 'right')
    return {
      isRightCheck,
      containerClass: computed(() => ({
        'no-check-mark': !props.selectedCheckMark,
        'check-left': isLeftCheck.value,
        'check-right': isRightCheck.value
      }))
    }
  }
})
</script>
<style scoped lang="scss">
.item-container {
  display: flex;
  align-items: center;
  padding: 8px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &__body {
    flex: 1;
  }
  &.check-left {
    padding-left: 8px;
  }
  &.check-right {
    flex-direction: row-reverse;
    padding-right: 8px;
  }
  & > * {
    min-width: 0;
  }
  .check-status {
    flex-grow: 0;
    flex-shrink: 0;
    width: 30px;
    line-height: 1;
    text-align: center;
    .check-icon {
      display: none;
    }
  }
}

.spir-dropdown-item.is-active {
  background-color: initial;
  .item-container .check-status .check-icon {
    display: inline;
  }
}
</style>
<style lang="scss">
.item-container .check-status i.mdi:before {
  color: $spir2_black;
}
</style>
