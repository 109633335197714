import { Plan, TeamOverview } from '@/models/team'
import store from '@/store'
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators'
import ProfileModule from './profile'
import { TeamPlanStateModule } from './teamSubscriptionState'

const MODULE_NAME = 'Teams'

export interface IModuleTeam {
  _teams: TeamOverview[]
}

@Module({
  dynamic: true,
  name: MODULE_NAME,
  namespaced: true,
  store
})
class Team extends VuexModule {
  _teams: TeamOverview[] = []

  get myTeams(): TeamOverview[] {
    return this._teams
  }
  get myUsableTeams(): TeamOverview[] {
    return this.myTeams.filter(team => TeamPlanStateModule.usable(team.id))
  }
  get myLoadedUsableTeams(): TeamOverview[] {
    return this.myUsableTeams.filter(team => {
      const status = TeamPlanStateModule.checkUsableStatus(team.id)
      return status.status === 'loaded' && status.result
    })
  }
  get isLoading(): boolean {
    return ProfileModule.isLoading
  }
  get getMyTeamById(): (teamId: string) => TeamOverview | undefined {
    return (teamId: string) => {
      return this.myTeams.find(team => team.id === teamId)
    }
  }

  @Mutation
  SET_TEAMS(teams: TeamOverview[] = []) {
    this._teams = teams
  }

  @Action
  async setTeams({ teams = [] }: { teams: { id: string; name: string; plan: Plan }[] }) {
    this.SET_TEAMS(teams.map(team => team))

    teams.forEach(async team => {
      TeamPlanStateModule.save({ teamId: team.id, plan: team.plan })
    })
  }
}

export const TeamModule = getModule(Team)
