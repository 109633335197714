<template>
  <AuthFormLayout
    :title="title"
    :isNotSupportedBrowser="isNotSupportedForLogin"
    :messageForInAppBrowser="$t('description.useDefaultBrowser.signIn.messageHtml')"
  >
    <slot />
  </AuthFormLayout>
</template>
<script lang="ts">
import { AuthFormLayout } from '@/components/features/auth/AuthFormLayout'
import { isNotSupportedForLogin } from '@/lib/utils/functions'
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'AuthLoginFormLayout',
  components: {
    AuthFormLayout
  },
  props: {
    title: {
      type: String
    }
  },
  setup() {
    return {
      isNotSupportedForLogin
    }
  }
})
</script>
