<template>
  <CommonLayoutModal class="confirm-modal spir-form-container" :showCloseButton="false" @close="$emit('close')">
    <template v-slot:modalHeader>
      <slot name="modalHeader">
        <b v-if="header">{{ header }}</b>
      </slot>
    </template>
    <template v-slot:modalBody>
      <div class="body">
        <slot />
        <p v-if="body" v-html="body"></p>
      </div>
    </template>
    <template v-slot:modalFooter>
      <div class="is-pulled-right" :class="btnType">
        <Button :type="cancelButtonType" @click="handleCancel">
          {{ cancelButtonTitle }}
        </Button>
        <Button type="primary" :disabled="disabled" @click="handleConfirm">
          {{ confirmButtonTitle }}
        </Button>
      </div>
    </template>
  </CommonLayoutModal>
</template>

<script lang="ts">
import CommonLayoutModal from '@/components/modal/CommonLayout.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/components/ui/Button.vue'

@Component({
  components: {
    Button,
    CommonLayoutModal: () => Promise.resolve(CommonLayoutModal) // solving circular dependency
  }
})
export default class ConfirmModal extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: null }) header: string
  // @ts-expect-error TS2564
  @Prop() body: string
  // @ts-expect-error TS2564
  @Prop({ default: '' }) cancelBtn: string
  // @ts-expect-error TS2564
  @Prop({ default: '' }) cancelBtnType: string
  // @ts-expect-error TS2564
  @Prop({ default: '' }) confirmBtn: string
  // @ts-expect-error TS2564
  @Prop({ default: false }) disabled: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) closeWhenButtonClicked: boolean
  // @ts-expect-error TS2564
  @Prop({ default: 'default-type' }) btnType: 'is-danger' | 'default-type'

  get cancelButtonTitle() {
    return this.cancelBtn || this.$t('buttons.cancel').toString()
  }
  get confirmButtonTitle() {
    return this.confirmBtn || this.$t('buttons.confirm').toString()
  }
  get cancelButtonType() {
    return this.cancelBtnType || 'danger'
  }

  handleCancel() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('cancel')
  }
  handleConfirm() {
    if (this.closeWhenButtonClicked) {
      this.$emit('close')
    }
    this.$emit('confirm')
  }
}
</script>

<style scoped lang="scss">
.actions {
  display: flex;
}
</style>
