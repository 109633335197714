import { ERROR_CODE } from '@spirinc/message-catalog'
import { AxiosResponse } from 'axios'

export class APIError extends Error {
  status: number
  code?: ERROR_CODE
  error: string
  // @ts-expect-error TS2564
  message: string
  response?: AxiosResponse

  constructor(status, body: { code?: ERROR_CODE; error: string; message: string }, response?: AxiosResponse) {
    super(body.message)
    this.status = status
    this.code = body.code
    this.error = body.error
    this.response = response
  }
}

export class SubscriptionCanceledInConfirmError extends Error {}

export class SubscriptionCanceledInEditError extends Error {}
