<template>
  <div class="inline-timezone-selector field" :class="{ updateHeight: updateHeight }">
    <TimezoneCommon
      :timeZone="timeZone"
      size="small"
      @activeChanged="handleActiveChanged"
      @onChangeTimeZone="handleChangeTimeZone"
    >
      <template v-slot:selectorLabel>
        <SpirDropdownButton :active="false" size="small">
          <div class="selected-label">
            <div class="icon-frame">
              <b-icon v-if="!hideIcon" class="mr-0 ml-0" size="is-small" icon="earth" />
            </div>
            <TimezoneLabel :timezoneInfo="selectedTimeZone" size="small" />
          </div>
        </SpirDropdownButton>
      </template>
    </TimezoneCommon>
  </div>
</template>

<script lang="ts">
import TimezoneLabel from '@/components/features/timezones/TimezoneLabel/index.vue'
import SpirDropdownButton from '@/components/ui/spir/SpirDropdown/SpirDropdownButton.vue'
import TimeZoneModule, { TimezoneDetailWithTimeLabel } from '@/store/modules/timezones'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TimezoneCommon from './Common.vue'

@Component({
  components: {
    TimezoneCommon,
    TimezoneLabel,
    SpirDropdownButton
  }
})
export default class InlineTimezoneSelector extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: '' }) timeZone: string
  @Prop({ default: false }) hideIcon
  isDropdownActive = false

  get updateHeight() {
    return this.isDropdownActive
  }
  get selectedTimeZone(): TimezoneDetailWithTimeLabel {
    return TimeZoneModule.timezoneByKey({ key: this.timeZone })
  }
  handleChangeTimeZone(key: string) {
    this.$emit('onChangeTimeZone', key)
  }
  handleActiveChanged(dropdownStatus: boolean) {
    this.isDropdownActive = dropdownStatus
  }
}
</script>
<style lang="scss">
.inline-timezone-selector.field {
  input.input {
    padding-left: 10px;
  }
}
</style>
<style scoped lang="scss">
.inline-timezone-selector.field {
  width: 100%;
  transition: min-height 0.15s ease-out;
  box-shadow: none;
  margin: 0;
  &.updateHeight {
    min-height: 250px;
    transition: min-height 0.25s ease-in;
  }
  .selected-label {
    display: flex;
    align-items: center;
    min-width: 0;
  }
}
</style>
