<template>
  <ValidationObserver v-slot="{ invalid }">
    <ConfirmModal
      :disabled="invalid"
      :closeWhenButtonClicked="false"
      :cancelBtn="cancelBtn"
      @cancel="$emit('cancel')"
      @confirm="register"
      class="schedule-confirm-modal"
    >
      <template v-slot:modalHeader>
        <ShowConfirmedDate :formattedDate="formattedDate" />
      </template>
      <template v-if="isSignIn">
        <p class="select-calender-title">{{ $t('message.confirm.calendarSelectLabel') }}</p>
        <div class="mt-5 mb-2">
          <EmailOrCalendarSelect
            v-if="selectedCalendar"
            :showForm="!isOrganizer"
            :formattedDate="formattedDate"
            :email.sync="confirmDataSynced.email"
            :name.sync="confirmDataSynced.name"
            :selectedCalendar.sync="selectedCalendar"
          ></EmailOrCalendarSelect>
        </div>
      </template>
      <template v-else>
        <p class="select-calender-title">{{ $t('message.confirm.pleaseInputYourInfo') }}</p>
        <div class="mt-5 mb-2">
          <YourInfoForm @changeName="changeName" @changeEmail="changeEmail" />
        </div>
      </template>
      <slot></slot>
    </ConfirmModal>
  </ValidationObserver>
</template>

<script lang="ts">
import YourInfoForm from '@/components/forms/YourInfoForm.vue'
import ConfirmModal from '@/components/modal/Confirm.vue'
import CalendarsModule from '@/store/modules/calendars'
import { ValidationObserver } from 'vee-validate'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ConfirmData } from '../ConfirmSchedule.vue'
import {
  defaultAccountAndCalendarId,
  saveSelectedCalendar,
  SelectedCalendar
} from '../data/defaultAccountAndCalendarId'
import EmailOrCalendarSelect from './EmailOrCalendarSelect.vue'
import ShowConfirmedDate from './ShowConfirmedDate.vue'

@Component({
  components: {
    ConfirmModal,
    EmailOrCalendarSelect,
    YourInfoForm,
    ValidationObserver,
    ShowConfirmedDate
  }
})
export default class ConfirmScheduleModal extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: null }) formattedDate: string
  // @ts-expect-error TS2564
  @Prop() isOrganizer: boolean
  @Prop() header
  @Prop() isSignIn
  // @ts-expect-error TS2564
  @Prop({ default: false }) isVote: boolean
  // @ts-expect-error TS2564
  @Prop() cancelBtn: string
  @PropSync('confirmData') confirmDataSynced!: ConfirmData

  // @ts-expect-error TS2322
  selectedCalendar: SelectedCalendar = null

  mounted() {
    if (this.isSignIn) {
      this.selectedCalendar = defaultAccountAndCalendarId()
    }
  }
  changeName(newValue: string) {
    this.confirmDataSynced.name = newValue
  }
  changeEmail(newValue: string) {
    this.confirmDataSynced.email = newValue
  }
  register() {
    if (this.selectedCalendar) {
      saveSelectedCalendar(this.selectedCalendar)
      CalendarsModule.visibleCalendarIfNotVisible(this.selectedCalendar)
    }
    this.$emit('register')
    this.$emit('close')
  }
}
</script>

<style scoped lang="scss"></style>
