<template>
  <div class="daily-view">
    <b-loading :is-full-page="false" :active="isLoading"></b-loading>
    <Body
      :eventDate="eventDate"
      :gridHeight="gridHeight"
      :selectable="selectable"
      :columns="columns"
      :events="events"
      :isDisplayDay="isDisplayDay"
      :fullWidthEvents="fullWidthEvents"
      :isDisplayWeekDay="isDisplayWeekDay"
    />
  </div>
</template>

<script lang="ts">
import { EventBus, EVENTS } from '@/lib/eventBus'
import { addDays, startOfDay } from '@/lib/utils/timezone'
import { ResourceColumn, ResourceEvent } from '@/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Body from './body/Index.vue'

@Component({
  components: {
    Body
  }
})
export default class ResourceView extends Vue {
  // @ts-expect-error TS2564
  @Prop({ default: false }) isLoading: boolean
  // @ts-expect-error TS2564
  @Prop({ default: 52 }) gridHeight: number
  // @ts-expect-error TS2564
  @Prop({ default: true }) selectable: boolean
  // @ts-expect-error TS2564
  @Prop({ default: () => [] }) events: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop({ default: () => [] }) fullWidthEvents: ResourceEvent[]
  // @ts-expect-error TS2564
  @Prop() eventDate: Date
  // @ts-expect-error TS2564
  @Prop({ default: 0 }) lastScrollHeight: number
  // @ts-expect-error TS2564
  @Prop() columns: ResourceColumn[]
  // @ts-expect-error TS2564
  @Prop({ default: false }) hideAllday: boolean
  @Prop() timezoneButtonAsLabel
  // @ts-expect-error TS2564
  @Prop({ default: true }) isDisplayDay: boolean
  // @ts-expect-error TS2564
  @Prop({ default: true }) isDisplayWeekDay: boolean

  private created() {
    // @ts-expect-error TS2769
    EventBus.on(EVENTS.CLICKED_EVENT, this.clickEventListener)
    // @ts-expect-error TS2769
    EventBus.on(EVENTS.UPDATE_EVENT, this.updateEventListener)
    // @ts-expect-error TS2769
    EventBus.on(EVENTS.CREATE_EVENT, this.createEvent)
    EventBus.on(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
    this.$nextTick(() => {
      EventBus.emit(EVENTS.RESOURCE_TO_SCROLL, this.lastScrollHeight)
    })
  }
  beforeDestroy() {
    // @ts-expect-error TS2769
    EventBus.off(EVENTS.CLICKED_EVENT, this.clickEventListener)
    // @ts-expect-error TS2769
    EventBus.off(EVENTS.UPDATE_EVENT, this.updateEventListener)
    // @ts-expect-error TS2769
    EventBus.off(EVENTS.CREATE_EVENT, this.createEvent)
    EventBus.off(EVENTS.RESOURCE_VIEW_SCROLL, this.syncScroll)
  }
  clickEventListener({ event, jsEvent, el }) {
    return this.$emit('eventClick', { event, jsEvent, el })
  }
  updateEventListener({ event, jsEvent, el, revert }) {
    return this.$emit('updateEvent', { event, jsEvent, el, revert })
  }

  syncScroll(srcElement) {
    this.$emit('scrolled', srcElement.scrollTop)
  }
  createEvent({ columnKey, allDay, start, end, jsEvent }) {
    const payload = {
      columnKey,
      start,
      end,
      allDay,
      jsEvent
    }
    if (allDay) {
      payload.allDay = true
      payload.start = startOfDay(this.eventDate)
      payload.end = startOfDay(addDays(this.eventDate, 1))
    }
    this.$emit('createEvent', payload)
  }
}
</script>

<style scoped lang="scss">
.daily-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-overflow-scrolling: auto;
  padding: 16px;
  .daily-view-body {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
