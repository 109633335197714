<template>
  <div
    class="profile-images"
    :style="{
      backgroundImage: `url(${backgroundPhotoURLSynced})`
    }"
  >
    <div class="uploader-background">
      <ProfileImageUploader
        v-if="isProfileOwner && !isViewOnly"
        :sizeLimitKB="imageSizeLimitKB"
        :onFileChange="file => uploadImage('backgroundPhoto', file)"
      />
    </div>
    <div class="avater-wrapper">
      <figure class="image avatar">
        <img
          class="is-rounded"
          :src="photoURLSynced"
          :style="{
            'object-fit': 'cover',
            background: 'lightgrey',
            height: '5em',
            width: '5em'
          }"
        />
        <div class="uploader-avatar">
          <ProfileImageUploader
            v-if="isProfileOwner && !isViewOnly"
            :sizeLimitKB="imageSizeLimitKB"
            :onFileChange="file => uploadImage('photo', file)"
          />
        </div>
      </figure>
    </div>
    <div class="headline">
      <p class="name">
        {{ fullName }}
        <router-link class="more-cliclable-area" to="/profile-edit" v-if="isProfileOwner && isViewOnly">
          <b-icon icon="pencil" custom-size="mdi-18px" />
        </router-link>
      </p>
      <p class="company-info">{{ headLine }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { ProfileImageUploader } from '@/components/features/profiles/ProfileImageUploader'
import UserModule from '@/store/modules/user'
import { IProfileBasic } from '@/types'
import Compress from 'client-compress'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

type ImageType = 'photo' | 'backgroundPhoto'

type CompressOptions = {
  targetSize: number // in MB
  quality: number
  maxWidth: number
  maxHeight: number
}

const bucketNameMap: Record<ImageType, string> = {
  photo: 'photo',
  backgroundPhoto: 'backgroundPhoto'
}
const imageFieldNameMap: Record<ImageType, string> = {
  photo: 'photoURL',
  backgroundPhoto: 'backgroundPhotoURL'
}

const defaultBackgroundCompressOptions: CompressOptions = {
  targetSize: Infinity,
  quality: 0.75,
  maxWidth: 1200,
  maxHeight: 1000
}

const defaultAvatarCompressOptions: CompressOptions = {
  targetSize: Infinity,
  quality: 0.75,
  maxWidth: 250,
  maxHeight: 250
}

const defaultCompressOptions: Record<ImageType, CompressOptions> = {
  photo: defaultAvatarCompressOptions,
  backgroundPhoto: defaultBackgroundCompressOptions
}

@Component({
  components: {
    ProfileImageUploader
  }
})
export default class ProfileHeader extends Vue {
  get currentUser() {
    return UserModule.currentUser
  }

  @Prop({ default: true })
  // @ts-expect-error TS2564
  isViewOnly: boolean

  @Prop()
  // @ts-expect-error TS2564
  id: Pick<IProfileBasic, 'id'>

  @Prop()
  // @ts-expect-error TS2564
  fullName: Pick<IProfileBasic, 'fullName'>

  @Prop()
  // @ts-expect-error TS2564
  headLine: Pick<IProfileBasic, 'headLine'>

  @PropSync('photoURL', { type: String }) photoURLSynced!: string
  @PropSync('backgroundPhotoURL', { type: String })
  backgroundPhotoURLSynced!: string

  imageSizeLimitKB = 12000

  get isProfileOwner() {
    return this.currentUser && this.currentUser.uid === this.id
  }

  async uploadImage(imageType: ImageType, file: File) {
    const compress = new Compress(defaultCompressOptions[imageType])

    const fileName = file.name
    const bucketName = bucketNameMap[imageType]
    const imageFieldName = imageFieldNameMap[imageType]
    // @ts-expect-error TS2531
    const uid = this.currentUser.uid
    const storagePath = `users/${uid}/${bucketName}/${fileName}`

    const {
      photo: { data }
    } = (await compress.compress([file]))[0]

    const storage = getStorage()
    const storageRef = ref(storage, storagePath)
    const result = await uploadBytes(storageRef, data)
    const downloadURL = await getDownloadURL(result.ref)
    this[`${imageFieldName}Synced`] = downloadURL
  }
}
</script>

<style scoped lang="scss">
.profile-images {
  position: relative;
  background: lightgrey;
  background-position: center;
  background-size: cover;
}
.headline {
  color: white;
  text-align: left;
  font-weight: 700;
  min-height: 5em;
  padding: 1.1rem;
  background-color: rgba(0, 0, 0, 0.5);
  .name {
    font-size: 1.4rem;
    font-weight: 900;
  }
  .company-info {
    font-weight: 400;
    word-wrap: break-word;
  }
}

.background-photo {
  filter: opacity(60%);
}
.avater-wrapper {
  height: 15rem;
}
.avatar {
  position: absolute;
  top: 23%;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid white;
  border-radius: 50%;
}

.uploader-avatar {
  position: absolute;
  right: 0;
  bottom: 0;
}

.uploader-background {
  position: absolute;
  top: 0.5em;
  right: 0.75em;
}
.more-cliclable-area {
  display: inline-block;
  padding: 0.5rem;
  margin: -0.5em;
  position: relative;
  & > * {
    color: white;
  }
}
</style>
