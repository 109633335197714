<template>
  <div class="language-and-time-zone">
    <div class="form-frame">
      <FormItemTitle :title="$t('settings.localization.label')" />
      <FormItemSpacer spaceName="t8" />
      <LanguageDropdown v-model="currentLanguage" size="small" :isShortenSelectedValue="true" />
    </div>
    <div class="form-frame">
      <FormItemTitle :title="$t('settings.timeZone')" />
      <FormItemSpacer spaceName="t8" />
      <InlineTimeZoneSelector :timeZone="timeZone" @onChangeTimeZone="onTimeZoneChange" />
    </div>
  </div>
</template>

<script lang="ts">
import InlineTimeZoneSelector from '@/components/organisms/timezoneSelector/InlineTimezoneSelector.vue'
import LanguageDropdown from '@/components/ui/domain/dropdown/LanguageDropdown/index.vue'
import FormItemSpacer from '@/components/ui/form/FormItemSpacer.vue'
import FormItemTitle from '@/components/ui/form/FormItemTitle.vue'
import { FrontSupportLanguage } from '@/types'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'CopyBoxLanguageAndTimeZone',
  components: {
    LanguageDropdown,
    InlineTimeZoneSelector,
    FormItemTitle,
    FormItemSpacer
  },
  props: {
    timeZone: {
      type: String,
      required: true
    },
    language: {
      type: String as PropType<FrontSupportLanguage>,
      required: true
    },
    onTimeZoneChange: {
      type: Function as PropType<(newTZ: string) => void>,
      required: true
    },
    onLanguageUpdate: {
      type: Function as PropType<(lang: FrontSupportLanguage) => Promise<void>>,
      required: true
    }
  },
  setup(props) {
    const currentLanguage = computed({
      get: () => props.language,
      set: (l: FrontSupportLanguage) => props.onLanguageUpdate(l)
    })
    return {
      currentLanguage
    }
  }
})
</script>

<style scoped lang="scss">
.language-and-time-zone {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  .form-frame {
    min-width: 0;
    flex: 1;
  }
}
</style>
